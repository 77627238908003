import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { Component, Schema, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";
import { TreeSpawnerSchema } from "../enemy/TreeSpawner";
import { EnemyTypeSchema, PlayerTypeSchema } from "yage/components/entity/Types";

@Component("TreeSpawnerEnhancer")
export class TreeSpawnerEnhancerSchema extends TreeSpawnerSchema {}

class TreeSpawnerEnhancerSystem implements System {
  schema = TreeSpawnerEnhancerSchema;
  type = "TreeSpawnerEnhancer";
  dependencies = ["Child"];
  init(entity: number, gameModel: GameModel) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (gameModel.hasComponent(parent, "TreeSpawner")) {
        const enhancerData = gameModel.getTypedUnsafe(entity, TreeSpawnerEnhancerSchema);
        const treeSpawnerData = gameModel.getTypedUnsafe(parent, TreeSpawnerSchema);
        treeSpawnerData.treeStat += enhancerData.treeStat;
        console.log("ENHANCING", enhancerData.spawnTimer);

        if (enhancerData.spawnTimer !== -1) {
          treeSpawnerData.spawnTimer = enhancerData.spawnTimer;
        }
        if (enhancerData.spawnFrequency < treeSpawnerData.spawnFrequency) {
          treeSpawnerData.spawnFrequency = enhancerData.spawnFrequency;
          treeSpawnerData.spawnTimer = treeSpawnerData.spawnFrequency * 1000;
        }
      }
    }
  }

  cleanup(entity: number, gameModel: GameModel, ejecting: boolean) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (gameModel.hasComponent(parent, "TreeSpawner")) {
        const enhancerData = gameModel.getTypedUnsafe(entity, TreeSpawnerEnhancerSchema);
        const treeSpawnerData = gameModel.getTypedUnsafe(parent, TreeSpawnerSchema);
        treeSpawnerData.treeStat -= enhancerData.treeStat;
        if (enhancerData.spawnTimer !== -1) {
          treeSpawnerData.spawnTimer = 0;
        }
        if (enhancerData.spawnFrequency < treeSpawnerData.spawnFrequency) {
          treeSpawnerData.spawnFrequency = 0;
          treeSpawnerData.spawnTimer = 0;
        }
      }
    }
  }
}
registerSystem(TreeSpawnerEnhancerSystem);

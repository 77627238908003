import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { ComponentCategory, DamageTypeEnum } from "yage/constants/enums";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { StoreItemSchema } from "../pickups/StoreItem";
import { DamageStatsSchema, createDamageStats } from "../weapons/DamageStats";
import { takeDamage } from "../damage/Damageable";
import { DamageSchema } from "../../schema/damage/Damage";

@Component("ChanceDamageOnPickupInstance")
export class ChanceDamageOnPickupInstanceSchema extends Schema {
  @type("number")
  chance: number;

  @type("number")
  @defaultValue(0.25)
  chanceDamageScale: number;
}

@Component("ChanceDamageOnPickup")
export class ChanceDamageOnPickupSchema extends Schema {
  @type([ChanceDamageOnPickupInstanceSchema])
  @defaultValue([])
  chance: ChanceDamageOnPickupInstanceSchema[];

  @type(StoreItemSchema)
  pickupData: StoreItemSchema;
}

class ChanceDamageOnPickupSystem implements System {
  schema = ChanceDamageOnPickupSchema;
  type = "ChanceDamageOnPickup";
  category = ComponentCategory.ON_ADD_TO_INVENTORY;

  run(entity: number, gameModel: GameModel) {
    const chanceDamageOnPickup = gameModel.getTypedUnsafe(entity, ChanceDamageOnPickupSchema);
    if (chanceDamageOnPickup.pickupData.inventoryType === "coins") {
      const chance = gameModel.getTypedUnsafe(entity, DamageStatsSchema).chance;

      const enemies = gameModel.enemies;

      if (enemies.length === 0) {
        return;
      }
      const enemy = enemies[gameModel.rand.int(0, enemies.length - 1)];

      const damages: Partial<DamageSchema>[] = [];

      for (let i = 0; i < chanceDamageOnPickup.chance.length; ++i) {
        if (gameModel.rand.number() < chanceDamageOnPickup.chance[i].chance) {
          const damageStats = createDamageStats();
          const damage = Math.max(1, chance * chanceDamageOnPickup.chance[i].chanceDamageScale);
          damageStats.minDamage = damage;
          damageStats.maxDamage = damage;

          damages.push({
            frame: gameModel.timeElapsed,
            damage: damage,
            damageStats: damageStats,
            damageType: DamageTypeEnum.NORMAL,
          });
        }
      }
      if (damages.length > 0) {
        takeDamage(enemy, gameModel, damages);
      }
    }
  }
}

registerSystem(ChanceDamageOnPickupSystem);

import { System } from "yage/components/System";
import { ComponentCategory } from "yage/constants/enums";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { DamageStatsSchema } from "./DamageStats";
import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";

@Component("AttackCooldown")
export class AttackCooldownSchema extends Schema {
  @type("number")
  @defaultValue(100)
  randomCooldown: number;

  @type("number")
  @defaultValue(0)
  internalCooldown: number;

  @type("number")
  @defaultValue(0)
  internalRandomCooldown: number;

  @type("boolean")
  @defaultValue(false)
  ready: boolean;
}

class AttackCooldownSystem implements System {
  type = "AttackCooldown";
  category: ComponentCategory = ComponentCategory.BEHAVIOR;
  depth = DEPTHS.DAMAGE;
  schema = AttackCooldownSchema;
  dependencies = ["Attack"];

  run(entity: number, gameModel: GameModel) {
    const damageStats = gameModel.getTypedUnsafe(entity, DamageStatsSchema);
    const cooldownData = gameModel.getTypedUnsafe(entity, AttackCooldownSchema);

    if (damageStats.attackSpeedScale < -1) {
      cooldownData.ready = false;
      return;
    }

    if (cooldownData.ready) {
      cooldownData.internalRandomCooldown = gameModel.rand.int(0, cooldownData.randomCooldown);
      return;
    }
    cooldownData.internalCooldown += gameModel.dt<number>(entity);

    const speed = damageStats.attackSpeed / Math.max(1 + damageStats.attackSpeedScale, 0.01);

    if (cooldownData.internalCooldown > cooldownData.internalRandomCooldown + speed * 1000) {
      cooldownData.ready = true;
      cooldownData.internalCooldown = 0;
    }
  }
}
registerSystem(AttackCooldownSystem);

import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { ComponentCategory, ComponentDataSchema } from "yage/components/types";
import { Component, Schema, defaultValue, required, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { ShopSchema } from "../core/Shop";
import { ChildSchema } from "yage/schemas/entity/Child";
import { WeaponTypeSchema } from "yage/components/entity/Types";
import { WeaponContainerSchema } from "../weapons/WeaponContainer";
import { EnemyTypeSchema, PlayerTypeSchema } from "yage/components/entity/Types";

@Component("WeaponTagEnhancer")
export class WeaponTagEnhancerSchema extends Schema {
  @type("string")
  @required()
  tag: string;

  @type([ComponentDataSchema])
  @defaultValue([])
  components: ComponentDataSchema[];
}

class WeaponTagEnhancerSystem implements System {
  schema = WeaponTagEnhancerSchema;
  type = "WeaponTagEnhancer";

  dependencies = ["Child", "Shop"];

  category = ComponentCategory.WEAPON_AUGMENT;

  init(entity: number, gameModel: GameModel) {
    const enhancer = gameModel.getTypedUnsafe(entity, WeaponTagEnhancerSchema);

    if (!gameModel.hasComponent(entity, WeaponTypeSchema)) {
      if (
        !gameModel.hasComponent(entity, PlayerTypeSchema) &&
        !gameModel.hasComponent(entity, EnemyTypeSchema) &&
        gameModel.hasComponent(entity, ChildSchema)
      ) {
        const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent;
        if (parent && gameModel.hasComponent(parent, WeaponContainerSchema)) {
          const weaponContainer = gameModel.getTypedUnsafe(parent, WeaponContainerSchema);
          weaponContainer.weaponIds.forEach((weaponId) => {
            gameModel.addComponent(weaponId, WeaponTagEnhancerSchema, enhancer);
          });
        }
      }

      return;
    }
    const shop = gameModel.getTypedUnsafe(entity, ShopSchema);

    if (shop.tags.includes(enhancer.tag)) {
      const enhancerEntity = gameModel.addEntity();

      gameModel.addComponent(enhancerEntity, ChildSchema, {
        parent: entity,
      });

      enhancer.components.forEach((component) => {
        gameModel.addComponent(enhancerEntity, component.type, component.data);
      });
    }
  }
}

registerSystem(WeaponTagEnhancerSystem);

import { System } from "yage/components/System";
import { Component, Schema, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { WeaponContainerSchema } from "./WeaponContainer";
import { ChildSchema } from "yage/schemas/entity/Child";
import { HealthSchema, getMaxHealth } from "../core";
import { DamageStatsSchema } from "./DamageStats";
import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import { AttackCooldownSchema } from "./AttackCooldown";
import { EntityType, DamageDirectionEnum } from "yage/constants/enums";
import { EntityFactory } from "yage/entity/EntityFactory";
import { applyComponentsToProjectile, getWeaponAugmentComponents } from "../../utils/weapons";
import { OwnerSchema } from "yage/schemas/core/Owner";
import { OrbsSchema } from "./Orbs";

@Component("SwapOrbsOnCooldownReset")
class SwapOrbsOnCooldownResetSchema extends Schema {
  @type("boolean")
  lastCooldown: boolean;

  @type("string")
  swapEntityDescription: string;

  @type("string")
  otherEntityDescription: string;

  @type("Entity")
  entityContainer: number;
}

class SwapOrbsOnCooldownResetSystem implements System {
  schema = SwapOrbsOnCooldownResetSchema;
  type = "SwapOrbsOnCooldownReset";
  dependencies = ["Child"];

  depth = DEPTHS.LOCOMOTION - 101;

  init(entity: number, gameModel: GameModel) {}

  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, SwapOrbsOnCooldownResetSchema);
    const orbData = gameModel.getTypedUnsafe(entity, OrbsSchema);

    if (!orbData.projectiles.length) {
      this.createEntity(entity, data.swapEntityDescription, gameModel);
      return;
    }

    const attackCooldown = gameModel.getTypedUnsafe(orbData.projectiles[0], AttackCooldownSchema);
    if (attackCooldown.ready && !data.lastCooldown) {
      let entityDescription =
        data.swapEntityDescription === orbData.projectileDescription
          ? data.otherEntityDescription
          : data.swapEntityDescription;
      this.createEntity(entity, entityDescription, gameModel);
    }

    if (!attackCooldown.ready && data.lastCooldown) {
      data.lastCooldown = false;
    }

    // if (!attackCooldown.ready && data.lastCooldown) data.lastCooldown = false;
    // if (data.entityContainer) {
    //   gameModel.removeEntity(data.entityContainer);
    //   if (data.lastEntityDescription === data.swapEntityDescription) {
    //     this.createEntity(entity, data.otherEntityDescription, gameModel);
    //     data.lastEntityDescription = data.otherEntityDescription;
    //   } else {
    //     this.createEntity(entity, data.swapEntityDescription, gameModel);
    //     data.lastEntityDescription = data.swapEntityDescription;
    //   }
    // }
  }

  createEntity(entity: number, entityDescription: string, gameModel: GameModel) {
    const orbData = gameModel.getTypedUnsafe(entity, OrbsSchema);

    for (let i = 0; i < orbData.projectiles.length; i++) {
      gameModel.removeEntity(orbData.projectiles[i]);
      orbData.projectiles = [];
    }
    const data = gameModel.getTypedUnsafe(entity, SwapOrbsOnCooldownResetSchema);
    data.lastCooldown = true;

    orbData.projectileDescription = entityDescription;
  }
}

registerSystem(SwapOrbsOnCooldownResetSystem);

import { System } from "yage/components/System";
import { Component, Schema, type } from "yage/decorators/type";
import { DamageStatsSchema } from "../weapons/DamageStats";
import { GameModel } from "yage/game/GameModel";
import { OwnerSchema } from "yage/schemas/core/Owner";
import { ChildSchema } from "yage/schemas/entity/Child";
import { registerSchema, registerSystem } from "yage/components/ComponentRegistry";
import { ComponentCategory } from "yage/constants/enums";
import { HealthSchema } from "../core";

@Component("PercentMaxHealthDamage")
export class PercentMaxHealthDamageSchema extends Schema {
  @type("number")
  percentMaxHealthDamage: number;

  @type(DamageStatsSchema)
  damageStats: DamageStatsSchema;
}

@Component("InvalidateDamageStatsOnMaxHealthChange")
export class InvalidateDamageStatsOnMaxHealthChangeSchema extends Schema {}

registerSchema(InvalidateDamageStatsOnMaxHealthChangeSchema);

class PercentMaxHealthDamageSystem implements System {
  schema = PercentMaxHealthDamageSchema;
  type = "PercentMaxHealthDamage";
  category = ComponentCategory.DAMAGEMOD;

  dependencies = ["Child"];

  init(entity: number, gameModel: GameModel) {
    if (gameModel.hasComponent(entity, ChildSchema)) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      gameModel.addComponent(parent, InvalidateDamageStatsOnMaxHealthChangeSchema);
    } else {
      gameModel.addComponent(entity, InvalidateDamageStatsOnMaxHealthChangeSchema);
    }
  }

  run(entity: number, gameModel: GameModel) {
    const percentMaxHealthDamage = gameModel.getTypedUnsafe(entity, PercentMaxHealthDamageSchema);
    const damageStats = percentMaxHealthDamage.damageStats;
    const owner = gameModel.getTyped(entity, OwnerSchema)?.owner ?? gameModel.getTyped(entity, ChildSchema)?.parent;
    if (!owner) return;

    HealthSchema.id = owner;
    let maxHealth = HealthSchema.maxHealth ?? 0;

    damageStats.minDamage += maxHealth * (percentMaxHealthDamage.percentMaxHealthDamage / 100);
    damageStats.maxDamage += maxHealth * (percentMaxHealthDamage.percentMaxHealthDamage / 100);
  }
}

registerSystem(PercentMaxHealthDamageSystem);

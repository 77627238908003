import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { ComponentCategory } from "yage/components/types";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { LifestealEnhancerSchema } from "../enhancers/LifestealEnhancer";
import { healEntity } from ".";
import { OwnerSchema } from "yage/schemas/core/Owner";
import { HpBlockSchema } from "../player/HpBlock";

@Component("Lifesteal")
export class LifestealSchema extends Schema {
  @type("number")
  @defaultValue(0)
  lifesteal: number;
}

class LifestealSystem implements System {
  category: ComponentCategory = ComponentCategory.ONHIT;
  schema = LifestealSchema;
  type = "Lifesteal";

  run(entity: number, gameModel: GameModel) {
    const lifesteal = gameModel.getTyped(entity, LifestealSchema)?.lifesteal ?? 0;
    const owner = gameModel.getTyped(entity, OwnerSchema)?.owner;
    const lifestealEnhancer = (owner && gameModel.getTyped(owner, LifestealEnhancerSchema)?.lifesteal) ?? 0;
    if (
      gameModel.hasComponent(owner ?? -1, "HpBlock") &&
      !gameModel.getTypedUnsafe(owner ?? -1, HpBlockSchema).excludedTypes.includes("Lifesteal")
    ) {
      return;
    }

    if (lifesteal + lifestealEnhancer <= 0 || !owner) {
      return;
    }
    if (gameModel.rand.number() < (lifesteal + lifestealEnhancer) / 100) {
      healEntity(owner, 1, gameModel);
    }
  }
}

registerSystem(LifestealSystem);

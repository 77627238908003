import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import type { System } from "yage/components/System";
import { ComponentCategory, ComponentData, ComponentDataSchema } from "yage/components/types";
import { Component, defaultValue, Schema, type } from "yage/decorators/type";
import { EntityFactory } from "yage/entity/EntityFactory";
import type { GameModel } from "yage/game/GameModel";
import { TransformSchema } from "yage/schemas/entity/Transform";
import { Vector2d } from "yage/utils/vector";
import { GlobalSpawnSchema } from "./GlobalSpawn";
import { FlatMapSchema } from "../core/Map";
import { MobSpawnPositionEnum } from "yage/constants/enums";
import { GlobalSpawnIntervalEnhancerSchema } from "../enhancers/GlobalSpawnIntervalEnhancer";

@Component("MobSpawn")
export class MobSpawnSchema extends Schema {
  @type("number")
  @defaultValue(0)
  spawnTime = 0;

  @type("number")
  @defaultValue(1000)
  spawnInterval: number;

  @type("number")
  @defaultValue(3)
  spawnGroup: number;

  @type("number")
  @defaultValue(0)
  spawnCount: number;

  @type("number")
  @defaultValue(100)
  spawnLimit: number;

  @type("number")
  @defaultValue(550)
  spawnRadius: number;

  @type("number")
  @defaultValue(1)
  spawnChance: number;

  @type("number")
  @defaultValue(0)
  playerRadius: number;

  @type("string")
  spawnName: string;

  @type(MobSpawnPositionEnum)
  @defaultValue(MobSpawnPositionEnum.RANDOM)
  spawnPositionType: MobSpawnPositionEnum;

  @type("number")
  @defaultValue(150)
  spawnVariation: number;

  @type([ComponentDataSchema])
  @defaultValue([])
  overrideComponents: ComponentData[];

  @type("EntityArray")
  @defaultValue([])
  entities: number[];

  @type("boolean")
  @defaultValue(false)
  ignoreGlobalSpawn: boolean;
}

export class MobSpawnSystem implements System {
  type = "MobSpawn";
  category: ComponentCategory = ComponentCategory.ENEMY;
  schema = MobSpawnSchema;
  depth = DEPTHS.CORE;

  getSpawnInterval(entity: number, gameModel: GameModel) {
    const data = gameModel.getComponent(entity, this.schema) as MobSpawnSchema;
    if (gameModel.hasComponent(gameModel.coreEntity, GlobalSpawnIntervalEnhancerSchema)) {
      const enhancer = gameModel.getTypedUnsafe(gameModel.coreEntity, GlobalSpawnIntervalEnhancerSchema);
      return data.spawnInterval * enhancer.intervalScale;
    }
    return data.spawnInterval;
  }

  runAll?(gameModel: GameModel): void {
    const entityFactory = EntityFactory.getInstance();
    const entities = gameModel.getComponentActives(this.type);
    const globalSpawnActive = gameModel.getTypedUnsafe(gameModel.coreEntity, GlobalSpawnSchema).enabled;
    const mapScale = gameModel.getTypedUnsafe(gameModel.coreEntity, FlatMapSchema).scale;
    const mapData = gameModel.getComponentUnsafe(gameModel.coreEntity, FlatMapSchema);
    const mapMinX = -(mapScale * mapData.width) / 2;
    const mapMaxX = (mapScale * mapData.width) / 2;
    const mapMinY = -(mapScale * mapData.height) / 2;
    const mapMaxY = (mapScale * mapData.height) / 2;

    for (let i = 0; i < entities.length; ++i) {
      const data = gameModel.getComponent(entities[i], this.schema) as MobSpawnSchema;
      if (data.spawnTime === 0) {
        data.spawnTime = gameModel.timeElapsed - data.spawnInterval;
      }
      if ((!globalSpawnActive && !data.ignoreGlobalSpawn) || gameModel.players.length === 0) {
        data.spawnTime += gameModel.frameDt;
        continue;
      }
      const timeElapsed = gameModel.timeElapsed - data.spawnTime;
      const spawnInterval = this.getSpawnInterval(entities[i], gameModel);

      if (timeElapsed > spawnInterval) {
        const spawnCount = data.spawnGroup || timeElapsed / spawnInterval;
        const spawnPositions = this.generateSpawnPositions(
          spawnCount,
          gameModel.getComponentActives("PlayerType"),
          entities[i],
          mapScale,
          data,
          gameModel
        ).map((p) => ({ x: Math.min(mapMaxX, Math.max(p.x, mapMinX)), y: Math.min(mapMaxY, Math.max(p.y, mapMinY)) }));
        for (let j = 0; j < spawnCount; ++j) {
          data.spawnTime = gameModel.timeElapsed;

          if (data.spawnChance < 1 && gameModel.rand.number() > data.spawnChance) {
            continue;
          }
          if (data.spawnCount < data.spawnLimit) {
            data.spawnCount++;
            const position = spawnPositions.shift();
            const spawnComponents: any = {
              Chase: {
                target: 0,
              },
              Transform: {
                ...position,
              },
            };
            for (let k = 0; k < data.overrideComponents.length; ++k) {
              const component = data.overrideComponents[k];
              spawnComponents[component.type] = component.data;
            }

            const spawnedEntity = entityFactory.generateEntity(gameModel, data.spawnName, spawnComponents);
            data.entities.push(spawnedEntity);
          }
        }
      }
    }
  }

  capSpawnPosition(position: { x: number; y: number }, gameModel: GameModel) {
    const mapSize = gameModel.getTypedUnsafe(gameModel.coreEntity, FlatMapSchema);
    return {
      x: Math.min(Math.max(position.x, -mapSize.width / 2 + 10), mapSize.width / 2 - 10),
      y: Math.min(Math.max(position.y, -mapSize.height / 2 + 10), mapSize.height / 2 - 10),
    };
  }

  generateSpawnPositions(
    count: number,
    playersInRange: number[],
    entity: number,
    mapScale: number,
    data: MobSpawnSchema,
    gameModel: GameModel
  ) {
    switch (data.spawnPositionType) {
      case MobSpawnPositionEnum.RANDOM:
        return this.generateRandomSpawnPositions(count, playersInRange, entity, mapScale, data, gameModel);
      case MobSpawnPositionEnum.CIRCLE:
        return this.generateCircleSpawnPositions(count, playersInRange, entity, mapScale, data, gameModel);
      case MobSpawnPositionEnum.GROUPED:
        return this.generateGroupedSpawnPositions(count, playersInRange, entity, mapScale, data, gameModel);
    }
    return Array.from({ length: count }, () => ({ x: 0, y: 0 }));
  }

  generateRandomSpawnPositions(
    count: number,
    playersInRange: number[],
    entity: number,
    mapScale: number,
    data: MobSpawnSchema,
    gameModel: GameModel
  ): { x: number; y: number }[] {
    const positions = [];
    for (let i = 0; i < count; ++i) {
      const randomPlayer = playersInRange.length === 1 ? 0 : gameModel.rand.int(0, playersInRange.length - 1);

      TransformSchema.id = playersInRange[randomPlayer];
      const playerPosition = TransformSchema.position;

      positions.push(this.generateRandomSpawnPosition(playerPosition, data.spawnRadius * mapScale, gameModel, 150));
    }
    return positions;
  }

  generateGroupedSpawnPositions(
    count: number,
    playersInRange: number[],
    entity: number,
    mapScale: number,
    data: MobSpawnSchema,
    gameModel: GameModel
  ): { x: number; y: number }[] {
    const positions = [];

    const randomPlayer = playersInRange.length === 1 ? 0 : gameModel.rand.int(0, playersInRange.length - 1);

    const playerAngles = this.generatePlayerAngles(playersInRange[randomPlayer], data, gameModel);

    const randomAngle = playerAngles[Math.floor(gameModel.rand.int(0, playerAngles.length - 1))] * (Math.PI / 180);

    const playerTransform = gameModel.getTypedUnsafe(playersInRange[randomPlayer], TransformSchema).position;

    const spawnRadius = data.spawnRadius * mapScale;

    const x =
      playerTransform.x +
      Math.cos(randomAngle) * (spawnRadius / 2 + gameModel.rand.int(0, Math.floor(spawnRadius / 2)));
    const y =
      playerTransform.y +
      Math.sin(randomAngle) * (spawnRadius / 2 + gameModel.rand.int(0, Math.floor(spawnRadius / 2)));

    for (let i = 0; i < count; ++i) {
      positions.push(
        this.generateRandomSpawnPosition(
          {
            x,
            y,
          },
          data.spawnVariation * mapScale,
          gameModel,
          0
        )
      );
    }
    return positions;
  }

  generatePlayerAngles(player: number, data: MobSpawnSchema, gameModel: GameModel) {
    const playerTransform = gameModel.getTypedUnsafe(player, TransformSchema).position;

    const mapSize = gameModel.getComponentUnsafe(gameModel.coreEntity, FlatMapSchema);

    let angles = Array.from({ length: 360 }, (_, i) => i);
    if (playerTransform.x + data.spawnRadius > mapSize.width / 2) {
      angles = this.calculateValidSpawnAngles(
        playerTransform,
        { x: mapSize.width / 2, y: -Number.MAX_SAFE_INTEGER / 2, width: 1000, height: Number.MAX_SAFE_INTEGER },
        data.spawnRadius,
        angles
      );
    } else if (playerTransform.x - data.spawnRadius < -mapSize.width / 2) {
      angles = this.calculateValidSpawnAngles(
        playerTransform,
        { x: -mapSize.width / 2 - 1000, y: -Number.MAX_SAFE_INTEGER / 2, width: 1000, height: Number.MAX_SAFE_INTEGER },
        data.spawnRadius,
        angles
      );
    }
    if (playerTransform.y + data.spawnRadius > mapSize.height / 2) {
      angles = this.calculateValidSpawnAngles(
        playerTransform,
        { x: -Number.MAX_SAFE_INTEGER / 2, y: mapSize.height / 2, width: Number.MAX_SAFE_INTEGER, height: 1000 },
        data.spawnRadius,
        angles
      );
    } else if (playerTransform.y - data.spawnRadius < -mapSize.height / 2) {
      angles = this.calculateValidSpawnAngles(
        playerTransform,
        {
          x: -Number.MAX_SAFE_INTEGER / 2,
          y: -mapSize.height / 2 - 1000,
          width: Number.MAX_SAFE_INTEGER,
          height: 1000,
        },
        data.spawnRadius,
        angles
      );
    }
    return angles;
  }

  generateCircleSpawnPositions(
    count: number,
    playersInRange: number[],
    entity: number,
    mapScale: number,
    data: MobSpawnSchema,
    gameModel: GameModel
  ): { x: number; y: number }[] {
    const positions = [];
    const playerAngles = playersInRange.map((player) => {
      return this.generatePlayerAngles(player, data, gameModel);
    });

    let currentPlayer = gameModel.rand.int(0, playersInRange.length - 1);
    for (let i = 0; i < count; ++i) {
      positions.push(
        this.generateCircleSpawnPosition(
          playersInRange[currentPlayer],
          playerAngles[currentPlayer],
          mapScale,
          data,
          gameModel
        )
      );
      currentPlayer = (currentPlayer + 1) % playersInRange.length;
    }
    return positions;
  }

  generateRandomSpawnPosition(
    playerPosition: Vector2d,
    spawnRadius: number,
    gameModel: GameModel,
    avoidRadius = 0
  ): { x: number; y: number } {
    const r = gameModel.rand.number() * spawnRadius + avoidRadius;
    const theta = gameModel.rand.number() * Math.PI * 2;
    const x = r * Math.cos(theta);
    const y = r * Math.sin(theta);

    return this.capSpawnPosition(
      {
        x: x + playerPosition.x,
        y: y + playerPosition.y,
      },
      gameModel
    );
  }

  generateCircleSpawnPosition(
    player: number,
    angles: number[],
    mapScale: number,
    data: MobSpawnSchema,
    gameModel: GameModel
  ): { x: number; y: number } {
    const playerTransform = gameModel.getComponentUnsafe(player, TransformSchema).position;
    const angle = angles[Math.floor(gameModel.rand.int(0, angles.length - 1))] * (Math.PI / 180);
    const radius = data.spawnRadius + gameModel.rand.int(0, data.spawnVariation) * mapScale;
    const x = playerTransform.x + Math.cos(angle) * radius;
    const y = playerTransform.y + Math.sin(angle) * radius;
    return this.capSpawnPosition({ x, y }, gameModel);
  }

  calculateValidSpawnAngles(
    playerTransform: Vector2d,
    wall: { x: number; y: number; width: number; height: number },
    spawnRadius: number,
    angles: number[]
  ): number[] {
    for (let i = 0; i < angles.length; i++) {
      const angle = angles[i];
      const angleRadians = angle * (Math.PI / 180);
      const x = playerTransform.x + Math.cos(angleRadians) * spawnRadius;
      const y = playerTransform.y + Math.sin(angleRadians) * spawnRadius;
      if (x < wall.x || x > wall.x + wall.width || y < wall.y || y > wall.y + wall.height) {
        continue;
      }
      angles.splice(i, 1);
      i--;
    }
    return angles;
  }
}

registerSystem(MobSpawnSystem);

import { System } from "yage/components/System";
import { ComponentCategory } from "yage/constants/enums";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { InventorySchema } from "../player/Inventory";
import { registerSystem } from "yage/components/ComponentRegistry";
import { getEntityController, getPlayerCharacter } from "../../utils/playerCharacter";

@Component("AddItemToInventoryOnRoundEnd")
class AddItemToInventoryOnRoundEndSchema extends Schema {
  @type("string")
  inventoryPrefix: string;

  @type("string")
  entityDescription: string;

  @type("number")
  @defaultValue(0)
  owner: number;
}

class AddItemToInventoryOnRoundEndSystem implements System {
  type = "AddItemToInventoryOnRoundEnd";
  schema = AddItemToInventoryOnRoundEndSchema;
  category = ComponentCategory.ON_END;

  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, AddItemToInventoryOnRoundEndSchema);
    let owner = data.owner;
    const player = getPlayerCharacter(owner, gameModel);

    const inventory = gameModel.getTypedUnsafe(getEntityController(player, gameModel), InventorySchema);

    if (!inventory.inventory[data.inventoryPrefix + "::" + data.entityDescription]) {
      inventory.inventory[data.inventoryPrefix + "::" + data.entityDescription] = {
        amount: 0,
      };
    }
    inventory.inventory[data.inventoryPrefix + "::" + data.entityDescription].amount += 1;
  }
}

registerSystem(AddItemToInventoryOnRoundEndSystem);

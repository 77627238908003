import type { GameModel } from "yage/game/GameModel";
import { registerPixiComponent } from "yage/components/ComponentRegistry";
import { SpriteComponentPixi } from "yage/components/render/Sprite";
import { KeySpriteSchema } from "../../schema/player/KeySprite";
import { PlayerInputSchema } from "yage/schemas/core/PlayerInput";
import { ChildSchema } from "yage/schemas/entity/Child";
import { Viewport } from "pixi-viewport";

export class KeySpriteComponentPixi extends SpriteComponentPixi {
  schema = KeySpriteSchema;

  init(entity: number, gameModel: GameModel, viewport: Viewport): void {
    const keySprite = gameModel.getTypedUnsafe(entity, KeySpriteSchema);
    const key = keySprite.key;

    keySprite.spriteKey = "ui/kb";
    keySprite.currentAnimation = keySprite.currentAnimation ?? `ui/kb_${key.toLowerCase()}`;
    super.init(entity, gameModel, viewport);
  }

  run(entity: number, gameModel: GameModel, viewport: Viewport): void {
    const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent;
    if (!parent) {
      return;
    }
    const entityInput = gameModel.getTypedUnsafe(parent, PlayerInputSchema);
    // const selfId = entityInput.id;
    // if (selfId !== gameModel.netId) {
    //   return;
    // }
    const keySprite = gameModel.getTypedUnsafe(entity, KeySpriteSchema);
    const key = keySprite.key;
    let pressed = (entityInput.keyMap.has(key as any) && entityInput.keyMap.get(key as any)) || false;
    keySprite.spriteKey = "ui/kb";
    if (pressed) {
      keySprite.currentAnimation = `ui/kb_${key.toLowerCase()}_pressed`;
      keySprite.animationIndex = 0;
    } else {
      keySprite.currentAnimation = `ui/kb_${key.toLowerCase()}`;
      keySprite.animationIndex = 0;
    }
    super.run(entity, gameModel, viewport);
  }
}
registerPixiComponent("KeySprite", KeySpriteComponentPixi);

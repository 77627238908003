import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { ComponentCategory, DamageDirectionEnum } from "yage/constants/enums";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import DescriptionSchema from "yage/schemas/core/Description";
import { EntityFactory } from "yage/entity/EntityFactory";
import { OwnerSchema } from "yage/schemas/core/Owner";
import { ChildSchema } from "yage/schemas/entity/Child";
import { LocomotionSchema } from "yage/schemas/entity/Locomotion";
import { TransformSchema } from "yage/schemas/entity/Transform";
import { BV2 } from "yage/utils/vector";
import { getWeaponAugmentComponents, applyComponentsToProjectile } from "../../utils/weapons";
import { EnemyCollidersSchema } from "../enemy/EnemyColliders";
import { ComponentData, ComponentDataSchema } from "yage/components/types";
import def from "ajv/dist/vocabularies/discriminator";

@Component("SpawnProjectileOnPickup")
export class SpawnProjectileOnPickupSchema extends Schema {
  @type(["string"])
  @defaultValue([])
  pickupables: string[];

  @type("number")
  @defaultValue(1)
  projectileCount: number;

  @type("number")
  @defaultValue(1)
  spawnChance: number;

  @type("string")
  @defaultValue("Bullet")
  entityDescription: string;

  @type("boolean")
  @defaultValue(false)
  augmentFromParent: boolean;

  @type([ComponentDataSchema])
  @defaultValue([])
  overrideComponents: ComponentData[];

  @type("number")
  @defaultValue(0)
  pickedup: number;
}

class SpawnProjectileOnPickupSystem implements System {
  schema = SpawnProjectileOnPickupSchema;
  type = "SpawnProjectileOnPickup";
  category = ComponentCategory.ON_PICKUP;

  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, SpawnProjectileOnPickupSchema);

    const pickedUp = data.pickedup;

    const entityName = gameModel.getTypedUnsafe(pickedUp, DescriptionSchema).description;

    TransformSchema.id = pickedUp;
    const hitPosition = TransformSchema.position;

    if (data.pickupables.includes(entityName)) {
      if (gameModel.rand.number() > data.spawnChance) {
        return;
      }

      const owner = gameModel.getTypedUnsafe(entity, OwnerSchema).owner!;
      const parent = entity; //gameModel.getTyped(entity, ChildSchema)?.parent ?? entity;

      const angles = [];
      for (let i = 0; i < data.projectileCount; i++) {
        angles.push(gameModel.rand.int(0, 359));
      }

      const position = hitPosition;
      const colliders = [...gameModel.getTypedUnsafe(owner, EnemyCollidersSchema).colliders];

      angles.forEach((angle) => {
        const overrides: any = {
          Transform: position,
          EnemyColliders: {
            colliders,
          },
          Owner: {
            owner,
          },
          Child: {
            parent: parent,
          },
        };
        const projectile = EntityFactory.getInstance().generateEntity(gameModel, data.entityDescription, overrides);

        TransformSchema.id = projectile;
        TransformSchema.position = position;

        LocomotionSchema.id = projectile;
        const rotatedVelocity = BV2.rotateDegVector2d(0, LocomotionSchema.speed, angle);
        LocomotionSchema.velocityX = rotatedVelocity[0];
        LocomotionSchema.velocityY = rotatedVelocity[1];

        const [augmentComponents, auraComponents] = getWeaponAugmentComponents(
          projectile,
          data.augmentFromParent ? parent : owner,
          gameModel
        );
        applyComponentsToProjectile(
          projectile,
          DamageDirectionEnum.PROJECTILE,
          augmentComponents,
          auraComponents,
          gameModel
        );

        if (data.overrideComponents.length > 0) {
          for (let i = 0; i < data.overrideComponents.length; i++) {
            const override = data.overrideComponents[i];
            gameModel.addComponent(projectile, override.type, override.data ?? override);
          }
        }
      });
    }
  }
}

registerSystem(SpawnProjectileOnPickupSystem);

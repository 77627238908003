import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { ComponentCategory } from "yage/constants/enums";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { StoreItemSchema } from "../pickups/StoreItem";
import { getEntityController } from "../../utils/playerCharacter";
import { InventorySchema } from "../player/Inventory";

@Component("ChanceDoubleValueOnPickup")
export class ChanceDoubleValueOnPickupSchema extends Schema {
  @type("number")
  @defaultValue(0.0)
  chance: number;

  @type(StoreItemSchema)
  pickupData: StoreItemSchema;
}

class ChanceDoubleValueOnPickupSystem implements System {
  schema = ChanceDoubleValueOnPickupSchema;
  type = "ChanceDoubleValueOnPickup";
  category = ComponentCategory.ON_ADD_TO_INVENTORY;

  run(entity: number, gameModel: GameModel) {
    const chanceDoubleValueOnPickup = gameModel.getTypedUnsafe(entity, ChanceDoubleValueOnPickupSchema);

    if (chanceDoubleValueOnPickup.pickupData.inventoryType === "coins") {
      if (gameModel.rand.number() < chanceDoubleValueOnPickup.chance) {
        const inventoryOwner = getEntityController(entity, gameModel);
        const inventory = gameModel.getTypedUnsafe(inventoryOwner, InventorySchema).inventory;
        inventory.coins.amount++;
      }
    }
  }
}

registerSystem(ChanceDoubleValueOnPickupSystem);

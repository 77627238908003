import { registerSystem } from "yage/components/ComponentRegistry";
import type { System } from "yage/components/System";
import { ComponentCategory } from "yage/components/types";
import { Component, defaultValue, nullable, required, Schema, type } from "yage/decorators/type";
import type { GameModel } from "yage/game/GameModel";
import { addToInventory } from "../player/Inventory";

@Component("StoreItem")
export class StoreItemSchema extends Schema {
  @type("Entity")
  @nullable()
  owner: number | null;

  @type("boolean")
  @defaultValue(false)
  carried: boolean;

  @type("string")
  @required()
  inventoryType: string;

  @type("string")
  @defaultValue("unknown")
  inventorySpriteKey: string;

  @type("string")
  @defaultValue("")
  pickupSound: string;

  @type("boolean")
  @defaultValue(true)
  destroyOnPickup: boolean;

  @type("boolean")
  @defaultValue(false)
  share: boolean;
}

class StoreItemSystem implements System {
  type = "StoreItem";
  category: ComponentCategory = ComponentCategory.PICKUP;
  schema = StoreItemSchema;
  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, StoreItemSchema);
    if (data.owner) {
      if (data.share) {
        const players = gameModel.players;
        for (const player of players) {
          data.destroyOnPickup = addToInventory(player, entity, data, data.carried, gameModel) || data.destroyOnPickup;
        }
      } else {
        data.destroyOnPickup = addToInventory(data.owner, entity, data, data.carried, gameModel);
      }
    }
  }
}

registerSystem(StoreItemSystem);

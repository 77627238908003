import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { ComponentCategory } from "yage/constants/enums";
import { Component } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { PlayerTypeSchema, EnemyTypeSchema } from "yage/components/entity/Types";
import { ChildSchema } from "yage/schemas/entity/Child";
import { StatOnHordeContainerSchema, StatOnHordeSchema, StatOnHordeSystem } from "../effects/StatOnHorde";

@Component("StatOnHordeEnhancer")
export class StatOnHordeEnhancerSchema extends StatOnHordeSchema {}

class StatOnHordeEnhancerSystem implements System {
  schema = StatOnHordeEnhancerSchema;
  type = "StatOnHordeEnhancer";
  category = ComponentCategory.ON_ADD_TO_INVENTORY;

  dependencies = ["Child"];

  init(entity: number, gameModel: GameModel) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (!gameModel.hasComponent(parent, "StatOnHorde")) {
        gameModel.addComponent(parent, StatOnHordeContainerSchema);
      }
      gameModel.getTypedUnsafe(parent, StatOnHordeContainerSchema).horde.push({
        scale: gameModel.getTypedUnsafe(entity, StatOnHordeEnhancerSchema).scale,
        divisor: gameModel.getTypedUnsafe(entity, StatOnHordeEnhancerSchema).divisor,
        currentStatValue: 0,
        scalingStat: gameModel.getTypedUnsafe(entity, StatOnHordeEnhancerSchema).scalingStat,
        scalingComponentType: gameModel.getTypedUnsafe(entity, StatOnHordeEnhancerSchema).scalingComponentType,
      });

      gameModel.getTypedUnsafe(parent, StatOnHordeContainerSchema).pickupData = {
        owner: parent,
        inventoryType: "coins",
        carried: false,
        inventorySpriteKey: "ui/exp",
        pickupSound: "",
        destroyOnPickup: false,
        share: false,
      };
      gameModel.getSystem(StatOnHordeSystem).run?.(parent, gameModel);
    }
  }
}

registerSystem(StatOnHordeEnhancerSystem);

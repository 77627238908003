import { registerSystem } from "yage/components/ComponentRegistry";
import { ComponentCategory } from "yage/components/types";
import { Component, defaultValue, type, Schema } from "yage/decorators/type";
import type { GameModel } from "yage/game/GameModel";
import type { System } from "yage/components/System";
import { Vector2dSchema } from "yage/utils/vector";
import { EnemyCollidersSchema } from "../enemy/EnemyColliders";
import { OwnerSchema } from "yage/schemas/core/Owner";
import { ChildSchema } from "yage/schemas/entity/Child";
import { sortedByDistance } from "yage/utils/Collision";
import { SpeedModSchema } from "../auras";

@Component("SlowOnHit")
export class SlowOnHitSchema extends Schema {
  @type("number")
  @defaultValue(1)
  slowChance: number;

  @type("number")
  @defaultValue(0.5)
  slowMod: number;

  @type("number")
  @defaultValue(100)
  range: number;

  @type("number")
  duration: number;

  @type("number")
  @defaultValue(1)
  maxSlow: number;

  @type(Vector2dSchema)
  @defaultValue({ x: 0, y: 0 })
  hitPosition: Vector2dSchema;
}

class SlowOnHitSystem implements System {
  type = "SlowOnHit";
  category: ComponentCategory = ComponentCategory.ONHIT;
  schema = SlowOnHitSchema;
  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, SlowOnHitSchema);

    if (gameModel.rand.number() > data.slowChance) {
      return;
    }

    const owner = gameModel.getTyped(entity, OwnerSchema)?.owner ?? entity;
    const position = data.hitPosition;
    const colliders = [...(gameModel.getTyped(owner, EnemyCollidersSchema)?.colliders ?? [])];

    if (colliders.length === 0) {
      return;
    }

    let slowableEnemies: number[] = [];
    if (data.range) {
      const enemies = gameModel.getEntities(colliders);
      slowableEnemies = sortedByDistance(gameModel, position, enemies, data.range);
    } else {
      slowableEnemies = colliders;
    }
    for (let i = 0; i < slowableEnemies.length; i++) {
      const enemy = slowableEnemies[i];
      if (gameModel.hasComponent(enemy, "SpeedMod")) {
        let mods = gameModel.getTypedUnsafe(enemy, SpeedModSchema).speedMods;
        const totalSelfMod = -mods.filter((mod) => mod.source === entity).reduce((acc, mod) => acc + mod.speedMod, 0);
        const totalMod = mods.reduce((acc, mod) => acc + mod.speedMod, 0);
        if (totalSelfMod >= data.maxSlow || totalMod - data.slowMod <= 0) {
          continue;
        }
        const maxMod = data.maxSlow - totalSelfMod;
        const mod = Math.min(data.slowMod, maxMod);
        mods.push({ source: entity, speedMod: -mod, duration: data.duration });
        continue;
      }

      gameModel.addComponent(enemy, "SpeedMod", {
        speedMods: [{ source: entity, speedMod: -data.slowMod, duration: data.duration }],
      });
    }
  }
}

registerSystem(SlowOnHitSystem);

import { DEPTHS, registerSchema, registerSystem } from "yage/components/ComponentRegistry";
import type { System } from "yage/components/System";
import type { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";
import { LocomotionSchema } from "yage/schemas/entity/Locomotion";
import { scaleVector2d } from "yage/utils/vector";
import { type, Schema, Component, defaultValue } from "yage/decorators/type";
import { CollisionsSchema } from "yage/schemas/physics/Collisions";
import { OwnerSchema } from "yage/schemas/core/Owner";
import { SpeedEnhancerSchema } from "../enhancers/SpeedEnhancer";
import { DamageStatsEnhancerSchema } from "../enhancers/DamageStatsEnhancer";
import { HealthSchema } from "../core";
import { BuffAuraSchema } from "./BuffAura";

@Component("BuffAllies")
export class BuffAlliesSchema extends Schema {
  @type("number")
  @defaultValue(0.25)
  damageScale: number;

  @type("number")
  @defaultValue(0.5)
  speedScale: number;

  @type("number")
  @defaultValue(1.5)
  healthScale: number;

  @type("number")
  @defaultValue(100)
  radius: number;
}

class BuffAlliesSystem implements System {
  type: string = "BuffAllies";
  schema: typeof Schema = BuffAlliesSchema;
  depth = DEPTHS.COLLISION + 1;

  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, BuffAlliesSchema);

    const collisionData = gameModel.getTyped(gameModel.coreEntity, CollisionsSchema)?.collisions[entity] ?? {};
    const owner = gameModel.getTypedUnsafe(entity, OwnerSchema).owner!;
    const colliders = Object.keys(collisionData)
      .filter((key) => key !== owner.toString())
      .map((key) => parseInt(key));

    for (let i = 0; i < colliders.length; i++) {
      const collider = colliders[i];
      if (gameModel.hasComponent(collider, BuffAuraSchema)) {
        continue;
      }
      const buffEntity = gameModel.addEntity();
      gameModel.addComponent(buffEntity, ChildSchema, { parent: collider });
      gameModel.addComponent(buffEntity, SpeedEnhancerSchema, {
        speedScale: data.speedScale,
      });
      gameModel.addComponent(buffEntity, DamageStatsEnhancerSchema, {
        damageScale: data.damageScale,
      });
      HealthSchema.id = collider;
      HealthSchema.health += HealthSchema.health * data.healthScale;
      HealthSchema.maxHealth += HealthSchema.maxHealth * data.healthScale;
      gameModel.addComponent(collider, BuffAuraSchema);
    }
  }
  cleanup?: ((entity: number, gameModel: GameModel, ejecting: boolean) => void) | undefined;
}

registerSystem(BuffAlliesSystem);

import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { PlayerTypeSchema, EnemyTypeSchema } from "yage/components/entity/Types";
import { ComponentCategory } from "yage/constants/enums";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";

@Component("ShareOnApplyDamage")
export class ShareOnApplyDamageSchema extends Schema {
  @type(["number"])
  @defaultValue([])
  entities: number[];

  @type("number")
  @defaultValue(-1)
  owner: number;

  @type("number")
  @defaultValue(-1)
  taker: number;

  @type("number")
  @defaultValue(-1)
  maker: number;

  @type("number")
  @defaultValue(-1)
  currentDamageScale: number;

  @type("number")
  @defaultValue(-1)
  damage: number;
}

class ShareOnApplyDamageSystem implements System {
  schema = ShareOnApplyDamageSchema;
  type = "ShareOnApplyDamage";
  dependencies = ["Child"];

  init(entity: number, gameModel: GameModel) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      gameModel.addIfMissing(parent, ShareOnApplyDamageSchema);
      const parentData = gameModel.getTypedUnsafe(parent, ShareOnApplyDamageSchema);
      parentData.entities.push(entity);
    }
  }
}

registerSystem(ShareOnApplyDamageSystem);

import { System } from "yage/components/System";
import { Component, Schema, type } from "yage/decorators/type";
import { DamageStatsSchema } from "../weapons/DamageStats";
import { GameModel } from "yage/game/GameModel";
import { OwnerSchema } from "yage/schemas/core/Owner";
import { ChildSchema } from "yage/schemas/entity/Child";
import { registerSchema, registerSystem } from "yage/components/ComponentRegistry";
import { ComponentCategory } from "yage/constants/enums";
import { DamageMitigationSchema } from "./DamageMitigation";

@Component("PercentDefenseDamage")
export class PercentDefenseDamageSchema extends Schema {
  @type("number")
  percentDefenseDamage: number;

  @type(DamageStatsSchema)
  damageStats: DamageStatsSchema;
}

@Component("InvalidateDamageStatsOnDefenseChange")
export class InvalidateDamageStatsOnDefenseChangeSchema extends Schema {}

registerSchema(InvalidateDamageStatsOnDefenseChangeSchema);

class PercentDefenseDamageSystem implements System {
  schema = PercentDefenseDamageSchema;
  type = "PercentDefenseDamage";
  category = ComponentCategory.DAMAGEMOD;
  dependencies = ["Child"];

  init(entity: number, gameModel: GameModel) {
    if (gameModel.hasComponent(entity, ChildSchema)) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      gameModel.addComponent(parent, InvalidateDamageStatsOnDefenseChangeSchema);
    } else {
      gameModel.addComponent(entity, InvalidateDamageStatsOnDefenseChangeSchema);
    }
  }

  run(entity: number, gameModel: GameModel) {
    const percentDefenseDamage = gameModel.getTypedUnsafe(entity, PercentDefenseDamageSchema);
    const damageStats = percentDefenseDamage.damageStats;
    const owner = gameModel.getTyped(entity, OwnerSchema)?.owner ?? gameModel.getTyped(entity, ChildSchema)?.parent;
    if (!owner || !gameModel.hasComponent(owner, DamageMitigationSchema)) return;

    const ownerDefense = gameModel.getTypedUnsafe(owner, DamageMitigationSchema).defense;

    damageStats.minDamage += ownerDefense * (percentDefenseDamage.percentDefenseDamage / 100);
    damageStats.maxDamage += ownerDefense * (percentDefenseDamage.percentDefenseDamage / 100);
  }
}

registerSystem(PercentDefenseDamageSystem);

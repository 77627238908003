import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { EnemyTypeSchema } from "yage/components/entity/Types";
import { Component, type, defaultValue, Schema } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";
import { DamageStatsSchema } from "../weapons/DamageStats";

@Component("AttackSpeedOnEnemyCount")
export class AttackSpeedOnEnemyCountSchema extends Schema {
  @type("number")
  @defaultValue(1)
  attackSpeed: number;

  @type("number")
  @defaultValue(0)
  currentEnemyCount: number;
}

class AttackSpeedOnEnemyCountSystem implements System {
  schema = AttackSpeedOnEnemyCountSchema;
  type = "AttackSpeedOnEnemyCount";
  depth = DEPTHS.DAMAGE + 1;
  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, AttackSpeedOnEnemyCountSchema);
    const enemyCount = gameModel.getComponentActives("EnemyType").length;

    if (data.currentEnemyCount !== enemyCount) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (!gameModel.hasComponent(parent, DamageStatsSchema)) {
        return;
      }
      const stats = gameModel.getTypedUnsafe(parent, DamageStatsSchema).initialDamageStats;
      stats.attackSpeedScale =
        stats.attackSpeedScale + ((enemyCount - data.currentEnemyCount) * data.attackSpeed) / 100;
      gameModel.getTypedUnsafe(parent, DamageStatsSchema).invalidated = true;
      data.currentEnemyCount = enemyCount;
    }
  }
}

registerSystem(AttackSpeedOnEnemyCountSystem);

import { registerSchema } from "yage/components/ComponentRegistry";
import { ComponentCategory } from "yage/components/types";
import { Component, defaultValue, Schema, type } from "yage/decorators/type";

@Component("WeaponSize")
export class WeaponSizeSchema extends Schema {
  @type("number")
  @defaultValue(2)
  maxSize: number;

  @type("number")
  @defaultValue(0)
  size: number;
}

registerSchema(ComponentCategory.BEHAVIOR, WeaponSizeSchema);

import { System } from "yage/components/System";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { HealthSchema } from "../core";
import { ComponentCategory } from "yage/constants/enums";
import { registerSchema, registerSystem } from "yage/components/ComponentRegistry";
import { OwnerSchema } from "yage/schemas/core/Owner";

@Component("ProjectileCount")
export class ProjectileCountSchema extends Schema {
  @type("number")
  @defaultValue(0)
  projectileCount: number;
}

registerSchema(ComponentCategory.WEAPON_AUGMENT, ProjectileCountSchema);

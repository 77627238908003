import { System } from "yage/components/System";
import { Component, Schema, type } from "yage/decorators/type";
import { DamageStatsSchema } from "../weapons/DamageStats";
import { GameModel } from "yage/game/GameModel";
import { OwnerSchema } from "yage/schemas/core/Owner";
import { ChildSchema } from "yage/schemas/entity/Child";
import { registerSystem } from "yage/components/ComponentRegistry";
import { ComponentCategory } from "yage/constants/enums";

@Component("PercentAttackSpeedDamage")
export class PercentAttackSpeedDamageSchema extends Schema {
  @type("number")
  percentAttackSpeedDamage: number;

  @type(DamageStatsSchema)
  damageStats: DamageStatsSchema;
}

class PercentAttackSpeedDamageSystem implements System {
  schema = PercentAttackSpeedDamageSchema;
  type = "PercentAttackSpeedDamage";
  category = ComponentCategory.DAMAGEMOD;

  run(entity: number, gameModel: GameModel) {
    const percentAttackSpeedDamage = gameModel.getTypedUnsafe(entity, PercentAttackSpeedDamageSchema);
    const damageStats = percentAttackSpeedDamage.damageStats;
    const owner = gameModel.getTyped(entity, OwnerSchema)?.owner ?? gameModel.getTyped(entity, ChildSchema)?.parent;
    if (!owner) return;

    const ownerDamageStats = gameModel.getTypedUnsafe(owner, DamageStatsSchema);

    damageStats.minDamage +=
      ownerDamageStats.attackSpeedScale * 100 * (percentAttackSpeedDamage.percentAttackSpeedDamage / 100);
    damageStats.maxDamage +=
      ownerDamageStats.attackSpeedScale * 100 * (percentAttackSpeedDamage.percentAttackSpeedDamage / 100);
  }
}

registerSystem(PercentAttackSpeedDamageSystem);

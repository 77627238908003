import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { ListenEntityCreationSchema } from "yage/components/core/ListenEntityCreation";
import { EntityTypeSchema } from "yage/components/entity/Types";
import { ComponentDataSchema } from "yage/components/types";
import { ComponentCategory, EntityType } from "yage/constants/enums";
import { Component, Schema, defaultValue, required, type } from "yage/decorators/type";
import { EntityFactory } from "yage/entity/EntityFactory";
import { GameModel } from "yage/game/GameModel";

@Component("ApplyOnSpawn")
export class ApplyOnSpawnSchema extends Schema {
  @type(EntityType)
  @required()
  filter: EntityType;

  @type([ComponentDataSchema])
  @defaultValue([])
  components: ComponentDataSchema[];

  @type(["string"])
  @defaultValue([])
  children: string[];

  @type("number")
  @defaultValue(-1)
  entity: number;
}

export class ApplyOnSpawnSystem implements System {
  type = "ApplyOnSpawn";
  schema = ApplyOnSpawnSchema;

  category = ComponentCategory.ON_ENTITY_CREATION;

  init(entity: number, gameModel: GameModel) {
    gameModel.addIfMissing(gameModel.coreEntity, ListenEntityCreationSchema);
    gameModel.getTypedUnsafe(gameModel.coreEntity, ListenEntityCreationSchema).entities.push(entity);
  }

  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, ApplyOnSpawnSchema);

    EntityTypeSchema.id = data.entity;
    if (EntityTypeSchema.entityType !== data.filter) {
      return;
    }

    if (data.children.length === 0) {
      for (let i = 0; i < data.components.length; i++) {
        gameModel.addComponent(data.entity, data.components[i].type, data.components[i].data);
      }
    } else {
      for (let i = 0; i < data.children.length; i++) {
        const child = EntityFactory.getInstance().generateEntity(gameModel, data.children[i], {
          Child: { parent: data.entity },
        });
        for (let i = 0; i < data.components.length; i++) {
          gameModel.addComponent(child, data.components[i].type, data.components[i].data);
        }
      }
    }
  }
}

registerSystem(ApplyOnSpawnSystem);

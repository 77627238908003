import { registerSystem } from "yage/components/ComponentRegistry";
import type { System } from "yage/components/System";
import { ComponentCategory } from "yage/components/types";
import { PickupStateEnum, FlingComponentPositionTypeEnum } from "yage/constants/enums";
import { Component, nullable, Schema, type } from "yage/decorators/type";
import type { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";
import { LocomotionSchema } from "yage/schemas/entity/Locomotion";
import { ParentSchema } from "yage/schemas/entity/Parent";
import { EnemyCollidersSchema } from "../enemy/EnemyColliders";
import { PickupStateSchema } from "../pickups/PickupState";
import { WeaponContainerSchema } from "./WeaponContainer";
import { WeaponSizeSchema } from "./WeaponSize";

@Component("WeaponPickup")
export class WeaponPickupSchema extends Schema {
  @type("Entity")
  @nullable()
  owner: number;
}

class WeaponPickupSystem implements System {
  type = "WeaponPickup";
  category: ComponentCategory = ComponentCategory.PICKUP;
  schema = WeaponPickupSchema;

  run(entity: number, gameModel: GameModel) {
    const pickupData = gameModel.getTypedUnsafe(entity, WeaponPickupSchema);
    const childData = gameModel.getTypedUnsafe(entity, ChildSchema);

    const ownerWeaponContainer = gameModel.getTypedUnsafe(pickupData.owner, WeaponContainerSchema);

    const weaponSize = gameModel.getTyped(entity, WeaponSizeSchema)?.size ?? 10000;

    if (weaponSize > ownerWeaponContainer.size) {
      return;
    }

    let totalCarriedWeapons = 0;

    if (ownerWeaponContainer.weaponIds.length > 0) {
      for (let i = 0; i < ownerWeaponContainer.weaponIds.length; i++) {
        const weapon = gameModel.getTypedUnsafe(ownerWeaponContainer.weaponIds[i], WeaponSizeSchema);
        totalCarriedWeapons += weapon.maxSize;
      }
    }
    if (totalCarriedWeapons >= ownerWeaponContainer.maxSize) {
      for (let i = 0; i < ownerWeaponContainer.weaponIds.length; i++) {
        const weaponId = ownerWeaponContainer.weaponIds[i];
        gameModel.addComponent(weaponId, "Radius", { radius: 8 });
        const parent = gameModel.getTypedUnsafe(weaponId, ChildSchema).parent;
        if (parent && gameModel.hasComponent(parent, "Parent")) {
          const parentData = gameModel.getTypedUnsafe(parent, ParentSchema);
          const index = parentData.children.indexOf(weaponId);
          if (index != -1) {
            parentData.children.splice(index, 1);
          }
        }
        gameModel.cleanupEntity(weaponId);

        gameModel.getTypedUnsafe(weaponId, ChildSchema).parent = null;

        gameModel.removeComponent(weaponId, "EnemyColliders");
        gameModel.getTypedUnsafe(weaponId, PickupStateSchema).state = PickupStateEnum.STUCK;
        LocomotionSchema.id = weaponId;
        LocomotionSchema.directionX = 1;
        LocomotionSchema.directionY = 0;

        gameModel.addComponent(weaponId, "Fling", {
          positionType: FlingComponentPositionTypeEnum.CIRCLE,
          position: { x: 70, y: 100 },
        });
      }
      ownerWeaponContainer.weaponIds = [];
    }

    ownerWeaponContainer.weaponIds.push(entity);

    gameModel.logEntity(entity);

    gameModel.addComponent(pickupData.owner, "Owner", {
      owner: pickupData.owner,
    });

    childData.parent = pickupData.owner;
    gameModel.removeComponent(entity, "Radius"); // stop collision
    gameModel.getTypedUnsafe(entity, PickupStateSchema).state = PickupStateEnum.CARRIED;
    gameModel.addComponent(entity, "EnemyColliders", gameModel.getTypedUnsafe(pickupData.owner, EnemyCollidersSchema));
  }
}

registerSystem(WeaponPickupSystem);

import { PlayerTypeSchema } from "yage/components/entity/Types";
import { GameModel } from "yage/game/GameModel";
import { PlayerInputSchema } from "yage/schemas/core/PlayerInput";
import { ChildSchema } from "yage/schemas/entity/Child";
import { ParentSchema } from "yage/schemas/entity/Parent";

export const getEntityController = (entity: number, gameModel: GameModel) => {
  const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
  if (gameModel.hasComponent(parent, PlayerInputSchema)) {
    return parent;
  }
  return entity;
};

export const getPlayerCharacter = (entity: number, gameModel: GameModel) => {
  const children = gameModel.getTypedUnsafe(entity, ParentSchema)?.children ?? [];
  for (const child of children) {
    if (gameModel.hasComponent(child, PlayerTypeSchema)) {
      return child;
    }
  }
  return -1;
};

import { System } from "yage/components/System";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { DamageStatsSchema } from "../weapons/DamageStats";
import { GameModel } from "yage/game/GameModel";
import { RadiusSchema } from "yage/schemas/entity/Radius";
import { SpriteSchema } from "yage/schemas/render/Sprite";
import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";

@Component("AoeScale")
class AoeScaleSchema extends Schema {
  @type("number")
  @defaultValue(1)
  currentScale: number;

  @type("number")
  @defaultValue(0)
  baseRadius: number;

  @type("number")
  @defaultValue(0)
  baseSpriteScale: number;
}

class AoeScaleSystem implements System {
  type = "AoeScale";
  schema = AoeScaleSchema;

  depth = DEPTHS.DAMAGE - 0.001;
  run(entity: number, gameModel: GameModel) {
    const damageStats = gameModel.getTypedUnsafe(entity, DamageStatsSchema);
    if (damageStats.areaOfEffect === 0) {
      return;
    }
    const aoeScale = gameModel.getTypedUnsafe(entity, AoeScaleSchema);
    if (aoeScale.currentScale === 1 && aoeScale.baseRadius === 0) {
      RadiusSchema.id = entity;
      aoeScale.baseRadius = RadiusSchema.radius;
      if (gameModel.hasComponent(entity, SpriteSchema)) {
        const spriteData = gameModel.getTypedUnsafe(entity, SpriteSchema);
        aoeScale.baseSpriteScale = spriteData.scale;
      }
    }
    if (aoeScale.currentScale === 1 + damageStats.areaOfEffect) {
      return;
    }

    aoeScale.currentScale = 1 + damageStats.areaOfEffect;

    if (gameModel.hasComponent(entity, RadiusSchema)) {
      RadiusSchema.id = entity;
      RadiusSchema.radius = aoeScale.baseRadius * aoeScale.currentScale;
    }
    if (gameModel.hasComponent(entity, SpriteSchema)) {
      const spriteData = gameModel.getTypedUnsafe(entity, SpriteSchema);
      spriteData.scale = aoeScale.baseSpriteScale * aoeScale.currentScale;
    }
  }
}

registerSystem(AoeScaleSystem);

import { registerSystem } from "yage/components/ComponentRegistry";
import { ComponentCategory } from "yage/components/types";
import { Component, defaultValue, type, Schema } from "yage/decorators/type";
import type { GameModel } from "yage/game/GameModel";
import type { System } from "yage/components/System";
import { EnemyCollidersSchema } from "../enemy/EnemyColliders";
import { OwnerSchema } from "yage/schemas/core/Owner";
import { DestroyOnTimeoutSchema } from "yage/schemas/timeouts/DestroyOnTimeoutComponent";
import { HealthSchema } from "../core";
import { DamageSchema, DamageStatsSchema } from "../../schema/damage/Damage";
import { FollowSchema } from "../weapons/Follow";
import { getNextRandomEnemy, getNextRandomEnemyInRange } from "../../utils/target";
import { Vector2dSchema } from "yage/utils/vector";
import { RetargetOnHitEnhancerSchema } from "../enhancers/RetargetOnHitEnhancer";
import { ProjectileTypeSchema } from "yage/components/entity/Types";

@Component("RetargetOnHit")
export class RetargetOnHitSchema extends Schema {
  @type("number")
  @defaultValue(1)
  maxRetargets: number;

  @type("number")
  @defaultValue(1)
  retargetChance: number;

  @type("boolean")
  @defaultValue(true)
  incrementHealth: boolean;

  @type(DestroyOnTimeoutSchema)
  timeout: DestroyOnTimeoutSchema;

  @type([DamageSchema])
  @defaultValue([])
  damages: DamageSchema[];

  @type("boolean")
  @defaultValue(false)
  onCrit: boolean;

  @type(Vector2dSchema)
  @defaultValue({ x: 0, y: 0 })
  hitPosition: Vector2dSchema;

  @type("number")
  @defaultValue(-1)
  collider: number;
}

class RetargetOnHitSystem implements System {
  type = "RetargetOnHit";
  category: ComponentCategory = ComponentCategory.ONHIT;
  schema = RetargetOnHitSchema;
  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, RetargetOnHitSchema);

    if (!gameModel.hasComponent(entity, ProjectileTypeSchema)) {
      return;
    }

    if (data.onCrit) {
      if (!data.damages.some((damage) => damage.critHit)) {
        return;
      }
    }

    if (gameModel.rand.number() > data.retargetChance) {
      return;
    }

    if (!data.timeout && gameModel.hasComponent(entity, DestroyOnTimeoutSchema)) {
      data.timeout = gameModel.getTypedUnsafe(entity, DestroyOnTimeoutSchema);
      gameModel.removeComponent(entity, DestroyOnTimeoutSchema);
    }

    const owner = gameModel.getTypedUnsafe(entity, OwnerSchema).owner!;
    const colliders = [...gameModel.getTypedUnsafe(owner, EnemyCollidersSchema).colliders];

    if (colliders.length === 0) {
      if (data.timeout) {
        gameModel.addComponent(entity, DestroyOnTimeoutSchema, data.timeout);
      }
      gameModel.removeComponent(entity, "RetargetOnHit");
      return;
    }
    const currentTarget = gameModel.getTyped(entity, FollowSchema)?.target ?? data.collider;
    const range = gameModel.getTypedUnsafe(entity, DamageStatsSchema).range;
    const enemy = getNextRandomEnemyInRange(data.hitPosition, range, currentTarget, colliders, gameModel);

    if (enemy === -1) {
      if (data.timeout) {
        gameModel.addComponent(entity, DestroyOnTimeoutSchema, data.timeout);
      }
      gameModel.removeComponent(entity, "RetargetOnHit");
      return;
    }
    gameModel.addComponent(entity, "Follow", { target: enemy });
    data.maxRetargets--;

    if (data.incrementHealth) {
      HealthSchema.id = entity;
      HealthSchema.health++;
    }

    data.damages.forEach((damage) => {
      damage.damageStats.damageScale = Math.min(
        Math.max((damage.damageStats.damageScale || 1) / ((damage.damageStats.pierceScale ?? 0) + 1), 0.0001),
        1
      );
    });

    const enhanced = gameModel.getTyped(owner, RetargetOnHitEnhancerSchema)?.maxRetargets ?? 0;

    if (data.maxRetargets + enhanced === 0) {
      if (data.timeout) {
        data.timeout.timeElapsed = 0;
        gameModel.addComponent(entity, DestroyOnTimeoutSchema, data.timeout);
      }
      gameModel.removeComponent(entity, "RetargetOnHit");
    }
  }
}

registerSystem(RetargetOnHitSystem);

import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import type { System } from "yage/components/System";
import { ComponentCategory } from "yage/components/types";
import { Component, defaultValue, Schema, type } from "yage/decorators/type";
import type { GameModel } from "yage/game/GameModel";
import { LocomotionSchema } from "yage/schemas/entity/Locomotion";
import { RigidBoxSchema } from "yage/schemas/physics/RigidBox";
import type { Vector2d } from "yage/utils/vector";
import { angleOfVector2d, BV2, rotateDegVector2d, rotateVector2d, Vector2dSchema } from "yage/utils/vector";
import { applyComponentsToProjectile, directionFromTarget, getWeaponAugmentComponents } from "../../utils/weapons";
import { TransformSchema } from "yage/schemas/entity/Transform";
import { AttackCooldownSchema } from "./AttackCooldown";
import { EnemyCollidersSchema } from "../enemy/EnemyColliders";
import { closestEntity } from "yage/utils/Collision";
import { DamageApplierSchema } from "../damage/DamageApplier";
import { TargetingSchema } from "./Targeting";
import { OwnerSchema } from "yage/schemas/core/Owner";
import { RigidBoxSystem } from "yage/components/physics/RigidBox";
import { RigidCircleSystem } from "yage/components/physics/RigidCircle";
import { DamageDirectionEnum, EntityType } from "yage/constants/enums";
import { EntityFactory } from "yage/entity/EntityFactory";
import { RigidCircleSchema } from "yage/schemas/physics/RigidCircle";
import { DamageStatsSchema } from "./DamageStats";
import { ProjectileCountSchema } from "../damage/ProjectileCount";
import { DestroyOnTimeoutSchema } from "yage/schemas/timeouts/DestroyOnTimeoutComponent";

@Component("Shoot")
export class ShootSchema extends Schema {
  @type(Vector2dSchema)
  shootDirection: Vector2d;

  @type("boolean")
  @defaultValue(true)
  enemyInRange: boolean;

  @type("string")
  @defaultValue("Bullet")
  entityDescription: string;

  @type("boolean")
  @defaultValue(true)
  autoDestroy: boolean;

  @type("number")
  @defaultValue(60)
  spread: number;

  @type("string")
  shootSound: string;
}

class ShootSystem implements System {
  type = "Shoot";
  category: ComponentCategory = ComponentCategory.RENDERING;
  schema = ShootSchema;
  depth = DEPTHS.COLLISION - 1;

  run(entity: number, gameModel: GameModel) {
    const shootData = gameModel.getTypedUnsafe(entity, ShootSchema);
    const cooldown = gameModel.getTypedUnsafe(entity, AttackCooldownSchema);
    gameModel.getTypedUnsafe(entity, DamageApplierSchema).damageDelay = 500;

    if (!cooldown) {
      return;
    }
    LocomotionSchema.id = entity;
    const targetDirection = directionFromTarget(entity, gameModel, shootData.shootDirection);
    shootData.shootDirection = { x: targetDirection[0], y: targetDirection[1] };
    if (cooldown.ready) {
      if (shootData.enemyInRange) {
        const enemies = gameModel.getTypedUnsafe(entity, EnemyCollidersSchema).colliders;
        const damageables = gameModel.getComponentActives("Damageable");
        const allEnemies = gameModel.getEntities(enemies).filter((e) => damageables.includes(e));
        TransformSchema.id = entity;
        const range = gameModel.getTypedUnsafe(entity, DamageStatsSchema).range;

        const closest = closestEntity(gameModel, TransformSchema.position, allEnemies, range);
        if (closest !== undefined) {
          cooldown.ready = false;
          gameModel.getTypedUnsafe(entity, TargetingSchema).target = closest;

          TransformSchema.id = entity;
          this.generateProjectiles(
            entity,
            gameModel.getTyped(entity, OwnerSchema)?.owner ?? entity,
            shootData,
            enemies,
            shootData.shootDirection,
            TransformSchema.position,
            gameModel
          );
        }
      } else {
        cooldown.ready = false;
        const enemies = gameModel.getTypedUnsafe(entity, EnemyCollidersSchema).colliders;

        TransformSchema.id = entity;
        this.generateProjectiles(
          entity,
          gameModel.getTyped(entity, OwnerSchema)?.owner ?? entity,
          shootData,
          enemies,
          shootData.shootDirection,
          TransformSchema.position,
          gameModel
        );
      }
    } else {
      if (shootData.enemyInRange) {
        const damageables = gameModel.getComponentActives("Damageable");

        const enemies = gameModel.getTypedUnsafe(entity, EnemyCollidersSchema).colliders;
        const allEnemies = gameModel.getEntities(enemies).filter((e) => damageables.includes(e));
        TransformSchema.id = entity;
        const range = gameModel.getTypedUnsafe(entity, DamageStatsSchema).range;

        const closest = closestEntity(gameModel, TransformSchema.position, allEnemies, range);
        if (closest !== undefined) {
          gameModel.getTypedUnsafe(entity, TargetingSchema).target = closest;
        }
      }
    }
    LocomotionSchema.id = entity;
    LocomotionSchema.directionX = shootData.shootDirection.x;
    LocomotionSchema.directionY = shootData.shootDirection.y;
    if (cooldown.ready) {
    } else {
      // LocomotionSchema.directionX =
      //   LocomotionSchema.store.directionX[gameModel.getTypedUnsafe(entity, OwnerSchema).owner!] || 1;
      // LocomotionSchema.directionY = 0;
    }
  }

  generateProjectiles(
    entity: number,
    owner: number,
    data: ShootSchema,
    enemyColliders: EntityType[],
    direction: Vector2dSchema,
    position: Vector2d,
    gameModel: GameModel
  ) {
    const projectileCount = gameModel.getTyped(entity, ProjectileCountSchema)?.projectileCount || 1;

    const baseDirection = directionFromTarget(entity, gameModel, direction);
    const baseBaseDirection = data.enemyInRange
      ? {
          x: baseDirection[0],
          y: baseDirection[1],
        }
      : direction;

    const range = gameModel.getTypedUnsafe(entity, DamageStatsSchema).range;

    const spread = data.spread * (Math.PI / 180);
    const step = spread / projectileCount;

    if (data.shootSound) {
      gameModel.queueSound(data.shootSound);
    }

    for (let i = 0; i < projectileCount; i++) {
      let iOffset = i % 2 === 0 ? i / 2 : -Math.floor(i / 2) - 1;
      if (i === 0 && data.enemyInRange === false && projectileCount % 2 === 0) {
        iOffset = 1;
      }
      const shootDirection = rotateVector2d(baseBaseDirection, iOffset * step);

      baseDirection[0] = shootDirection.x;
      baseDirection[1] = shootDirection.y;

      const projectile = EntityFactory.getInstance().generateEntity(gameModel, data.entityDescription, {
        Transform: {
          ...position,
        },
        EnemyColliders: {
          colliders: enemyColliders,
        },
        Child: {
          parent: entity,
        },
        Owner: {
          owner,
        },
        Follow: {
          target: gameModel.getTyped(entity, TargetingSchema)?.target ?? -1,
        },
      });
      LocomotionSchema.id = projectile;

      const velocity = BV2.scaleVector2d(baseDirection[0], baseDirection[1], LocomotionSchema.speed);
      LocomotionSchema.velocityX = velocity[0];
      LocomotionSchema.velocityY = velocity[1];
      LocomotionSchema.directionX = baseDirection[0];
      LocomotionSchema.directionY = baseDirection[1];

      if (gameModel.hasComponent(projectile, DestroyOnTimeoutSchema) && data.autoDestroy !== false) {
        const destroyTime = (range / LocomotionSchema.speed) * 16;
        const dest = gameModel.getTypedUnsafe(projectile, DestroyOnTimeoutSchema);
        if (dest.timeout > destroyTime) {
          dest.timeElapsed = 0;
          dest.timeout = destroyTime;
        }
      }

      if (gameModel.hasComponent(entity, RigidBoxSchema)) {
        gameModel.getSystem(RigidBoxSystem).init(entity, gameModel);
      } else if (gameModel.hasComponent(entity, RigidCircleSchema)) {
        gameModel.getSystem(RigidCircleSystem).init(entity, gameModel);
      }

      const [augmentComponents, auraComponents] = getWeaponAugmentComponents(entity, owner, gameModel);

      applyComponentsToProjectile(projectile, DamageDirectionEnum.OWNER, augmentComponents, auraComponents, gameModel);
    }
  }
}

registerSystem(ShootSystem);

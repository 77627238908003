import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { EnemyTypeSchema, PlayerTypeSchema } from "yage/components/entity/Types";
import { Component, defaultValue, Schema, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";

@Component("HealthPickupEnhancer")
export class HealthPickupEnhancerSchema extends Schema {
  @type("number")
  @defaultValue(0)
  health: number;
}

class HealthPickupEnhancerSystem implements System {
  schema = HealthPickupEnhancerSchema;
  type = "HealthPickupEnhancer";
  dependencies = ["Child"];
  init(entity: number, gameModel: GameModel) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (!gameModel.hasComponent(parent, "HealthPickupEnhancer")) {
        gameModel.addComponent(parent, HealthPickupEnhancerSchema);
      }
      gameModel.getTypedUnsafe(parent, HealthPickupEnhancerSchema).health += gameModel.getTypedUnsafe(
        entity,
        HealthPickupEnhancerSchema
      ).health;
    }
  }

  cleanup(entity: number, gameModel: GameModel, ejecting: boolean) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (gameModel.hasComponent(parent, "HealthPickupEnhancer")) {
        gameModel.getTypedUnsafe(parent, HealthPickupEnhancerSchema).health -= gameModel.getTypedUnsafe(
          entity,
          HealthPickupEnhancerSchema
        ).health;
      }
    }
  }
}
registerSystem(HealthPickupEnhancerSystem);

import { registerSystem } from "yage/components/ComponentRegistry";
import { ComponentCategory } from "yage/components/types";
import { Component, defaultValue, type, Schema } from "yage/decorators/type";
import type { GameModel } from "yage/game/GameModel";
import type { System } from "yage/components/System";
import { ChildSchema } from "yage/schemas/entity/Child";
import { DamageSchema, DamageableSchema } from "../../schema/damage/Damage";
import { HealthSchema, healEntity } from "../core";
import { ItemTypeSchema } from "../core/Types";
import { HpBlockSchema } from "../player/HpBlock";

@Component("HealOnCrit")
export class HealOnCritSchema extends Schema {
  @type("number")
  @defaultValue(-1)
  maxHeals: number;

  @type("number")
  @defaultValue(0)
  healChance: number;

  @type([DamageSchema])
  @defaultValue([])
  damages: DamageSchema[];
}

class HealOnCritSystem implements System {
  type = "HealOnCrit";
  category: ComponentCategory = ComponentCategory.ONHIT;
  schema = HealOnCritSchema;

  dependencies = ["ItemType", "Child"];

  init(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, HealOnCritSchema);
    if (gameModel.hasComponent(entity, ItemTypeSchema) && gameModel.hasComponent(entity, ChildSchema)) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (!gameModel.hasComponent(parent, HealOnCritSchema)) {
        gameModel.addComponent(parent, "HealOnCrit");
      }
      const parentData = gameModel.getTypedUnsafe(parent, HealOnCritSchema);
      parentData.healChance += data.healChance;
      if (data.maxHeals > 0 && parentData.maxHeals > 0) {
        parentData.maxHeals += data.maxHeals;
      }
    }
  }

  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, HealOnCritSchema);

    if (
      gameModel.hasComponent(entity, "HpBlock") &&
      !gameModel.getTypedUnsafe(entity, HpBlockSchema).excludedTypes.includes("HealOnCrit")
    ) {
      return;
    }

    if (data.damages.length === 0) {
      return;
    }
    if (!(data.healChance > 100 || data.healChance === 0 || gameModel.rand.number() < data.healChance / 100)) {
      return;
    }
    if (data.damages.find((damage) => damage.critHit)) {
      if (data.maxHeals > 0) {
        data.maxHeals--;
      }

      if (gameModel.hasComponent(entity, DamageableSchema)) {
        healEntity(entity, 1, gameModel);
      } else {
        HealthSchema.id = entity;
        HealthSchema.health++;
      }
    }
    if (data.maxHeals === 0) {
      gameModel.removeComponent(entity, "HealOnCrit");
    }
  }
}

registerSystem(HealOnCritSystem);

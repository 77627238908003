import { registerSystem } from "yage/components/ComponentRegistry";
import type { System } from "yage/components/System";
import { ComponentCategory } from "yage/components/types";
import { Component, defaultValue, Schema, type } from "yage/decorators/type";
import type { GameModel } from "yage/game/GameModel";
import { healEntity, HealthSchema } from "../core/Health";
import { HealthPickupEnhancerSchema } from "../enhancers/HealthPickupEnhancer";
import { HpBlockSchema } from "../player/HpBlock";

@Component("HealthPickup")
export class HealthPickupSchema extends Schema {
  @type("number")
  @defaultValue(0)
  health: number;

  @type("Entity")
  owner: number;

  @type("boolean")
  destroyOnPickup: boolean;
}

class HealthPickupSystem implements System {
  type = "HealthPickup";
  category: ComponentCategory = ComponentCategory.PICKUP;
  schema = HealthPickupSchema;
  run(entity: number, gameModel: GameModel) {
    const healthPickupData = gameModel.getTypedUnsafe(entity, HealthPickupSchema);

    if (
      gameModel.hasComponent(healthPickupData.owner ?? -1, "HpBlock") &&
      !gameModel.getTypedUnsafe(healthPickupData.owner ?? -1, HpBlockSchema).excludedTypes.includes("HealthPickup")
    ) {
      healthPickupData.destroyOnPickup = true;

      return;
    }

    if (healthPickupData.owner != undefined && gameModel.hasComponent(healthPickupData.owner, "Health")) {
      HealthSchema.id = healthPickupData.owner;

      let amount = healthPickupData.health;
      if (gameModel.hasComponent(healthPickupData.owner, "HealthPickupEnhancer")) {
        amount += gameModel.getTypedUnsafe(healthPickupData.owner, HealthPickupEnhancerSchema).health;
      }
      healEntity(healthPickupData.owner, amount, gameModel);

      healthPickupData.destroyOnPickup = true;
    }
  }
}

registerSystem(HealthPickupSystem);

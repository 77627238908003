import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { PlayerTypeSchema, EnemyTypeSchema } from "yage/components/entity/Types";
import { Component, Schema, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { OwnerSchema } from "yage/schemas/core/Owner";
import { ChildSchema } from "yage/schemas/entity/Child";
import { TransformSchema } from "yage/schemas/entity/Transform";
import { SpawnAlliesCloserSchema } from "../ally/SpawnAlliesCloser";

@Component("SpawnAlliesCloserEnhancer")
export class SpawnAlliesCloserEnhancerSchema extends Schema {
  @type("number")
  distance: number;
}

class SpawnAlliesCloserEnhancerSystem implements System {
  schema = SpawnAlliesCloserEnhancerSchema;
  type = "SpawnAlliesCloserEnhancer";
  dependencies = ["Child"];

  init(entity: number, gameModel: GameModel) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (!gameModel.hasComponent(parent, SpawnAlliesCloserSchema)) {
        gameModel.addComponent(parent, SpawnAlliesCloserSchema);
      }

      gameModel.getTypedUnsafe(parent, SpawnAlliesCloserSchema).distance = gameModel.getTypedUnsafe(
        entity,
        SpawnAlliesCloserEnhancerSchema
      ).distance;
    }
  }
}

registerSystem(SpawnAlliesCloserEnhancerSystem);

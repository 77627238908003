import { registerSystem } from "yage/components/ComponentRegistry";
import { ComponentCategory } from "yage/components/types";
import { Component, defaultValue, type, Schema, required } from "yage/decorators/type";
import type { GameModel } from "yage/game/GameModel";
import type { System } from "yage/components/System";
import { DamageStatsSchema, applyDamageStats, createDamageStats } from "../weapons/DamageStats";
import { BurnTickEnhancerSchema } from "../enhancers/BurnTickEnhancer";

@Component("FireDamageOnHit")
export class FireDamageOnHitSchema extends Schema {
  @type("number")
  damage: number;

  @type("number")
  @required()
  minFireDamage: number;

  @type("number")
  @required()
  maxFireDamage: number;

  @type("Entity")
  @required()
  owner: number;

  @type(["number"])
  @defaultValue([])
  colliders: number[];

  @type("number")
  @defaultValue(0.1)
  burnChance: number;

  @type("number")
  @defaultValue(3)
  burnDuration: number;

  @type("number")
  @defaultValue(0)
  spreadDuration: number;
}

class FireDamageOnHitSystem implements System {
  type = "FireDamageOnHit";
  category: ComponentCategory = ComponentCategory.DAMAGE;
  schema = FireDamageOnHitSchema;

  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, FireDamageOnHitSchema);

    if (gameModel.rand.number() <= data.burnChance / 100) {
      if (!gameModel.hasComponent(entity, "Burning")) {
        const ownerStats = gameModel.getTypedUnsafe(data.owner, DamageStatsSchema);
        const damageStats = createDamageStats();
        if (data.minFireDamage) {
          damageStats.minFireDamage = data.minFireDamage;
          damageStats.maxFireDamage = data.maxFireDamage || data.minFireDamage;
        } else if (data.damage) {
          damageStats.minFireDamage = data.damage;
          damageStats.maxFireDamage = data.damage;
        } else {
          damageStats.minFireDamage = ownerStats.minFireDamage;
          damageStats.maxFireDamage = ownerStats.maxFireDamage;
        }
        if (data.burnDuration) {
          damageStats.burnDuration = data.burnDuration;
        }
        damageStats.inheritFromOwner = false;

        let burnInterval = 1;
        if (gameModel.hasComponent(data.owner, "BurnTickEnhancer")) {
          burnInterval = burnInterval * gameModel.getTypedUnsafe(data.owner, BurnTickEnhancerSchema).burnTickScale;
        }

        gameModel.addComponent(entity, "Burning", {
          damageStats,
          owner: data.owner,
          colliders: data.colliders,
          burnInterval: burnInterval,
          spreadsLeft: data.spreadDuration,
        });
      }
    }
  }
}

registerSystem(FireDamageOnHitSystem);

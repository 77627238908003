import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { ComponentCategory, DamageTypeEnum } from "yage/constants/enums";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { StoreItemSchema } from "../pickups/StoreItem";
import { DamageStatsSchema, createDamageStats } from "../weapons/DamageStats";
import { takeDamage } from "../damage/Damageable";
import { DamageSchema } from "../../schema/damage/Damage";
import { getMaxHealth } from "../core";

@Component("ChanceDamageMaxHealthOnHealInstance")
export class ChanceDamageMaxHealthOnHealInstanceSchema extends Schema {
  @type("number")
  chance: number;

  @type("number")
  @defaultValue(0.25)
  healthDamageScale: number;
}

@Component("ChanceDamageMaxHealthOnHeal")
export class ChanceDamageMaxHealthOnHealSchema extends Schema {
  @type([ChanceDamageMaxHealthOnHealInstanceSchema])
  @defaultValue([])
  chance: ChanceDamageMaxHealthOnHealInstanceSchema[];

  @type("number")
  @defaultValue(0)
  owner: number;
}

class ChanceDamageMaxHealthOnHealSystem implements System {
  schema = ChanceDamageMaxHealthOnHealSchema;
  type = "ChanceDamageMaxHealthOnHeal";
  category = ComponentCategory.ONHEAL;

  run(entity: number, gameModel: GameModel) {
    const ChanceDamageMaxHealthOnHeal = gameModel.getTypedUnsafe(entity, ChanceDamageMaxHealthOnHealSchema);
    const chance = gameModel.getTypedUnsafe(entity, DamageStatsSchema).chance;

    const enemies = gameModel.enemies;

    if (enemies.length === 0) {
      return;
    }
    const enemy = enemies[gameModel.rand.int(0, enemies.length - 1)];

    const damages: Partial<DamageSchema>[] = [];

    for (let i = 0; i < ChanceDamageMaxHealthOnHeal.chance.length; ++i) {
      if (gameModel.rand.number() < ChanceDamageMaxHealthOnHeal.chance[i].chance) {
        const damageStats = createDamageStats();
        const maxHealth = getMaxHealth(ChanceDamageMaxHealthOnHeal.owner, gameModel);
        const damage = Math.max(1, ChanceDamageMaxHealthOnHeal.chance[i].healthDamageScale * maxHealth);
        damageStats.minDamage = damage;
        damageStats.maxDamage = damage;

        damages.push({
          frame: gameModel.timeElapsed,
          damage: damage,
          damageStats: damageStats,
          damageType: DamageTypeEnum.NORMAL,
        });
      }
    }
    if (damages.length > 0) {
      takeDamage(enemy, gameModel, damages);
    }
  }
}

registerSystem(ChanceDamageMaxHealthOnHealSystem);

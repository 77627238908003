import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { Component, defaultValue, Schema, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";
import { HealthSchema } from "../core";
import { LocomotionSchema } from "yage/schemas/entity/Locomotion";
import { EnemyTypeSchema, PlayerTypeSchema, WeaponTypeSchema } from "yage/components/entity/Types";
import { LifestealSchema } from "../core/Lifesteal";

@Component("LifestealEnhancer")
export class LifestealEnhancerSchema extends Schema {
  @type("number")
  @defaultValue(0)
  lifesteal: number;
}

class LifestealEnhancerSystem implements System {
  schema = LifestealEnhancerSchema;
  type = "LifestealEnhancer";
  dependencies = ["Child"];
  init(entity: number, gameModel: GameModel) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      !gameModel.hasComponent(entity, WeaponTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      const parentIsWeapon = gameModel.hasComponent(parent, WeaponTypeSchema);
      if (!parentIsWeapon) {
        if (!gameModel.hasComponent(parent, "LifestealEnhancer")) {
          gameModel.addComponent(parent, LifestealEnhancerSchema, {
            lifesteal: 0,
          });
        }

        gameModel.getTypedUnsafe(parent, LifestealEnhancerSchema).lifesteal += gameModel.getTypedUnsafe(
          entity,
          LifestealEnhancerSchema
        ).lifesteal;
      } else {
        if (!gameModel.hasComponent(parent, "Lifesteal")) {
          gameModel.addComponent(parent, LifestealSchema, {
            lifesteal: 0,
          });
        }
        gameModel.getTypedUnsafe(parent, LifestealSchema).lifesteal += gameModel.getTypedUnsafe(
          entity,
          LifestealEnhancerSchema
        ).lifesteal;
      }
    }
  }

  cleanup(entity: number, gameModel: GameModel, ejecting: boolean) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (gameModel.hasComponent(parent, "LifestealEnhancer")) {
        gameModel.getTypedUnsafe(parent, LifestealEnhancerSchema).lifesteal -= gameModel.getTypedUnsafe(
          entity,
          LifestealEnhancerSchema
        ).lifesteal;
      }
    }
  }
}
registerSystem(LifestealEnhancerSystem);

import { DEPTHS, registerSchema, registerSystem } from "yage/components/ComponentRegistry";
import type { System } from "yage/components/System";
import { ComponentCategory } from "yage/components/types";
import { Component, defaultValue, Schema, type } from "yage/decorators/type";
import type { GameModel } from "yage/game/GameModel";
import { TransformSchema } from "yage/schemas/entity/Transform";
import { closestEntity, sortedByDistance } from "yage/utils/Collision";
import { EnemyCollidersSchema } from "../enemy/EnemyColliders";

@Component("Targeting")
export class TargetingSchema extends Schema {
  @type("Entity")
  @defaultValue(-1)
  target: number;

  @type("number")
  @defaultValue(0)
  maxTargets: number;

  @type("number")
  @defaultValue(0)
  range: number;

  @type("boolean")
  @defaultValue(false)
  retargetOnDeath = false;

  @type("boolean")
  @defaultValue(false)
  alwaysRetarget = false;

  @type("number")
  @defaultValue(800)
  retargetCooldown: number;

  @type("number")
  @defaultValue(0)
  currentRetargetCooldown: number;

  @type("boolean")
  @defaultValue(false)
  inheritable: boolean;
}

@Component("Targeted")
export class TargetedSchema extends Schema {
  @type(["number"])
  @defaultValue([])
  targetSources: number[];
}

@Component("Untargetable")
export class UntargetableSchema extends Schema {}

registerSchema(ComponentCategory.BEHAVIOR, TargetedSchema);
registerSchema(ComponentCategory.BEHAVIOR, UntargetableSchema);

class TargetingSystem implements System {
  type = "Targeting";
  category: ComponentCategory = ComponentCategory.WEAPON_AUGMENT;
  schema = TargetingSchema;

  depth = DEPTHS.HEALTH + 500;

  dependencies = ["EnemyColliders", "Transform"];
  init(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, TargetingSchema);
    if (data.target === -1) {
      const enemyColliders = gameModel.getTypedUnsafe(entity, EnemyCollidersSchema);
      const enemies = gameModel.getEntities(enemyColliders.colliders);
      if (!enemies.length) {
        return;
      }
      TransformSchema.id = entity;
      const position = TransformSchema.position;

      const sortedEnemies = sortedByDistance(gameModel, position, enemies, data.range || undefined);
      const furthestEnemy = sortedEnemies[sortedEnemies.length - 1];
      let lowestTargetCount = 0;
      if (gameModel.hasComponent(furthestEnemy, TargetedSchema)) {
        lowestTargetCount = gameModel.getTypedUnsafe(furthestEnemy, TargetedSchema).targetSources.length;
      }
      for (let i = 0; i < sortedEnemies.length; i++) {
        const enemy = sortedEnemies[i];
        if (!gameModel.hasComponent(enemy, UntargetableSchema)) {
          if (gameModel.hasComponent(enemy, TargetedSchema)) {
            const targetedData = gameModel.getTypedUnsafe(enemy, TargetedSchema);

            if (targetedData.targetSources.length <= lowestTargetCount) {
              data.target = enemy;
              targetedData.targetSources.push(entity);
              break;
            }
          } else {
            data.target = enemy;
            gameModel.addComponent(enemy, TargetedSchema, { targetSources: [entity] });
            break;
          }
        }
      }
    }
  }

  runAll(gameModel: GameModel): void {
    const entities = gameModel.getComponentActives("Targeting");
    for (let i = 0; i < entities.length; i++) {
      const entity = entities[i];
      const data = gameModel.getTypedUnsafe(entity, TargetingSchema);
      if (data.retargetOnDeath && (data.target === -1 || !gameModel.isActive(data.target))) {
        data.target = -1;
        this.init(entity, gameModel);
      } else if (data.alwaysRetarget) {
        if (data.currentRetargetCooldown > 0) {
          data.currentRetargetCooldown -= gameModel.frameDt;
        } else {
          if (data.target !== -1) {
            const targetedData = gameModel.getTypedUnsafe(data.target, TargetedSchema);
            targetedData.targetSources = targetedData.targetSources.filter((e) => e !== entity);
          }
          data.target = -1;

          this.init(entity, gameModel);
          data.currentRetargetCooldown = data.retargetCooldown;
        }
      }
    }
  }

  cleanup(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, TargetingSchema);

    if (data.target !== -1) {
      if (gameModel.hasComponent(data.target, TargetedSchema)) {
        const targetedData = gameModel.getTypedUnsafe(data.target, TargetedSchema);
        targetedData.targetSources = targetedData.targetSources.filter((e) => e !== entity);
        if (targetedData.targetSources.length === 0) {
          gameModel.removeComponent(data.target, TargetedSchema);
        }
      }
    }
  }
}
registerSystem(TargetingSystem);

import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import type { System } from "yage/components/System";
import { ComponentCategory } from "yage/components/types";
import { type, Component, Schema, defaultValue } from "yage/decorators/type";
import type { GameModel } from "yage/game/GameModel";
import {
  Vector2d,
  Vector2dSchema,
  distanceVector2d,
  normalizeVector2d,
  scaleVector2d,
  subtractVector2d,
} from "yage/utils/vector";
import { LocomotionSchema } from "yage/schemas/entity/Locomotion";
import { RadiusSchema } from "yage/schemas/entity/Radius";
import { TransformSchema } from "yage/schemas/entity/Transform";
import { ChaseSchema } from ".";
import { AttackCooldownSchema } from "../weapons/AttackCooldown";
import { getSpeed } from "../enhancers/SpeedEnhancer";

enum ChargeState {
  CHARGE_READY,
  CHARGING,
  CHARGED,
  COOLDOWN,
}

@Component("Charge")
export class ChargeSchema extends Schema {
  @type("number")
  @defaultValue(300)
  distance: number;

  @type(ChargeState)
  @defaultValue(ChargeState.CHARGE_READY)
  state: ChargeState;

  @type(Vector2dSchema)
  chargeTarget: Vector2d;

  @type(Vector2dSchema)
  chargeDirection: Vector2d;

  @type("number")
  @defaultValue(3)
  chargeSpeed: number;

  @type("number")
  @defaultValue(600)
  chargeTime: number;

  @type("number")
  @defaultValue(0)
  chargeTimer: number;

  @type("boolean")
  @defaultValue(false)
  resetAttackCooldownOnCharge: boolean;
}

class ChargeSystem implements System {
  type = "Charge";
  category: ComponentCategory = ComponentCategory.TARGET;
  schema = ChargeSchema;
  depth = DEPTHS.LOCOMOTION + 11;

  dependencies = ["Chase", "AttackCooldown"];

  runAll?(gameModel: GameModel): void {
    const entities = gameModel.getComponentActives("Charge");

    for (let i = 0; i < entities.length; i++) {
      const entity = entities[i];
      LocomotionSchema.id = entity;
      RadiusSchema.id = entity;
      const Charge = gameModel.getTypedUnsafe(entity, ChargeSchema);
      if (Charge.distance === 0) {
        continue;
      }

      const AttackCooldown = gameModel.getTypedUnsafe(entity, AttackCooldownSchema);

      if (Charge.state === ChargeState.COOLDOWN) {
        if (AttackCooldown.ready) {
          Charge.state = ChargeState.CHARGE_READY;
        } else {
          continue;
        }
      }

      const Chase = gameModel.getTypedUnsafe(entity, ChaseSchema);

      TransformSchema.id = entity;
      const position = TransformSchema.position;
      if (!gameModel.isActive(Chase.target)) {
        Chase.target = 0;
      }
      if (Charge.state !== ChargeState.CHARGED && Charge.resetAttackCooldownOnCharge) {
        AttackCooldown.ready = false;
      }

      const target = Chase.target;
      if (target) {
        if (Charge.state === ChargeState.CHARGE_READY) {
          TransformSchema.id = target;
          const currentTargetPosition = TransformSchema.position;

          const distance = distanceVector2d(position, currentTargetPosition);

          if (distance > Charge.distance) {
            continue;
          }

          Charge.state = ChargeState.CHARGING;
          Charge.chargeTimer = 0;
        }

        if (Charge.state === ChargeState.CHARGING) {
          Charge.chargeTimer += gameModel.dt<number>(entity);
          if (Charge.chargeTimer > Charge.chargeTime) {
            if (Charge.resetAttackCooldownOnCharge) {
              AttackCooldown.ready = true;
            }
            Charge.state = ChargeState.CHARGED;
            TransformSchema.id = target;

            const currentTargetPosition = TransformSchema.position;
            Charge.chargeTarget = currentTargetPosition;
            Charge.chargeDirection = normalizeVector2d(subtractVector2d(currentTargetPosition, position));
          } else {
            LocomotionSchema.id = entity;
            LocomotionSchema.velocityX = 0;
            LocomotionSchema.velocityY = 0;
            continue;
          }
        }

        const targetPosition = Charge.chargeTarget;

        LocomotionSchema.id = entity;
        const direction = Charge.chargeDirection;
        const velocity = scaleVector2d(direction, getSpeed(entity, gameModel) * Charge.chargeSpeed);
        LocomotionSchema.velocityX = velocity.x;
        LocomotionSchema.velocityY = velocity.y;
        LocomotionSchema.directionX = direction.x;
        LocomotionSchema.directionY = direction.y;

        const toPoint = subtractVector2d(targetPosition, position);
        const dot = toPoint.x * LocomotionSchema.velocityX + toPoint.y * LocomotionSchema.velocityY;

        if (dot < 0) {
          Charge.state = ChargeState.COOLDOWN;
          AttackCooldown.ready = false;
          continue;
        }
      }
    }
  }
}

registerSystem(ChargeSystem);

import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import type { System } from "yage/components/System";
import { ComponentCategory } from "yage/components/types";
import { Component, defaultValue, Schema, type } from "yage/decorators/type";
import type { GameModel } from "yage/game/GameModel";
import { LocomotionSchema } from "yage/schemas/entity/Locomotion";
import { RigidBoxSchema } from "yage/schemas/physics/RigidBox";
import type { Vector2d } from "yage/utils/vector";
import { angleOfVector2d, BV2, Vector2dSchema } from "yage/utils/vector";
import { directionFromTarget } from "../../utils/weapons";
import { AttackCooldownSchema } from "./AttackCooldown";
import { DamageTypeEnum, SwingStateEnum } from "yage/constants/enums";
import { EnemyCollidersSchema } from "../enemy/EnemyColliders";
import { TransformSchema } from "yage/schemas/entity/Transform";
import { closestEntity } from "yage/utils/Collision";
import { TargetingSchema } from "./Targeting";
import { DamageApplierSchema } from "../damage/DamageApplier";

@Component("Swing")
export class SwingSchema extends Schema {
  @type("number")
  @defaultValue(SwingStateEnum.NONE)
  state: SwingStateEnum;

  @type("number")
  @defaultValue(100)
  range: number;

  @type("number")
  @defaultValue(0)
  currentSwingSpeed: number;

  @type("number")
  @defaultValue(160)
  angle: number;

  @type("number")
  @defaultValue(200)
  swingLengthMs: number;

  @type("number")
  @defaultValue(150)
  windupLengthMs: number;

  @type("number")
  @defaultValue(-1)
  startAngle: number;

  @type(Vector2dSchema)
  direction: Vector2d;

  @type(Vector2dSchema)
  swingDirection: Vector2d;

  @type("boolean")
  @defaultValue(true)
  enemyInRange: boolean;
}

class SwingSystem implements System {
  type = "Swing";
  category: ComponentCategory = ComponentCategory.RENDERING;
  schema = SwingSchema;
  depth = DEPTHS.COLLISION - 1;
  run(entity: number, gameModel: GameModel) {
    const swingData = gameModel.getTypedUnsafe(entity, SwingSchema);
    const cooldown = gameModel.getTypedUnsafe(entity, AttackCooldownSchema);

    // const targetDirection = directionFromTarget(entity, gameModel, swingData.swingDirection);
    // swingData.swingDirection = { x: targetDirection[0], y: targetDirection[1] };

    const damages = gameModel.getTypedUnsafe(entity, DamageApplierSchema).damages;
    const damage = damages.find((d) => d.damageType === DamageTypeEnum.NORMAL) ?? damages[0];

    const range = (swingData.range + (damage?.damageStats.range ?? 100) / 100) * 2;

    let angle = swingData.angle / 2;
    if (swingData.state === SwingStateEnum.WINDUP) {
      swingData.currentSwingSpeed += gameModel.dt<number>(entity);
      if (swingData.currentSwingSpeed > swingData.windupLengthMs) {
        swingData.state = SwingStateEnum.SWING;
        swingData.currentSwingSpeed = 0;
        angle = swingData.angle;
      } else {
        angle =
          swingData.angle -
          ((swingData.currentSwingSpeed / swingData.windupLengthMs / 2) * swingData.angle + swingData.angle / 2);
      }
    }
    if (swingData.state === SwingStateEnum.SWING) {
      swingData.currentSwingSpeed += gameModel.dt<number>(entity);
      if (swingData.currentSwingSpeed > swingData.swingLengthMs) {
        swingData.state = SwingStateEnum.NONE;
        swingData.currentSwingSpeed = 0;
      } else {
        angle = (swingData.currentSwingSpeed / swingData.swingLengthMs) * swingData.angle;
      }
    } else if (cooldown.ready) {
      if (swingData.enemyInRange) {
        const enemies = gameModel.getTypedUnsafe(entity, EnemyCollidersSchema).colliders;
        const allEnemies = gameModel.getEntities(enemies);
        TransformSchema.id = entity;
        const closest = closestEntity(gameModel, TransformSchema.position, allEnemies, range + 100);
        if (closest !== undefined) {
          cooldown.ready = false;
          swingData.state = SwingStateEnum.WINDUP;
          gameModel.getTypedUnsafe(entity, TargetingSchema).target = closest;
        }
      } else {
        cooldown.ready = false;
        swingData.state = SwingStateEnum.WINDUP;
      }
    }

    let directionX = swingData.direction?.x;
    let directionY = swingData.direction?.y;
    LocomotionSchema.id = entity;

    if (directionX === undefined) {
      const targetDirection = directionFromTarget(entity, gameModel);
      directionX = targetDirection[0];
      directionY = targetDirection[1];
    }
    if (swingData.startAngle === -1) {
      angle -= swingData.angle / 2;
    } else {
      angle += swingData.startAngle;
    }

    if (directionX < 0) {
      angle = -angle;
    }

    const rotatedDirection = BV2.rotateDegVector2d(directionX, directionY, angle);
    LocomotionSchema.directionX = rotatedDirection[0];
    LocomotionSchema.directionY = rotatedDirection[1];

    if (gameModel.hasComponent(entity, RigidBoxSchema)) {
      const rigidBox = gameModel.getTypedUnsafe(entity, RigidBoxSchema);
      rigidBox.disabled = swingData.state !== SwingStateEnum.SWING;
      rigidBox.angle = angleOfVector2d({ x: LocomotionSchema.directionX, y: LocomotionSchema.directionY });
    }
  }
}

registerSystem(SwingSystem);

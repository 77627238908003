import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { Component, defaultValue, Schema, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";

@Component("HpScaler")
export class HpScalerSchema extends Schema {
  @type("number")
  @defaultValue(0)
  healthScale: number;
}

class HpScalerSystem implements System {
  schema = HpScalerSchema;
  type = "HpScaler";
  dependencies = ["Child"];
  init(entity: number, gameModel: GameModel) {
    if (gameModel.hasComponent(entity, ChildSchema)) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (!gameModel.hasComponent(parent, "HpScaler")) {
        gameModel.addComponent(parent, HpScalerSchema);
      }
      gameModel.getTypedUnsafe(parent, HpScalerSchema).healthScale += gameModel.getTypedUnsafe(
        entity,
        HpScalerSchema
      ).healthScale;
    }
  }
}
registerSystem(HpScalerSystem);

import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { PlayerTypeSchema, EnemyTypeSchema } from "yage/components/entity/Types";
import { ComponentCategory } from "yage/constants/enums";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";
import { BossTypeSchema } from "../core/BossType";
import { HealthSchema } from "../core";

@Component("EnemyPercentHealthDamageMod")
export class EnemyPercentHealthDamageModSchema extends Schema {
  @type("number")
  @defaultValue(0.1)
  percentDamage: number;

  @type("number")
  @defaultValue(0.01)
  bossPercentDamage: number;

  @type("boolean")
  @defaultValue(false)
  onCrit: boolean;

  @type("number")
  @defaultValue(0)
  damage: number;

  @type("number")
  @defaultValue(0)
  maker: number;

  @type("number")
  @defaultValue(0)
  taker: number;

  @type("boolean")
  @defaultValue(false)
  crit: boolean;
}

class EnemyPercentHealthDamageModSystem implements System {
  schema = EnemyPercentHealthDamageModSchema;
  type = "EnemyPercentHealthDamageMod";
  category = ComponentCategory.ON_APPLY_DAMAGE;

  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, EnemyPercentHealthDamageModSchema);

    if (data.onCrit && !data.crit) {
      return;
    }

    HealthSchema.id = data.taker;
    const currentHealth = HealthSchema.health ?? 0;

    if (currentHealth <= 0) {
      return;
    }

    const additionalDamage =
      currentHealth *
      (gameModel.hasComponent(data.taker, BossTypeSchema) ? data.bossPercentDamage : data.percentDamage);
    data.damage += Math.ceil(additionalDamage);
  }
}

registerSystem(EnemyPercentHealthDamageModSystem);

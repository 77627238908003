import { PlayerInputSchema } from "yage/schemas/core/PlayerInput";
import { DEPTHS, registerPixiComponent, registerSystem } from "yage/components/ComponentRegistry";
import type { PixiDrawSystem } from "yage/components/PixiDrawSystem";
import { ComponentCategory } from "yage/components/types";
import { Component, defaultValue, type } from "yage/decorators/type";
import type { GameModel } from "yage/game/GameModel";
import type { Vector2d } from "yage/utils/vector";
import { Vector2dSchema, distanceSquaredVector2d } from "yage/utils/vector";
import * as PIXI from "pixi.js";
import { keyPressed } from "yage/utils/keys";
import { MappedKeys } from "yage/inputs/InputManager";
import { BaseTriggerSchema, BaseTriggerSystem } from "./BaseTrigger";
import { TransformSchema } from "yage/schemas/entity/Transform";
import { SpriteSchema } from "yage/schemas/render/Sprite";
import { MapSpriteSchema } from "yage/schemas/render/MapSprite";
import { Viewport } from "pixi-viewport";

@Component("AtLocationTrigger")
export class AtLocationTriggerSchema extends BaseTriggerSchema {
  @type(Vector2dSchema)
  location: Vector2d;

  @type("number")
  @defaultValue(10)
  radius: number;

  @type("number")
  @defaultValue(0)
  innerRadius: number;

  @type("boolean")
  @defaultValue(false)
  triggerOnUse: boolean;

  @type("string")
  sourceDescription: string;

  @type("boolean")
  @defaultValue(false)
  inclusiveOfSource: boolean;
}

export class AtLocationTriggerSystem extends BaseTriggerSystem {
  type = "AtLocationTrigger";
  category: ComponentCategory = ComponentCategory.TRIGGER;
  schema = AtLocationTriggerSchema;
  depth = DEPTHS.COLLISION + 10;

  dependencies = ["Transform"];

  shouldTrigger(entity: number, gameModel: GameModel): false | number[] {
    const trigger = gameModel.getComponent(entity, this.type) as AtLocationTriggerSchema;

    if (trigger.disableOnHidden) {
      let sprite; //gameModel.getTypedUnsafe(entity, SpriteSchema);
      if (gameModel.hasComponent(entity, "Sprite")) {
        sprite = gameModel.getTypedUnsafe(entity, SpriteSchema);
      } else if (gameModel.hasComponent(entity, "MapSprite")) {
        sprite = gameModel.getTypedUnsafe(entity, MapSpriteSchema);
      } else {
        return false;
      }
      if (sprite.opacity === 0) return false;
    }

    let triggerLocation = trigger.location;
    if (!triggerLocation) {
      TransformSchema.id = entity;
      triggerLocation = TransformSchema.position;
    }
    const radiusSq = trigger.radius * trigger.radius;
    const innerRadiusSq = trigger.innerRadius * trigger.innerRadius;

    let entities: number[] = [];

    if (trigger.sourceDescription) {
      entities = gameModel.getEntityByDescription(trigger.sourceDescription) ?? [];
      if (trigger.inclusiveOfSource) {
        entities.push(...gameModel.players);
      }
    } else {
      entities = gameModel.players;
    }

    const shouldTrigger: number[] = [];

    for (let i = 0; i < entities.length; i++) {
      const player = entities[i];
      TransformSchema.id = player;
      const position = TransformSchema.position;
      const distance = distanceSquaredVector2d(position, triggerLocation);

      let keyPressCheck = true;
      if (trigger.triggerOnUse) {
        const netData = gameModel.getTypedUnsafe(player, PlayerInputSchema);
        keyPressCheck = keyPressed([MappedKeys.USE], netData.keyMap, netData.prevKeyMap);
      }

      if (distance < radiusSq && distance > innerRadiusSq && keyPressCheck) {
        shouldTrigger.push(player);
      } else if (trigger.triggerType === "ALLPLAYERS") {
        return false;
      }
    }

    return !!shouldTrigger.length && shouldTrigger;
  }
}

registerSystem(AtLocationTriggerSystem);

class DebugAtLocationTrigger implements PixiDrawSystem {
  schema = AtLocationTriggerSchema;
  ids: Set<number> = new Set();
  entities: {
    [id: number]: { container: PIXI.Container; radiusGraphic: PIXI.Graphics };
  } = {};
  debug = true;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  run: (entity: number, gameModel: GameModel) => void = () => {};

  init(entity: number, gameModel: GameModel, viewport: Viewport) {
    const container = new PIXI.Container();
    container.zIndex = 100;
    const trigger = gameModel.getTypedUnsafe(entity, AtLocationTriggerSchema);

    if (!trigger.location) {
      TransformSchema.id = entity;
      trigger.location = TransformSchema.position;
    }

    if (trigger.innerRadius) {
      const innerRadius = trigger.innerRadius;
      const innerRadiusGraphic = new PIXI.Graphics();
      innerRadiusGraphic.lineStyle(5, 0x00ff00);
      innerRadiusGraphic.drawCircle(0, 0, innerRadius);

      container.addChild(innerRadiusGraphic as any);
    }

    const radius = trigger.radius;
    const radiusGraphic = new PIXI.Graphics();
    radiusGraphic.lineStyle(5, 0xff0000);
    radiusGraphic.drawCircle(0, 0, radius);

    container.addChild(radiusGraphic as any);
    container.zIndex = 100000;
    container.position.set(trigger.location.x, trigger.location.y);

    const entityObj: any = {
      container,
      radiusGraphic,
    };

    viewport.addChild(container as any);
    this.entities[entity] = entityObj;
    this.ids.add(entity);
  }

  cleanup(entity: number) {
    if (!this.entities[entity]) {
      return;
    }
    const container = this.entities[entity].container;
    container.children.forEach((child) => {
      container.removeChild(child);
      child.destroy();
    });

    container.destroy();
    delete this.entities[entity];
    this.ids.delete(entity);
  }
}

registerPixiComponent("AtLocationTrigger", DebugAtLocationTrigger);

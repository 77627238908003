import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { HealthSchema } from "../core";
import { createDamageStats } from "../weapons/DamageStats";
import { takeDamage } from "./Damageable";
import { ChildSchema } from "yage/schemas/entity/Child";
import { DamageTypeEnum } from "yage/constants/enums";

@Component("Poison")
export class PoisonSchema extends Schema {
  @type("number")
  @defaultValue(0)
  damage: number;

  @type("number")
  @defaultValue(0)
  timeElapsed: number;

  @type("number")
  @defaultValue(0)
  duration: number;

  @type("Entity")
  @defaultValue(-1)
  target: number;
}

class PoisonSystem implements System {
  schema = PoisonSchema;
  type = "Poison";
  depth = DEPTHS.HEALTH - 0.000001;

  dependencies = ["Child", "Health"];

  init(entity: number, gameModel: GameModel) {
    const poisonData = gameModel.getTypedUnsafe(entity, PoisonSchema);
    if (poisonData.target === -1) {
      if (!gameModel.hasComponent(entity, HealthSchema)) {
        const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
        if (gameModel.hasComponent(parent, HealthSchema)) {
          poisonData.target = parent;
        }
      }
    }
  }

  run(entity: number, gameModel: GameModel) {
    const poisonData = gameModel.getTypedUnsafe(entity, PoisonSchema);
    if (poisonData.damage <= 0) return;

    if (
      !gameModel.isActive(poisonData.target) ||
      (poisonData.duration && poisonData.timeElapsed > poisonData.duration)
    ) {
      gameModel.removeComponent(entity, "Poison");
      return;
    }

    const tick = gameModel.dt<number>(entity);
    if ((poisonData.timeElapsed + tick) % 1000 < poisonData.timeElapsed % 1000) {
      const randDamageStats = createDamageStats();
      randDamageStats.minChaosDamage = poisonData.damage;
      randDamageStats.maxChaosDamage = poisonData.damage;
      const owner = gameModel.getTypedUnsafe(entity, ChildSchema).parent ?? entity;

      takeDamage(poisonData.target, gameModel, [
        {
          damageStats: randDamageStats,
          source: entity,
          owner: owner,
          frame: gameModel.timeElapsed,
          damageType: DamageTypeEnum.CHAOS,
        },
      ]);
    }
    poisonData.timeElapsed += tick;
  }
}

registerSystem(PoisonSystem);

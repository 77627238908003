import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";
import { ChanceDoubleValueOnPickupSchema } from "../effects/ChanceDoubleValueOnPickup";
import { EnemyTypeSchema, PlayerTypeSchema } from "yage/components/entity/Types";

@Component("ChanceDoubleValueOnPickupEnhancer")
export class ChanceDoubleValueOnPickupEnhancerSchema extends Schema {
  @type("number")
  @defaultValue(0.05)
  chance: number;
}

class ChanceDoubleValueOnPickupSystem implements System {
  schema = ChanceDoubleValueOnPickupEnhancerSchema;
  type = "ChanceDoubleValueOnPickupEnhancer";
  dependencies = ["Child"];
  init(entity: number, gameModel: GameModel) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (!gameModel.hasComponent(parent, "ChanceDoubleValueOnPickup")) {
        gameModel.addComponent(parent, ChanceDoubleValueOnPickupSchema);
      }
      gameModel.getTypedUnsafe(parent, ChanceDoubleValueOnPickupSchema).chance += gameModel.getTypedUnsafe(
        entity,
        ChanceDoubleValueOnPickupEnhancerSchema
      ).chance;
    }
  }

  cleanup(entity: number, gameModel: GameModel, ejecting: boolean) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (gameModel.hasComponent(parent, "ChanceDoubleValueOnPickup")) {
        gameModel.getTypedUnsafe(parent, ChanceDoubleValueOnPickupSchema).chance -= gameModel.getTypedUnsafe(
          entity,
          ChanceDoubleValueOnPickupEnhancerSchema
        ).chance;
      }
    }
  }
}
registerSystem(ChanceDoubleValueOnPickupSystem);

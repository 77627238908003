import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { ComponentCategory } from "yage/constants/enums";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { addBloop } from "../render/TextBloop";
import { TransformSchema } from "yage/schemas/entity/Transform";

@Component("NegateDamage")
export class NegateDamageSchema extends Schema {
  @type("number")
  @defaultValue(0)
  negateCount: number;

  @type("number")
  damageScale: number;

  @type("number")
  @defaultValue(0)
  currentDamageScale: number;

  @type("number")
  @defaultValue(0)
  damage: number;

  @type("number")
  @defaultValue(-1)
  maker: number;
}

class NegateDamageSystem implements System {
  schema = NegateDamageSchema;
  type = "NegateDamage";
  category = ComponentCategory.ONDAMAGE;
  depth = DEPTHS.NORUN + 1;

  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, NegateDamageSchema);
    const damage = data.currentDamageScale * data.damage;
    if (damage <= 0) {
      return;
    }

    if (data.negateCount > 0) {
      data.negateCount--;
      data.damageScale = 0;
      TransformSchema.id = entity;
      const startingPosition = TransformSchema.position;

      addBloop(
        {
          gravity: 10,
          color: 0x7f00ff,
          startFrame: gameModel.timeElapsed,
          timeout: gameModel.timeElapsed + 500,
          position: {
            x: startingPosition.x + gameModel.rand.int(0, 100) - 50,
            y: startingPosition.y + gameModel.rand.int(0, 100) - 50,
          },
          yVelocity: -400,
          xVelocity: gameModel.rand.int(30, 200) * (gameModel.rand.int(0, 1) ? 1 : -1),
          text: "Nullified",
        },
        gameModel
      );
      return;
    }

    gameModel.removeComponent(entity, "NegateDamage");
  }
}

registerSystem(NegateDamageSystem);

import { System } from "yage/components/System";
import { Component, Schema, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { HealthSchema } from ".";
import { PlayerTypeSchema, EnemyTypeSchema } from "yage/components/entity/Types";
import { ChildSchema } from "yage/schemas/entity/Child";
import { registerSystem } from "yage/components/ComponentRegistry";
import { ComponentCategory } from "yage/constants/enums";

@Component("HealthCap")
export class HealthCapSchema extends Schema {
  @type("number")
  healthCap: number;
}

class HealthCapSystem implements System {
  type = "HealthCap";
  schema = HealthCapSchema;
  category = ComponentCategory.PERSIST;

  dependencies = ["Child"];

  init(entity: number, gameModel: GameModel) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      gameModel.addIfMissing(parent, HealthCapSchema);
    } else {
      const data = gameModel.getTypedUnsafe(entity, HealthCapSchema);
      HealthSchema.id = entity;
      if (data.healthCap === undefined) {
        data.healthCap = HealthSchema.maxHealth;
      }
    }
  }
}

registerSystem(HealthCapSystem);

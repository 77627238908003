import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { healEntity } from "../core";
import { HpBlockSchema } from "./HpBlock";

@Component("HpRegenFlat")
export class HpRegenFlatSchema extends Schema {
  @type("number")
  @defaultValue(0)
  regen: number;

  @type("number")
  @defaultValue(0)
  lastRegenTime: number;

  @type("number")
  @defaultValue(1000)
  frequency: number;
}

class HpRegenFlatSystem implements System {
  schema = HpRegenFlatSchema;
  type = "HpRegenFlat";
  depth = DEPTHS.HEALTH - 0.000001;

  dependencies?: string[] | undefined;

  init(entity: number, gameModel: GameModel) {
    gameModel.getTypedUnsafe(entity, HpRegenFlatSchema).lastRegenTime = gameModel.timeElapsed;
  }

  run(entity: number, gameModel: GameModel) {
    const regenData = gameModel.getTypedUnsafe(entity, HpRegenFlatSchema);
    if (regenData.regen <= 0) return;

    if (
      gameModel.hasComponent(entity, "HpBlock") &&
      !gameModel.getTypedUnsafe(entity, HpBlockSchema).excludedTypes.includes("HpRegenFlat")
    ) {
      return;
    }

    if (regenData.lastRegenTime <= gameModel.timeElapsed - regenData.frequency) {
      healEntity(entity, regenData.regen, gameModel);
      regenData.lastRegenTime = gameModel.timeElapsed;
    }
  }
  cleanup?: ((entity: number, gameModel: GameModel, ejecting: boolean) => void) | undefined;
}

registerSystem(HpRegenFlatSystem);

import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import { ComponentCategory } from "yage/constants/enums";
import { Component, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { HealthSchema } from "../core";
import { BaseTriggerSchema, BaseTriggerSystem } from "./BaseTrigger";

@Component("SpawnTrigger")
export class SpawnTriggerSchema extends BaseTriggerSchema {
  @type("string")
  spawnName: string;
}

export class SpawnTriggerSystem extends BaseTriggerSystem {
  type = "SpawnTrigger";
  category: ComponentCategory = ComponentCategory.TRIGGER;
  schema = SpawnTriggerSchema;
  depth = DEPTHS.DAMAGE + 10;

  dependencies = ["Transform"];

  shouldTrigger(entity: number, gameModel: GameModel): false | number[] {
    if (!super.shouldTrigger(entity, gameModel)) {
      return false;
    }
    const trigger = gameModel.getComponent(entity, this.type) as SpawnTriggerSchema;

    const health = gameModel.getTypedUnsafe(entity, HealthSchema);

    if (health.health > 0) {
      return false;
    }

    trigger.triggerEvent = [
      {
        ...trigger.triggerEvent[0],
        event: "ENTITY",
        name: trigger.spawnName,
      },
    ];
    return gameModel.players;
  }
}

registerSystem(SpawnTriggerSystem);

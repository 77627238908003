import { registerSystem } from "yage/components/ComponentRegistry";
import { Component } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";
import { System } from "yage/components/System";
import { ProjectilePierceSchema } from "../../schema/damage/ProjectilePierce";
import { EnemyTypeSchema, PlayerTypeSchema } from "yage/components/entity/Types";

@Component("ProjectilePierceEnhancer")
export class ProjectilePierceEnhancerSchema extends ProjectilePierceSchema {}

class ProjectilePierceEnhancerSystem implements System {
  schema = ProjectilePierceEnhancerSchema;
  type = "ProjectilePierceEnhancer";
  dependencies = ["Child"];
  init(entity: number, gameModel: GameModel) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (!gameModel.hasComponent(parent, ProjectilePierceEnhancerSchema)) {
        gameModel.addComponent(parent, ProjectilePierceEnhancerSchema);
      }

      gameModel.getTypedUnsafe(parent, ProjectilePierceEnhancerSchema).pierceCount += gameModel.getTypedUnsafe(
        entity,
        ProjectilePierceEnhancerSchema
      ).pierceCount;
    }
  }

  cleanup(entity: number, gameModel: GameModel, ejecting: boolean) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (gameModel.hasComponent(parent, ProjectilePierceEnhancerSchema)) {
        gameModel.getTypedUnsafe(parent, ProjectilePierceEnhancerSchema).pierceCount -= gameModel.getTypedUnsafe(
          entity,
          ProjectilePierceEnhancerSchema
        ).pierceCount;
      }
    }
  }
}
registerSystem(ProjectilePierceEnhancerSystem);

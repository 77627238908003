//

import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { ComponentCategory } from "yage/constants/enums";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { StoreItemSchema } from "../pickups/StoreItem";
import { EntityFactory } from "yage/entity/EntityFactory";
import { InventorySchema } from "../player/Inventory";
import { getPlayerCharacter } from "../../utils/playerCharacter";
import { ExpEnhancerSchema } from "../enhancers/ExpEnhancer";
import { StorePlayerStateOnDeathSchema } from "../player/StorePlayerStateOnDeath";
import { DamageStatsSchema, invalidateDamageStats } from "../weapons/DamageStats";
import { generateShareList } from "yage/utils/generateShareList";
import { ShareOnLevelUpSchema } from "./ShareOnLevelUp";

@Component("Exp")
export class ExpSchema extends Schema {
  @type("number")
  @defaultValue(0)
  exp: number;

  @type("number")
  @defaultValue(1)
  level: number;

  @type(StoreItemSchema)
  pickupData: StoreItemSchema;
}

export class ExpSystem implements System {
  schema = ExpSchema;
  type = "Exp";
  category = ComponentCategory.ON_ADD_TO_INVENTORY;

  run(entity: number, gameModel: GameModel) {
    const ExpOnPickupData = gameModel.getTypedUnsafe(entity, ExpSchema);

    if (ExpOnPickupData.pickupData.inventoryType === "coins") {
      if (gameModel.hasComponent(entity, "Exp") && !gameModel.hasComponent(entity, StorePlayerStateOnDeathSchema)) {
        const expData = gameModel.getTypedUnsafe(entity, ExpSchema);
        const playerCharacter = getPlayerCharacter(entity, gameModel);
        expData.exp += 1;

        // (Level + 3)*(Level + 3)
        const nextLevelRequirement = (expData.level + 3) * (expData.level + 3);

        let expScale = 1;
        if (gameModel.hasComponent(playerCharacter, "ExpEnhancer")) {
          expScale = gameModel.getTypedUnsafe(playerCharacter, ExpEnhancerSchema).expScale;
        }

        if (expData.exp * expScale >= nextLevelRequirement) {
          expData.exp = 0;
          expData.level += 1;

          const shareList = generateShareList(
            playerCharacter,
            ShareOnLevelUpSchema,
            ComponentCategory.ONLEVELUP,
            gameModel
          );
          if (shareList.length > 0) {
            for (let i = 0; i < shareList.length; i++) {
              const [component, entities] = shareList[i];
              for (let j = 0; j < entities.length; j++) {
                const entityId = entities[j];
                const mod = gameModel.getComponentUnsafe(entityId, component);
                if (mod.owner !== undefined) {
                  mod.owner = playerCharacter;
                }
                const system = gameModel.getSystem((mod as any).type);
                system.run?.(entityId, gameModel);
              }
            }
          }

          const inventory = gameModel.getTypedUnsafe(entity, InventorySchema);

          if (!inventory.inventory["levelUps"]) {
            inventory.inventory["levelUps"] = {
              amount: 0,
            };
          }
          inventory.inventory["levelUps"].amount += 1;

          if (gameModel.hasComponent(playerCharacter, "InvalidateDamageStatsOnLevelChange")) {
            invalidateDamageStats(playerCharacter, gameModel);
          }
        }
      }
    }
  }
}

registerSystem(ExpSystem);

import { registerPixiComponent, registerSchema } from "yage/components/ComponentRegistry";
import { PixiDrawSystem } from "yage/components/PixiDrawSystem";
import { Component, Schema } from "yage/decorators/type";
import * as PIXIType from "pixi.js";
import { GameModel } from "yage/game/GameModel";
import { Viewport } from "pixi-viewport";
import { TransformSchema } from "yage/schemas/entity/Transform";
import { SpriteSchema } from "yage/schemas/render/Sprite";
import { SpriteComponentPixi } from "yage/components/render/Sprite";

import * as PixiFilters from "pixi-filters";

// @ts-ignore
const PIXI = () => window.__YAGE__["PIXI"];

@Component("BuffAura")
export class BuffAuraSchema extends Schema {}

registerSchema(BuffAuraSchema);

class RenderBuffAuraSystem implements PixiDrawSystem {
  ids: Set<number> = new Set();
  schema = BuffAuraSchema;

  init(entity: number, gameModel: GameModel, viewport: Viewport) {
    this.ids.add(entity);
    const sprite = gameModel.getPixiSystem("Sprite") as SpriteComponentPixi;
    const spriteInstance = sprite.instances[entity];
    spriteInstance.sprite.filters = [
      new PixiFilters.GlowFilter({
        distance: 15,
        outerStrength: 2,
        innerStrength: 1,
        color: 0xff0000,
        alpha: 0.5,
      }),
    ];
  }

  run(entity: number, gameModel: GameModel) {}

  cleanup(entity: number, gameModel: GameModel, viewport: Viewport) {
    this.ids.delete(entity);

    const sprite = gameModel.getPixiSystem("Sprite") as SpriteComponentPixi;
    const spriteInstance = sprite?.instances?.[entity];
    if (!spriteInstance) {
      return;
    }
    spriteInstance.sprite.filters = [];
  }
}

registerPixiComponent("BuffAura", RenderBuffAuraSystem);

import { registerSystem } from "yage/components/ComponentRegistry";
import type { System } from "yage/components/System";
import { ComponentCategory } from "yage/components/types";
import { Component, defaultValue, Schema, type } from "yage/decorators/type";
import { EntityFactory } from "yage/entity/EntityFactory";
import type { GameModel } from "yage/game/GameModel";
import { rotateDegVector2d } from "yage/utils/vector";

@Component("WeaponContainer")
export class WeaponContainerSchema extends Schema {
  @type("EntityArray")
  @defaultValue([])
  weaponIds: number[];

  @type("number")
  @defaultValue(2)
  maxSize: number;

  @type("number")
  @defaultValue(2)
  size: number;

  @type(["string"])
  @defaultValue([])
  weapons: string[];
}

class WeaponContainerSystem implements System {
  type = "WeaponContainer";
  category: ComponentCategory = ComponentCategory.CONTAINER;
  schema = WeaponContainerSchema;

  init(entity: number, gameModel: GameModel) {
    const weaponContainer = gameModel.getTypedUnsafe(entity, WeaponContainerSchema);
    if (weaponContainer.weapons.length && !weaponContainer.weaponIds.length) {
      for (let i = 0; i < weaponContainer.weapons.length; ++i) {
        let weaponName = weaponContainer.weapons[i];
        let distance = Math.min(weaponContainer.weapons.length * 20, 70);

        const positionOffset = 360 / weaponContainer.weapons.length;
        const rotation = rotateDegVector2d(
          {
            x: -distance,
            y: 0,
          },
          positionOffset * i
        );
        rotation.y = rotation.y + 20;
        const weapon = EntityFactory.getInstance().generateEntity(gameModel, weaponName, {
          Owner: {
            owner: entity,
          },
          Child: {
            parent: entity,
            offset: rotation,
          },
        });
        weaponContainer.weaponIds.push(weapon);
      }
    }
  }

  cleanup(entity: number, gameModel: GameModel, ejecting: boolean) {
    if (ejecting) return;
    console.log("REMOVING?!");
    gameModel.getTypedUnsafe(entity, WeaponContainerSchema).weaponIds.forEach((id: number) => {
      gameModel.removeEntity(id);
    });
    gameModel.getTypedUnsafe(entity, WeaponContainerSchema).weaponIds = [];
  }
}

registerSystem(WeaponContainerSystem);

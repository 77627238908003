import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { Component, Schema, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { LocomotionSchema } from "yage/schemas/entity/Locomotion";
import { TransformSchema } from "yage/schemas/entity/Transform";
import { distanceVector2d, lengthVector2d, normalizeVector2d, subtractVector2d } from "yage/utils/vector";

@Component("Follow")
export class FollowSchema extends Schema {
  @type("Entity")
  target: number;
}

class FollowSystem implements System {
  schema = FollowSchema;
  type = "Follow";
  depth = DEPTHS.LOCOMOTION - 0.0001;
  run(entity: number, gameModel: GameModel) {
    const target = gameModel.getTypedUnsafe(entity, FollowSchema).target;
    if (gameModel.isActive(target)) {
      TransformSchema.id = entity;
      const position = TransformSchema.position;
      TransformSchema.id = target;
      const targetPosition = TransformSchema.position;
      const distance = distanceVector2d(position, targetPosition);
      const direction = normalizeVector2d(subtractVector2d(targetPosition, position));
      LocomotionSchema.id = entity;
      let speed = LocomotionSchema.speed;
      if (distance < LocomotionSchema.speed) {
        speed = distance * 0.3;
      }
      LocomotionSchema.directionX = direction.x;
      LocomotionSchema.directionY = direction.y;
      LocomotionSchema.velocityX = direction.x * speed;
      LocomotionSchema.velocityY = direction.y * speed;
    } else {
      LocomotionSchema.id = entity;
      const speed = lengthVector2d(LocomotionSchema.velocity);
      if (speed < LocomotionSchema.speed) {
        LocomotionSchema.velocityX = LocomotionSchema.directionX * LocomotionSchema.speed;
        LocomotionSchema.velocityY = LocomotionSchema.directionY * LocomotionSchema.speed;
      }
      gameModel.removeComponent(entity, FollowSchema);
    }
  }
}

registerSystem(FollowSystem);

import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import { ComponentCategory } from "yage/constants/enums";
import { Component, required, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { BaseTriggerSchema, BaseTriggerSystem } from "./BaseTrigger";

@Component("TimeTrigger")
export class TimeTriggerSchema extends BaseTriggerSchema {
  @type("number")
  @required()
  value: number;

  @type("number")
  initialTime: number;
}

export class TimeTriggerSystem extends BaseTriggerSystem {
  type = "TimeTrigger";
  category: ComponentCategory = ComponentCategory.TRIGGER;
  schema = TimeTriggerSchema;
  depth = DEPTHS.ITEMS + 10;

  init(entity: number, gameModel: GameModel) {
    const trigger = gameModel.getTypedUnsafe(entity, this.schema);
    trigger.initialTime = gameModel.timeElapsed;
  }

  shouldTrigger(entity: number, gameModel: GameModel): false | number[] {
    if (!super.shouldTrigger(entity, gameModel)) {
      console.log("not triggering time");
      return false;
    }
    const trigger = gameModel.getComponent(entity, this.type) as TimeTriggerSchema;

    if (gameModel.timeElapsed - trigger.initialTime < trigger.value) {
      return false;
    }

    return gameModel.players;
  }
}

registerSystem(TimeTriggerSystem);

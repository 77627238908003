import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import type { System } from "yage/components/System";
import { ComponentCategory } from "yage/components/types";
import { PickupStateEnum } from "yage/constants/enums";
import { Component, defaultValue, Schema, type } from "yage/decorators/type";
import type { GameModel } from "yage/game/GameModel";
import { TransformSchema } from "yage/schemas/entity/Transform";
import { closestEntity, fastEntityCollision } from "yage/utils/Collision";
import type { Vector2d } from "yage/utils/vector";
import { Vector2dSchema, lerpVector2d } from "yage/utils/vector";
import { MagnetEnhancerSchema } from "../enhancers/MagnetEnhancer";
import { PickupStateSchema } from "./PickupState";
import { AutoPickupChanceSchema } from "./AutoPickupChance";

@Component("MagnetPickup")
export class MagnetPickupSchema extends Schema {
  @type("number")
  @defaultValue(50)
  radius: number;

  @type(Vector2dSchema)
  @defaultValue({ x: 0, y: 0 })
  startPosition: Vector2d;

  @type("number")
  @defaultValue(-1)
  target: number;

  @type("number")
  @defaultValue(0)
  startFrame: number;

  @type("number")
  @defaultValue(500)
  pullTimeMs: number;
}
class MagnetPickup implements System {
  type = "MagnetPickup";
  category: ComponentCategory = ComponentCategory.BEHAVIOR;
  schema = MagnetPickupSchema;
  depth: number = DEPTHS.COLLISION + 100;

  dependencies = ["Transform"];

  init(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, MagnetPickupSchema);
    if (
      gameModel.hasComponent(entity, "PickupState") &&
      gameModel.getTypedUnsafe(entity, PickupStateSchema).state !== PickupStateEnum.ON_THE_GROUND
    ) {
      return;
    }

    const entities = gameModel.players.filter((player) => {
      if (gameModel.hasComponent(player, "AutoPickupChance")) {
        return gameModel.rand.number() < gameModel.getTypedUnsafe(player, AutoPickupChanceSchema).chance;
      }
    });

    if (entities.length) {
      TransformSchema.id = entity;
      const entityPosition = TransformSchema.position;
      data.target = entities[0];
      if (entities.length > 1) {
        data.target = closestEntity(gameModel, entityPosition, entities) || -1;
      }

      if (gameModel.hasComponent(entity, "PickupState")) {
        gameModel.getTypedUnsafe(entity, PickupStateSchema).owner = data.target;
      }
      if (gameModel.hasComponent(entity, "Shadow")) {
        gameModel.removeComponent(entity, "Shadow");
      }
      data.startPosition = entityPosition;
      data.startFrame = gameModel.timeElapsed;
    }
  }

  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, MagnetPickupSchema);
    if (
      gameModel.hasComponent(entity, "PickupState") &&
      gameModel.getTypedUnsafe(entity, PickupStateSchema).state !== PickupStateEnum.ON_THE_GROUND
    ) {
      return;
    }

    if (data.target < 0) {
      const entities = gameModel.players.filter((player) => {
        let radius = data.radius;
        if (gameModel.hasComponent(player, "MagnetEnhancer")) {
          radius += gameModel.getTypedUnsafe(player, MagnetEnhancerSchema).radius * 0.6;
        }
        return fastEntityCollision(gameModel, entity, player, radius);
      });
      if (entities.length) {
        TransformSchema.id = entity;
        const entityPosition = TransformSchema.position;
        data.target = entities[0];
        if (entities.length > 1) {
          data.target = closestEntity(gameModel, entityPosition, entities) || -1;
        }

        if (gameModel.hasComponent(entity, "PickupState")) {
          gameModel.getTypedUnsafe(entity, PickupStateSchema).owner = data.target;
        }
        if (gameModel.hasComponent(entity, "Shadow")) {
          gameModel.removeComponent(entity, "Shadow");
        }
        data.startPosition = entityPosition;
        data.startFrame = gameModel.timeElapsed;
      }
    }
    if (data.target >= 0) {
      if (!gameModel.isActive(data.target)) {
        data.target = -1;
        return;
      }

      TransformSchema.id = data.target;
      const targetPosition = TransformSchema.position;

      const timeElapsed = gameModel.timeElapsed - data.startFrame;
      const time = timeElapsed / data.pullTimeMs;
      if (time > 1) {
        TransformSchema.id = entity;
        TransformSchema.position = targetPosition;
      } else {
        const nextPosition = lerpVector2d(data.startPosition, targetPosition, time);
        TransformSchema.id = entity;
        TransformSchema.position = nextPosition;
      }
    }
  }
}

registerSystem(MagnetPickup);

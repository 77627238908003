import { PlayerInputSchema } from "yage/schemas/core/PlayerInput";
import { DEPTHS, registerSchema, registerSystem } from "yage/components/ComponentRegistry";
import type { System } from "yage/components/System";
import { ComponentCategory } from "yage/components/types";
import { Component, Schema, componentSchemaString, defaultValue, type } from "yage/decorators/type";
import type { GameModel } from "yage/game/GameModel";
import { MappedKeys } from "yage/inputs/InputManager";
import { LocomotionSchema } from "yage/schemas/entity/Locomotion";
import { keyDown, keyPressed } from "yage/utils/keys";
import { normalizeSafeVector2d, rotateDegVector2d } from "yage/utils/vector";
import { SpeedEnhancerSchema, getSpeed } from "../enhancers/SpeedEnhancer";
import { ChildSchema } from "yage/schemas/entity/Child";

@Component("PlayerMovement")
export class PlayerMovementSchema extends Schema {}

@Component("PlayerFrozen")
export class PlayerFrozenSchema extends Schema {}

console.log(componentSchemaString);

registerSchema(ComponentCategory.CORE, PlayerFrozenSchema);
export class PlayerMovementSystem implements System {
  type = "PlayerMovement";
  category: ComponentCategory = ComponentCategory.CORE;
  schema = PlayerMovementSchema;
  depth = DEPTHS.PLAYER_MOVEMENT;

  run(entity: number, gameModel: GameModel) {
    LocomotionSchema.id = entity;
    const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
    const netData = gameModel.getTypedUnsafe(parent, PlayerInputSchema);
    const speed = getSpeed(entity, gameModel);

    const { keyMap } = netData;
    if (!keyMap) {
      return;
    }

    let offset = {
      x: 0,
      y: 0,
    };

    const skewAmount = 0.3;

    if (keyDown([MappedKeys.ARROW_DOWN, MappedKeys.ARROW_DOWN_ALT], netData.keyMap)) {
      offset.y = 1;
      if (keyDown(["↘️"], netData.keyMap)) {
        offset.x = skewAmount;
      } else if (keyDown(["↖️"], netData.keyMap)) {
        offset.x = -skewAmount;
      }
    } else if (keyDown([MappedKeys.ARROW_UP, MappedKeys.ARROW_UP_ALT], netData.keyMap)) {
      offset.y = -1;
      if (keyDown(["↘️"], netData.keyMap)) {
        offset.x = skewAmount;
      } else if (keyDown(["↖️"], netData.keyMap)) {
        offset.x = -skewAmount;
      }
    }
    if (keyDown([MappedKeys.ARROW_LEFT, MappedKeys.ARROW_LEFT_ALT], netData.keyMap)) {
      offset.x = -1;
      if (keyDown(["↘️"], netData.keyMap)) {
        offset.y = skewAmount;
      } else if (keyDown(["↖️"], netData.keyMap)) {
        offset.y = -skewAmount;
      }
    }
    if (keyDown([MappedKeys.ARROW_RIGHT, MappedKeys.ARROW_RIGHT_ALT], netData.keyMap)) {
      offset.x = 1;
      if (keyDown(["↘️"], netData.keyMap)) {
        offset.y = skewAmount;
      } else if (keyDown(["↖️"], netData.keyMap)) {
        offset.y = -skewAmount;
      }
    }

    if (offset.x != 0 || offset.y != 0) {
      const direction = normalizeSafeVector2d(offset);
      LocomotionSchema.directionX = direction.x;
      LocomotionSchema.directionY = direction.y;

      offset.x = direction.x * speed;
      offset.y = direction.y * speed;
    }

    LocomotionSchema.velocityX = offset.x;
    LocomotionSchema.velocityY = offset.y;
  }
}

registerSystem(PlayerMovementSystem);

import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { PlayerTypeSchema, EnemyTypeSchema } from "yage/components/entity/Types";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";
import { BossPercentageDamageModSchema } from "../damagemods/BossPercentDamageMod";

@Component("BossPercentageDamageModEnhancer")
class BossPercentageDamageModEnhancerSchema extends Schema {
  @type("number")
  @defaultValue(0)
  damageScale: number;
}

class BossPercentageDamageModEnhancerSystem implements System {
  schema = BossPercentageDamageModEnhancerSchema;
  type = "BossPercentageDamageModEnhancer";
  dependencies = ["Child"];
  init(entity: number, gameModel: GameModel) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      gameModel.addIfMissing(parent, BossPercentageDamageModSchema);
      const bossDamageModDamage = gameModel.getTypedUnsafe(parent, BossPercentageDamageModSchema);
      bossDamageModDamage.additionalDamageScale += gameModel.getTypedUnsafe(
        entity,
        BossPercentageDamageModEnhancerSchema
      ).damageScale;
    }
  }
}

registerSystem(BossPercentageDamageModEnhancerSystem);

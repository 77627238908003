import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { Component, Schema, type } from "yage/decorators/type";
import { EntityFactory } from "yage/entity/EntityFactory";
import { GameModel } from "yage/game/GameModel";
import DescriptionSchema from "yage/schemas/core/Description";
import { OwnerSchema } from "yage/schemas/core/Owner";
import { ChildSchema } from "yage/schemas/entity/Child";
import { ParentSchema } from "yage/schemas/entity/Parent";

@Component("SpawnSingletonChild")
class SpawnSingletonChildSchema extends Schema {
  @type("string")
  entityDescription: string;
}

class SpawnSingletonChildSystem implements System {
  schema = SpawnSingletonChildSchema;
  type = "SpawnSingletonChild";
  dependencies = ["Child", "Owner"];

  init(entity: number, gameModel: GameModel) {
    if (gameModel.hasComponent(entity, ChildSchema)) {
      const data = gameModel.getTypedUnsafe(entity, SpawnSingletonChildSchema);
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent;
      const children = gameModel.getTyped(parent ?? -1, ParentSchema)?.children ?? [];
      const existingChild = children.find((child) => {
        const description = gameModel.getTyped(child, DescriptionSchema)?.description;
        return description === data.entityDescription;
      });
      if (existingChild) {
        return;
      }
      const owner = gameModel.getTyped(entity, OwnerSchema)?.owner ?? parent;

      EntityFactory.getInstance().generateEntity(gameModel, data.entityDescription, {
        Child: {
          parent: parent,
        },
        Owner: {
          owner: owner,
        },
      });
    }
  }
}

registerSystem(SpawnSingletonChildSystem);

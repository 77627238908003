import { registerSchema, registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { ComponentCategory } from "yage/constants/enums";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { getSourceWeapon } from "../../utils/weapons";

@Component("TrackDamage")
export class TrackDamageSchema extends Schema {
  @type("number")
  @defaultValue(0)
  totalDamage: number;
}

registerSchema(ComponentCategory.PERSIST, TrackDamageSchema);

@Component("TrackDamageOnHit")
export class TrackDamageOnHitSchema extends Schema {
  @type("number")
  @defaultValue(0)
  damage: number;
}
class TrackDamageOnHitSystem implements System {
  schema = TrackDamageOnHitSchema;
  type = "TrackDamageOnHit";
  category = ComponentCategory.ONHIT;
  run(entity: number, gameModel: GameModel) {
    const weapon = getSourceWeapon(entity, gameModel);
    if (gameModel.hasComponent(weapon, TrackDamageSchema)) {
      const data = gameModel.getTypedUnsafe(weapon, TrackDamageSchema);
      const onHit = gameModel.getTypedUnsafe(entity, TrackDamageOnHitSchema);
      data.totalDamage += onHit.damage;
    }
  }
}

registerSystem(TrackDamageOnHitSystem);

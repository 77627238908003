import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { ComponentCategory } from "yage/constants/enums";
import { Component, Schema, type } from "yage/decorators/type";
import { EjectedEntity, GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";

@Component("StorePlayerStateOnDeath")
export class StorePlayerStateOnDeathSchema extends Schema {
  @type("object")
  state: EjectedEntity;
}

class StorePlayerStateOnDeathSystem implements System {
  schema = StorePlayerStateOnDeathSchema;
  type = "StorePlayerStateOnDeath";
  category = ComponentCategory.ONDEATH;

  run(entity: number, gameModel: GameModel) {
    const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
    const playerState = gameModel.ejectEntity(entity);

    if (!gameModel.hasComponent(parent, StorePlayerStateOnDeathSchema)) {
      gameModel.addComponent(parent, StorePlayerStateOnDeathSchema, {
        state: playerState,
      });
    }
  }
}

registerSystem(StorePlayerStateOnDeathSystem);

import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import type { System } from "yage/components/System";
import type { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";
import { LocomotionSchema } from "yage/schemas/entity/Locomotion";
import { normalizeSafeVector2d, normalizeVector2d, scaleVector2d } from "yage/utils/vector";
import { type, Schema, Component, defaultValue } from "yage/decorators/type";
import { EntityType } from "yage/constants/enums";

@Component("GlobalEnemySpeedMod")
export class GlobalEnemySpeedModSchema extends Schema {
  @type("number")
  @defaultValue(1)
  speedMod: number;
}

class GlobalEnemySpeedModSystem implements System {
  type: string = "GlobalEnemySpeedMod";
  schema: typeof Schema = GlobalEnemySpeedModSchema;
  depth = DEPTHS.COLLISION - 1;

  init?: ((entity: number, gameModel: GameModel) => void) | undefined;

  runAll(gameModel: GameModel) {
    const enemies = gameModel.getEntities(EntityType.ENEMY);
    if (!gameModel.hasComponent(gameModel.coreEntity, GlobalEnemySpeedModSchema)) {
      return;
    }
    const data = gameModel.getTypedUnsafe(gameModel.coreEntity, GlobalEnemySpeedModSchema);

    for (let i = 0; i < enemies.length; i++) {
      LocomotionSchema.id = enemies[i];

      const velocity = scaleVector2d(
        normalizeSafeVector2d(LocomotionSchema.velocity),
        Math.max(data.speedMod, 0.01) * LocomotionSchema.speed
      );
      LocomotionSchema.velocityX = velocity.x;
      LocomotionSchema.velocityY = velocity.y;
    }
  }
  cleanup?: ((entity: number, gameModel: GameModel, ejecting: boolean) => void) | undefined;
}

registerSystem(GlobalEnemySpeedModSystem);

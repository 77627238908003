import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { Component, Schema } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { HealthSchema, getMaxHealth, healEntity } from "../core";
import { HpRegenSchema } from "./HpRegen";
import { ChildSchema } from "yage/schemas/entity/Child";
import { EnemyTypeSchema, PlayerTypeSchema } from "yage/components/entity/Types";
import { HpBlockSchema } from "./HpBlock";

@Component("HpRegenPlus")
export class HpRegenPlusSchema extends Schema {}

class HpRegenPlusSystem implements System {
  schema = HpRegenPlusSchema;
  type = "HpRegenPlus";
  depth = DEPTHS.HEALTH - 0.000001;

  dependencies = ["Child", "HpRegen"];

  init(entity: number, gameModel: GameModel) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema)?.parent!;
      if (!gameModel.hasComponent(parent, "HpRegenPlus")) {
        gameModel.addComponent(parent, HpRegenPlusSchema);
      }
      gameModel.removeComponent(entity, HpRegenPlusSchema);
    }
  }

  run(entity: number, gameModel: GameModel) {
    const regenData = gameModel.getTypedUnsafe(entity, HpRegenSchema);
    if (regenData.regen <= 0) return;

    if (
      gameModel.hasComponent(entity, "HpBlock") &&
      !gameModel.getTypedUnsafe(entity, HpBlockSchema).excludedTypes.includes("HpRegenPlus")
    ) {
      return;
    }
    console.log(gameModel.hasComponent(entity, "HpBlock"));

    let HPEveryXSeconds = 5.0 / (1.0 + (regenData.regen - 1) / 2.25);
    HealthSchema.id = entity;

    if (HealthSchema.health / getMaxHealth(entity, gameModel) < 0.5) {
      HPEveryXSeconds /= 2;
    }

    if (HPEveryXSeconds > 0 && regenData.lastRegenTime <= gameModel.timeElapsed - HPEveryXSeconds * 1000) {
      if (regenData.lastRegenTime > 0) {
        let amount = 1 * (regenData.regenAmountScale + 1);
        healEntity(entity, amount, gameModel);
      }
      regenData.lastRegenTime = gameModel.timeElapsed;
    }
  }
  cleanup?: ((entity: number, gameModel: GameModel, ejecting: boolean) => void) | undefined;
}

registerSystem(HpRegenPlusSystem);

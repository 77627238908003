import { System } from "yage/components/System";
import { Component, Schema, type } from "yage/decorators/type";
import { DamageStatsSchema } from "../weapons/DamageStats";
import { GameModel } from "yage/game/GameModel";
import { OwnerSchema } from "yage/schemas/core/Owner";
import { ChildSchema } from "yage/schemas/entity/Child";
import { registerSchema, registerSystem } from "yage/components/ComponentRegistry";
import { ComponentCategory } from "yage/constants/enums";
import { getEntityController } from "../../utils/playerCharacter";
import { ExpSchema } from "../core/Exp";

@Component("PercentLevelDamage")
export class PercentLevelDamageSchema extends Schema {
  @type("number")
  percentLevelDamage: number;

  @type(DamageStatsSchema)
  damageStats: DamageStatsSchema;
}

@Component("InvalidateDamageStatsOnLevelChange")
export class InvalidateDamageStatsOnLevelChangeSchema extends Schema {}

registerSchema(InvalidateDamageStatsOnLevelChangeSchema);

class PercentLevelDamageSystem implements System {
  schema = PercentLevelDamageSchema;
  type = "PercentLevelDamage";
  category = ComponentCategory.DAMAGEMOD;
  dependencies = ["Child"];

  init(entity: number, gameModel: GameModel) {
    if (gameModel.hasComponent(entity, ChildSchema)) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      gameModel.addComponent(parent, InvalidateDamageStatsOnLevelChangeSchema);
    } else {
      gameModel.addComponent(entity, InvalidateDamageStatsOnLevelChangeSchema);
    }
  }

  run(entity: number, gameModel: GameModel) {
    const percentLevelDamage = gameModel.getTypedUnsafe(entity, PercentLevelDamageSchema);
    const damageStats = percentLevelDamage.damageStats;
    const owner = gameModel.getTyped(entity, OwnerSchema)?.owner ?? gameModel.getTyped(entity, ChildSchema)?.parent;
    if (!owner) return;

    const entityController = getEntityController(owner, gameModel);
    const level = gameModel.getTypedUnsafe(entityController, ExpSchema).level;

    damageStats.minDamage += level * percentLevelDamage.percentLevelDamage;
    damageStats.maxDamage += level * percentLevelDamage.percentLevelDamage;
  }
}

registerSystem(PercentLevelDamageSystem);

import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { SoundType } from "yage/constants/enums";
import { Component, Schema, defaultValue, required, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";

@Component("SpawnSound")
class SpawnSoundSchema extends Schema {
  @type("string")
  @required()
  sound: string;

  @type(["string"])
  @defaultValue([])
  sounds: string[];

  @type("number")
  @defaultValue(SoundType.DEFAULT)
  soundType: SoundType;

  @type(["object"])
  @defaultValue([])
  filters: any[];
}

class SpawnSoundSystem implements System {
  schema = SpawnSoundSchema;
  type = "SpawnSound";

  init(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, SpawnSoundSchema);
    if (data.sound === "random" && data.sounds.length > 0) {
      // User specific sounds shouldn't modify the game random logic so use Math.random() directly
      gameModel.queueSound(data.sounds[Math.floor(Math.random() * data.sounds.length)], {
        type: data.soundType,
        filters: data.filters,
      });
    } else {
      gameModel.queueSound(data.sound, { type: data.soundType, filters: data.filters });
    }
  }
}

registerSystem(SpawnSoundSystem);

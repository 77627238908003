import type { SceneTimestep } from "yage/game/Scene";
import { Scene } from "yage/game/Scene";
import { Position, Size } from "yage/ui/Rectangle";
import { customAlphabet } from "nanoid";
import { ConnectionInstance } from "yage/connection/ConnectionInstance";
import { InputEventType, InputManager } from "yage/inputs/InputManager";
import { GameInstance } from "yage/game/GameInstance";
import { GameModel } from "yage/game/GameModel";
import { PlayerInputSchema } from "yage/schemas/core/PlayerInput";
import { EntityFactory } from "yage/entity/EntityFactory";
import { TransformSchema } from "yage/schemas/entity/Transform";
import { Box } from "yage/ui/Box";
import { KeyboardListener } from "yage/inputs/KeyboardListener";
import { GamepadListener, StandardGamepadRegions } from "yage/inputs/GamepadListener";
import { LeftStick, PreconfiguredTouchRegions, TouchListener } from "yage/inputs/TouchListener";
import { rotateVector2d } from "yage/utils/vector";
import { toggleFlag } from "yage/console/flags";
import { PlayerReadyState, PlayerState, defaultPlayerState } from "../types/PlayerState.types";
import { WaveSchema } from "../components/wave/Wave";
import { getShopInfo, itemInfoContext } from "./utils/renderItemInfo";
import { cloneDeep } from "lodash";
import { UiMap, buildUiMap, registerUiClass } from "yage/ui/UiMap";

import uis from "../ui";

import { generatePlayer } from "./utils/generatePlayer";
import { UIService } from "yage/ui/UIService";
import { CoopConnectionInstance } from "yage/connection/CoopConnectionInstance";

registerUiClass("btn-primary", {
  backgroundColor: "rgba(0,0,0,0.5)",
  borderRadius: "10px",
  textTransform: "uppercase",
});

registerUiClass("image-button", {
  backgroundColor: "#404040",
  borderRadius: "10px",
});

const nanoid = customAlphabet("234579ACDEFGHJKMNPQRTWXYZ", 5);

export class TwintatoLobbyScene extends Scene {
  static sceneName = "TwintatoLobby";

  timestep: SceneTimestep = "continuous";
  dt = 4;

  private possibleCharacters: string[] = [];

  private connection: ConnectionInstance<PlayerState>;

  private hosting = false;

  private unlisteners: (() => void)[] = [];

  private players: {
    [key: string]: PlayerState;
  } = {};

  private unsub: () => void;

  private instance: GameInstance<PlayerState>;
  private unsubPlayerConnect: () => void;
  private startingGame: boolean;
  private resizeListener: () => void;

  private playerDisplays: {
    [key: number]: {
      shownWeapon: string;
      shownCharacter: string;
      selectedCharacter: string;
      shownPlayerIndex: number;
      possibleWeapons: string[];
    };
  } = {};

  weaponPickerMap: UiMap;
  weaponInfoMap: UiMap;

  lobbyMaps: UiMap[] = [];

  public initialize = async (args: any[]): Promise<void> => {
    let uiInputs: [InputEventType, number][] = [
      [InputEventType.TOUCH, 0],
      [InputEventType.TOUCH, 1],
    ];
    if (args.length) {
      if (Array.isArray(args[0])) {
        uiInputs = args[0];
      } else {
        this.instance = args[0].instance;
        this.players = {};
        this.connection = this.instance.options.connection;
      }
    }
    const inputManager = new InputManager();
    const keyboardListener = new KeyboardListener(inputManager);
    keyboardListener.init(["w", "a", "s", "d", "i", "j", "k", "l", "space", "escape"]);
    new GamepadListener(inputManager).init(StandardGamepadRegions);

    UIService.getInstance().enableKeyCapture(inputManager);

    this.resizeListener = () => {
      this.renderUi();
    };
    window.addEventListener("resize", this.resizeListener);

    const player1Region = cloneDeep(LeftStick);
    const player2Region = cloneDeep(LeftStick);
    player2Region.index = 1;
    player2Region.id.x = 960;

    const touchListener = new TouchListener(inputManager);
    touchListener.replaceRegions(PreconfiguredTouchRegions.TwinStickDoubleTap);

    // check if query param has the word server in it
    if (window.location.search.includes("server")) {
      toggleFlag("USE_SERVER", true);
    } else {
      toggleFlag("USE_SERVER", false);
    }

    let addressId;
    let lobbyId;
    if (window.location.hash) {
      lobbyId = window.location.hash.substring(1);
      addressId = nanoid();
    } else {
      lobbyId = nanoid();
      addressId = lobbyId;
      // set search to lobbyId
      window.history.pushState({}, "", "#" + lobbyId);
      this.hosting = true;
    }

    // UIService.getInstance().playerInputs = [
    //   [InputEventType.KEYBOARD, 0],
    //   [InputEventType.GAMEPAD, 0],
    // ];

    if (!this.connection) {
      UIService.getInstance().playerInputs = uiInputs;
      this.connection = new CoopConnectionInstance(
        inputManager,
        uiInputs.map((input) => {
          return [...input, cloneDeep(defaultPlayerState)];
        }),
        [player1Region, player2Region]
      );

      this.unsubPlayerConnect = this.connection.onPlayerConnect((playerConnect) => {
        if (playerConnect.config) {
          this.players[playerConnect.netId] = playerConnect.config;
        }

        if (playerConnect.config) {
          this.players[playerConnect.netId] = playerConnect.config;
          if (
            !this.initializingPlayer &&
            Object.values(this.players).every((p) => p.readyState === PlayerReadyState.Ready)
          ) {
            for (let i = 0; i < this.connection.localPlayers.length; i++) {
              this.initializePlayer(i);
            }
          } else if (
            !this.startingGame &&
            Object.values(this.players).every((p) => p.readyState === PlayerReadyState.PlayerInitialized)
          ) {
            const host = Object.keys(this.connection.players).sort()[0];
            const localHost = this.connection.localPlayers[0].netId;
            const isHosting = host === localHost;

            this.startGame(isHosting);
          } else {
            this.renderUi();
          }
        }
      });

      await this.attemptConnect(lobbyId);
    } else {
      this.unsubPlayerConnect = this.connection.onPlayerConnect((playerConnect) => {
        if (playerConnect.config) {
          this.players[playerConnect.netId] = playerConnect.config;
        }

        if (playerConnect.config) {
          this.players[playerConnect.netId] = playerConnect.config;
          if (
            !this.initializingPlayer &&
            Object.values(this.players).every((p) => p.readyState === PlayerReadyState.Ready)
          ) {
            for (let i = 0; i < this.connection.localPlayers.length; i++) {
              this.initializePlayer(i);
            }
          } else if (
            !this.startingGame &&
            Object.values(this.players).every((p) => p.readyState === PlayerReadyState.PlayerInitialized)
          ) {
            const host = Object.keys(this.connection.players).sort()[0];
            const localHost = this.connection.localPlayers[0].netId;
            const isHosting = host === localHost;

            this.startGame(isHosting);
          } else {
            this.renderUi();
          }
        }
      });
      for (let i = 0; i < this.connection.localPlayers.length; i++) {
        this.connection.updatePlayerConnect(
          {
            config: cloneDeep(defaultPlayerState),
          },
          i
        );
      }
    }
    this.ui.background = new Box(new Position("full", "full"), {
      style: {
        background: "linear-gradient(to bottom, #666, #333)",
        zIndex: "-3",
      },
    });

    this.renderUi();
  };
  initializingPlayer: boolean;

  generateContext = (playerIndex: number) => {
    if (!this.playerDisplays[playerIndex]) {
      this.playerDisplays[playerIndex] = {
        shownWeapon: "",
        shownCharacter: "",
        selectedCharacter: "",
        shownPlayerIndex: -1,
        possibleWeapons: [],
      };
    }
    const { shownWeapon, shownCharacter, shownPlayerIndex, selectedCharacter } = this.playerDisplays[playerIndex];

    if (this.possibleCharacters.length === 0) {
      this.findCharacters();
    }
    if (selectedCharacter) {
      const characterInfo = getShopInfo(selectedCharacter)!;
      const weapons = characterInfo.tags.filter((tag) => tag.startsWith("Weapon::"));
      this.playerDisplays[playerIndex].possibleWeapons = [];

      // const grid = this.uiMap.regions.weaponPicker.grid!;

      weapons.forEach((weapon, index) => {
        weapon = weapon.split("::")[1];
        this.playerDisplays[playerIndex].possibleWeapons.push(weapon);
      });
    } else {
      this.playerDisplays[playerIndex].possibleWeapons = [];
    }
    const player = this.connection.localPlayers[playerIndex].netId;

    console.log(this.connection.localPlayers[playerIndex].config?.weapons);

    const context = {
      readyLabel: this.players[player]?.readyState === PlayerReadyState.Ready ? "Unready" : "Ready",
      characters: this.possibleCharacters.map((character) => {
        return {
          name: character,
          image: `shopitem::${character.toLowerCase()}`,
          tags: getShopInfo(character)?.tags,
          text: "",
        };
      }),
      weapons: this.playerDisplays[playerIndex].possibleWeapons.map((weapon) => {
        return {
          name: weapon,
          image: `shopitem::${weapon.toLowerCase()}`,
          tags: getShopInfo(weapon)?.tags,
          text: this.connection.localPlayers[playerIndex].config!.weapons.includes(weapon) ? " ✓" : "",
        };
      }),
      players: Object.values(this.connection.players).map((player, index) => {
        const character = Object.keys(player.config!.inventory)
          .find((item) => {
            if (!item.startsWith("shopitem::")) {
              return false;
            }
            item = item.split("::")[1];
            return getShopInfo(item, "Character::");
          })
          ?.split("::")[1];
        const playerWeapon = player.config?.weapons[0] ?? "";

        return {
          ...itemInfoContext(character ?? ""),
          showDetails: !!character && index === shownPlayerIndex,
          image: character ? `shopitem::${character.toLowerCase()}` : "",
          weaponImage: playerWeapon ? `shopitem::${playerWeapon}` : "",
          showWeaponDetails: !!playerWeapon,
          weaponDetails: itemInfoContext(playerWeapon),
          text: player.config?.readyState === PlayerReadyState.Ready ? " ✓" : "",
        };
      }, []),
      shownCharacter: itemInfoContext(selectedCharacter || shownCharacter),
      showCharacterDisplay: !!shownCharacter,
      shownWeapon: itemInfoContext(shownWeapon),
      showWeaponDisplay: !!shownWeapon,
      showCharacterPicker: !selectedCharacter,
      showWeaponPicker: !!selectedCharacter,
      showBack: !!selectedCharacter,
      showReady: !!this.connection.localPlayers[playerIndex].config?.weapons.length,
      showShare: false,
    };

    return context;
  };

  renderUi = () => {
    for (let i = 0; i < this.connection.localPlayers.length; i++) {
      const context = this.generateContext(i);

      if (!this.lobbyMaps[i]) {
        this.lobbyMaps[i] = buildUiMap(
          uis.lobby,
          new Position("left", "center", { width: "50%", height: "100%", xOffset: (i * 100 + "%") as Size }),
          {
            scale: 0.75,
            captureFocus: i,
          }
        );
        Object.entries(
          this.lobbyMaps[i].build(context, (playerIndex, name, type, context) => {
            console.log(playerIndex, name, type, context);
            switch (name) {
              case "showCharacter":
                this.showCharacter(playerIndex, context.name);
                break;
              case "selectCharacter":
                this.selectCharacter(playerIndex, context.name);
                break;
              case "showPlayer":
                this.showPlayer(playerIndex, context.$index);
                break;
              case "hidePlayer":
                this.hidePlayer(playerIndex);
                break;
              case "back":
                this.back(playerIndex);
                break;
              case "selectWeapon":
                this.selectWeapon(playerIndex, context.name);
                break;
              case "showWeapon":
                this.showWeapon(playerIndex, context.name);
                break;
              case "leaveWeapon":
                if (this.players[this.connection.localPlayers[playerIndex].netId].weapons[0]) {
                  this.showWeapon(playerIndex, this.connection.localPlayers[playerIndex].config!.weapons[0]);
                }
                break;
              case "ready":
                this.ready(playerIndex);
                break;
              case "share":
                this.share();
                break;
              default:
                console.log("Invalid name");
                break;
            }
          })
        ).forEach(([key, value]) => {
          this.ui["player" + i + "_" + key] = value;
        });
      } else {
        this.lobbyMaps[i].update(context);
      }
    }
  };

  share = () => {
    navigator.clipboard.writeText(window.location.origin + window.location.pathname + "#" + this.connection.address);
  };

  ready = (playerIndex: number) => {
    this.connection.updatePlayerConnect(
      {
        config: {
          ...this.connection.localPlayers[playerIndex].config!,
          readyState:
            this.connection.localPlayers[playerIndex].config!.readyState === PlayerReadyState.Ready
              ? PlayerReadyState.NotReady
              : PlayerReadyState.Ready,
        },
      },
      playerIndex
    );
  };

  back = (playerIndex: number) => {
    delete this.connection.localPlayers[playerIndex].config!.inventory[
      "shopitem::" + this.playerDisplays[playerIndex].selectedCharacter.toLowerCase()
    ];
    this.playerDisplays[playerIndex].shownCharacter = this.playerDisplays[playerIndex].selectedCharacter;
    this.playerDisplays[playerIndex].selectedCharacter = "";

    this.connection.updatePlayerConnect(
      {
        config: {
          ...this.connection.localPlayers[playerIndex].config!,
          weapons: [],
          readyState: PlayerReadyState.NotReady,
        },
      },
      playerIndex
    );
  };

  showWeapon = (playerIndex: number, name: string) => {
    if (this.playerDisplays[playerIndex].shownWeapon !== name.toLowerCase()) {
      this.playerDisplays[playerIndex].shownWeapon = name.toLowerCase();
      this.playerDisplays[playerIndex].shownCharacter = "";
      this.playerDisplays[playerIndex].shownPlayerIndex = -1;
      this.renderUi();
    }
  };

  selectWeapon = (playerIndex: number, weapon: string) => {
    if (this.playerDisplays[playerIndex].possibleWeapons.includes(weapon)) {
      this.connection.updatePlayerConnect(
        {
          config: {
            ...this.connection.localPlayers[playerIndex].config!,
            weapons: [weapon],
            readyState: PlayerReadyState.NotReady,
          },
        },
        playerIndex
      );
    }
  };

  showCharacter = (playerIndex: number, name: string) => {
    this.playerDisplays[playerIndex].shownCharacter = name.toLowerCase();
    this.playerDisplays[playerIndex].shownPlayerIndex = -1;
    this.renderUi();
  };

  showPlayer = (playerIndex: number, index: number) => {
    this.playerDisplays[playerIndex].shownPlayerIndex = index;
    this.renderUi();
  };

  hidePlayer = (playerIndex: number) => {
    this.playerDisplays[playerIndex].shownPlayerIndex = -1;
    this.renderUi();
  };

  selectCharacter = (playerIndex: number, character: string) => {
    if (this.playerDisplays[playerIndex].selectedCharacter !== character) {
      if (this.playerDisplays[playerIndex].selectedCharacter) {
        delete this.connection.localPlayers[playerIndex].config!.inventory[
          "shopitem::" + this.playerDisplays[playerIndex].selectedCharacter.toLowerCase()
        ];
      }
      this.playerDisplays[playerIndex].selectedCharacter = character;

      this.connection.updatePlayerConnect(
        {
          config: {
            ...this.connection.localPlayers[playerIndex].config!,
            inventory: {
              ...this.connection.localPlayers[playerIndex].config!.inventory,
              ["shopitem::" + this.playerDisplays[playerIndex].selectedCharacter.toLowerCase()]: {
                amount: 1,
              },
            },
          },
        },
        playerIndex
      );
    }
  };

  findCharacters = () => {
    const items = EntityFactory.getInstance().findEntitiesWithComponent("Shop");
    const characters = items.filter((item) => !!getShopInfo(item, "Character::"));
    this.possibleCharacters = characters;
  };

  clearCharacterPicker = () => {
    let index = 0;
    while (this.ui[`characters_${index}`]) {
      delete this.ui[`characters_${index}`];
      index++;
    }
  };

  startGame = (isHosting: boolean) => {
    this.startingGame = true;
    this.changeScene("ProjectVGame", {
      instance: this.instance,
      hosting: isHosting,
      wave: this.connection.localPlayers[0].config!.wave,
    });
  };

  initializePlayer = (playerIndex: number) => {
    this.initializingPlayer = true;
    const character =
      Object.keys(this.connection.localPlayers[playerIndex].config!.inventory)
        .find((item) => {
          if (!item.startsWith("shopitem::")) {
            return false;
          }
          item = item.split("::")[1];
          return getShopInfo(item, "Character::");
        })
        ?.split("::")[1] ?? this.playerDisplays[playerIndex].selectedCharacter;

    const characterInfo = getShopInfo(character)!;
    const startingWeapons = characterInfo.tags
      .filter((tag) => tag.startsWith("StartingWeapon::"))
      .map((tag) => tag.split("::")[1]);
    const startingItems = characterInfo.tags
      .filter((tag) => tag.startsWith("StartingItem::"))
      .map((tag) => tag.split("::")[1]);

    startingItems.push("Twintato");
    const config = {
      ...this.connection.localPlayers[playerIndex].config!,
    };

    if (startingWeapons.length > 0) {
      config.weapons = [...startingWeapons, ...config.weapons];
      config.weaponMods = [...Array(startingWeapons.length).fill([]), ...config.weaponMods];
    }

    config.inventory["StartingWeapons::" + config.weapons.join(",")] = {
      amount: 1,
    };

    if (startingItems.length > 0) {
      startingItems.forEach((item) => {
        config.inventory["shopitem::" + item.toLowerCase()] = {
          amount: 1,
        };
      });
    }

    config.readyState = PlayerReadyState.PlayerInitialized;
    this.connection.updatePlayerConnect(
      {
        config,
      },
      playerIndex
    );
  };

  async attemptConnect(lobbyId: string) {
    this.instance = new GameInstance({
      gameName: "Project V",
      connection: this.connection,
      uiService: true,
      buildWorld: (gameModel: GameModel, firstPlayerConfig: PlayerState) => {
        const zero_zero = gameModel.addEntity();
        gameModel.addComponent(zero_zero, "Transform", {
          x: 0,
          y: 0,
        });
        gameModel.addComponent(zero_zero, "Radius", { radius: 10 });

        gameModel.setTyped(gameModel.coreEntity, WaveSchema, {
          wave: firstPlayerConfig.wave,
        });

        const arena = EntityFactory.getInstance().generateEntity(gameModel, "arena");
        // const spawn = EntityFactory.getInstance().generateEntity(gameModel, "spawn");
        // TransformSchema.id = spawn;
        // TransformSchema.position = {
        //   x: -100,
        //   y: -100,
        // };
      },
      onPlayerJoin: (gameModel: GameModel, playerId: string, playerConfig: PlayerState & { name: string }) => {
        const [player] = generatePlayer(playerConfig, playerId, gameModel);

        const index = gameModel.players.length - 1;
        const angle = (index * Math.PI * 2) / 3;
        TransformSchema.position = rotateVector2d(
          {
            x: 0,
            y: 200,
          },
          angle
        );

        return player;
      },
      onPlayerLeave: (gameModel: GameModel, playerId: string) => {
        const players = gameModel.players;
        const player = players.find((p) => {
          const PlayerInput = gameModel.getTypedUnsafe(p, PlayerInputSchema);
          return PlayerInput.id === playerId;
        });
        if (player) {
          gameModel.removeEntity(player);
        }
      },
    });

    this.unlisteners.push(
      this.connection.onPlayerConnect((player) => {
        console.log("Player connected", player, player.config?.weapons);
      })
    );
    this.unsub = this.connection.onReceiveMessage((message) => {
      console.log("message", message);
      this.handleMessage(message, false);
    });
    console.log(this.hosting, lobbyId);

    if (!this.hosting) {
      try {
        await this.connection.connect();
      } catch (e) {
        console.error(e);
        this.ui.chatBox.config.label = "Failed to connect";
        this.ui.initLobby.visible = true;
        return;
      }
    }
  }

  handleMessage = (message: string, self: boolean) => {
    console.log(message);
    if (message.startsWith("/")) {
      const parts = message.substring(1).split(" ");
      switch (parts[0]) {
        case "start":
          this.changeScene("ProjectVGame", {
            instance: this.instance,
            hosting: false,
            wave: this.connection.localPlayers[0].config!.wave,
          });
          break;
      }
      return;
    }
    const label = this.ui.chatBox.config.label + "\n" + (self ? "You: " + message : "Thm: " + message);
    if (label.split("\n").length > 3) {
      this.ui.chatBox.config.label = label.split("\n").slice(1).join("\n");
    } else {
      this.ui.chatBox.config.label = label;
    }
  };

  run = () => {};

  public destroy = (): void => {
    super.destroy();
    this.unsub?.();
    this.unlisteners.forEach((unlistener) => unlistener());
    this.unsubPlayerConnect();
    window.removeEventListener("resize", this.resizeListener);
    console.log("this is the lobby scene");
    console.log("MinMediator: destroy!");
  };
}

import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { Component, defaultValue, Schema, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";

@Component("DamageMitigationScaler")
export class DamageMitigationScalerSchema extends Schema {
  @type("number")
  @defaultValue(0)
  dodgeChanceScale: number;

  @type("number")
  @defaultValue(0)
  defenseScale: number;
}

class DamageMitigationScalerSystem implements System {
  schema = DamageMitigationScalerSchema;
  type = "DamageMitigationScaler";
  dependencies = ["Child"];
  init(entity: number, gameModel: GameModel) {
    if (gameModel.hasComponent(entity, ChildSchema)) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (!gameModel.hasComponent(parent, "DamageMitigationScaler")) {
        gameModel.addComponent(parent, DamageMitigationScalerSchema);
      }
      gameModel.getTypedUnsafe(parent, DamageMitigationScalerSchema).dodgeChanceScale += gameModel.getTypedUnsafe(
        entity,
        DamageMitigationScalerSchema
      ).dodgeChanceScale;
      gameModel.getTypedUnsafe(parent, DamageMitigationScalerSchema).defenseScale += gameModel.getTypedUnsafe(
        entity,
        DamageMitigationScalerSchema
      ).defenseScale;
    }
  }
}
registerSystem(DamageMitigationScalerSystem);

import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";

@Component("Invincibility")
export class InvincibilitySchema extends Schema {
  @type("number")
  time: number;

  @type("number")
  @defaultValue(0)
  timeElapsed: number;
}

export class InvincibilitySystem implements System {
  schema = InvincibilitySchema;
  type = "Invincibility";
  depth = DEPTHS.CORE;

  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, InvincibilitySchema);
    if (!data.time) {
      return;
    }
    data.timeElapsed += gameModel.dt<number>(entity);
    if (data.timeElapsed >= data.time) {
      gameModel.removeComponent(entity, "Invincibility");
    }
  }
}

registerSystem(InvincibilitySystem);

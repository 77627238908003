import { DEPTHS, registerPixiComponent, registerSystem } from "yage/components/ComponentRegistry";
import type { PixiDrawSystem } from "yage/components/PixiDrawSystem";
import type { System } from "yage/components/System";
import { ComponentCategory } from "yage/components/types";
import { Component, defaultValue, Schema, type } from "yage/decorators/type";
import type { GameModel } from "yage/game/GameModel";
import type { Vector2d } from "yage/utils/vector";
import { Vector2dSchema } from "yage/utils/vector";
import * as PIXI from "pixi.js";
import { DamageTypeEnum } from "yage/constants/enums";
import { TransformSchema } from "yage/schemas/entity/Transform";
import { Viewport } from "pixi-viewport";
import { DamageableSchema } from "../../schema/damage/Damage";
import { addBloop } from "./TextBloop";

@Component("ShowDamage")
export class ShowDamageSchema extends Schema {
  @type("number")
  @defaultValue(500)
  timeout: number;

  @type("number")
  @defaultValue(10)
  gravity: number;

  @type("number")
  @defaultValue(32)
  fontSize: number;

  @type("number")
  @defaultValue(0xffffff)
  color: number;

  @type("number")
  @defaultValue(0x000000)
  strokeColor: number;

  @type("number")
  @defaultValue(0x00ff00)
  healColor: number;

  @type("boolean")
  @defaultValue(false)
  showDodge: boolean;

  @type(["number"])
  @defaultValue([])
  damages: number[] = [];
}

@Component("DamageText")
export class DamageTextSchema extends Schema {
  @type("number")
  @defaultValue(0)
  damage: number;

  @type("boolean")
  @defaultValue(false)
  critical: boolean;

  @type("number")
  @defaultValue(0)
  timeout: number;

  @type("number")
  startFrame: number;

  @type("number")
  @defaultValue(0)
  gravity: number;

  @type("number")
  @defaultValue(0)
  yVelocity: number;

  @type("number")
  @defaultValue(0)
  xVelocity: number;

  @type("number")
  @defaultValue(300)
  fontSize: number;

  @type("number")
  @defaultValue(0xff2b2b)
  color: number;

  @type("number")
  @defaultValue(0x00ff00)
  healColor: number;

  @type("number")
  @defaultValue(0x000000)
  strokeColor: number;

  @type(Vector2dSchema)
  @defaultValue({ x: 0, y: 0 })
  position: Vector2d;
}

class ShowDamageSystem implements System {
  type = "ShowDamage";
  category: ComponentCategory = ComponentCategory.RENDERING;
  schema = ShowDamageSchema;
  depth = DEPTHS.PREDRAW;
  dependencies = ["Health", "Transform", "Damageable"];

  run(entityId: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entityId, this.schema);
    const damageable = gameModel.getTypedUnsafe(entityId, DamageableSchema);
    TransformSchema.id = entityId;
    const startingPosition = TransformSchema.position;

    if (damageable.damages[damageable.damages.length - 1]?.frame === gameModel.timeElapsed) {
      const damages = [];
      let i = damageable.damages.length - 1;
      while (true) {
        const damage = damageable.damages[i];

        if (!damage || damage.frame !== gameModel.timeElapsed) {
          break;
        }
        damages.push(damage);
        i--;
      }

      for (let i = 0; i < damages.length; ++i) {
        const damage = damages[i];
        let damageValue = damage.damage.toFixed(0);

        let color = data.color;
        if (damage.damage <= 0) {
          color = data.healColor;
          damageValue = "+" + Math.abs(damage.damage).toFixed(0);
        } else if (damage.critHit) {
          color = 0xffd700;
        } else if (damage.damageType === DamageTypeEnum.FIRE) {
          color = 0xdc143c;
        } else if (damage.damageType === DamageTypeEnum.ICE) {
          color = 0x00ffff;
        } else if (damage.damageType === DamageTypeEnum.SHOCK) {
          color = 0x0000ff;
        } else if (damage.damageType === DamageTypeEnum.CHAOS) {
          color = 0xdc143c;
        }

        addBloop(
          {
            text: damageValue,
            startFrame: gameModel.timeElapsed,
            timeout: gameModel.timeElapsed + data.timeout,
            strokeThickness: damage.critHit ? 10 : 5,
            gravity: data.gravity,
            fontSize: data.fontSize,
            color,
            position: {
              x: startingPosition.x + gameModel.rand.int(0, 100) - 50,
              y: startingPosition.y + gameModel.rand.int(0, 100) - 50,
            },
            yVelocity: -400,
            xVelocity: gameModel.rand.int(30, 200) * (gameModel.rand.int(0, 1) ? 1 : -1),
          },
          gameModel
        );
      }
    }
  }
}

registerSystem(ShowDamageSystem);

import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { Component, Schema } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";
import { AoeDamageScaleSchema } from "../effects/AoeDamageScale";
import { EnemyTypeSchema, PlayerTypeSchema } from "yage/components/entity/Types";

@Component("AoeDamageScaleEnhancer")
class AoeDamageScaleEnhancerSchema extends AoeDamageScaleSchema {}

class AoeDamageScaleEnhancerSystem implements System {
  schema = AoeDamageScaleEnhancerSchema;
  type = "AoeDamageScaleEnhancer";
  dependencies = ["Child"];
  init(entity: number, gameModel: GameModel) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      gameModel.addIfMissing(parent, AoeDamageScaleSchema);
      const data = gameModel.getTypedUnsafe(entity, AoeDamageScaleEnhancerSchema);
      const parentDamageScale = gameModel.getTypedUnsafe(parent, AoeDamageScaleSchema);
      parentDamageScale.damageScale += data.damageScale;
    }
  }

  cleanup(entity: number, gameModel: GameModel, ejecting: boolean) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (gameModel.hasComponent(parent, "AoeDamageScale")) {
        const data = gameModel.getTypedUnsafe(entity, AoeDamageScaleEnhancerSchema);
        const parentDamageScale = gameModel.getTypedUnsafe(parent, AoeDamageScaleSchema);
        parentDamageScale.damageScale -= data.damageScale;
      }
    }
  }
}
registerSystem(AoeDamageScaleEnhancerSystem);

import { DEPTHS, registerPixiComponent, registerSystem } from "yage/components/ComponentRegistry";
import type { PixiDrawSystem } from "yage/components/PixiDrawSystem";
import type { System } from "yage/components/System";
import { ComponentCategory } from "yage/components/types";
import { type, Component, Schema, defaultValue, required } from "yage/decorators/type";
import type { GameModel } from "yage/game/GameModel";
import { distanceVector2d, normalizeVector2d, scaleVector2d, subtractVector2d } from "yage/utils/vector";
import * as PIXI from "pixi.js";
import { LocomotionSchema } from "yage/schemas/entity/Locomotion";
import { RadiusSchema } from "yage/schemas/entity/Radius";
import { TransformSchema } from "yage/schemas/entity/Transform";
import { Viewport } from "pixi-viewport";
import { closestEntity } from "yage/utils/Collision";
import { getSpeed } from "../enhancers/SpeedEnhancer";

@Component("Avoid")
export class AvoidSchema extends Schema {
  @type("Entity")
  target: number;

  @type("number")
  @required()
  distance: number;
}

class AvoidSystem implements System {
  type = "Avoid";
  category: ComponentCategory = ComponentCategory.TARGET;
  schema = AvoidSchema;
  depth = DEPTHS.LOCOMOTION + 11;

  runAll?(gameModel: GameModel): void {
    const entities = gameModel.getComponentActives("Avoid");

    for (let i = 0; i < entities.length; i++) {
      const entity = entities[i];
      LocomotionSchema.id = entity;
      RadiusSchema.id = entity;
      const radius = RadiusSchema.radius;
      const Avoid = gameModel.getTypedUnsafe(entity, AvoidSchema);

      TransformSchema.id = entity;
      const position = TransformSchema.position;
      if (!gameModel.isActive(Avoid.target)) {
        Avoid.target = 0;
      }

      if (!Avoid.target) {
        const closestPlayer = closestEntity(gameModel, position, gameModel.players);
        if (closestPlayer) {
          Avoid.target = closestPlayer;
        } else {
          continue;
        }
      }

      const target = Avoid.target;
      if (target) {
        TransformSchema.id = target;
        const currentTargetPosition = TransformSchema.position;

        const distance = distanceVector2d(position, currentTargetPosition);
        if (distance > Avoid.distance) {
          continue;
        }

        const targetX = TransformSchema.store.x[target];
        const targetY = TransformSchema.store.y[target];
        const targetPosition = { x: targetX, y: targetY };

        const randomX = gameModel.rand.int(0, 30) - 15;
        const randomY = gameModel.rand.int(0, 30) - 15;
        targetPosition.x += randomX;
        targetPosition.y += randomY;

        const direction = normalizeVector2d(subtractVector2d(targetPosition, position));
        const velocity = scaleVector2d(direction, -getSpeed(entity, gameModel));
        LocomotionSchema.velocityX = velocity.x;
        LocomotionSchema.velocityY = velocity.y;
        LocomotionSchema.directionX = -direction.x;
        LocomotionSchema.directionY = -direction.y;
      }
    }
  }
}

registerSystem(AvoidSystem);

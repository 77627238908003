import { registerSystem } from "yage/components/ComponentRegistry";
import type { System } from "yage/components/System";
import { ComponentCategory } from "yage/components/types";
import { Component, defaultValue, Schema, type } from "yage/decorators/type";
import type { GameModel } from "yage/game/GameModel";

@Component("AuraContainer")
export class AuraContainerSchema extends Schema {
  @type("EntityArray")
  @defaultValue([])
  auras: number[];

  @type(["number"])
  @defaultValue([])
  applicationTime: number[];
}

class AuraContainerSystem implements System {
  type = "AuraContainer";
  category: ComponentCategory = ComponentCategory.CONTAINER;
  schema = AuraContainerSchema;
  cleanup(entity: number, gameModel: GameModel) {
    const auraContainer = gameModel.getComponent(entity, this.type) as AuraContainerSchema;
    if (auraContainer) {
      auraContainer.auras.forEach((aura) => {
        gameModel.removeEntity(aura);
      });
    }
  }
}

registerSystem(AuraContainerSystem);

import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { Component, Schema, defaultValue, required, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { LocomotionSchema } from "yage/schemas/entity/Locomotion";

@Component("Accelerator")
class AcceleratorScheam extends Schema {
  @type("number")
  @defaultValue(5000)
  accelerationTime: number;

  @type("number")
  @defaultValue(0)
  accelerationTimer: number;

  @type("number")
  @required()
  maxSpeed: number;

  @type("number")
  initialSpeed: number;
}

class AcceleratorSystem implements System {
  schema = AcceleratorScheam;
  type = "Accelerator";
  depth = DEPTHS.LOCOMOTION - 1;

  runAll?(gameModel: GameModel): void {
    const entities = gameModel.getComponentActives("Accelerator");
    for (let i = 0; i < entities.length; i++) {
      const entity = entities[i];
      const Accelerator = gameModel.getTypedUnsafe(entity, AcceleratorScheam);
      LocomotionSchema.id = entity;

      if (Accelerator.accelerationTimer === 0) {
        Accelerator.initialSpeed = LocomotionSchema.speed;
      }
      if (Accelerator.accelerationTimer < Accelerator.accelerationTime) {
        Accelerator.accelerationTimer += gameModel.dt<number>(entity);
        LocomotionSchema.speed =
          Accelerator.initialSpeed +
          (Accelerator.maxSpeed - Accelerator.initialSpeed) *
            (Accelerator.accelerationTimer / Accelerator.accelerationTime);
      } else {
        gameModel.removeComponent(entity, "Accelerator");
      }
    }
  }
}

registerSystem(AcceleratorSystem);

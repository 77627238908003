import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { ComponentCategory } from "yage/constants/enums";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { generateDodgeDamage } from "../../utils/generateSpecialDamage";
import { TransformSchema } from "yage/schemas/entity/Transform";
import { addBloop } from "../render/TextBloop";
import { generateShareList } from "yage/utils/generateShareList";
import { ShareOnDodgeSchema } from "../core/ShareOnDodge";

@Component("DamageMitigation")
export class DamageMitigationSchema extends Schema {
  @type("number")
  @defaultValue(0)
  dodgeChance: number;

  @type("number")
  @defaultValue(60)
  maxDodgeChance: number;

  @type("number")
  @defaultValue(0)
  defense: number;

  @type("number")
  damageScale: number;

  @type("number")
  @defaultValue(-1)
  maker: number;
}

class DamageMitigationSystem implements System {
  schema = DamageMitigationSchema;
  type = "DamageMitigation";
  category = ComponentCategory.ONDAMAGE;
  depth = DEPTHS.NORUN + 100;

  run(entity: number, gameModel: GameModel) {
    const damageMitigationData = gameModel.getTypedUnsafe(entity, DamageMitigationSchema);
    damageMitigationData.damageScale = 1;
    if (damageMitigationData.defense !== 0) {
      damageMitigationData.damageScale = 1 / (1 + Math.abs(damageMitigationData.defense) / 15);
      if (damageMitigationData.defense < 0) {
        damageMitigationData.damageScale = 1 + (1 - damageMitigationData.damageScale);
      }
    }

    if (damageMitigationData.dodgeChance > 0) {
      const rand = gameModel.rand.int(1, 100);
      if (rand < Math.min(damageMitigationData.maxDodgeChance, damageMitigationData.dodgeChance)) {
        damageMitigationData.damageScale = 0;

        const shareList = generateShareList(entity, ShareOnDodgeSchema, ComponentCategory.ONDODGE, gameModel);
        if (shareList.length > 0) {
          for (let i = 0; i < shareList.length; i++) {
            const [component, entities] = shareList[i];
            for (let j = 0; j < entities.length; j++) {
              const entityId = entities[j];
              const data = gameModel.getComponentUnsafe(entityId, component);
              if (data.maker !== undefined) {
                data.maker = damageMitigationData.maker;
              }
              if (data.dodgeSource !== undefined) {
                data.dodgeSource = entity;
              }
              gameModel.getSystem(component).run?.(entityId, gameModel);
            }
          }
        }
        TransformSchema.id = entity;
        const startingPosition = TransformSchema.position;

        addBloop(
          {
            gravity: 10,
            color: 0xff00ff,
            startFrame: gameModel.timeElapsed,
            timeout: gameModel.timeElapsed + 500,
            position: {
              x: startingPosition.x + gameModel.rand.int(0, 100) - 50,
              y: startingPosition.y + gameModel.rand.int(0, 100) - 50,
            },
            yVelocity: -400,
            xVelocity: gameModel.rand.int(30, 200) * (gameModel.rand.int(0, 1) ? 1 : -1),
            text: "Dodged",
          },
          gameModel
        );
      }
    }
  }
}

registerSystem(DamageMitigationSystem);

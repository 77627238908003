import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import type { System } from "yage/components/System";
import { ComponentCategory } from "yage/components/types";
import { EntityType, PickupStateEnum } from "yage/constants/enums";
import { Component, defaultValue, Schema, type } from "yage/decorators/type";
import type { GameModel } from "yage/game/GameModel";
import { MappedKeys } from "yage/inputs/InputManager";
import { CollisionsSchema } from "yage/schemas/physics/Collisions";
import { keyPressed } from "yage/utils/keys";
import { PickupStateSchema } from "./PickupState";
import { PlayerInputSchema } from "yage/schemas/core/PlayerInput";
import DescriptionSchema from "yage/schemas/core/Description";
import { ShareOnPickupSchema } from "../core/ShareOnPickup";
import { generateShareList } from "yage/utils/generateShareList";

@Component("ItemPickup")
export class ItemPickupSchema extends Schema {
  @type("boolean")
  @defaultValue(false)
  interacted: boolean;
}

class ItemPickupSystem implements System {
  type = "ItemPickup";
  category: ComponentCategory = ComponentCategory.BEHAVIOR;
  schema = ItemPickupSchema;
  depth = DEPTHS.ITEMS;
  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, ItemPickupSchema);
    data.interacted = false;
    const collisionData =
      gameModel.getTypedUnsafe(gameModel.coreEntity, CollisionsSchema)?.collisions[entity]?.filters ?? {};
    if (
      collisionData[EntityType.PICKUP]?.length ||
      // collisionData[EntityType.WEAPON]?.length ||
      collisionData[EntityType.INTERACTABLE]?.length ||
      collisionData[EntityType.WALL]?.length
    ) {
      const pickup = collisionData[EntityType.PICKUP] ?? [];
      // const weapon = collisionData[EntityType.WEAPON] ?? [];
      const interactable = collisionData[EntityType.INTERACTABLE] ?? [];
      const wall = collisionData[EntityType.WALL] ?? [];
      const pickups: number[] = pickup.concat(interactable).concat(wall);
      // const pickups: number[] = pickup.concat(weapon).concat(interactable).concat(wall);
      for (let i = 0; i < pickups.length; ++i) {
        const pickup = pickups[i];
        const pickupComponents = gameModel.getComponentIdsByCategory(pickup, ComponentCategory.PICKUP);
        const pickupState = gameModel.getTyped(pickup, PickupStateSchema);

        if (pickupState?.state === PickupStateEnum.INTERACTABLE) {
          const netData = gameModel.getTypedUnsafe(entity, PlayerInputSchema);

          if (keyPressed([MappedKeys.INTERACT], netData.keyMap, netData.prevKeyMap)) {
            const interactionComponents = gameModel.getComponentIdsByCategory(pickup, ComponentCategory.INTERACTION);
            for (let j = 0; j < interactionComponents.length; ++j) {
              const interactionComponent = gameModel.getComponent(pickup, interactionComponents[j]) as any;
              interactionComponent.owner = entity;
              const system = gameModel.getSystem(interactionComponents[j]) as System;
              if (system.run) system.run(pickup, gameModel);
            }
            data.interacted = true;
          }
        } else if (
          pickupState?.state === PickupStateEnum.ON_THE_GROUND &&
          (!pickupState.owner || pickupState.owner === entity)
        ) {
          let destroy = false;
          for (let j = 0; j < pickupComponents.length; ++j) {
            const pickupComponent = gameModel.getComponent(pickup, pickupComponents[j]) as any;
            pickupComponent.owner = entity;
            const system = gameModel.getSystem(pickupComponents[j]) as System;
            if (system.run) system.run(pickup, gameModel);
            destroy = pickupComponent.destroyOnPickup ?? destroy;
          }
          if (destroy) {
            const shareList = generateShareList(entity, ShareOnPickupSchema, ComponentCategory.ON_PICKUP, gameModel);
            if (shareList.length > 0) {
              for (let i = 0; i < shareList.length; i++) {
                const [component, entities] = shareList[i];
                for (let j = 0; j < entities.length; j++) {
                  const entityId = entities[j];
                  const mod = gameModel.getComponentUnsafe(entityId, component);
                  if (mod.pickedup !== undefined) {
                    mod.pickedup = pickup;
                  }
                  if (mod.owner !== undefined) {
                    mod.owner = entity;
                  }
                  const system = gameModel.getSystem(component) as System;
                  if (system.run) system.run(entityId, gameModel);
                }
              }
            }

            gameModel.removeEntity(pickup);
          }
        }
      }
    }
  }
}

registerSystem(ItemPickupSystem);

import { System } from "yage/components/System";
import { ComponentCategory } from "yage/constants/enums";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { EntityFactory } from "yage/entity/EntityFactory";
import { GameModel } from "yage/game/GameModel";
import { InventorySchema } from "../player/Inventory";
import { DEPTHS, registerSchema, registerSystem } from "yage/components/ComponentRegistry";
import { getEntityController } from "../../utils/playerCharacter";
import { TextBloopSchema, addBloop } from "../render/TextBloop";
import { TransformSchema } from "yage/schemas/entity/Transform";
import { OwnerSchema } from "yage/schemas/core/Owner";
import { ChildSchema } from "yage/schemas/entity/Child";

@Component("AddItemOverTime")
class AddItemOverTimeSchema extends Schema {
  @type("number")
  @defaultValue(0)
  timeElapsed: number;

  @type("number")
  @defaultValue(1000)
  frequency: number;

  @type("number")
  @defaultValue(10000)
  quantity: number;

  @type("string")
  @defaultValue("")
  inventoryPrefix: string;

  @type("string")
  entityDescription: string;

  @type("boolean")
  @defaultValue(true)
  destroyOnEmpty: boolean;

  @type(TextBloopSchema)
  bloop: Partial<TextBloopSchema>;

  @type("string")
  @defaultValue("")
  bloopTemplate: string;
}

class AddItemOverTimeSystem implements System {
  type = "AddItemOverTime";
  depth = DEPTHS.ITEMS;
  schema = AddItemOverTimeSchema;
  intraFrame = 30;

  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, AddItemOverTimeSchema);
    let previousTime = data.timeElapsed;
    data.timeElapsed += gameModel.dt<number>(entity) * this.intraFrame;

    if (data.quantity === 0) {
      if (data.destroyOnEmpty) {
        gameModel.removeEntity(entity);
      } else {
        gameModel.removeComponent(entity, AddItemOverTimeSchema);
      }
      return;
    }

    if (previousTime % data.frequency > data.timeElapsed % data.frequency && data.quantity > 0) {
      TransformSchema.id = entity;
      const startingPosition = TransformSchema.position;
      data.quantity -= 1;
      console.log(
        "ADDING ITEM",
        data.entityDescription,
        "AT",
        startingPosition.x,
        startingPosition.y,
        "WITH",
        data.bloopTemplate,
        "AND",
        data.bloop,
        "AND",
        data.inventoryPrefix,
        "AND",
        data.quantity,
        "LEFT"
      );

      if (data.bloop) {
        addBloop(
          {
            ...data.bloop,
            startFrame: gameModel.timeElapsed,
            timeout: gameModel.timeElapsed + (data.bloop.timeout || 500),
            position: {
              x: startingPosition.x + gameModel.rand.int(0, 100) - 50,
              y: startingPosition.y + gameModel.rand.int(0, 100) - 50,
            },
            yVelocity: data.bloop.yVelocity || -400,
            xVelocity: data.bloop.xVelocity || gameModel.rand.int(30, 200) * (gameModel.rand.int(0, 1) ? 1 : -1),
            text: data.bloopTemplate.replace("$$VALUE", "1"),
          },
          gameModel
        );
      }

      const player =
        gameModel.getTyped(entity, OwnerSchema)?.owner ?? gameModel.getTyped(entity, ChildSchema)?.parent ?? entity;

      EntityFactory.getInstance().generateEntity(gameModel, data.entityDescription, {
        Child: {
          parent: player,
        },
        Owner: {
          owner: player,
        },
      });
      if (data.inventoryPrefix) {
        const inventory = gameModel.getTypedUnsafe(getEntityController(player, gameModel), InventorySchema);
        if (!inventory.inventory[data.inventoryPrefix + "::" + data.entityDescription]) {
          inventory.inventory[data.inventoryPrefix + "::" + data.entityDescription] = {
            amount: 0,
          };
        }
        inventory.inventory[data.inventoryPrefix + "::" + data.entityDescription].amount += 1;
      }
    }
  }
}

registerSystem(AddItemOverTimeSystem);

import { System } from "yage/components/System";
import { ComponentCategory } from "yage/constants/enums";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { EntityFactory } from "yage/entity/EntityFactory";
import { GameModel } from "yage/game/GameModel";
import { InventorySchema } from "../player/Inventory";
import { registerSchema, registerSystem } from "yage/components/ComponentRegistry";
import { getEntityController } from "../../utils/playerCharacter";
import { TextBloopSchema, addBloop } from "../render/TextBloop";
import { TransformSchema } from "yage/schemas/entity/Transform";
import { TrackAddStatStatSchema } from "../persistmods/TrackAddStat";

@Component("AddItemOnKill")
class AddItemOnKillSchema extends Schema {
  @type("number")
  @defaultValue(0)
  kills: number;

  @type("number")
  @defaultValue(1)
  killCount: number;

  @type("Entity")
  @defaultValue(-1)
  owner: number;

  @type("string")
  @defaultValue("Stat")
  inventoryPrefix: string;

  @type("string")
  entityDescription: string;

  @type(TextBloopSchema)
  bloop: Partial<TextBloopSchema>;

  @type("string")
  @defaultValue("")
  bloopTemplate: string;
}

class AddItemOnKillSystem implements System {
  type = "AddItemOnKill";
  category = ComponentCategory.ONKILL;
  schema = AddItemOnKillSchema;
  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, AddItemOnKillSchema);
    data.kills += 1;
    if (data.kills >= data.killCount) {
      if (!gameModel.hasComponent(entity, TrackAddStatStatSchema)) {
        gameModel.addComponent(entity, TrackAddStatStatSchema);
      }
      const TrackAddStatStat = gameModel.getTypedUnsafe(entity, TrackAddStatStatSchema);
      TrackAddStatStat.statIncrements++;

      TransformSchema.id = entity;
      const startingPosition = TransformSchema.position;

      if (data.bloop) {
        addBloop(
          {
            ...data.bloop,
            startFrame: gameModel.timeElapsed,
            timeout: gameModel.timeElapsed + (data.bloop.timeout || 500),
            position: {
              x: startingPosition.x + gameModel.rand.int(0, 100) - 50,
              y: startingPosition.y + gameModel.rand.int(0, 100) - 50,
            },
            yVelocity: data.bloop.yVelocity || -400,
            xVelocity: data.bloop.xVelocity || gameModel.rand.int(30, 200) * (gameModel.rand.int(0, 1) ? 1 : -1),
            text: data.bloopTemplate.replace("$$VALUE", "1"),
          },
          gameModel
        );
      }

      data.kills = 0;

      const player = gameModel.getTypedUnsafe(entity, AddItemOnKillSchema).owner!;

      EntityFactory.getInstance().generateEntity(gameModel, data.entityDescription, {
        Child: {
          parent: player,
          autoAttach: false,
        },
      });
      if (data.inventoryPrefix) {
        const inventory = gameModel.getTypedUnsafe(getEntityController(player, gameModel), InventorySchema);
        if (!inventory.inventory[data.inventoryPrefix + "::" + data.entityDescription]) {
          inventory.inventory[data.inventoryPrefix + "::" + data.entityDescription] = {
            amount: 0,
          };
        }
        inventory.inventory[data.inventoryPrefix + "::" + data.entityDescription].amount += 1;
      }
    }
  }
}

registerSystem(AddItemOnKillSystem);

import { registerSchema } from "yage/components/ComponentRegistry";
import { ComponentCategory } from "yage/constants/enums";
import { Component, type } from "yage/decorators/type";
import { SpriteSchema } from "yage/schemas/render/Sprite";

@Component("KeySprite")
export class KeySpriteSchema extends SpriteSchema {
  @type("string")
  key: string;
}

registerSchema(ComponentCategory.RENDERING, KeySpriteSchema);

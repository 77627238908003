import { PlayerTypeSchema, EnemyTypeSchema } from "yage/components/entity/Types";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";
import { registerSystem } from "yage/components/ComponentRegistry";

@Component("ExpEnhancer")
export class ExpEnhancerSchema extends Schema {
  @type("number")
  @defaultValue(1)
  expScale: number;
}

export class ExpEnhancerSystem {
  schema = ExpEnhancerSchema;
  type = "ExpEnhancer";

  dependencies = ["Child"];

  init(entity: number, gameModel: GameModel) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (!gameModel.hasComponent(parent, "ExpEnhancer")) {
        gameModel.addComponent(parent, ExpEnhancerSchema);
      }
      gameModel.getTypedUnsafe(parent, ExpEnhancerSchema).expScale += gameModel.getTypedUnsafe(
        entity,
        ExpEnhancerSchema
      ).expScale;
    }
  }
}

registerSystem(ExpEnhancerSystem);

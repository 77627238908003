import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { InventorySchema } from "../player/Inventory";
import { getEntityController } from "../../utils/playerCharacter";
import { PlayerTypeSchema, EnemyTypeSchema } from "yage/components/entity/Types";
import { ChildSchema } from "yage/schemas/entity/Child";

@Component("PercentMaterialsOnStart")
export class PercentMaterialsOnStartSchema extends Schema {
  @type("number")
  @defaultValue(0.2)
  percentMaterials: number;
}

class PercentMaterialsOnStartSystem implements System {
  schema = PercentMaterialsOnStartSchema;
  type = "PercentMaterialsOnStart";

  dependencies = ["Child"];
  init(entity: number, gameModel: GameModel) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      const entityController = getEntityController(parent, gameModel);
      const inventory = gameModel.getTypedUnsafe(entityController, InventorySchema).inventory;

      if (gameModel.getTypedUnsafe(entity, PercentMaterialsOnStartSchema).percentMaterials === -1) {
        inventory.coins.amount = 0;
      } else {
        inventory.coins.amount += Math.floor(
          inventory.coins.amount * gameModel.getTypedUnsafe(entity, PercentMaterialsOnStartSchema).percentMaterials
        );
        if (inventory.coins.amount < 0) {
          inventory.coins.amount = 0;
        }
      }
    }
  }
}

registerSystem(PercentMaterialsOnStartSystem);

import { registerSystem } from "yage/components/ComponentRegistry";
import type { System } from "yage/components/System";
import { ComponentCategory } from "yage/components/types";
import type { EntityType } from "yage/constants/enums";
import { Component, defaultValue, required, Schema, type } from "yage/decorators/type";
import type { GameModel } from "yage/game/GameModel";
import { OwnerSchema } from "yage/schemas/core/Owner";
import { CollisionFiltersSchema } from "yage/schemas/physics/Collisions";
import { getOwner } from "../../utils/getOwner";

@Component("EnemyColliders")
export class EnemyCollidersSchema extends Schema {
  @required()
  @type(["number"])
  @defaultValue([])
  colliders: EntityType[];

  @type("boolean")
  @defaultValue(false)
  applyToCollisionFilters: boolean;
}

class EnemyCollidersSystem implements System {
  type = "EnemyColliders";
  category: ComponentCategory = ComponentCategory.BEHAVIOR;
  schema = EnemyCollidersSchema;
  dependencies = ["Owner", "CollisionFilters"];

  init(entity: number, gameModel: GameModel) {
    const enemyColliders = gameModel.getTypedUnsafe(entity, EnemyCollidersSchema);

    if (!enemyColliders.colliders || enemyColliders.colliders.length === 0) {
      if (gameModel.hasComponent(entity, "Owner")) {
        const owner = getOwner(entity, gameModel);
        if (owner && gameModel.hasComponent(owner, "EnemyColliders")) {
          enemyColliders.colliders = [...gameModel.getTypedUnsafe(owner, EnemyCollidersSchema).colliders];
        }
      }
    }
    if (enemyColliders.applyToCollisionFilters && gameModel.hasComponent(entity, "CollisionFilters")) {
      const collisionFilters = gameModel.getTypedUnsafe(entity, CollisionFiltersSchema);
      if (collisionFilters.filters.length) {
        enemyColliders.colliders.forEach((filter) => {
          if (!collisionFilters.filters.includes(filter)) {
            collisionFilters.filters.push(filter);
          }
        });
      } else {
        collisionFilters.filters = [...enemyColliders.colliders];
      }
    }
  }
}

registerSystem(EnemyCollidersSystem);

import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { DamageStatsSchema, invalidateDamageStats } from "../weapons/DamageStats";
import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";
import { DamageMitigationSchema } from "../damagemods/DamageMitigation";
import { HealthSchema, getMaxHealth } from "../core";
import { InvalidateDamageStatsOnMaxHealthChangeSchema } from "../damagemods/PercentMaxHealthDamage";

@Component("ScaleOppositeStats")
export class ScaleOppositeStatsSchema extends Schema {
  @type("number")
  @defaultValue(1)
  baseScale: number;

  @type("string")
  baseStat: string;

  @type("string")
  baseComponentType: string;

  @type("string")
  oppositeStat: string;

  @type("string")
  oppositeComponentType: string;

  @type("number")
  @defaultValue(1)
  oppositeScale: number;
}

class ScaleOppositeStatsSystem implements System {
  schema = ScaleOppositeStatsSchema;
  type = "ScaleOppositeStats";
  dependencies = ["Child", "DamageMitigationEnhancer"];
  init(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, ScaleOppositeStatsSchema);
    const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
    if (
      !gameModel.hasComponent(parent, data.oppositeComponentType) ||
      !gameModel.hasComponent(parent, data.baseComponentType)
    ) {
      return;
    }

    // @ts-ignore
    const sourceStatValue = gameModel.getComponentUnsafe(parent, data.baseComponentType)[data.baseStat] as number;
    const scalingStatValue = gameModel.getComponentUnsafe(parent, data.oppositeComponentType)[
      data.oppositeStat
    ] as number;

    const scaleStats = (stat: string, statComponentType: string, statValue: number, scale: number) => {
      if (stat === "maxHealth") {
        HealthSchema.id = parent;

        HealthSchema.maxHealth = HealthSchema.maxHealth + statValue * scale;
        const maxHealth = getMaxHealth(parent, gameModel);

        if (HealthSchema.health > maxHealth) {
          HealthSchema.health = maxHealth;
        } else if (statValue * scale > 0) {
          HealthSchema.health = HealthSchema.health + statValue * scale;
        }
      } else {
        const stats =
          statComponentType === "DamageStats"
            ? gameModel.getTypedUnsafe(parent, DamageStatsSchema).initialDamageStats
            : gameModel.getComponentUnsafe(parent, statComponentType);

        if (stat.startsWith("min")) {
          const maxStat = "max" + stat.substring(3);
          // @ts-ignore
          stats[maxStat] = stats[maxStat] + statValue * scale;
        }
        // @ts-ignore
        stats[stat] = stats[stat] + statValue * scale;
      }
    };
    scaleStats(data.baseStat, data.baseComponentType, scalingStatValue, data.baseScale);
    scaleStats(data.oppositeStat, data.oppositeComponentType, sourceStatValue, data.oppositeScale);

    invalidateDamageStats(parent, gameModel);
  }
}

registerSystem(ScaleOppositeStatsSystem);

import { DEPTHS, registerSystem, registerUIComponent } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { HealthSchema } from "../core";
import { localIndex } from "../../utils/localIndex";
import { Button } from "yage/ui/Button";
import { Position } from "yage/ui/Rectangle";
import { PlayerInputSchema } from "yage/schemas/core/PlayerInput";
import { ChildSchema } from "yage/schemas/entity/Child";
import { getPlayerCharacter } from "../../utils/playerCharacter";
import { InvincibilitySchema } from "../core/Invincibility";
import { strokeStyle } from "../../utils/ui-styles";

@Component("OfferReviveOnDeath")
export class OfferReviveOnDeathSchema extends Schema {
  @type("number")
  reviveCost: number = 0.5;

  @type("boolean")
  offerRevive: boolean = false;

  @type("number")
  initialCoins: number;

  @type("object")
  ejectedCharacter: any;

  @type("boolean")
  reviveTriggered: boolean = false;

  @type("string")
  @defaultValue("ProjectVLobby")
  endGameScene: string;
}

class OfferReviveOnDeathSystem implements System {
  schema = OfferReviveOnDeathSchema;
  type: string = "OfferReviveOnDeath";
  depth = DEPTHS.HEALTH - 0.0001;

  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, OfferReviveOnDeathSchema);

    if (data.offerRevive) {
      const playerInput = gameModel.getTypedUnsafe(entity, PlayerInputSchema);
      if (playerInput.events.includes("revive")) {
        const ejectedCharacter = data.ejectedCharacter;
        const playerCharacter = gameModel.injectEntity(ejectedCharacter);
        gameModel.logEntity(playerCharacter, true);
        gameModel.addComponent(playerCharacter, ChildSchema, {
          parent: entity,
          autoAttach: false,
        });
        gameModel.addComponent(playerCharacter, InvincibilitySchema, {
          time: 1000,
        });
        gameModel.logEntity(entity, true);
        const healthData = gameModel.getTypedUnsafe(playerCharacter, HealthSchema);
        healthData.health = healthData.maxHealth;
        data.offerRevive = false;
        data.reviveTriggered = true;
      }
      return;
    }
    const playerCharacter = getPlayerCharacter(entity, gameModel);
    const healthData = gameModel.getTypedUnsafe(playerCharacter, HealthSchema);

    if (healthData.health > 0) {
      return;
    }

    data.ejectedCharacter = gameModel.ejectEntity(playerCharacter);
    data.offerRevive = true;
  }
}

registerSystem(OfferReviveOnDeathSystem);

let reviveButton: Button | null = null;
let triggered = false;

registerUIComponent(
  "OfferReviveOnDeath",
  (ui, entity, model, viewport) => {
    const data = model.getTypedUnsafe(entity, OfferReviveOnDeathSchema);
    if (!data.offerRevive || triggered || data.reviveTriggered) {
      if (data.reviveTriggered) {
        triggered = false;
      }
      return;
    }

    if (localIndex(entity, model) !== -1) {
      if (!reviveButton) {
        reviveButton = new Button(new Position("center", "center", { width: 200, height: 100 }), {
          label: "Revive",
          fontSize: 30,
          style: {
            backgroundColor: "#404040",
            borderRadius: "10px",
            ...strokeStyle(),
          },
          onClick: () => {
            model.instance!.options.connection.eventsManager.addEvent("revive");
            ui.removeFromUI(reviveButton!);
            reviveButton = null;
            triggered = true;
          },
        });
        ui.addToUI(reviveButton);
      }
    }
  },
  {
    cleanup: (ui) => {
      if (reviveButton) {
        ui.removeFromUI(reviveButton);
        reviveButton = null;
      }
    },
  }
);

import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";
import { ChanceDamageMaxHealthOnHealSchema } from "../effects/ChanceDamageMaxHealthOnHeal";
import { EnemyTypeSchema, PlayerTypeSchema } from "yage/components/entity/Types";

@Component("ChanceDamageMaxHealthOnHealEnhancer")
export class ChanceDamageMaxHealthOnHealEnhancerSchema extends Schema {
  @type("number")
  @defaultValue(0.25)
  chance: number;

  @type("number")
  @defaultValue(0.25)
  healthDamageScale: number;
}

class ChanceDamageMaxHealthOnHealSystem implements System {
  schema = ChanceDamageMaxHealthOnHealEnhancerSchema;
  type = "ChanceDamageMaxHealthOnHealEnhancer";
  dependencies = ["Child"];
  init(entity: number, gameModel: GameModel) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (!gameModel.hasComponent(parent, "ChanceDamageMaxHealthOnHeal")) {
        gameModel.addComponent(parent, ChanceDamageMaxHealthOnHealSchema);
      }
      gameModel
        .getTypedUnsafe(parent, ChanceDamageMaxHealthOnHealSchema)
        .chance.push(gameModel.getTypedUnsafe(entity, ChanceDamageMaxHealthOnHealEnhancerSchema));
    }
  }

  cleanup(entity: number, gameModel: GameModel, ejecting: boolean) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (gameModel.hasComponent(parent, "ChanceDamageMaxHealthOnHeal")) {
        const index = gameModel
          .getTypedUnsafe(parent, ChanceDamageMaxHealthOnHealSchema)
          .chance.findIndex(
            (chance) =>
              chance.chance === gameModel.getTypedUnsafe(entity, ChanceDamageMaxHealthOnHealEnhancerSchema).chance &&
              chance.healthDamageScale ===
                gameModel.getTypedUnsafe(entity, ChanceDamageMaxHealthOnHealEnhancerSchema).healthDamageScale
          );
        if (index !== -1) {
          gameModel.getTypedUnsafe(parent, ChanceDamageMaxHealthOnHealSchema).chance.splice(index, 1);
        }
      }
    }
  }
}
registerSystem(ChanceDamageMaxHealthOnHealSystem);

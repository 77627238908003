import { DEPTHS, registerUIComponent, registerSystem } from "yage/components/ComponentRegistry";
import { ComponentCategory } from "yage/components/types";
import { EnemyTypeEnum } from "yage/constants/enums";
import { Component, defaultValue, required, Schema, type } from "yage/decorators/type";
import type { GameModel } from "yage/game/GameModel";
import type { Vector2d } from "yage/utils/vector";
import { Vector2dSchema } from "yage/utils/vector";
import type { System } from "yage/components/System";
import { clone } from "yage/utils/clone";
import type { UIElement } from "yage/ui/UIElement";
import { Text } from "yage/ui/Text";
import { Rectangle } from "yage/ui/Rectangle";

class KillStatSchema extends Schema {
  @type("number")
  @defaultValue(0)
  [EnemyTypeEnum.U_DEF]: number;

  @type("number")
  @defaultValue(0)
  [EnemyTypeEnum.ALL]: number;

  @type("number")
  @defaultValue(0)
  [EnemyTypeEnum.DEMON]: number;

  @type("number")
  [EnemyTypeEnum.SLUDGE]: number;

  @type("number")
  [EnemyTypeEnum.NECROMANCER]: number;

  @type("number")
  [EnemyTypeEnum.ORC]: number;

  @type("number")
  [EnemyTypeEnum.ZOMBIE]: number;
}

export class KillFrameSchema extends Schema {
  @required()
  @type("number")
  type: EnemyTypeEnum;

  @type("string")
  @defaultValue("")
  description: string;

  @required()
  @type(Vector2dSchema)
  position: Vector2d;

  @required()
  @type("Entity")
  owner: number;

  @type("Entity")
  source: number;
}

export class PreviousKillsSchema extends Schema {
  @type(KillStatSchema)
  @defaultValue({})
  kills: KillStatSchema;

  @type([KillFrameSchema])
  @defaultValue([])
  killsThisFrame: Array<KillFrameSchema>;
}

@Component("KillStats")
export class KillStatsSchema extends Schema {
  @type(KillStatSchema)
  @defaultValue({})
  kills: KillStatSchema;

  @type([KillFrameSchema])
  @defaultValue([])
  killsThisFrame: Array<KillFrameSchema>;

  @type(PreviousKillsSchema)
  @defaultValue({})
  previousStats: PreviousKillsSchema;
}

class KillStatsSystem implements System {
  type = "KillStats";
  category: ComponentCategory = ComponentCategory.BEHAVIOR;
  schema = KillStatsSchema;
  depth = DEPTHS.HEALTH - 1;

  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, KillStatsSchema);
    data.previousStats = clone({
      kills: data.kills,
      killsThisFrame: data.killsThisFrame,
    });
    data.killsThisFrame = [];
  }
}

registerSystem(KillStatsSystem);

@Component("GlobalKillStats")
export class GlobalKillStatsSchema extends KillStatsSchema {}

class GlobalKillStatsSystem implements System {
  type = "GlobalKillStats";
  category: ComponentCategory = ComponentCategory.BEHAVIOR;
  schema = GlobalKillStatsSchema;
  depth = DEPTHS.HEALTH + 1;

  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, GlobalKillStatsSchema);
    data.previousStats = clone({
      kills: data.kills,
      killsThisFrame: data.killsThisFrame,
    });

    // @ts-ignore
    data.kills = {};
    data.killsThisFrame = data.killsThisFrame.length > 0 ? [] : data.killsThisFrame;
    const playerKillStats = gameModel.players.map((player) => gameModel.getTypedUnsafe(player, KillStatsSchema));
    playerKillStats.forEach((playerKillStat) => {
      Object.keys(playerKillStat.kills).forEach((enemyType) => {
        // @ts-ignore
        data.kills[enemyType] =
          // @ts-ignore
          (data.kills[enemyType] || 0) + playerKillStat.kills[enemyType];
      });
      data.killsThisFrame.push(...playerKillStat.killsThisFrame);
    });
  }
}

registerSystem(GlobalKillStatsSystem);

let ui: UIElement[] | null = null;
let lastKillCount = 0;

registerUIComponent(
  "KillStats",
  (uiService, entity, gameModel) => {
    if (!ui) {
      ui = [];

      const currentKills = new Text(new Rectangle(1600, 135, 1, 1), {
        label: "Kills: 0",
        fontSize: 16,
        style: {
          textTransform: "uppercase",
        },
      });
      ui.push(currentKills);
      uiService.addToUI(currentKills);
    }
    const data = gameModel.getTypedUnsafe(entity, KillStatsSchema);

    if (lastKillCount !== data.kills[EnemyTypeEnum.ALL]) {
      window.localStorage.setItem("kills", data.kills[EnemyTypeEnum.ALL].toString());
      lastKillCount = data.kills[EnemyTypeEnum.ALL];
    }

    ui[0].config.label = `Kills: ${data.kills[EnemyTypeEnum.ALL]}`;
  },
  {
    cleanup: () => {
      ui?.[0].onDestroy();
      ui = null;
    },
  }
);

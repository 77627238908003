import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { GameModel } from "yage/game/GameModel";
import { TrackAchievementsOnRoundEndSchema } from "./TrackAchievementsOnRoundEnd";
import { PlayerInputSchema } from "yage/schemas/core/PlayerInput";
import { AchievementsSchema, Achievement, AchievementCondition } from "../../schema/achievements/Achievements";
import { ConnectionInstance } from "yage/connection/ConnectionInstance";
import { PlayerState } from "../../types/PlayerState.types";
import { ChildSchema } from "yage/schemas/entity/Child";
import { getPlayerCharacter } from "../../utils/playerCharacter";
import { ShareOnRoundEndSchema } from "../core/ShareOnRoundEnd";
import { ShareOnKillSchema } from "../core/ShareOnKill";
import { TrackAchievementsOnKillSchema } from "./TrackAchievementsOnKill";
import { get } from "lodash";
import { ShareOnPickupSchema } from "../core/ShareOnPickup";
import { TrackAchievementsOnPickupSchema } from "./TrackAchievementsOnPickup";
import { ShareOnDeathSchema } from "../core/ShareOnDeath";
import { TrackAchievementsOnDeathSchema } from "./TrackAchievementsOnDeath";
import { TrackAchievementsActiveStatSchema } from "./TrackAchievementsActiveStat";

class AchievementsSystem implements System {
  schema = AchievementsSchema;
  type = "Achievements";
  depth = DEPTHS.TRIGGERS + 999;

  dependencies = ["Child"];
  // intraFrame = 60;
  init(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, AchievementsSchema);

    data.list.forEach((achievement) => {
      gameModel.instance!.achievementService.registerAchievement({
        name: achievement.name,
        description: achievement.description,
        target: achievement.target,
      });
    });

    const entityController = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
    if (!entityController) {
      return;
    }
    const playerInput = gameModel.getTypedUnsafe(entityController, PlayerInputSchema);
    const selfId = playerInput.id;

    console.log(gameModel.localNetIds);

    // only track achievements for single local player games
    if (!gameModel.localNetIds.includes(selfId) && gameModel.localNetIds.length === 1) {
      return;
    }

    const systemId = (gameModel.instance!.options.connection as ConnectionInstance<PlayerState>).localPlayers[0]
      .uniqueId;

    const userAchievements = gameModel.instance!.achievementService.getAchievements(systemId);

    if (!systemId) {
      return;
    }

    data.unlocked = userAchievements
      .map((a) => (a.progress === 100 ? "Achievement::" + a.name : ""))
      .filter((a) => a !== "") as string[];

    for (let i = 0; i < data.list.length; i++) {
      const achievement = data.list[i];
      if (data.unlocked.includes("Achievement::" + achievement.name)) {
        continue;
      }
      this.addAchievementTrigger(entity, entityController, achievement, gameModel);
    }
  }

  addAchievementTrigger(entity: number, entityController: number, achievement: Achievement, gameModel: GameModel) {
    switch (achievement.condition.type) {
      case "OnRoundEnd":
        gameModel.addIfMissing(entity, ShareOnRoundEndSchema);
        gameModel.addIfMissing(entity, TrackAchievementsOnRoundEndSchema);
        gameModel.getTypedUnsafe(entity, TrackAchievementsOnRoundEndSchema).achievements.push(achievement);
        break;
      case "OnKill":
        gameModel.addIfMissing(entity, ShareOnKillSchema);
        gameModel.addIfMissing(entity, TrackAchievementsOnKillSchema);
        gameModel.getTypedUnsafe(entity, TrackAchievementsOnKillSchema).achievements.push(achievement);
        break;
      case "OnDeath":
        gameModel.addIfMissing(entity, ShareOnDeathSchema);
        gameModel.addIfMissing(entity, TrackAchievementsOnDeathSchema);
        gameModel.getTypedUnsafe(entity, TrackAchievementsOnDeathSchema).achievements.push(achievement);
        break;
      case "OnPickup":
        gameModel.addIfMissing(entity, ShareOnPickupSchema);
        gameModel.addIfMissing(entity, TrackAchievementsOnPickupSchema);
        gameModel.getTypedUnsafe(entity, TrackAchievementsOnPickupSchema).achievements.push(achievement);
        break;
      case "OnStatChange":
        gameModel.addIfMissing(entity, TrackAchievementsActiveStatSchema);
        gameModel.getTypedUnsafe(entity, TrackAchievementsActiveStatSchema).achievements.push(achievement);
        break;
    }
  }

  cleanup(_entity: number, gameModel: GameModel) {
    gameModel.instance!.achievementService.flush();
  }
}

registerSystem(AchievementsSystem);

export const unlockAchievement = (entity: number, achievement: Achievement, gameModel: GameModel) => {
  const data = gameModel.getTypedUnsafe(entity, AchievementsSchema);
  data.unlocked.push("Achievement::" + achievement.name);

  const systemId = (gameModel.instance!.options.connection as ConnectionInstance<PlayerState>).localPlayers[0].uniqueId;
  if (!systemId) {
    return;
  }
  gameModel.instance!.achievementService.unlockAchievement(systemId, achievement.name);
};

export const incrementAchievement = (
  entity: number,
  achievement: Achievement,
  gameModel: GameModel,
  amount: number = 1
): boolean => {
  const systemId = (gameModel.instance!.options.connection as ConnectionInstance<PlayerState>).localPlayers[0].uniqueId;
  if (!systemId) {
    return false;
  }
  return gameModel.instance!.achievementService.incrementAchievementProgress(systemId, achievement.name, amount);
};

const operatorQuery = /\s(equal|gte|lte|gt|lt|neq)\s(.*)$/g;

export const checkCondition = (
  entityController: number,
  condition: AchievementCondition,
  gameModel: GameModel,
  eventData?: any
) => {
  const playerCharacter = getPlayerCharacter(entityController, gameModel);
  if (typeof condition.data === "object") {
    const getEntity = (entityType: string) => {
      if (entityType.startsWith("$")) {
        return gameModel.getComponentActives(entityType.substring(1));
      }

      let entity = entityController;
      switch (entityType) {
        case "PLAYERCHARACTER":
          entity = playerCharacter;
          break;
        case "CORE":
          entity = gameModel.coreEntity;
          break;
        case "CONTROLLER":
          entity = entityController;
          break;
        case "EVENTENTITY":
          entity = eventData.entity;
          break;
        case "EVENTSOURCE":
          entity = eventData.source;
          break;
        case "EVENTOWNER":
          entity = eventData.owner;
          break;
      }
      return [entity];
    };

    const getOperator = (operatorType: string) => {
      let operator: (a: any, b: any) => boolean = (a, b) => a === b;

      switch (operatorType) {
        case "gte":
          operator = (a, b) => a >= b;
          break;
        case "lte":
          operator = (a, b) => a <= b;
          break;
        case "gt":
          operator = (a, b) => a > b;
          break;
        case "lt":
          operator = (a, b) => a < b;
          break;
        case "neq":
          operator = (a, b) => a !== b;
          break;
        case "contains":
          operator = (a, b) => a.includes(b);
          break;
      }
      return operator;
    };

    let total = 0;

    const result = Object.entries(condition.data).every(([component, value]) => {
      const entities = getEntity(value.entityType);
      for (const entity of entities) {
        if (condition.operator === "exists") {
          return gameModel.hasComponent(entity, component);
        }
        if (!gameModel.hasComponent(entity, component)) {
          // console.warn("Component not found", entity, value.entityType);
          // console.warn(component, value);
          // console.warn(condition, gameModel);
          // gameModel.logEntity(entity, true);
          return false;
        }
        let data = gameModel.getComponent(entity, component);
        const operator = getOperator(condition.operator);
        const getValue = (data: any, key: string) => {
          if (key.includes("[*]")) {
            let operator = "";
            let amount = 0;
            if (key.includes(" lte ") || key.includes(" gte ") || key.includes(" lt ") || key.includes(" gt ")) {
              const match = operatorQuery.exec(key);
              if (match) {
                operator = match[1].trim();
                amount = parseInt(match[2]);
              }
            }

            let [keyWithoutArray, arrQuery] = key.split("[*]");
            const arr = get(data, keyWithoutArray);
            if (arrQuery.startsWith(".")) {
              arrQuery = arrQuery.substring(1, arrQuery.indexOf(" "));
            }

            return arr?.reduce((acc: number, val: any) => {
              const value = get(val, arrQuery);
              if (operator && !getOperator(operator)(value, amount)) {
                return acc;
              }
              return acc + value;
            }, 0);
          }
          return get(data, key);
        };

        for (const key in value) {
          if (key === "entityType") {
            if (condition.sum && Object.keys(value).length === 1) {
              total += 1;
            }
            continue;
          }
          if (value[key] === "__SUM__") {
            total += getValue(data, key);
            continue;
          }
          if (value[key] === "PLAYERCHARACTER") {
            if (condition.sum && Object.keys(value).length === 2) {
              if (getValue(data, key) === playerCharacter) {
                total++;
              }
              continue;
            }
            if (getValue(data, key) === playerCharacter) {
              continue;
            } else {
              return false;
            }
          }

          if (!operator(getValue(data, key), value[key])) {
            return false;
          }
        }
      }
      return true;
    });
    if (condition.sum) {
      return result && getOperator(condition.operator)(total, condition.sum);
    }
    return result;
  }
  return false;
};

import { registerSystem } from "yage/components/ComponentRegistry";
import type { System } from "yage/components/System";
import { ComponentCategory } from "yage/components/types";
import { Component, Schema, type } from "yage/decorators/type";
import type { GameModel } from "yage/game/GameModel";
import { OwnerSchema } from "yage/schemas/core/Owner";
import { ChildSchema } from "yage/schemas/entity/Child";
import { TransformSchema } from "yage/schemas/entity/Transform";
import { AuraContainerSchema } from "./AuraContainer";
import { AuraTypeSchema } from "yage/components/entity/Types";

@Component("AuraApplicator")
export class AuraApplicatorSchema extends Schema {
  @type(["object"])
  auraComponents: any[];

  @type("Entity")
  owner: number;
}

export class AuraApplicatorSystem implements System {
  type = "AuraApplicator";
  schema = AuraApplicatorSchema;
  category: ComponentCategory = ComponentCategory.BEHAVIOR;
  run(entity: number, gameModel: GameModel) {
    const auraApplicator = gameModel.getComponent(entity, this.type) as AuraApplicatorSchema;
    let auraContainer: AuraContainerSchema;
    const auraComponents = [...auraApplicator.auraComponents];
    if (gameModel.hasComponent(entity, AuraContainerSchema)) {
      auraContainer = gameModel.getComponent(entity, AuraContainerSchema) as AuraContainerSchema;
      const auras = auraContainer.auras;
      for (let i = 0; i < auras.length; ++i) {
        const aura = auras[i];
        for (let j = 0; j < auraComponents.length; ++j) {
          const auraComponent = auraComponents[j];
          if (gameModel.hasComponent(aura, auraComponent.type)) {
            gameModel.removeComponent(aura, auraComponent.type);
          }
          const ids = gameModel.getComponentIdsByCategory(aura, ComponentCategory.AURA);
          if (ids.length === 0) {
            gameModel.removeEntity(aura);
            auras.splice(i, 1);
            auraContainer.applicationTime.splice(i, 1);
            i--;
          }
        }
      }
    } else {
      gameModel.addComponent(entity, AuraContainerSchema);
      auraContainer = gameModel.getTypedUnsafe(entity, AuraContainerSchema);
    }
    const nextAura = gameModel.addEntity();
    gameModel.addComponent(nextAura, AuraTypeSchema, { spawnTime: gameModel.timeElapsed });
    gameModel.addComponent(nextAura, TransformSchema);
    gameModel.addComponent(nextAura, ChildSchema, {
      parent: entity,
    });
    gameModel.addComponent(nextAura, OwnerSchema, {
      owner: auraApplicator.owner,
    });
    auraComponents.forEach((component) => {
      gameModel.addComponent(nextAura, component.type, component);
    });
    auraContainer.auras.push(nextAura);
    auraContainer.applicationTime.push(gameModel.timeElapsed);
    gameModel.removeComponent(entity, this.type);
  }
  cleanup?: ((entity: number, gameModel: GameModel, ejecting: boolean) => void) | undefined;
}

registerSystem(AuraApplicatorSystem);

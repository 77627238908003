import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { ComponentCategory, ComponentDataSchema } from "yage/components/types";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import DescriptionSchema from "yage/schemas/core/Description";
import { ChildSchema } from "yage/schemas/entity/Child";
import { ParentSchema } from "yage/schemas/entity/Parent";
import { ShareOnAddToParentSchema } from "./ShareOnAddToParent";
import { ShareOnRemoveFromParentSchema } from "./ShareOnRemoveFromParent";

@Component("EntityQuantitySwapper")
class EntityQuantitySwapperSchema extends Schema {
  @type("string")
  @defaultValue("")
  entityDescription: string;

  @type("number")
  @defaultValue(0)
  componentCount: number;

  @type([ComponentDataSchema])
  @defaultValue([])
  components: ComponentDataSchema[];

  @type("number")
  @defaultValue(0)
  currentCount: number;

  @type("number")
  @defaultValue(-1)
  child: number;
}

@Component("EntityQuantitySwapperOnRemove")
class EntityQuantitySwapperOnRemoveSchema extends Schema {}

class EntityQuantitySwapperSystem implements System {
  schema = EntityQuantitySwapperSchema;
  type = "EntityQuantitySwapper";
  dependencies = ["Child"];
  category = ComponentCategory.ON_ADD_TO_PARENT;

  swap(entity: number, nextCount: number, data: EntityQuantitySwapperSchema, gameModel: GameModel) {
    if (nextCount > data.components.length) {
      return;
    }

    let start = (data.currentCount - 1) * (data.componentCount ?? 1);

    for (let i = 0; i < (data.componentCount ?? 1); i++) {
      const previousComponent = data.components[start + i];

      if (previousComponent) {
        gameModel.removeComponent(entity, previousComponent.type);
      }
    }

    start = (nextCount - 1) * (data.componentCount ?? 1);

    for (let i = 0; i < (data.componentCount ?? 1); i++) {
      const nextComponent = data.components[start + i];

      if (nextComponent) {
        gameModel.addComponent(entity, nextComponent.type, nextComponent.data);
      }
    }

    data.currentCount = nextCount;
  }

  init(entity: number, gameModel: GameModel) {
    if (gameModel.hasComponent(entity, ChildSchema)) {
      const data = gameModel.getTypedUnsafe(entity, EntityQuantitySwapperSchema);
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema)?.parent!;
      if (parent === undefined) {
        return;
      }

      gameModel.addIfMissing(entity, ShareOnAddToParentSchema);
      gameModel.addIfMissing(entity, ShareOnRemoveFromParentSchema);

      const children = gameModel.getTypedUnsafe(parent, ParentSchema).children;
      const existingChildCount = children.filter((child) => {
        const description = gameModel.getTyped(child, DescriptionSchema)?.description;
        return description === data.entityDescription;
      }).length;

      this.swap(entity, existingChildCount, data, gameModel);
    }
  }

  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, EntityQuantitySwapperSchema);
    if (gameModel.getTyped(data.child, DescriptionSchema)?.description === data.entityDescription) {
      this.swap(entity, data.currentCount + 1, data, gameModel);
    }
  }
}

registerSystem(EntityQuantitySwapperSystem);

class EntityQuantitySwapperOnRemoveSystem implements System {
  schema = EntityQuantitySwapperOnRemoveSchema;
  type = "EntityQuantitySwapperOnRemove";
  dependencies = ["Child"];
  category = ComponentCategory.ON_REMOVE_FROM_PARENT;

  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, EntityQuantitySwapperSchema);
    if (gameModel.getTyped(data.child, DescriptionSchema)?.description === data.entityDescription) {
      gameModel.getSystem(EntityQuantitySwapperSystem).swap(entity, data.currentCount - 1, data, gameModel);
    }
  }
}

registerSystem(EntityQuantitySwapperOnRemoveSystem);

import { System } from "yage/components/System";
import { Component, Schema, type } from "yage/decorators/type";
import { DamageStatsSchema } from "../weapons/DamageStats";
import { GameModel } from "yage/game/GameModel";
import { LocomotionSchema } from "yage/schemas/entity/Locomotion";
import { OwnerSchema } from "yage/schemas/core/Owner";
import { ChildSchema } from "yage/schemas/entity/Child";
import { SpeedEnhancerSchema } from "../enhancers/SpeedEnhancer";
import { registerSchema, registerSystem } from "yage/components/ComponentRegistry";
import { ComponentCategory } from "yage/constants/enums";

@Component("PercentSpeedDamage")
export class PercentSpeedDamageSchema extends Schema {
  @type("number")
  percentSpeedDamage: number;

  @type(DamageStatsSchema)
  damageStats: DamageStatsSchema;
}

@Component("InvalidateDamageStatsOnSpeedChange")
export class InvalidateDamageStatsOnSpeedChangeSchema extends Schema {}

registerSchema(InvalidateDamageStatsOnSpeedChangeSchema);

class PercentSpeedDamageSystem implements System {
  schema = PercentSpeedDamageSchema;
  type = "PercentSpeedDamage";
  category = ComponentCategory.DAMAGEMOD;
  dependencies = ["Child"];

  init(entity: number, gameModel: GameModel) {
    if (gameModel.hasComponent(entity, ChildSchema)) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      gameModel.addComponent(parent, InvalidateDamageStatsOnSpeedChangeSchema);
    } else {
      gameModel.addComponent(entity, InvalidateDamageStatsOnSpeedChangeSchema);
    }
  }

  run(entity: number, gameModel: GameModel) {
    const percentSpeedDamage = gameModel.getTypedUnsafe(entity, PercentSpeedDamageSchema);
    const damageStats = percentSpeedDamage.damageStats;
    const owner = gameModel.getTyped(entity, OwnerSchema)?.owner ?? gameModel.getTyped(entity, ChildSchema)?.parent;
    if (!owner) return;

    LocomotionSchema.id = owner;
    let speed = LocomotionSchema.speed ?? 0;
    if (gameModel.hasComponent(owner, SpeedEnhancerSchema)) {
      speed *= 1 + gameModel.getTypedUnsafe(owner, SpeedEnhancerSchema).speedScale;
    }

    damageStats.minDamage += speed * (percentSpeedDamage.percentSpeedDamage / 100);
    damageStats.maxDamage += speed * (percentSpeedDamage.percentSpeedDamage / 100);
  }
}

registerSystem(PercentSpeedDamageSystem);

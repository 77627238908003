import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { PlayerTypeSchema, EnemyTypeSchema } from "yage/components/entity/Types";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";
import { getPlayerCharacter } from "../../utils/playerCharacter";

@Component("ShareOnHeal")
export class ShareOnHealSchema extends Schema {
  @type(["number"])
  @defaultValue([])
  entities: number[];

  @type("number")
  @defaultValue(-1)
  owner: number;
}

class ShareOnHealSystem implements System {
  schema = ShareOnHealSchema;
  type = "ShareOnHeal";
  dependencies = ["Child"];

  init(entity: number, gameModel: GameModel) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      let parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (gameModel.hasComponent(parent, "PlayerInput")) {
        parent = getPlayerCharacter(parent, gameModel);
      }
      gameModel.addIfMissing(parent, ShareOnHealSchema);
      const parentData = gameModel.getTypedUnsafe(parent, ShareOnHealSchema);
      parentData.entities.push(entity);
    }
  }
}

registerSystem(ShareOnHealSystem);

import { ComponentData } from "yage/components/types";
import { InventoryItemSchema } from "../components/player/Inventory";

export enum PlayerReadyState {
  NotReady,
  Ready,
  PlayerInitialized,
}

export type PlayerState = {
  playerSystemId?: string;
  readyState?: PlayerReadyState;
  exp: number;
  level: number;
  inventory: {
    [key: string]: InventoryItemSchema;
  };
  persistedComponents: ComponentData[];
  weapons: string[];
  weaponMods: ComponentData[][];
  lockedItems: ({ id: string; price: number; roundPrice: number } | undefined)[];
  wave: number;
  harvesting: number;
  unlocks: string[];
};

export type GlobalState = {
  players: {
    [key: string]: PlayerState;
  };
};

export const skipMenuState: Partial<PlayerState> = {
  wave: 0,
  level: 10,

  persistedComponents: [],
  inventory: {
    coins: {
      amount: 3090,
    },
    ["Stats::PlusOneMaxHpOnLevelUp"]: {
      amount: 1,
    },
    ["shopitem::wellrounded"]: {
      // ["shopitem::testcharacter"]: {
      amount: 1,
    },

    // ["shopitem::specialbait"]: {
    //   amount: 1,
    // },

    // ["shopitem::bait_consumed"]: {
    //   amount: 1,
    // },
    // ["shopitem::pencil"]: {
    //   amount: 5,
    // },

    // ["levelUps"]: {
    //   amount: 1,
    // },
    // ["crates"]: {
    //   amount: 1,
    // },
    // ["shopitem::snake"]: {
    //   amount: 1,
    // },
    // ["shopitem::coupon"]: {
    //   amount: 10,
    // },
  },
  // weapons: ["Shuriken2", "Shuriken2", "Shuriken3", "Shuriken4"],
  // weapons: [
  //   // "Screwdriver",
  //   // "Screwdriver2",
  //   // "Screwdriver3",
  //   // "Screwdriver3",
  //   // "Screwdriver4",
  //   // "Screwdriver4",
  //   // "Knife",
  //   // "Knife",
  //   "DoubleBarrelShotgun",
  //   "DoubleBarrelShotgun2",
  //   "DoubleBarrelShotgun3",
  //   "DoubleBarrelShotgun4",
  //   // "DoubleBarrelShotgun4",
  //   // "DoubleBarrelShotgun4",
  // ],
  // weapons: ["ThiefDagger", "ThiefDagger2", "ThiefDagger3", "ThiefDagger4"],
  // weapons: ["LightningShiv", "LightningShiv2", "LightningShiv3", "LightningShiv4"],
  // weapons: ["Wrench", "Wrench", "Wrench", "Wrench", "Wrench", "Wrench"],
  // weapons: ["Sword2", "Sword2", "Sword3", "Sword4"],
  // weapons: ["GhostScepter", "GhostScepter2", "GhostScepter3", "GhostScepter4"],
  // weapons: ["FightingStick", "FightingStick2", "FightingStick3", "FightingStick4"],
  // weapons: ["Stick2", "Stick2", "Stick", "Stick"],
  // weapons: ["Revolver", "Revolver2", "Revolver3", "Revolver4"],
  // weapons: ["Taser", "Taser2", "Taser3", "Taser4"],
  // weapons: ["GhostAxe", "GhostAxe2", "GhostAxe3", "GhostAxe4"],
  // weapons: ["Crossbow", "Crossbow2", "Crossbow3", "Crossbow4"],
  // weapons: ["Torch", "Torch2", "Torch3", "Torch4"],
  // weapons: ["GhostFlint"],
  // weapons: ["Plank", "Plank2", "Plank3", "Plank4"],
  // weapons: ["Shredder", "Shredder2", "Shredder3", "Shredder4"],
  // weapons: ["Smg", "Smg2", "Smg3", "Smg4"],
  // weapons: ["GhostAxe"],
  // weapons: ["Fist", "Fist2", "Fist3", "Fist4"],
  // weapons: ["Stick", "Stick", "Stick", "Stick", "Stick"],
  // weapons: ["Hand", "Hand2", "Hand3", "Hand4"],
  // weapons: ["Rock", "Rock2", "Rock3", "Rock4"],
  // weapons: ["Pistol", "Knife"],
  // weapons: ["Scissors", "Scissors2", "Scissors3", "Scissors4"],
  weapons: ["MedicalGun", "MedicalGun2", "MedicalGun3", "MedicalGun4"],
  // weapons: ["JoustingLance", "JoustingLance", "JoustingLance", "JoustingLance"],
  // weapons: [],
  // weapons: ["LaserGun2", "LaserGun3", "LaserGun4", "LaserGun2"],
};

export const defaultPlayerState: PlayerState = {
  unlocks: [],
  wave: 0,
  readyState: PlayerReadyState.NotReady,
  persistedComponents: [],
  exp: 0,
  level: 1,
  inventory: {
    coins: {
      amount: 30,
    },
    ["Stat::PlusOneMaxHpOnLevelUp"]: {
      amount: 1,
    },
  },
  weaponMods: [],
  weapons: [],
  lockedItems: [],
  harvesting: 0,
};

export const DoctorWin: Partial<PlayerState> = {
  wave: 19,
  readyState: 0,
  persistedComponents: [],
  exp: 380,
  level: 25,
  inventory: {
    coins: {
      amount: 437,
    },
    "shopitem::doctor": {
      amount: 1,
    },
    "Stat::PlusHp": {
      amount: 12,
    },
    levelUps: {
      amount: 0,
    },
    "shopitem::shoulders": {
      amount: 1,
    },
    "shopitem::shoulders2": {
      amount: 2,
    },
    "shopitem::boxingglove": {
      amount: 3,
    },
    "shopitem::alienworm": {
      amount: 1,
    },
    "shopitem::sharpbullet": {
      amount: 1,
    },
    "shopitem::headinjury": {
      amount: 1,
    },
    "shopitem::eyes2": {
      amount: 1,
    },
    "shopitem::lemonade": {
      amount: 3,
    },
    "shopitem::brokenmouth": {
      amount: 1,
    },
    "shopitem::shadypotion": {
      amount: 2,
    },
    "shopitem::beanie": {
      amount: 1,
    },
    "shopitem::nose3": {
      amount: 2,
    },
    "shopitem::blooddonation": {
      amount: 1,
    },
    "shopitem::uglytooth": {
      amount: 1,
    },
    "shopitem::shoulders3": {
      amount: 3,
    },
    "shopitem::alientongue": {
      amount: 3,
    },
    "shopitem::medal": {
      amount: 2,
    },
    "shopitem::tardigrade": {
      amount: 1,
    },
    "shopitem::littlefrog": {
      amount: 1,
    },
    "shopitem::smallmagazine": {
      amount: 2,
    },
    "shopitem::glasses": {
      amount: 1,
    },
    "shopitem::lens": {
      amount: 2,
    },
    "shopitem::mutation": {
      amount: 1,
    },
    "shopitem::pumpkin": {
      amount: 2,
    },
    "shopitem::banner": {
      amount: 1,
    },
    "shopitem::cutemonkey": {
      amount: 2,
    },
    "shopitem::spicysauce": {
      amount: 1,
    },
    "shopitem::cake": {
      amount: 1,
    },
    "shopitem::acid": {
      amount: 2,
    },
    "shopitem::alloy": {
      amount: 1,
    },
    "shopitem::gummyberserker": {
      amount: 2,
    },
    "shopitem::wings": {
      amount: 1,
    },
    "shopitem::fertilizer": {
      amount: 1,
    },
    "shopitem::ritual": {
      amount: 1,
    },
    "shopitem::vigilantering": {
      amount: 1,
    },
    "Stat::plusthreedamage": {
      amount: 9,
    },
    "shopitem::scaredsausage": {
      amount: 1,
    },
    "shopitem::teeth3": {
      amount: 2,
    },
    "shopitem::bloodleech": {
      amount: 2,
    },
    "shopitem::boilingwater": {
      amount: 1,
    },
    "shopitem::tree": {
      amount: 2,
    },
    "shopitem::shmoop": {
      amount: 2,
    },
    "shopitem::coffee": {
      amount: 1,
    },
    "shopitem::statue": {
      amount: 1,
    },
    "shopitem::whiteflag": {
      amount: 1,
    },
    "shopitem::padding": {
      amount: 2,
    },
    "shopitem::pocketfactory": {
      amount: 1,
    },
    "shopitem::tentacle": {
      amount: 1,
    },
    "shopitem::weirdfood": {
      amount: 1,
    },
    "shopitem::wisdom": {
      amount: 1,
    },
    "shopitem::whetstone": {
      amount: 1,
    },
    "shopitem::cog": {
      amount: 1,
    },
    "shopitem::injection": {
      amount: 1,
    },
    "shopitem::warriorhelmet": {
      amount: 1,
    },
    "shopitem::bat": {
      amount: 1,
    },
    "shopitem::metalplate": {
      amount: 1,
    },
    "shopitem::stoneskin": {
      amount: 1,
    },
    "shopitem::babywithabeard": {
      amount: 1,
    },
    "shopitem::insanity": {
      amount: 1,
    },
    "shopitem::reflexes4": {
      amount: 1,
    },
  },
  weaponMods: [
    [
      {
        type: "TrackDamage",
        data: {
          totalDamage: 48605.82400000014,
        },
      },
    ],
    [
      {
        type: "TrackDamage",
        data: {
          totalDamage: 47884.5080000002,
        },
      },
    ],
    [
      {
        type: "TrackDamage",
        data: {
          totalDamage: 41490.37799999991,
        },
      },
    ],
    [
      {
        type: "TrackDamage",
        data: {
          totalDamage: 46549.80200000019,
        },
      },
    ],
    [
      {
        type: "TrackDamage",
        data: {
          totalDamage: 34244.13600000001,
        },
      },
    ],
    [
      {
        type: "TrackDamage",
        data: {
          totalDamage: 46290.90200000011,
        },
      },
    ],
  ],
  weapons: ["MedicalGun4", "MedicalGun4", "MedicalGun4", "medicalgun4", "MedicalGun4", "medicalgun4"],
  lockedItems: [],
  harvesting: 56.22435716734726,
};

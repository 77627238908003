import { EntityTypeSchema } from "yage/components/entity/Types";
import { registerSystem } from "yage/components/ComponentRegistry";
import type { ComponentData } from "yage/components/types";
import { ComponentCategory, ComponentDataSchema } from "yage/components/types";
import { DamageDirectionEnum } from "yage/constants/enums";
import { Component, defaultValue, type, Schema } from "yage/decorators/type";
import type { GameModel } from "yage/game/GameModel";
import type { System } from "yage/components/System";
import { EntityFactory } from "yage/entity/EntityFactory";
import { BV2, Vector2dSchema } from "yage/utils/vector";
import { getWeaponAugmentComponents, applyComponentsToProjectile } from "../../utils/weapons";
import { EnemyCollidersSchema } from "../enemy/EnemyColliders";
import { OwnerSchema } from "yage/schemas/core/Owner";
import { ChildSchema } from "yage/schemas/entity/Child";
import { LocomotionSchema } from "yage/schemas/entity/Locomotion";
import { TransformSchema } from "yage/schemas/entity/Transform";

@Component("SpawnProjectileOnHit")
export class SpawnProjectileOnHitSchema extends Schema {
  @type("number")
  @defaultValue(1)
  projectileCount: number;

  @type("number")
  @defaultValue(1)
  spawnChance: number;

  @type("string")
  @defaultValue("Bullet")
  entityDescription: string;

  @type([ComponentDataSchema])
  @defaultValue([])
  overrideComponents: ComponentData[];

  @type(Vector2dSchema)
  @defaultValue({ x: 0, y: 0 })
  hitPosition: Vector2dSchema;
}

class SpawnProjectileOnHitSystem implements System {
  type = "SpawnProjectileOnHit";
  category: ComponentCategory = ComponentCategory.ONHIT;
  schema = SpawnProjectileOnHitSchema;
  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, SpawnProjectileOnHitSchema);

    if (gameModel.rand.number() > data.spawnChance) {
      return;
    }

    const owner = gameModel.getTypedUnsafe(entity, OwnerSchema).owner!;
    const parent = gameModel.getTyped(entity, ChildSchema)?.parent ?? entity;

    const angles = [];
    for (let i = 0; i < data.projectileCount; i++) {
      angles.push(gameModel.rand.int(0, 359)); //((360 / data.projectileCount) * i);
    }

    const position = data.hitPosition;
    const colliders = [...gameModel.getTypedUnsafe(owner, EnemyCollidersSchema).colliders];

    angles.forEach((angle) => {
      const overrides: any = {
        Transform: position,
        EnemyColliders: {
          colliders,
        },
        Owner: {
          owner,
        },
        Child: {
          parent,
        },
      };
      const projectile = EntityFactory.getInstance().generateEntity(gameModel, data.entityDescription, overrides);

      TransformSchema.id = projectile;
      TransformSchema.position = position;

      LocomotionSchema.id = projectile;
      const rotatedVelocity = BV2.rotateDegVector2d(0, LocomotionSchema.speed, angle);
      LocomotionSchema.velocityX = rotatedVelocity[0];
      LocomotionSchema.velocityY = rotatedVelocity[1];

      const [augmentComponents, auraComponents] = getWeaponAugmentComponents(projectile, owner, gameModel);
      // damageStats.minDamage += data.damage;
      applyComponentsToProjectile(
        projectile,
        DamageDirectionEnum.PROJECTILE,
        augmentComponents,
        auraComponents,
        gameModel
      );

      if (data.overrideComponents.length > 0) {
        for (let i = 0; i < data.overrideComponents.length; i++) {
          const override = data.overrideComponents[i];
          gameModel.addComponent(projectile, override.type, override.data ?? override);
        }
      }
    });
  }
}

registerSystem(SpawnProjectileOnHitSystem);

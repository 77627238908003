import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { Component, Schema, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";
import { EnemyTypeSchema, PlayerTypeSchema } from "yage/components/entity/Types";
import { HpRegenFlatSchema } from "../player/HpRegenFlat";

@Component("HpRegenFlatEnhancer")
export class HpRegenFlatEnhancerSchema extends Schema {
  @type("number")
  regen: number;
}

class HpRegenFlatEnhancerSystem implements System {
  schema = HpRegenFlatEnhancerSchema;
  type = "HpRegenFlatEnhancer";
  dependencies = ["Child", "HpRegenScaler"];
  init(entity: number, gameModel: GameModel) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (!gameModel.hasComponent(parent, "HpRegenFlat")) {
        gameModel.addComponent(parent, HpRegenFlatSchema);
      }
      gameModel.getTypedUnsafe(parent, HpRegenFlatSchema).regen += gameModel.getTypedUnsafe(
        entity,
        HpRegenFlatEnhancerSchema
      ).regen;
    }
  }

  cleanup(entity: number, gameModel: GameModel, ejecting: boolean) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (gameModel.hasComponent(parent, "HpRegenFlat")) {
        gameModel.getTypedUnsafe(parent, HpRegenFlatSchema).regen -= gameModel.getTypedUnsafe(
          entity,
          HpRegenFlatEnhancerSchema
        ).regen;
      }
    }
  }
}
registerSystem(HpRegenFlatEnhancerSystem);

import { registerSchema } from "yage/components/ComponentRegistry";
import { ComponentCategory } from "yage/constants/enums";
import { Component, Schema, type, defaultValue } from "yage/decorators/type";

@Component("AdditionalProjectiles")
export class AdditionalProjectilesSchema extends Schema {
  @type("number")
  @defaultValue(2)
  projectiles: number;
}

registerSchema(ComponentCategory.PROJECTILECOUNTMOD, AdditionalProjectilesSchema);

import type { SceneTimestep } from "yage/game/Scene";
import { Scene } from "yage/game/Scene";
import type { MouseManager } from "yage/inputs/MouseManager";
import { UIService } from "yage/ui/UIService";
import "../components";
import { Text } from "yage/ui/Text";
import type { UIElement } from "yage/ui/UIElement";
import { Rectangle } from "yage/ui/Rectangle";
import { ConnectionInstance } from "yage/connection/ConnectionInstance";
import { Button } from "yage/ui/Button";
import { GameCoordinator } from "yage/game/GameCoordinator";
import { GameInstance } from "yage/game/GameInstance";

export class ProjectWinScene extends Scene {
  static sceneName = "ProjectVWin";

  timestep: SceneTimestep = "continuous";
  dt = 4;

  paused = false;
  mouseManager: MouseManager;
  gameCanvasContext: CanvasRenderingContext2D;

  public initialize = async (args: any[]): Promise<void> => {
    UIService.configureUi(document.getElementById("uicanvas") as HTMLCanvasElement);

    const [instance, player] = args as [GameInstance<any>, string];
    const connection = instance.options.connection;

    if (connection.player.uniqueId === player) {
      connection.updatePlayerConnect({
        token: parseInt(connection.player.token || "0") + 1 + "",
        config: {
          ...connection.player.config,
          ready: false,
        },
      });
    } else {
      connection.updatePlayerConnect({
        config: {
          ...connection.player.config,
          ready: false,
        },
      });
    }

    this.ui.frameTime = new Text(new Rectangle(250, 255, 1, 1), {
      label: player + " wins!",
      fontSize: 32,
      style: {
        textTransform: "uppercase",
      },
    });

    this.ui.againCta = new Button(new Rectangle(250, 300, 400, 100), {
      label: "Play Again",
      fontSize: 32,
      style: {
        backgroundColor: "green",
        textTransform: "uppercase",
      },
      onClick: () => {
        connection.updatePlayerConnect({
          config: {
            ...connection.player.config,
            ready: true,
          },
        });
        this.changeScene("ProjectVLobby", instance);
      },
    });
  };

  run = () => {};

  public destroy = (): void => {
    super.destroy();
    console.log("MinMediator: destroy");
  };
}

import { LandmineDropperSchema } from "../ally/LandmineDropper";

import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { Component, Schema } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";
import { EnemyTypeSchema, PlayerTypeSchema } from "yage/components/entity/Types";

@Component("LandmineDropperEnhancer")
class LandmineDropperEnhancerSchema extends LandmineDropperSchema {}

class LandmineDropperSystem implements System {
  schema = LandmineDropperEnhancerSchema;
  type = "LandmineDropperEnhancer";
  dependencies = ["Child"];
  init(entity: number, gameModel: GameModel) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (!gameModel.hasComponent(parent, "LandmineDropper")) {
        gameModel.addComponent(parent, LandmineDropperSchema);
      }
      const data = gameModel.getTypedUnsafe(entity, LandmineDropperEnhancerSchema);
      const dropper = gameModel.getTypedUnsafe(parent, LandmineDropperSchema);
      dropper.spawnRate = dropper.spawnRate ? Math.min(dropper.spawnRate, data.spawnRate) : data.spawnRate;
      dropper.mines += data.mines;
    }
  }

  cleanup(entity: number, gameModel: GameModel, ejecting: boolean) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (gameModel.hasComponent(parent, "LandmineDropper")) {
        const data = gameModel.getTypedUnsafe(entity, LandmineDropperEnhancerSchema);
        const dropper = gameModel.getTypedUnsafe(parent, LandmineDropperSchema);
        dropper.spawnRate = dropper.spawnRate ? Math.max(dropper.spawnRate, data.spawnRate) : data.spawnRate;
        dropper.mines -= data.mines;
      }
    }
  }
}
registerSystem(LandmineDropperSystem);

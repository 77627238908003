import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { ComponentDataSchema } from "yage/components/types";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { OwnerSchema } from "yage/schemas/core/Owner";
import { ChildSchema } from "yage/schemas/entity/Child";
import { LocomotionSchema } from "yage/schemas/entity/Locomotion";

@Component("ComponentsWhileMoving")
export class ComponentsWhileMovingSchema extends Schema {
  @type([ComponentDataSchema])
  @defaultValue([])
  movingComponents: ComponentDataSchema[];

  @type([ComponentDataSchema])
  @defaultValue([])
  idleComponents: ComponentDataSchema[];

  @type("boolean")
  moving: boolean;

  @type("boolean")
  @defaultValue(true)
  ownerMoving: boolean;
}

export class ComponentsWhileMovingSystem implements System {
  schema = ComponentsWhileMovingSchema;
  type = "ComponentsWhileMoving";
  depth = DEPTHS.LOCOMOTION + 10;
  run(entity: number, gameModel: GameModel) {
    const componentsWhileMoving = gameModel.getTypedUnsafe(entity, ComponentsWhileMovingSchema);
    let moving = false;
    if (componentsWhileMoving.ownerMoving) {
      const owner = gameModel.getTyped(entity, OwnerSchema)?.owner ?? gameModel.getTyped(entity, ChildSchema)?.parent;
      if (owner) {
        LocomotionSchema.id = owner;
        moving = !!(LocomotionSchema.velocityX || LocomotionSchema.velocityY);
      }
    } else {
      LocomotionSchema.id = entity;
      moving = !!(LocomotionSchema.velocityX || LocomotionSchema.velocityY);
    }
    if (moving !== componentsWhileMoving.moving) {
      componentsWhileMoving.moving = moving;
      if (moving) {
        for (const component of componentsWhileMoving.idleComponents) {
          if (gameModel.hasComponent(entity, component.type)) {
            gameModel.removeComponent(entity, component.type);
          }
        }
        for (const component of componentsWhileMoving.movingComponents) {
          gameModel.addComponent(entity, component.type, component.data);
        }
      } else {
        for (const component of componentsWhileMoving.movingComponents) {
          if (gameModel.hasComponent(entity, component.type)) {
            gameModel.removeComponent(entity, component.type);
          }
        }
        for (const component of componentsWhileMoving.idleComponents) {
          gameModel.addComponent(entity, component.type, component.data);
        }
      }
    }
  }
  cleanup(entity: number, gameModel: GameModel, ejecting: boolean) {
    if (!ejecting) {
      const componentsWhileMoving = gameModel.getTypedUnsafe(entity, ComponentsWhileMovingSchema);
      if (componentsWhileMoving.moving) {
        for (const component of componentsWhileMoving.movingComponents) {
          gameModel.removeComponent(entity, component.type);
        }
      } else {
        for (const component of componentsWhileMoving.idleComponents) {
          gameModel.removeComponent(entity, component.type);
        }
      }
    }
  }
}

registerSystem(ComponentsWhileMovingSystem);

import { registerSchema } from "yage/components/ComponentRegistry";
import { Component, Schema, defaultValue, required, type } from "yage/decorators/type";

@Component("Html")
export class HtmlSchema extends Schema {
  @type("string")
  @required()
  tag: string;

  @type("string")
  style?: string | Partial<CSSStyleDeclaration>;

  @type(["object"])
  @defaultValue([])
  children?: HtmlSchema[];

  @type("string")
  @defaultValue("")
  innerText?: string;
}

@Component("Shop")
export class ShopSchema extends Schema {
  @type(["string"])
  tags: string[];

  @type("string")
  title: string;

  @type("number")
  basePrice: number;

  @type("string")
  tagline: string;

  @type("string")
  description: string;

  @type(HtmlSchema)
  content: HtmlSchema;

  @type("number")
  @defaultValue(0)
  zIndex: number;

  @type("number")
  @defaultValue(1000)
  listIndex: number;
}

registerSchema(ShopSchema);

import { System } from "yage/components/System";
import { Component, Schema, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";
import { WeaponContainerSchema } from "./WeaponContainer";
import { ShopSchema } from "../core/Shop";
import { ComponentDataSchema } from "yage/components/types";
import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import { AppliedWeaponTagsSchema } from "./AppliedWeaponTags";
import { OwnerSchema } from "yage/schemas/core/Owner";

@Component("WeaponBonus")
class WeaponBonusSchema extends Schema {
  @type("string")
  tag: string;

  @type([ComponentDataSchema])
  components: ComponentDataSchema[];
}

class WeaponBonusSystem implements System {
  schema = WeaponBonusSchema;
  type = "WeaponBonus";
  depth = DEPTHS.ITEMS;
  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, WeaponBonusSchema);
    const tag = data.tag;
    const parent =
      gameModel.getTyped(entity, OwnerSchema)?.owner ?? gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
    let player = parent;
    if (gameModel.hasComponent(parent, OwnerSchema)) {
      player = gameModel.getTypedUnsafe(parent, OwnerSchema).owner!;
    }
    const weapons = gameModel.getTypedUnsafe(player, WeaponContainerSchema).weaponIds;

    if (!gameModel.hasComponent(player, AppliedWeaponTagsSchema)) {
      gameModel.addComponent(player, AppliedWeaponTagsSchema, {
        tags: [],
      });
    }
    const appliedTags = gameModel.getTypedUnsafe(parent, AppliedWeaponTagsSchema)?.tags ?? [];
    if (appliedTags.includes(tag)) {
      gameModel.removeComponent(entity, WeaponBonusSchema);
      return;
    }
    appliedTags.push(tag);

    let count = 0;
    let tagName = "Tag::" + tag;
    for (let i = 0; i < weapons.length; i++) {
      if (gameModel.getTypedUnsafe(weapons[i], ShopSchema).tags.some((t) => t === tagName)) {
        count++;
      }
    }
    if (count > 1) {
      count--;
      count = Math.min(count - 1, 4);
      const offset = Math.floor(data.components.length / 5);
      for (let j = 0; j < offset; j++) {
        gameModel.addComponent(
          entity,
          data.components[count * offset + j].type,
          data.components[count * offset + j].data ?? {}
        );
      }
    }
    gameModel.removeComponent(entity, WeaponBonusSchema);
  }
}

registerSystem(WeaponBonusSystem);

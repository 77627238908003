import { registerSchema } from "yage/components/ComponentRegistry";
import type { ComponentData } from "yage/components/types";
import { ComponentDataSchema, ComponentCategory } from "yage/components/types";
import { DamageCategoryEnum, DamageTypeEnum } from "yage/constants/enums";
import { Component, defaultValue, Schema, required, type } from "yage/decorators/type";
import type { GameModel } from "yage/game/GameModel";
import { LocomotionSchema } from "yage/schemas/entity/Locomotion";
import { TransformSchema } from "yage/schemas/entity/Transform";
import type { Vector2d } from "yage/utils/vector";
import {
  Vector2dSchema,
  distanceSquaredVector2d,
  scaleVector2d,
  lengthVector2d,
  normalizeSafeVector2d,
} from "yage/utils/vector";
import { HealthSchema, getMaxHealth } from "../core/Health";
import { System } from "yage/components/System";
import { Damage, DamageableSchema, DamageSchema } from "../../schema/damage/Damage";
import { DamageApplicationSchema } from "./DamageApplier";
import { ShareOnDamageSchema } from "../core/ShareOnDamage";
import { generateShareList } from "yage/utils/generateShareList";
import { ShareOnApplyDamageSchema } from "../core/ShareOnApplyDamage";

registerSchema(ComponentCategory.BEHAVIOR, DamageableSchema);

export const takeDamage = (
  entityId: number,
  gameModel: GameModel,
  damageDatas: Partial<
    DamageApplicationSchema & {
      // damage: number;
      direction: Vector2d;
      owner: number;
      source: number;
      frame: number;
    }
  >[]
): [number, number, Damage[]] => {
  if (!gameModel.hasComponent(entityId, "Damageable")) {
    // gameModel.logEntity(entityId);
    // console.error("invalid damage taker");
    return [0, 0, []];
  }
  const data = gameModel.getTypedUnsafe(entityId, DamageableSchema);
  const damagesTaken: Damage[] = [];

  let damageTaken = 0;

  let critChance = 0;
  for (let i = 0; i < damageDatas.length; i++) {
    const damage = damageDatas[i];
    critChance = Math.max(critChance, damage.damageStats?.critChance ?? 0);
  }

  for (let j = 0; j < damageDatas.length; j++) {
    const damageData = damageDatas[j];
    if (data.radius && damageData.owner) {
      TransformSchema.id = damageData.owner;
      const ownerPosition = TransformSchema.position;
      if (ownerPosition) {
        TransformSchema.id = entityId;
        const entityPosition = TransformSchema.position;

        const distance = distanceSquaredVector2d(ownerPosition, entityPosition);
        if (distance > data.radius * data.radius) {
          continue;
        }
      }
    }

    let minDamage = 0;
    let maxDamage = 0;
    const damageStats = damageData.damageStats;
    if (damageStats) {
      switch (damageData.damageType) {
        case DamageTypeEnum.NORMAL:
          minDamage = damageStats.minDamage || 0;
          maxDamage = damageStats.maxDamage || damageStats.minDamage || 0;
          break;
        case DamageTypeEnum.CHAOS:
          minDamage = damageStats.minChaosDamage || 0;
          maxDamage = damageStats.maxChaosDamage || damageStats.minChaosDamage || 0;
          break;
        case DamageTypeEnum.FIRE:
          minDamage = (damageStats.minFireDamage || 0) + (damageStats.minElementalDamage || 0);
          maxDamage =
            damageStats.maxFireDamage || damageStats.minFireDamage || 0 + (damageStats.maxElementalDamage || 0);
          break;
        case DamageTypeEnum.ICE:
          minDamage = (damageStats.minColdDamage || 0) + (damageStats.minElementalDamage || 0);
          maxDamage =
            (damageStats.maxColdDamage || damageStats.minColdDamage || 0) + (damageStats.maxElementalDamage || 0);
          break;
        case DamageTypeEnum.SHOCK:
          minDamage = (damageStats.minLightningDamage || 0) + (damageStats.minElementalDamage || 0);
          maxDamage =
            (damageStats.maxLightningDamage || damageStats.minLightningDamage || 0) +
            (damageStats.maxElementalDamage || 0);
          break;
        case DamageTypeEnum.ALLY:
          minDamage =
            (damageStats.minDamage || 0) + (damageStats.minAllyDamage || 0) * ((damageStats.allyDamageScale ?? 0) + 1);
          maxDamage =
            (damageStats.maxDamage || damageStats.minDamage || 0) +
            (damageStats.maxAllyDamage || damageStats.minAllyDamage || 0) * ((damageStats.allyDamageScale ?? 0) + 1);
          break;
      }

      switch (damageData.damageCategory) {
        case DamageCategoryEnum.MELEE:
          minDamage += (damageStats.minMeleeDamage || 0) * (damageStats.meleeDamageScale ?? 0);
          maxDamage += (damageStats.maxMeleeDamage || 0) * (damageStats.meleeDamageScale ?? 0);
          break;
        case DamageCategoryEnum.RANGED:
          minDamage += (damageStats.minRangedDamage || 0) * (damageStats.rangedDamageScale ?? 0);
          maxDamage += (damageStats.maxRangedDamage || 0) * (damageStats.rangedDamageScale ?? 0);
          break;
        case DamageCategoryEnum.AOE:
          minDamage += (damageStats.minAoeDamage || 0) * (damageStats.aoeDamageScale ?? 0);
          maxDamage += (damageStats.maxAoeDamage || 0) * (damageStats.aoeDamageScale ?? 0);
          break;
        case DamageCategoryEnum.ELEMENTAL:
          minDamage += (damageStats.minElementalDamage || 0) * (damageStats.elementalDamageScale ?? 0);
          maxDamage += (damageStats.maxElementalDamage || 0) * (damageStats.elementalDamageScale ?? 0);
          break;
        case DamageCategoryEnum.ALL:
          minDamage += (damageStats.minMeleeDamage || 0) * (damageStats.meleeDamageScale ?? 0);
          maxDamage += (damageStats.maxMeleeDamage || 0) * (damageStats.meleeDamageScale ?? 0);
          minDamage += (damageStats.minRangedDamage || 0) * (damageStats.rangedDamageScale ?? 0);
          maxDamage += (damageStats.maxRangedDamage || 0) * (damageStats.rangedDamageScale ?? 0);
          minDamage += (damageStats.minAoeDamage || 0) * (damageStats.aoeDamageScale ?? 0);
          maxDamage += (damageStats.maxAoeDamage || 0) * (damageStats.aoeDamageScale ?? 0);
          minDamage += (damageStats.minElementalDamage || 0) * (damageStats.elementalDamageScale ?? 0);
          maxDamage += (damageStats.maxElementalDamage || 0) * (damageStats.elementalDamageScale ?? 0);
          break;
      }
    }
    const crit = gameModel.rand.number() < critChance / 100 ? damageData.damageStats?.critScale ?? 2 : 1;

    let damage =
      minDamage && maxDamage ? (minDamage === maxDamage ? minDamage : gameModel.rand.int(minDamage, maxDamage)) : 0;
    damage *= ((damageData.damageStats?.damageScale ?? 0) || 1) * crit;
    let damageMods = 1;

    if (gameModel.isActive(damageData.owner ?? -1)) {
      const shareList = generateShareList(
        damageData.owner!,
        ShareOnApplyDamageSchema,
        ComponentCategory.ON_APPLY_DAMAGE,
        gameModel
      );

      for (let i = 0; i < shareList.length; i++) {
        const [component, entities] = shareList[i];
        for (let j = 0; j < entities.length; j++) {
          const entity = entities[j];

          const mod = gameModel.getComponent(entity, component) as any;
          if (mod.taker !== undefined) {
            mod.taker = entityId;
          }
          if (mod.maker !== undefined) {
            mod.maker = damageData.owner;
          }
          if (mod.damage !== undefined) {
            mod.damage = damage;
          }

          if (mod.crit !== undefined) {
            mod.crit = crit > 1;
          }
          const system: System = gameModel.getSystem(component);
          system.run?.(entity, gameModel);

          damage = mod.damage ?? damage;

          damageMods *= mod.damageScale ?? 1;
        }
      }
    }
    const shareList = generateShareList(entityId, ShareOnDamageSchema, ComponentCategory.ONDAMAGE, gameModel);
    for (let i = 0; i < shareList.length; i++) {
      const [component, entities] = shareList[i];
      for (let j = 0; j < entities.length; j++) {
        const entity = entities[j];

        const mod = gameModel.getComponentUnsafe(entity, component);
        if (mod.maker !== undefined) {
          mod.maker = damageData.owner;
        }
        if (mod.taker !== undefined) {
          mod.taker = entityId;
        }
        if (mod.currentDamageScale !== undefined) {
          mod.currentDamageScale = damageMods;
        }
        if (mod.damage !== undefined) {
          mod.damage = damage;
        }
        if (mod.crit !== undefined) {
          mod.crit = crit > 1;
        }
        const system: System = gameModel.getSystem(component);
        system.run?.(entity, gameModel);

        damage = mod.damage ?? damage;

        damageMods *= mod.damageScale ?? 1;

        if (damageMods === 0) {
          i = shareList.length;
          break;
        }
      }
    }
    damage *= damageMods;
    const healthData = gameModel.getTypedUnsafe(entityId, HealthSchema);

    if (isNaN(damage)) {
      console.error(`Damage amount is NaN for entity ${entityId}`);
      console.error(damageData);
    }

    if (damage < 0) {
      const maxHealth = getMaxHealth(entityId, gameModel);
      if (maxHealth < healthData.health - damage) {
        damage = healthData.health - maxHealth;
      }
    }

    if (damage === 0) {
      continue;
    }

    data.currentDamage = damageData as DamageSchema;
    data.currentDamage.critHit = crit > 1;
    data.currentDamage.damage = damage;

    healthData.health -= damage;
    data.lastDamageTick = gameModel.timeElapsed;

    damageTaken += damage;

    if (damageData.onHit?.length) {
      for (let i = 0; i < damageData.onHit.length; i++) {
        const onHit = damageData.onHit[i];
        if (!onHit.data.damage && onHit.data.damage !== undefined) {
          onHit.data.damage = damage;
        }
        onHit.data.owner = damageData.owner;
        gameModel.addComponent(entityId, onHit.type, onHit.data);
        const system: (p: number, g: GameModel) => void = (gameModel.getSystem(onHit.type) as any).run;
        system(entityId, gameModel);
      }
    }
    if (!data.damages) {
      console.log("damages missing", data);
    }
    data.damages.push(damageData as DamageSchema);
    damagesTaken.push(damageData as DamageSchema);
    let knockback = 0;
    if (damageData.damageStats?.knockbackChance && damageData.damageStats?.knockback) {
      knockback =
        gameModel.rand.number() < damageData.damageStats.knockbackChance / 100
          ? (damageData.damageStats?.knockback ?? 0) / 2
          : 0;
    } else if (damageData.damageStats?.knockbackChance !== undefined) {
      knockback = 0;
    }
    if (damageData.direction && damage > 0 && knockback) {
      LocomotionSchema.id = entityId;
      const decayingVelocity = {
        ...scaleVector2d(normalizeSafeVector2d(damageData.direction), Math.min(knockback, 40)),
      };
      const prevDecayingVelocity = { x: LocomotionSchema.decayingVelocityX, y: LocomotionSchema.decayingVelocityY };
      if (lengthVector2d(decayingVelocity) > lengthVector2d(prevDecayingVelocity)) {
        LocomotionSchema.decayingVelocityX = decayingVelocity.x;
        LocomotionSchema.decayingVelocityY = decayingVelocity.y;
        LocomotionSchema.decayingVelocityTime = 200;
      }
    }
  }
  return [damageTaken, data.invulnerabilityMs, damagesTaken];
};

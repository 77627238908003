import { System } from "yage/components/System";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { DamageStatsSchema } from "../weapons/DamageStats";
import { GameModel } from "yage/game/GameModel";
import { OwnerSchema } from "yage/schemas/core/Owner";
import { ChildSchema } from "yage/schemas/entity/Child";
import { registerSchema, registerSystem } from "yage/components/ComponentRegistry";
import { ComponentCategory } from "yage/constants/enums";
import { HealthSchema } from "../core";
import { InvalidateDamageStatsOnMaxHealthChangeSchema } from "./PercentMaxHealthDamage";

@Component("MaxHealthDamageScaleInstance")
export class MaxHealthDamageScaleInstanceSchema extends Schema {
  @type("number")
  @defaultValue(0)
  damageScale: number;

  @type("number")
  @defaultValue(1)
  perHealth: number;
}

@Component("MaxHealthDamageScale")
export class MaxHealthDamageScaleSchema extends Schema {
  @type([MaxHealthDamageScaleInstanceSchema])
  @defaultValue([])
  instances: MaxHealthDamageScaleInstanceSchema[];

  @type(DamageStatsSchema)
  damageStats: DamageStatsSchema;
}

class MaxHealthDamageScaleSystem implements System {
  schema = MaxHealthDamageScaleSchema;
  type = "MaxHealthDamageScale";
  category = ComponentCategory.DAMAGEMOD;

  dependencies = ["Child"];

  init(entity: number, gameModel: GameModel) {
    if (gameModel.hasComponent(entity, ChildSchema)) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      gameModel.addComponent(parent, InvalidateDamageStatsOnMaxHealthChangeSchema);
    } else {
      gameModel.addComponent(entity, InvalidateDamageStatsOnMaxHealthChangeSchema);
    }
  }

  run(entity: number, gameModel: GameModel) {
    const maxHealthDamageScale = gameModel.getTypedUnsafe(entity, MaxHealthDamageScaleSchema);
    const damageStats = maxHealthDamageScale.damageStats;
    const owner = gameModel.getTyped(entity, OwnerSchema)?.owner ?? gameModel.getTyped(entity, ChildSchema)?.parent;
    if (!owner) return;

    HealthSchema.id = owner;
    let maxHealth = HealthSchema.maxHealth ?? 0;

    for (const instance of maxHealthDamageScale.instances) {
      damageStats.damageScale += (maxHealth / instance.perHealth) * instance.damageScale;
      damageStats.damageScale += (maxHealth / instance.perHealth) * instance.damageScale;
    }
  }
}

registerSystem(MaxHealthDamageScaleSystem);

import type { SceneTimestep } from "yage/game/Scene";
import { Scene } from "yage/game/Scene";
import { Position } from "yage/ui/Rectangle";
import { PeerMultiplayerInstance } from "yage/connection/PeerMultiplayerInstance";
import { WsSocketMultiplayerInstance } from "yage/connection/WsSocketMultiplayerInstance";
import { customAlphabet } from "nanoid";
import { ConnectionInstance } from "yage/connection/ConnectionInstance";
import { InputManager } from "yage/inputs/InputManager";
import { GameInstance } from "yage/game/GameInstance";
import { GameModel } from "yage/game/GameModel";
import { PlayerInputSchema } from "yage/schemas/core/PlayerInput";
import { EntityFactory } from "yage/entity/EntityFactory";
import { TransformSchema } from "yage/schemas/entity/Transform";
import { Box } from "yage/ui/Box";
import { KeyboardListener } from "yage/inputs/KeyboardListener";
import { GamepadListener, StandardGamepadRegions } from "yage/inputs/GamepadListener";
import { PreconfiguredTouchRegions, TouchListener } from "yage/inputs/TouchListener";
import { rotateVector2d } from "yage/utils/vector";
import { flags, toggleFlag } from "yage/console/flags";
import {
  DoctorWin,
  PlayerReadyState,
  PlayerState,
  defaultPlayerState,
  skipMenuState,
} from "../types/PlayerState.types";
import { WaveSchema } from "../components/wave/Wave";
import { getShopInfo, itemInfoContext } from "./utils/renderItemInfo";
import { cloneDeep } from "lodash";
import { UiMap, buildUiMap, registerUiClass } from "yage/ui/UiMap";

import uis from "../ui";

import { GameCoordinator } from "yage/game/GameCoordinator";
import { generatePlayer } from "./utils/generatePlayer";
import { UIService } from "yage/ui/UIService";
import { CollisionCategoryEnum } from "yage/constants/enums";
import { ShopSchema } from "../components/core/Shop";
import { Persist } from "yage/persist/persist";
import { LocalAchievementService } from "yage/achievements/LocalAchievementService";
import { Button } from "yage/ui/Button";
import { toggleFullscreen } from "../utils/toggleFullscreen";

registerUiClass("btn-primary", {
  backgroundColor: "rgba(0,0,0,0.5)",
  borderRadius: "10px",
  textTransform: "uppercase",
});

registerUiClass("image-button", {
  backgroundColor: "#404040",
  borderRadius: "10px",
});

const nanoid = customAlphabet("234579ACDEFGHJKMNPQRTWXYZ", 5);

export class ProjectVLobbyScene extends Scene {
  static sceneName = "ProjectVLobby";

  timestep: SceneTimestep = "continuous";
  dt = 4;

  private possibleCharacters: string[] = [];
  private possibleWeapons: string[] = [];

  private connection: ConnectionInstance<PlayerState>;

  private hosting = false;

  private unlisteners: (() => void)[] = [];

  private players: {
    [key: string]: PlayerState;
  } = {};

  private unsub: () => void;

  private instance: GameInstance<PlayerState>;
  private unsubPlayerConnect: () => void;
  private startingGame: boolean;
  private resizeListener: () => void;
  private shownWeapon: string;
  private shownCharacter: string;
  private shownPlayerIndex: number = -1;
  private selectedCharacter: string = "";

  weaponPickerMap: UiMap;
  weaponInfoMap: UiMap;

  lobbyMap: UiMap;

  public initialize = async (args: any[]): Promise<void> => {
    if (args.length) {
      this.instance = args[0].instance;
      this.players = {};
      this.connection = this.instance.options.connection;
    }

    const inputManager = new InputManager();
    const keyboardListener = new KeyboardListener(inputManager);
    keyboardListener.init(["w", "a", "s", "d", "i", "j", "k", "l", "space", "escape"]);
    new GamepadListener(inputManager).init(StandardGamepadRegions);

    UIService.getInstance().enableKeyCapture(inputManager);

    this.resizeListener = () => {
      this.renderUi();
    };
    window.addEventListener("resize", this.resizeListener);

    const touchListener = new TouchListener(inputManager);
    touchListener.replaceRegions(PreconfiguredTouchRegions.SingleStick);

    // check if query param has the word server in it
    if (window.location.search.includes("server")) {
      toggleFlag("USE_SERVER", true);
    } else {
      toggleFlag("USE_SERVER", false);
    }

    let addressId;
    let lobbyId;
    if (window.location.hash) {
      lobbyId = window.location.hash.substring(1);
      addressId = nanoid();
    } else {
      lobbyId = nanoid();
      addressId = lobbyId;
      // set search to lobbyId
      window.history.pushState({}, "", "#" + lobbyId);
      this.hosting = true;
    }

    const playerId = await Persist.getInstance().setIfMissing("playerId", nanoid());

    if (!this.connection) {
      if (flags.USE_SERVER) {
        this.connection = new WsSocketMultiplayerInstance(
          {
            uniqueId: playerId,
            token: "",
            netId: nanoid(),
            config: cloneDeep(defaultPlayerState),
          },
          inputManager,
          {
            solohost: true,
            // prefix: "group-chat-",
            address: lobbyId,
            host: "sock.yage.games", //window.location.hostname === "localhost" ? "localhost:3456" : "sock.yage.games",
          }
        );
      } else {
        this.connection = new PeerMultiplayerInstance(
          {
            uniqueId: playerId,
            token: "",
            netId: nanoid(),
            config: cloneDeep(defaultPlayerState),
          },
          inputManager,
          {
            solohost: true,
            prefix: "group-chat-",
            address: lobbyId,
            host: "peer.yage.games",
          }
        );
      }

      this.unsubPlayerConnect = this.connection.onPlayerConnect((playerConnect) => {
        if (playerConnect.config) {
          this.players[playerConnect.netId] = playerConnect.config;
        }

        if (playerConnect.config) {
          this.players[playerConnect.netId] = playerConnect.config;
          if (
            !this.initializingPlayer &&
            Object.values(this.players).every((p) => p.readyState === PlayerReadyState.Ready)
          ) {
            this.initializePlayer();
          } else if (
            !this.startingGame &&
            Object.values(this.players).every((p) => p.readyState === PlayerReadyState.PlayerInitialized)
          ) {
            const isHosting = Object.keys(this.players).sort()[0] === this.connection.player.netId;
            this.startGame(isHosting);
          } else {
            this.renderUi();
          }
        }
      });

      await this.attemptConnect(lobbyId, playerId);

      if (flags.STRAIGHT_TO_SHOP) {
        this.connection.updatePlayerConnect({
          config: {
            ...this.connection.player.config!,
            ...skipMenuState,
            // ...DoctorWin,
          },
        });
        if (flags.ADD_ALL_ITEMS) {
          this.flagsAddAllItems();
        }
        if (flags.ADD_ALL_WEAPONS) {
          this.flagsAddAllWeapons();
        }
        this.changeScene("Shop", {
          instance: this.instance,
          players: this.connection.players.reduce((acc, player) => {
            acc[player.netId] = player.config!;
            return acc;
          }, {} as { [key: string]: PlayerState }),
        });
        return;
      } else if (flags.SKIP_MENU) {
        GameCoordinator.GetInstance().minWidth = 600;
        GameCoordinator.GetInstance().minHeight = 600;

        this.connection.updatePlayerConnect({
          config: {
            ...this.connection.player.config!,
            ...skipMenuState,
            // ...DoctorWin,
          },
        });
        if (flags.ADD_ALL_ITEMS) {
          this.flagsAddAllItems();
        }
        if (flags.ADD_ALL_WEAPONS) {
          this.flagsAddAllWeapons();
        }
        this.changeScene("ProjectVGame", {
          instance: this.instance,
          hosting: true,
          wave: this.connection.player.config!.wave,
        });
        if (this.connection.player.connected) {
          this.connection.sendMessage("/start");
        }
        return;
      }
    } else {
      this.unsubPlayerConnect = this.connection.onPlayerConnect((playerConnect) => {
        if (playerConnect.config) {
          this.players[playerConnect.netId] = playerConnect.config;
        }

        if (playerConnect.config) {
          this.players[playerConnect.netId] = playerConnect.config;
          if (
            !this.initializingPlayer &&
            Object.values(this.players).every((p) => p.readyState === PlayerReadyState.Ready)
          ) {
            this.initializePlayer();
          } else if (
            !this.startingGame &&
            Object.values(this.players).every((p) => p.readyState === PlayerReadyState.PlayerInitialized)
          ) {
            const isHosting = Object.keys(this.players).sort()[0] === this.connection.player.netId;
            this.startGame(isHosting);
          } else {
            this.renderUi();
          }
        }
      });
      this.connection.updatePlayerConnect({
        config: cloneDeep(defaultPlayerState),
      });
    }
    this.ui.background = new Box(new Position("full", "full"), {
      style: {
        background: "linear-gradient(to bottom, #666, #333)",
        zIndex: "-3",
      },
    });

    this.renderUi();
  };
  initializingPlayer: boolean;
  flagsAddAllItems() {
    const ef = EntityFactory.getInstance();
    const itemIds = ef.findEntitiesWithComponent(["ItemType", "Shop"]);
    const levelUps = ef.findEntitiesWithComponent(["LevelUpType", "Shop"]);

    const inventory = this.connection.player.config!.inventory;

    itemIds.forEach((itemId) => {
      inventory["shopitem::" + itemId] = {
        amount: 1,
      };
    });

    levelUps.forEach((levelUp) => {
      inventory["shopitem::" + levelUp] = {
        amount: 1,
      };
    });

    console.log(inventory);

    this.connection.updatePlayerConnect({
      config: {
        ...this.connection.player.config!,
        inventory: {
          ...this.connection.player.config!.inventory,
          ...inventory,
        },
      },
    });
  }

  flagsAddAllWeapons() {
    const ef = EntityFactory.getInstance();
    const weaponIds = ef.findEntitiesWithComponent(["WeaponType", "Shop"]);

    const inventory = this.connection.player.config!.inventory;

    weaponIds.forEach((itemId) => {
      this.connection.player.config!.weapons.push(itemId);
    });

    this.connection.updatePlayerConnect({
      config: {
        ...this.connection.player.config!,
        inventory: {
          ...this.connection.player.config!.inventory,
          ...inventory,
        },
      },
    });
  }

  generateContext = () => {
    if (this.possibleCharacters.length === 0) {
      this.findCharacters();
    }
    if (this.selectedCharacter) {
      const characterInfo = getShopInfo(this.selectedCharacter)!;
      const weapons = characterInfo.tags.filter((tag) => tag.startsWith("Weapon::"));
      this.possibleWeapons = [];

      // const grid = this.uiMap.regions.weaponPicker.grid!;

      weapons.forEach((weapon, index) => {
        weapon = weapon.split("::")[1];
        this.possibleWeapons.push(weapon);
      });
    } else {
      this.possibleWeapons = [];
    }
    const player = this.connection.player.netId;
    const uniqueId = this.connection.player.uniqueId;

    const context = {
      readyLabel: this.players[player]?.readyState === PlayerReadyState.Ready ? "Unready" : "Ready",
      characters: this.possibleCharacters.map((character) => this.getCharacterInfo(character, uniqueId)),
      weapons: this.possibleWeapons.map((weapon) => {
        return {
          ...itemInfoContext(weapon),
          name: weapon,
          image: `shopitem::${weapon.toLowerCase()}`,
          tags: getShopInfo(weapon)?.tags,
          text: this.connection.player.config!.weapons.includes(weapon) ? " ✓" : "",
        };
      }),
      players: Object.values(this.connection.players).map((player, index) => {
        const character = Object.keys(player.config!.inventory)
          .find((item) => {
            if (!item.startsWith("shopitem::")) {
              return false;
            }
            item = item.split("::")[1];
            return getShopInfo(item, "Character::");
          })
          ?.split("::")[1];
        const playerWeapon = player.config?.weapons[0] ?? "";

        return {
          ...itemInfoContext(character ?? ""),
          showDetails: !!character && index === this.shownPlayerIndex,
          image: character ? `shopitem::${character.toLowerCase()}` : "",
          weaponImage: playerWeapon ? `shopitem::${playerWeapon}` : "",
          showWeaponDetails: !!playerWeapon,
          weaponDetails: itemInfoContext(playerWeapon),
          text: player.config?.readyState === PlayerReadyState.Ready ? " ✓" : "",
        };
      }, []),
      shownCharacter: this.getCharacterInfo(this.selectedCharacter || this.shownCharacter, uniqueId),
      showCharacterDisplay: !!this.shownCharacter,
      shownWeapon: itemInfoContext(this.shownWeapon),
      showWeaponDisplay: !!this.shownWeapon,
      showCharacterPicker: !this.selectedCharacter,
      showWeaponPicker: !!this.selectedCharacter && this.possibleWeapons.length > 0,
      showBack: !!this.selectedCharacter,
      showReady:
        !!this.connection.player.config?.weapons.length ||
        (this.selectedCharacter && this.possibleWeapons.length === 0),
      showShare: true,
    };

    return context;
  };

  renderUi = () => {
    const context = this.generateContext();

    if (!this.lobbyMap) {
      this.lobbyMap = buildUiMap(uis.lobby);
      Object.entries(
        this.lobbyMap.build(context, (playerIndex, name, type, context) => {
          switch (name) {
            case "fullscreen":
              toggleFullscreen();
              break;
            case "showCharacter":
              this.showCharacter(context.name);
              break;
            case "selectCharacter":
              if (context.locked) {
                return;
              }
              this.selectCharacter(context.name);
              break;
            case "showPlayer":
              this.showPlayer(context.$index);
              break;
            case "hidePlayer":
              this.hidePlayer();
              break;
            case "back":
              this.back();
              break;
            case "selectWeapon":
              this.selectWeapon(context.name);
              break;
            case "showWeapon":
              this.showWeapon(context.name);
              break;
            case "leaveWeapon":
              if (this.players[this.connection.player.netId].weapons[0]) {
                this.showWeapon(this.connection.player.config!.weapons[0]);
              }
              break;
            case "ready":
              this.ready();
              break;
            case "share":
              this.share();
              break;
            default:
              console.log("Invalid name");
              break;
          }
        })
      ).forEach(([key, value]) => {
        this.ui[key] = value;
      });
    } else {
      this.lobbyMap.update(context);
    }
  };

  share = () => {
    navigator.clipboard.writeText(window.location.origin + window.location.pathname + "#" + this.connection.address);
  };

  ready = () => {
    console.error("SENDING READY STATE");
    this.connection.updatePlayerConnect({
      config: {
        ...this.connection.player.config!,
        readyState:
          this.connection.player.config!.readyState === PlayerReadyState.Ready
            ? PlayerReadyState.NotReady
            : PlayerReadyState.Ready,
      },
    });
  };

  back = () => {
    delete this.connection.player.config!.inventory["shopitem::" + this.selectedCharacter.toLowerCase()];
    this.shownCharacter = this.selectedCharacter;
    this.selectedCharacter = "";

    this.connection.updatePlayerConnect({
      config: {
        ...this.connection.player.config!,
        weapons: [],
        readyState: PlayerReadyState.NotReady,
      },
    });
  };

  showWeapon = (name: string) => {
    if (this.shownWeapon !== name.toLowerCase()) {
      this.shownWeapon = name.toLowerCase();
      this.shownCharacter = "";
      this.shownPlayerIndex = -1;
      this.renderUi();
    }
  };

  selectWeapon = (weapon: string) => {
    if (this.possibleWeapons.includes(weapon)) {
      this.connection.updatePlayerConnect({
        config: {
          ...this.connection.player.config!,
          weapons: [weapon],
          readyState: PlayerReadyState.NotReady,
        },
      });
    }
  };

  showCharacter = (name: string) => {
    this.shownCharacter = name.toLowerCase();
    this.shownPlayerIndex = -1;
    this.renderUi();
  };

  showPlayer = (index: number) => {
    this.shownPlayerIndex = index;
    this.renderUi();
  };

  hidePlayer = () => {
    this.shownPlayerIndex = -1;
    this.renderUi();
  };

  selectCharacter = (character: string) => {
    if (this.selectedCharacter !== character) {
      if (this.selectedCharacter) {
        delete this.connection.player.config!.inventory["shopitem::" + this.selectedCharacter.toLowerCase()];
      }
      this.selectedCharacter = character;

      this.connection.updatePlayerConnect({
        config: {
          ...this.connection.player.config!,
          inventory: {
            ...this.connection.player.config!.inventory,
            ["shopitem::" + this.selectedCharacter.toLowerCase()]: {
              amount: 1,
            },
          },
        },
      });
    }
  };

  findCharacters = () => {
    const items = EntityFactory.getInstance().findEntitiesWithComponent("Shop");
    const characters = (
      items
        .filter((item) => !!getShopInfo(item, "Character::"))
        .map((item) => {
          const listIndex = getShopInfo(item)!.listIndex;
          console.log(getShopInfo(item));
          return [item, listIndex ?? 1000];
        }) as [string, number][]
    )
      .sort((a, b) => {
        console.log(a, b);
        if (a[1] === b[1]) {
          return a[0].localeCompare(b[0]);
        }
        return a[1] - b[1];
      })
      .map((item) => item[0]);
    this.possibleCharacters = characters;
  };

  getCharacterInfo = (character: string, uniqueId: string) => {
    if (!character) {
      return itemInfoContext("");
    }
    const shopInfo = EntityFactory.getInstance().getComponentFromEntity(character, "Shop")! as unknown as ShopSchema;
    const achievement = shopInfo.tags.find((t) => t.startsWith("AchievementLock::"));
    if (achievement) {
      const achievementName = achievement.split("::")[1];
      if (
        !flags.UNLOCK_ALL_CHARACTERS &&
        !this.instance.achievementService.getUnlockedAchievements(uniqueId).includes(achievementName)
      ) {
        const achievementDescription = this.instance.achievementService.getAchievement(
          uniqueId,
          achievementName
        )!.description;
        return {
          ...itemInfoContext(character),
          name: character,
          image: "ui/lock",
          tags: [],
          text: "",
          label: "Locked",
          locked: true,
          description: achievementDescription,
        };
      }
    }
    return {
      ...itemInfoContext(character),
      name: character,
      locked: false,
      image: `shopitem::${character.toLowerCase()}`,
      tags: getShopInfo(character)?.tags,
      text: "",
    };
  };

  clearCharacterPicker = () => {
    let index = 0;
    while (this.ui[`characters_${index}`]) {
      delete this.ui[`characters_${index}`];
      index++;
    }
  };

  startGame = (isHosting: boolean) => {
    this.startingGame = true;
    this.changeScene("ProjectVGame", {
      instance: this.instance,
      hosting: isHosting,
      wave: this.connection.player.config!.wave,
    });
  };

  initializePlayer = () => {
    this.initializingPlayer = true;
    const character =
      Object.keys(this.connection.player.config!.inventory)
        .find((item) => {
          if (!item.startsWith("shopitem::")) {
            return false;
          }
          item = item.split("::")[1];
          return getShopInfo(item, "Character::");
        })
        ?.split("::")[1] ?? this.selectedCharacter;

    const characterInfo = getShopInfo(character)!;
    const startingWeapons = characterInfo.tags
      .filter((tag) => tag.startsWith("StartingWeapon::"))
      .map((tag) => {
        const tagParts = tag.split("::");
        tagParts.shift();
        return tagParts.join("::");
      });
    const startingItems = characterInfo.tags
      .filter((tag) => tag.startsWith("StartingItem::"))
      .map((tag) => {
        const tagParts = tag.split("::");
        tagParts.shift();
        return tagParts.join("::");
      });

    const config = {
      ...this.connection.player.config!,
    };

    if (startingWeapons.length > 0) {
      config.weapons = [...startingWeapons, ...config.weapons];
      config.weaponMods = [...Array(startingWeapons.length).fill([]), ...config.weaponMods];
    }

    if (startingItems.length > 0) {
      startingItems.forEach((item) => {
        if (item.toLowerCase().startsWith("stat::")) {
          config.inventory[item.toLowerCase()] = {
            amount: 1,
          };
        } else {
          config.inventory["shopitem::" + item.toLowerCase()] = {
            amount: 1,
          };
        }
      });
    }

    config.readyState = PlayerReadyState.PlayerInitialized;
    this.connection.updatePlayerConnect({
      config,
    });
  };

  async attemptConnect(lobbyId: string, playerId: string) {
    this.instance = new GameInstance({
      gameName: "Project V",
      connection: this.connection,
      uiService: true,
      achievementService: new LocalAchievementService(),
      buildWorld: (gameModel: GameModel, firstPlayerConfig: PlayerState) => {
        const zero_zero = gameModel.addEntity();
        gameModel.addComponent(zero_zero, "Transform", {
          x: 0,
          y: 0,
        });
        gameModel.addComponent(zero_zero, "Radius", { radius: 10 });
        gameModel.addComponent(zero_zero, "RigidCircle", {
          mass: 2,
          isSensor: true,
          collisionCategory: CollisionCategoryEnum.ENEMY,
          collisionMask: [],
          collisionEvents: true,
        });

        gameModel.setTyped(gameModel.coreEntity, WaveSchema, {
          wave: firstPlayerConfig.wave,
        });

        const arena = EntityFactory.getInstance().generateEntity(gameModel, "arena");
        // const spawn = EntityFactory.getInstance().generateEntity(gameModel, "spawn");
        TransformSchema.id = arena;
        TransformSchema.position = {
          x: 0,
          y: 0,
        };
      },
      onPlayerJoin: (gameModel: GameModel, playerId: string, playerConfig: PlayerState & { name: string }) => {
        const [player] = generatePlayer(playerConfig, playerId, gameModel);

        const index = gameModel.players.length - 1;
        const angle = (index * Math.PI * 2) / 3;
        TransformSchema.position = rotateVector2d(
          {
            x: 0,
            y: 0,
          },
          angle
        );

        return player;
      },
      onPlayerLeave: (gameModel: GameModel, playerId: string) => {
        const players = gameModel.players;
        const player = players.find((p) => {
          const PlayerInput = gameModel.getTypedUnsafe(p, PlayerInputSchema);
          return PlayerInput.id === playerId;
        });
        if (player) {
          gameModel.removeEntity(player);
        }
      },
    });

    const gameModel = new GameModel(GameCoordinator.GetInstance(), this.instance);
    EntityFactory.getInstance().generateEntity(gameModel, "Achievements");
    gameModel.destroy(false);

    await this.instance.achievementService.update(playerId);

    // if (!this.instance.achievementService.getAchievement(playerId, "FirstGame")?.progress) {
    //   this.instance.achievementService.unlockAchievement(playerId, "FirstGame");
    //   console.log("Setting FirstGame achievement to true");
    // }
    // console.log(this.instance.achievementService.getAchievement(playerId, "FirstGame"));
    // this.instance.achievementService.resetAchievementProgress(playerId, "FirstGame");
    // console.log(this.instance.achievementService.getAchievement(playerId, "FirstGame"));

    this.unlisteners.push(
      this.connection.onPlayerConnect((player) => {
        console.log("Player connected", player, player.config?.weapons);
      })
    );
    this.unsub = this.connection.onReceiveMessage((message) => {
      console.log("message", message);
      this.handleMessage(message, false);
    });
    console.log(this.hosting, lobbyId);

    if (!this.hosting) {
      try {
        await this.connection.connect();
      } catch (e) {
        console.error(e);
        this.ui.chatBox.config.label = "Failed to connect";
        this.ui.initLobby.visible = true;
        return;
      }
    }
  }

  handleMessage = (message: string, self: boolean) => {
    console.log(message);
    if (message.startsWith("/")) {
      const parts = message.substring(1).split(" ");
      switch (parts[0]) {
        case "start":
          this.changeScene("ProjectVGame", {
            instance: this.instance,
            hosting: false,
            wave: this.connection.player.config!.wave,
          });
          break;
      }
      return;
    }
    const label = this.ui.chatBox.config.label + "\n" + (self ? "You: " + message : "Thm: " + message);
    if (label.split("\n").length > 3) {
      this.ui.chatBox.config.label = label.split("\n").slice(1).join("\n");
    } else {
      this.ui.chatBox.config.label = label;
    }
  };

  run = () => {};

  public destroy = (): void => {
    super.destroy();
    this.unsub?.();
    this.unlisteners.forEach((unlistener) => unlistener());
    this.unsubPlayerConnect();
    window.removeEventListener("resize", this.resizeListener);
    console.log("this is the lobby scene");
    console.log("MinMediator: destroy!");
  };
}

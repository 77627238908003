import { registerSchema, registerUIComponent } from "yage/components/ComponentRegistry";
import { flags, toggleFlag } from "yage/console/flags";
import { Component, Schema } from "yage/decorators/type";
import { Button } from "yage/ui/Button";
import { Position } from "yage/ui/Rectangle";
import { UIElement } from "yage/ui/UIElement";
import { UIService } from "yage/ui/UIService";

@Component("FpsToggle")
export class FpsToggleSchema extends Schema {}

registerSchema(FpsToggleSchema);

let ui: UIElement | null = null;
registerUIComponent(
  "FpsToggle",
  (uiService, entity, gameModel, viewport) => {
    if (!uiService.ui.FpsToggle) {
      uiService.ui.FpsToggle = new Button(new Position("left", "top", { xOffset: 3, yOffset: 3 }), {
        label: flags.FPS_30 ? "30 FPS" : "60 FPS",
        onClick: () => {
          toggleFlag("FPS_30");
          uiService.ui.FpsToggle.config.label = flags.FPS_30 ? "30 FPS" : "60 FPS";
        },
        style: {
          backgroundColor: "transparent",
          border: "none",
        },
      });
    }
  },
  {
    cleanup: () => {
      delete UIService.getInstance().ui.FpsToggle;
      ui = null;
    },
  }
);

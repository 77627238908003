import { System } from "yage/components/System";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { InventorySchema } from "../player/Inventory";
import { HarvestingEnhancerSchema } from "../enhancers/HarvestingEnhancer";
import { registerSystem } from "yage/components/ComponentRegistry";
import { getPlayerCharacter } from "../../utils/playerCharacter";
import { ComponentCategory } from "yage/constants/enums";

@Component("Harvesting")
export class HarvestingSchema extends Schema {
  @type("number")
  @defaultValue(0)
  harvesting: number;
}

export class HarvestingSystem implements System {
  schema = HarvestingSchema;
  type = "Harvesting";

  category = ComponentCategory.ON_END;

  run(entity: number, gameModel: GameModel) {
    const playerCharacter = getPlayerCharacter(entity, gameModel);
    const harvesting = gameModel.getTypedUnsafe(entity, HarvestingSchema);
    const inventory = gameModel.getTypedUnsafe(entity, InventorySchema);
    if (!inventory.inventory.coins) {
      inventory.inventory.coins = {
        amount: 0,
      };
    }
    const harvestingEnhancer = gameModel.getTypedUnsafe(playerCharacter, HarvestingEnhancerSchema);
    const harvestingPercentScale = 1 + (harvestingEnhancer?.harvestingPercentScale ?? 0);

    const harvestingAmount = harvesting.harvesting + (harvestingEnhancer?.harvesting ?? 0) * harvestingPercentScale;

    inventory.inventory.coins.amount += Math.floor(harvestingAmount);
    if (harvestingAmount > 0) {
      const harvestingPercent = 0.05 + (harvestingEnhancer?.harvestingPercent ?? 0);
      harvesting.harvesting += harvestingAmount * harvestingPercent * harvestingPercentScale;
    }
  }
}

registerSystem(HarvestingSystem);

import { addFlags } from "yage/console/flags";

addFlags({
  SKIP_MENU: {
    reload: true,
  },
  RANDO: {
    windowScope: true,
    reload: true,
  },
  STRAIGHT_TO_SHOP: {
    windowScope: true,
  },
  ADD_ALL_WEAPONS: {
    windowScope: true,
    reload: true,
  },
  ADD_ALL_ITEMS: {
    windowScope: true,
    reload: true,
  },
  SHORTCUT_WAVE: {
    reload: true,
  },
  USE_SERVER: {
    reload: true,
  },
  UNLOCK_ALL_CHARACTERS: {
    windowScope: true,
    reload: true,
  },
});

import { DEPTHS, registerPixiComponent, registerSystem } from "yage/components/ComponentRegistry";
import type { PixiDrawSystem } from "yage/components/PixiDrawSystem";
import type { System } from "yage/components/System";
import { ComponentCategory } from "yage/components/types";
import { Component, defaultValue, Schema, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import type { Vector2d } from "yage/utils/vector";
import { Vector2dSchema } from "yage/utils/vector";
import * as PIXI from "pixi.js";
import { Viewport } from "pixi-viewport";
import ImageLoader from "yage/loader/ImageLoader";

@Component("TextBloop")
export class TextBloopSchema extends Schema {
  @type("string")
  @defaultValue("")
  text: string;

  @type("string")
  @defaultValue("")
  prefixImage: string;

  @type("string")
  @defaultValue("")
  suffixImage: string;

  @type("number")
  @defaultValue(0)
  timeout: number;

  @type("number")
  startFrame: number;

  @type("number")
  @defaultValue(0)
  gravity: number;

  @type("number")
  @defaultValue(0)
  yVelocity: number;

  @type("number")
  @defaultValue(0)
  xVelocity: number;

  @type("number")
  @defaultValue(32)
  fontSize: number;

  @type("number")
  @defaultValue(0xff2b2b)
  color: number;

  @type("number")
  @defaultValue(0x000000)
  strokeColor: number;

  @type("number")
  @defaultValue(7)
  strokeThickness: number;

  @type(Vector2dSchema)
  @defaultValue({ x: 0, y: 0 })
  position: Vector2d;
}

@Component("TextBlooper")
export class TextBlooperSchema extends Schema {
  @type([TextBloopSchema])
  @defaultValue([])
  bloops: TextBloopSchema[];
}

class TextBlooperSystem implements System {
  type = "TextBlooper";
  category: ComponentCategory = ComponentCategory.RENDERING;
  schema = TextBlooperSchema;
  depth = DEPTHS.PREDRAW;

  run(entityId: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entityId, this.schema);
    const bloops = data.bloops;

    for (let i = 0; i < bloops.length; ++i) {
      const bloop = bloops[i];
      const bloopEntity = gameModel.addEntity();

      gameModel.addComponent(bloopEntity, TextBloopSchema, {
        ...bloop,
      });
    }
    data.bloops = [];
  }
}

registerSystem(TextBlooperSystem);

class TextBloopSystem implements System {
  type = "TextBloop";
  category: ComponentCategory = ComponentCategory.RENDERING;
  schema = TextBloopSchema;
  depth = DEPTHS.PREDRAW;

  run(entityId: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entityId, this.schema);
    if (gameModel.timeElapsed > data.timeout) {
      gameModel.removeEntity(entityId);
      return;
    }
    data.yVelocity += data.gravity;
    data.position.y += (data.yVelocity * gameModel.frameDt) / 1000;
    data.position.x += (data.xVelocity * gameModel.frameDt) / 1000;
  }
}

registerSystem(TextBloopSystem);

class TextBloopDraw implements PixiDrawSystem {
  ids: Set<number> = new Set();
  entities: {
    [id: number]: { container: PIXI.Container };
  } = {};
  schema = TextBloopSchema;

  init(entity: number, gameModel: GameModel, viewport: Viewport) {
    const container = new PIXI.Container();
    container.zIndex = 100;
    const data = gameModel.getTypedUnsafe(entity, TextBloopSchema);

    const text = new PIXI.Text(data.text, {
      fontSize: data.fontSize, //Math.max(36, data.fontSize * Math.min(data.damage / 100 + 0.5, 1.5)),
      fill: data.color,
      fontFamily: "arial",
      stroke: data.strokeColor,
      strokeThickness: data.strokeThickness,
    });
    if (data.prefixImage) {
      const prefix = new PIXI.Sprite(ImageLoader.getInstance().getPixiTexture(data.prefixImage));
      prefix.anchor.set(0.5, 0.5);
      prefix.position.set(-text.width / 2 - prefix.width / 2, 0);
      container.addChild(prefix);
    }
    if (data.suffixImage) {
      const suffix = new PIXI.Sprite(ImageLoader.getInstance().getPixiTexture(data.suffixImage));
      suffix.anchor.set(0.5, 0.5);
      suffix.position.set(text.width / 2 + suffix.width / 2, 0);
      container.addChild(suffix);
    }
    text.zIndex = 1000000;
    text.anchor.set(0.5, 0.5);
    container.addChild(text);

    this.entities[entity] = { container };
    this.ids.add(entity);
    viewport.addChild(container);
  }

  run(entity: number, gameModel: GameModel) {
    // TransformSchema.id = entity;
    // const entityPosition = TransformSchema.position
    const data = gameModel.getTypedUnsafe(entity, TextBloopSchema);
    const container = this.entities[entity].container;
    container.position.set(data.position.x, data.position.y);
    // const alpha = 1 - (gameModel.timeElapsed - data.startFrame) / (data.timeout - data.startFrame);
    container.alpha = 1;
    // container.alpha = alpha;
  }

  cleanup(entity: number) {
    if (!this.entities[entity]) {
      return;
    }
    const container = this.entities[entity].container;
    container.children.forEach((child) => {
      container.removeChild(child);
      child.destroy();
    });

    container.destroy();
    delete this.entities[entity];
    this.ids.delete(entity);
  }
}

registerPixiComponent("TextBloop", TextBloopDraw);

export const addBloop = (bloop: Partial<TextBloopSchema>, gameModel: GameModel) => {
  if (gameModel.hasComponent(gameModel.coreEntity, "TextBlooper")) {
    const blooper = gameModel.getTypedUnsafe(gameModel.coreEntity, TextBlooperSchema);
    blooper.bloops.push(bloop as TextBloopSchema);
  }
};

import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { PlayerTypeSchema, EnemyTypeSchema } from "yage/components/entity/Types";
import { ComponentCategory } from "yage/constants/enums";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";
import { DamageStatsSchema } from "../weapons/DamageStats";

@Component("DamageStatOnDamage")
class DamageStatOnDamageSchema extends Schema {
  @type("string")
  scalingStat: string;

  @type("number")
  @defaultValue(1)
  amount: number;

  @type("number")
  @defaultValue(0)
  currentDamageScale: number;

  @type("number")
  @defaultValue(0)
  damage: number;
}

class DamageStatOnDamageSystem implements System {
  schema = DamageStatOnDamageSchema;
  type = "DamageStatOnDamage";
  category = ComponentCategory.ONDAMAGE;
  depth = DEPTHS.NORUN + 100000;

  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, DamageStatOnDamageSchema);

    if (data.currentDamageScale * data.damage <= 0) {
      return;
    }

    const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
    if (!gameModel.hasComponent(parent, DamageStatsSchema)) {
      return;
    }
    const stats = gameModel.getTypedUnsafe(parent, DamageStatsSchema).initialDamageStats as any;
    stats[data.scalingStat] = stats[data.scalingStat] + data.amount;
    gameModel.getTypedUnsafe(parent, DamageStatsSchema).invalidated = true;
  }
}

registerSystem(DamageStatOnDamageSystem);

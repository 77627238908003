import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { ComponentCategory } from "yage/constants/enums";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import DescriptionSchema from "yage/schemas/core/Description";
import { EntityFactory } from "yage/entity/EntityFactory";
import { ChildSchema } from "yage/schemas/entity/Child";
import { InventorySchema } from "../player/Inventory";
import { getEntityController } from "../../utils/playerCharacter";
import { HealthSchema, getMaxHealth } from "../core";

@Component("AddItemOnMaxHpPickup")
export class AddItemOnMaxHpPickupSchema extends Schema {
  @type(["string"])
  @defaultValue([])
  pickupables: string[];

  @type("string")
  entityDescription: string;

  @type("string")
  inventoryPrefix: string;

  @type("number")
  @defaultValue(10)
  maxItems: number;

  @type("number")
  @defaultValue(0)
  itemsAdded: number;

  @type("number")
  @defaultValue(0)
  pickedup: number;
}

class AddItemOnMaxHpPickupSystem implements System {
  schema = AddItemOnMaxHpPickupSchema;
  type = "AddItemOnMaxHpPickup";
  category = ComponentCategory.ON_PICKUP;

  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, AddItemOnMaxHpPickupSchema);

    const pickedUp = data.pickedup;

    const entityName = gameModel.getTypedUnsafe(pickedUp, DescriptionSchema).description;

    if (data.pickupables.includes(entityName)) {
      const parent = gameModel.getTyped(entity, ChildSchema)?.parent ?? entity;

      HealthSchema.id = parent;
      if (HealthSchema.health < getMaxHealth(parent, gameModel)) {
        return;
      }

      if (data.itemsAdded >= data.maxItems) {
        return;
      }
      data.itemsAdded += 1;

      const player = getEntityController(parent, gameModel);

      EntityFactory.getInstance().generateEntity(gameModel, data.entityDescription, {
        Child: {
          parent: parent,
          autoAttach: false,
        },
      });
      if (data.inventoryPrefix) {
        const inventory = gameModel.getTypedUnsafe(player, InventorySchema);
        if (!inventory.inventory[data.inventoryPrefix + "::" + data.entityDescription]) {
          inventory.inventory[data.inventoryPrefix + "::" + data.entityDescription] = {
            amount: 0,
          };
        }
        inventory.inventory[data.inventoryPrefix + "::" + data.entityDescription].amount += 1;
      }
    }
  }
}

registerSystem(AddItemOnMaxHpPickupSystem);

import { registerSchema, registerUIComponent } from "yage/components/ComponentRegistry";
import { Component, Schema } from "yage/decorators/type";
import { HealthSchema, getMaxHealth } from "../core";
import { UIElement } from "yage/ui/UIElement";
import { Position } from "yage/ui/Rectangle";
import { Text } from "yage/ui/Text";
import { Box } from "yage/ui/Box";
import { PlayerInputSchema } from "yage/schemas/core/PlayerInput";
import { getPlayerCharacter } from "../../utils/playerCharacter";

@Component("GlobalHealthBar")
class GlobalHealthBarSchema extends Schema {}

registerSchema(GlobalHealthBarSchema);

let ui: UIElement[] | null = null;

registerUIComponent(
  "GlobalHealthBar",
  (uiService, entity, gameModel) => {
    const selfId = gameModel.getTypedUnsafe(entity, PlayerInputSchema).id;

    if (selfId !== gameModel.localNetIds[0]) {
      return;
    }
    if (!gameModel.hasComponent(getPlayerCharacter(entity, gameModel), HealthSchema)) {
      ui?.forEach((element) => {
        uiService.removeFromUI(element);
      });
      ui = null;
      return;
    }

    if (!ui) {
      ui = [];
      const position = new Position("left", "top", { width: 200, height: 40, xOffset: 20, yOffset: 50 });
      const healthColor = new Box(position, {
        style: {
          backgroundColor: "#880808",
          borderColor: "transparent",
          borderWidth: "3px",
          borderRadius: "5px",
        },
      });
      ui.push(healthColor);
      uiService.addToUI(healthColor);
      const healthBorder = new Box(position, {
        style: {
          backgroundColor: "transparent",
          borderColor: "black",
          borderWidth: "3px",
          borderRadius: "5px",
          zIndex: "100",
        },
      });
      ui.push(healthBorder);
      uiService.addToUI(healthBorder);
      const textPosition = new Position("left", "top", { width: 200, height: 40, xOffset: 20, yOffset: 50 });

      const HealthText = new Text(textPosition, {
        label: "00000",
        fontSize: 32,
        style: {
          textTransform: "uppercase",
          webkitTextStroke: "1px black",
          textShadow: `
        3px 3px 0 #000,
      -1px -1px 0 #000,  
       1px -1px 0 #000,
       -1px 1px 0 #000,
        1px 1px 0 #000`,
        },
      });
      ui.push(HealthText);
      uiService.addToUI(HealthText);
    }
    const playerCharacter = getPlayerCharacter(entity, gameModel);
    const health = gameModel.getTypedUnsafe(playerCharacter, HealthSchema);

    ui[0].position.width = (200 * health.health) / getMaxHealth(playerCharacter, gameModel);

    ui[2].config.label = health.health + "/" + getMaxHealth(playerCharacter, gameModel);
  },
  {
    cleanup: () => {
      ui = null;
    },
  }
);

import { DEPTHS, registerPixiComponent, registerSystem } from "yage/components/ComponentRegistry";
import type { PixiDrawSystem } from "yage/components/PixiDrawSystem";
import type { System } from "yage/components/System";
import { ComponentCategory } from "yage/components/types";
import { type, Component, Schema, defaultValue } from "yage/decorators/type";
import type { GameModel } from "yage/game/GameModel";
import {
  addVector2d,
  distanceVector2d,
  normalizeVector2d,
  rotateVector2d,
  scaleVector2d,
  subtractVector2d,
} from "yage/utils/vector";
import * as PIXI from "pixi.js";
import { LocomotionSchema } from "yage/schemas/entity/Locomotion";
import { RadiusSchema } from "yage/schemas/entity/Radius";
import { TransformSchema } from "yage/schemas/entity/Transform";
import { Viewport } from "pixi-viewport";
import { closestEntity } from "yage/utils/Collision";
import { getSpeed } from "../enhancers/SpeedEnhancer";

@Component("Chase")
export class ChaseSchema extends Schema {
  @type("Entity")
  target: number;

  @type("number")
  @defaultValue(0)
  distance: number;

  @type("boolean")
  @defaultValue(true)
  wanderNoTarget: boolean;
}

class ChaseSystem implements System {
  type = "Chase";
  category: ComponentCategory = ComponentCategory.TARGET;
  schema = ChaseSchema;
  depth = DEPTHS.LOCOMOTION + 10;

  runAll?(gameModel: GameModel): void {
    const entities = gameModel.getComponentActives("Chase");

    for (let i = 0; i < entities.length; i++) {
      const entity = entities[i];
      LocomotionSchema.id = entity;
      RadiusSchema.id = entity;
      const radius = RadiusSchema.radius;
      const chase = gameModel.getTypedUnsafe(entity, ChaseSchema);

      TransformSchema.id = entity;
      const position = TransformSchema.position;
      if (!gameModel.isActive(chase.target)) {
        chase.target = 0;
      }

      if (!chase.target) {
        const closestPlayer = closestEntity(gameModel, position, gameModel.players);
        if (closestPlayer) {
          chase.target = closestPlayer;
        } else {
          if (chase.wanderNoTarget) {
            // use boid wander
            const forward = addVector2d(
              position,
              scaleVector2d(
                {
                  x: LocomotionSchema.directionX,
                  y: LocomotionSchema.directionY,
                },
                100
              )
            );
            const randAngle = gameModel.rand.number() * Math.PI * 2;
            const wanderVector = rotateVector2d({ x: 0, y: 1 }, randAngle);
            const target = addVector2d(forward, scaleVector2d(wanderVector, 10));
            const direction = normalizeVector2d(subtractVector2d(target, position));
            LocomotionSchema.velocityX = (LocomotionSchema.speed / 4) * direction.x;
            LocomotionSchema.velocityY = (LocomotionSchema.speed / 4) * direction.y;
            LocomotionSchema.directionX = direction.x;
            LocomotionSchema.directionY = direction.y;
          }
          continue;
        }
      }

      const target = chase.target;
      if (target) {
        TransformSchema.id = target;
        const currentTargetPosition = TransformSchema.position;

        if (chase.distance !== 0) {
          const distance = distanceVector2d(position, currentTargetPosition);
          if (distance < chase.distance) {
            LocomotionSchema.velocityX = 0;
            LocomotionSchema.velocityY = 0;
            const direction = normalizeVector2d(subtractVector2d(currentTargetPosition, position));
            LocomotionSchema.directionX = direction.x;
            LocomotionSchema.directionY = direction.y;
            continue;
          }
        }

        const targetX = TransformSchema.store.x[target];
        const targetY = TransformSchema.store.y[target];
        const targetPosition = { x: targetX, y: targetY };

        const randomX = gameModel.rand.int(0, 30) - 15;
        const randomY = gameModel.rand.int(0, 30) - 15;
        targetPosition.x += randomX;
        targetPosition.y += randomY;

        const direction = normalizeVector2d(subtractVector2d(targetPosition, position));
        const velocity = scaleVector2d(direction, getSpeed(entity, gameModel));
        LocomotionSchema.velocityX = velocity.x;
        LocomotionSchema.velocityY = velocity.y;
        LocomotionSchema.directionX = direction.x;
        LocomotionSchema.directionY = direction.y;
      }
    }
  }
}

registerSystem(ChaseSystem);

import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import type { System } from "yage/components/System";
import { ComponentCategory } from "yage/components/types";
import { Component, defaultValue, Schema, type } from "yage/decorators/type";
import type { GameModel } from "yage/game/GameModel";
import { LocomotionSchema } from "yage/schemas/entity/Locomotion";
import { SpriteSchema } from "yage/schemas/render/Sprite";

const HITLENMS = 500;

@Component("ActiveSprite")
class ActiveSpriteSchema extends Schema {
  @type("number")
  @defaultValue(0)
  originalAnimationSpeed: number;

  @type("boolean")
  @defaultValue(false)
  noHit: boolean;

  @type("number")
  @defaultValue(-30)
  hitOffset: number;

  @type("number")
  @defaultValue(0)
  yoffset: number;

  @type("number")
  @defaultValue(200)
  hitTime: number;

  @type("number")
  @defaultValue(100)
  hitCooldown: number;

  @type("number")
  @defaultValue(0)
  hitCurrentTime: number;

  @type("number")
  @defaultValue(0)
  hitCooldownCurrentTime: number;
}

class ActiveSpriteSystem implements System {
  type = "ActiveSprite";
  category: ComponentCategory = ComponentCategory.RENDERING;
  schema = ActiveSpriteSchema;
  depth = DEPTHS.PREDRAW + 1;

  init(entity: number, gameModel: GameModel) {
    const spriteData = gameModel.getTypedUnsafe(entity, SpriteSchema);
    const data = gameModel.getTypedUnsafe(entity, ActiveSpriteSchema);
    data.yoffset = spriteData.yoffset;
  }

  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, ActiveSpriteSchema);
    const spriteData = gameModel.getTypedUnsafe(entity, SpriteSchema);
    LocomotionSchema.id = entity;

    // if (!data.originalAnimationSpeed) {
    //   data.originalAnimationSpeed = spriteData.animationSpeed;
    // }

    // spriteData.animationSpeed = data.originalAnimationSpeed / (dt<number>() / gameModel.dt<number>(entity));

    let nextAnimation = spriteData.animationKey + "_idle";

    if (LocomotionSchema.velocityX || LocomotionSchema.velocityY) {
      nextAnimation = spriteData.animationKey + "_run";
    }

    if (nextAnimation === spriteData.animationKey + "_hit") {
      spriteData.yoffset = data.yoffset + data.hitOffset;
    } else {
      spriteData.yoffset = data.yoffset;
    }
    if (nextAnimation !== spriteData.currentAnimation) {
      spriteData.currentAnimation = nextAnimation;
      spriteData.animationIndex = 0;
    }
  }
}

registerSystem(ActiveSpriteSystem);

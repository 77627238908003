import { registerSchema, registerSystem } from "yage/components/ComponentRegistry";
import type { System } from "yage/components/System";
import { ComponentCategory } from "yage/components/types";
import { Component, defaultValue, Schema, type } from "yage/decorators/type";
import type { GameModel } from "yage/game/GameModel";

@Component("GlobalSpawn")
export class GlobalSpawnSchema extends Schema {
  @type("boolean")
  @defaultValue(true)
  enabled: boolean;
}

registerSchema(ComponentCategory.ENEMY, GlobalSpawnSchema);

@Component("SetGlobalSpawn")
export class SetGlobalSpawnSchema extends Schema {
  @type("boolean")
  enabled: boolean;
}

class SetGlobalSpawnSystem implements System {
  type = "SetGlobalSpawn";
  category: ComponentCategory = ComponentCategory.ENEMY;
  schema = SetGlobalSpawnSchema;
  init(entity: number, gameModel: GameModel) {
    if (gameModel.hasComponent(gameModel.coreEntity, "GlobalSpawn")) {
      gameModel.getTypedUnsafe(gameModel.coreEntity, GlobalSpawnSchema).enabled = gameModel.getTypedUnsafe(
        entity,
        SetGlobalSpawnSchema
      ).enabled;
      gameModel.removeComponent(entity, "SetGlobalSpawn");
    }
  }
}
registerSystem(SetGlobalSpawnSystem);

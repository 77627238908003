import type { SceneTimestep } from "yage/game/Scene";
import { Scene } from "yage/game/Scene";
import { ConnectionInstance } from "yage/connection/ConnectionInstance";
import { GameInstance } from "yage/game/GameInstance";
import { EntityFactory } from "yage/entity/EntityFactory";
import { Random, generate } from "yage/utils/rand";
import { ShopSchema } from "../components/core/Shop";
import { PlayerReadyState, PlayerState } from "../types/PlayerState.types";
import { cloneDeep, debounce } from "lodash";

// @ts-ignore
import { itemInfoContext } from "./utils/renderItemInfo";
import { UiMap, buildUiMap, registerUiClass } from "yage/ui/UiMap";

import uis from "../ui";
import { GameModel } from "yage/game/GameModel";
import { GameCoordinator } from "yage/game/GameCoordinator";
import { PlayerInputSchema } from "yage/schemas/core/PlayerInput";
import { HarvestingSchema } from "../components/core/Harvesting";
import { HealthSchema, getMaxHealth } from "../components/core";
import { HpRegenSchema } from "../components/player/HpRegen";
import { LifestealSchema } from "../components/core/Lifesteal";
import { DamageStatsSchema } from "../schema/damage/Damage";
import { DamageMitigationSchema } from "../components/damagemods/DamageMitigation";
import { SpeedEnhancerSchema } from "../components/enhancers/SpeedEnhancer";
import { HarvestingEnhancerSchema } from "../components/enhancers/HarvestingEnhancer";
import { generatePlayer } from "./utils/generatePlayer";
import { LifestealEnhancerSchema } from "../components/enhancers/LifestealEnhancer";
import { UIService } from "yage/ui/UIService";
import { Position } from "yage/ui/Rectangle";
import { ComponentCategory } from "yage/constants/enums";
import { ComponentData } from "yage/components/types";
import { SpeedCapSchema } from "../components/core/SpeedCap";
import { DamageStatsScalerSchema } from "../components/scalers/DamageStatsScaler";
import { Button } from "yage/ui/Button";
import { toggleFullscreen } from "../utils/toggleFullscreen";

const stringToNumber = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = (hash << 5) - hash + str.charCodeAt(i);
    hash |= 0;
  }
  return hash;
};

type Tag = {
  id: string;
  tier: number;
  itemTags: string[];
  type: "weapon" | "item";
  price: number;
  roundPrice: number;
};

type WeaponTag = Tag & {
  type: "weapon";
  weaponType: "Melee" | "Range" | "NONE";
  baseId: string;
};

type RollMods = {
  alwaysSell: string[];
  filter: string[];
  weaponFilter: string[];
  itemFilter: string[];
};

type ShopDetails = {
  id: string;
  price: number;
  roundPrice: number;
};

type StatDetails = {
  maxHealth: number;
  hpRegen: number;
  lifesteal: number;
  damage: number;
  meleeDamage: number;
  rangedDamage: number;
  elementalDamage: number;
  attackSpeed: number;
  critChance: number;
  engineering: number;
  range: number;
  armor: number;
  dodge: number;
  maxDodge: number;
  speed: number;
  luck: number;
  harvesting: number;
};

registerUiClass("StrokeText", {
  webkitTextStroke: "1px black",
  textShadow: `
3px 3px 0 #000,
-1px -1px 0 #000,  
1px -1px 0 #000,
-1px 1px 0 #000,
1px 1px 0 #000`,
});

export class ProjectVShopScene extends Scene {
  static sceneName = "Shop";

  timestep: SceneTimestep = "continuous";
  dt = 4;

  private connection: ConnectionInstance<PlayerState>;

  private unsub: () => void;
  private instance: GameInstance<PlayerState>;

  private shopMap: UiMap;

  private wave: number;
  private players: {
    [key: string]: PlayerState;
  };

  private playerShopRerollCount: {
    [key: string]: number;
  } = {};

  private playerUpgradeRerollCount: {
    [playerId: string]: number;
  } = {};

  private playerShopItems: {
    [playerId: string]: ShopDetails[];
  } = {};

  private playerRecycleableItems: {
    [playerId: string]: ShopDetails[];
  } = {};

  private playerLevelUps: {
    [playerId: string]: string[];
  } = {};

  private shownItem: string;
  private shownWeapon: string;
  private interactingWeapon: number = -1;

  private baseSeed: number = 0;

  private playerRand: {
    [key: string]: Random;
  } = {};

  private playerStats: {
    [key: string]: StatDetails;
  } = {};

  public initialize = async (args: any[]): Promise<void> => {
    if (args.length) {
      this.instance = args[0].instance;
      const seed = args[0].seed;
      this.players = {};
      this.connection = this.instance.options.connection;
      UIService.getInstance().enableKeyCapture(this.connection.inputManager);

      this.generateBaseWeaponTagMap();

      Object.entries(args[0].players).forEach(([playerId, player]) => {
        const playerConnection = this.connection.players.find((p) => p.netId === playerId);
        if (playerConnection) {
          this.players[playerId] = {
            ...playerConnection.config!,
            ...(player as Partial<PlayerState>),
          };
        }
      });

      console.log(this.players);
      this.wave = this.players[this.connection.localPlayers[0].netId]!.wave;
      this.unsub = this.connection.onReceiveMessage((message) => {
        console.log("message", message);
        this.handleMessage(message, false);
      });
      this.unsubPlayerConnect = this.connection.onPlayerConnect((playerConnect) => {
        if (playerConnect.config) {
          this.players[playerConnect.netId] = playerConnect.config;
          if (Object.values(this.players).every((p) => p.readyState === PlayerReadyState.Ready)) {
            const host = Object.keys(this.connection.players).sort()[0];
            const localHost = this.connection.localPlayers[0].netId;
            const isHosting = host === localHost;

            this.changeScene("ProjectVGame", {
              instance: this.instance,
              hosting: isHosting,
              wave: this.connection.localPlayers[0].config!.wave,
            });
          } else if (!!this.connection.localPlayers.find((player) => player.netId === playerConnect.netId)) {
            this.renderUi();
          }
        }
      });
      this.baseSeed = stringToNumber(this.connection.players[0].config!.wave + this.connection.address) + seed;

      Object.keys(this.players)
        .sort((a, b) => a.localeCompare(b))
        .forEach((playerId, index) => {
          this.playerRand[playerId] = generate(index + this.baseSeed);

          this.handleCrates(playerId);

          const freeRolls = this.findTag("FreeShopRoll::", playerId);

          this.playerShopRerollCount[playerId] = -freeRolls.length;
          this.rollShop(playerId);
          this.rollLevelup(playerId);
        });
      this.recalculateStats(this.connection.localPlayers[0].netId);
    }
  };

  hasTag(tag: string, item: string) {
    return !!this.getTag(tag, item);
  }

  getTag(tag: string, item: string) {
    const shopInfo = EntityFactory.getInstance().getComponentFromEntity(item, "Shop")! as unknown as ShopSchema;
    return shopInfo?.tags?.find((t) => t.startsWith(tag));
  }

  findByTag(tag: string, playerId: string) {
    const ef = EntityFactory.getInstance();
    const allShopItems = ef.findEntitiesWithComponent(["Shop"]);

    const filteredItems = allShopItems
      .map((item) => {
        const foundTag = this.getTag(tag, item);
        if (!foundTag) {
          return null;
        }
        return [item, foundTag];
      })
      .filter((x) => !!x) as [string, string][];

    const player = this.players[playerId]!;
    const playerInventory = player.inventory;
    const playerItems = Object.keys(playerInventory).filter((item) => item.startsWith("shopitem::"));
    return filteredItems
      .map((item) => {
        const hasTag = playerItems.includes(`shopitem::${item[0].toLowerCase()}`);
        if (!hasTag) {
          return null;
        }
        return item;
      })
      .filter((x) => !!x)
      .flat() as string[];
  }

  findTag(tag: string): [string, string][];
  findTag(tag: string, playerId: string): string[];
  findTag(tag: string, playerId?: string) {
    const ef = EntityFactory.getInstance();
    const allShopItems = ef.findEntitiesWithComponent(["Shop"]);

    const filteredItems = allShopItems
      .map((item) => {
        const foundTag = this.getTag(tag, item);
        if (!foundTag) {
          return null;
        }
        return [item, foundTag];
      })
      .filter((x) => !!x) as [string, string][];

    if (playerId) {
      const player = this.players[playerId]!;
      const playerInventory = player.inventory;
      const playerItems = Object.keys(playerInventory).filter((item) => item.startsWith("shopitem::"));
      return filteredItems
        .map((item) => {
          const hasTag = playerItems.includes(`shopitem::${item[0].toLowerCase()}`);
          if (!hasTag) {
            return null;
          }
          return Array(playerInventory[`shopitem::${item[0]}`].amount).fill(item[1]);
        })
        .filter((x) => !!x)
        .flat() as string[];
    }

    return filteredItems;
  }

  public _renderUi = () => {
    console.log("renderUi");

    const context = this.generateContext(0);

    if (!this.shopMap) {
      this.shopMap = buildUiMap(uis.shop, new Position("full", "full"), {
        captureFocus: 0,
      });
      Object.entries(this.shopMap.build(context, this.handleUiEvents)).forEach(([key, value]) => {
        this.ui[key] = value;
      });
    } else {
      this.shopMap.update(context);
    }
  };

  public renderUi = debounce(this._renderUi, 10, { leading: false, trailing: true });

  handleUiEvents = (playerIndex: number, name: string, type: string, context: any) => {
    const player = this.connection.localPlayers[playerIndex].netId;
    const playerCoins = this.players[player]!.inventory.coins.amount;
    const ef = EntityFactory.getInstance();

    switch (name) {
      case "fullscreen":
        toggleFullscreen();
        break;

      case "upgradeLevelUp":
        this.sendMessage(`/selectupgrade ${context.$index} ${this.connection.localPlayers[playerIndex].netId}`);
        break;
      case "rerollLevelups":
        if (playerCoins > this.calculateUpgradeRollPrice(this.connection.localPlayers[playerIndex].netId)) {
          this.sendMessage(`/rerollupgrades ${this.connection.localPlayers[playerIndex].netId} true`);
        }
        break;
      case "lockShopItem":
        this.sendMessage(`/lockitem ${context.$index} ${this.connection.localPlayers[playerIndex].netId}`);
        break;
      case "unlockShopItem":
        this.sendMessage(`/lockitem ${context.$index} ${this.connection.localPlayers[playerIndex].netId}`);
        break;
      case "rerollShop":
        if (playerCoins >= this.calculateRollPrice(this.connection.localPlayers[playerIndex].netId)) {
          this.sendMessage(`/reroll ${this.connection.localPlayers[playerIndex].netId}`);
        }
        break;
      case "buyShopItem":
        if (this.canBuyItem(player, context.$index)) {
          this.sendMessage(`/buyitem ${context.$index} ${this.connection.localPlayers[playerIndex].netId}`);
        }
        break;
      case "keepItem":
        this.sendMessage(`/keepitem 0 ${this.connection.localPlayers[playerIndex].netId}`);
        break;
      case "recycleItem":
        this.sendMessage(`/recycleitem 0 ${this.connection.localPlayers[playerIndex].netId}`);
        break;
      case "recycleWeapon":
        this.interactingWeapon = -1;
        this.sendMessage(`/recycleweapon ${context.$index} ${this.connection.localPlayers[playerIndex].netId}`);
        break;
      case "combineWeapon":
        this.interactingWeapon = -1;
        this.sendMessage(`/combineweapon ${context.$index} ${this.connection.localPlayers[playerIndex].netId}`);
        break;
      case "selectWeapon":
        this.interactingWeapon = context.$index;
        this.renderUi();
        break;
      case "cancelWeaponSelect":
        this.interactingWeapon = -1;
        this.shownWeapon = "";
        this.renderUi();
        break;
      case "showWeapon":
        this.shownWeapon = `${context.item}_${context.$index}`;
        this.renderUi();
        break;
      case "showItem":
        this.shownItem = context.item;
        this.renderUi();
        break;
      case "hideItem":
        this.shownItem = "";
        this.shownWeapon = "";
        this.renderUi();
        break;
      case "ready":
        const hasTwintato = this.connection.localPlayers[0].config!.inventory["shopitem::twintato"];

        if (hasTwintato) {
          const parentCharacter = this.findByTag("Character::", this.connection.localPlayers[0].netId)[0]!;

          for (let i = 1; i < this.connection.localPlayers.length; i++) {
            const character = this.findByTag("Character::", this.connection.localPlayers[i].netId)[0]!;
            const startingWeapons = Object.keys(this.connection.localPlayers[i].config!.inventory)
              .find((item) => {
                return item.startsWith("StartingWeapons::");
              })!
              .substring("StartingWeapons::".length)
              .split(",");

            const startingWeaponMods = startingWeapons!.map((_, index) => {
              return this.connection.localPlayers[i].config!.weaponMods[index];
            });

            const modifedConfig = {
              ...cloneDeep(this.connection.localPlayers[0].config!),
            };
            for (let i = 0; i < startingWeapons.length; i++) {
              modifedConfig.weapons.unshift(startingWeapons[i]);
              modifedConfig.weaponMods.unshift(startingWeaponMods[i]);
            }
            delete modifedConfig.inventory[`shopitem::${parentCharacter}`];
            modifedConfig.inventory[`shopitem::${character}`] = {
              amount: 1,
            };

            this.connection.updatePlayerConnect(
              {
                config: {
                  ...modifedConfig,
                  readyState: PlayerReadyState.Ready,
                },
              },
              i
            );
          }
        }

        this.connection.updatePlayerConnect(
          {
            config: {
              ...this.connection.localPlayers[playerIndex].config!,
              readyState:
                this.connection.localPlayers[playerIndex].config!.readyState === PlayerReadyState.Ready
                  ? PlayerReadyState.NotReady
                  : PlayerReadyState.Ready,
            },
          },
          playerIndex
        );
        break;
    }
  };

  canBuyItem = (playerId: string, index: number) => {
    const ef = EntityFactory.getInstance();
    const item = this.playerShopItems[playerId]![index];
    const shopInfo = ef.getComponentFromEntity(item.id, "Shop")! as unknown as ShopSchema;
    const playerWeapons = this.players[playerId]!.weapons;
    const matchingWeapons = playerWeapons.filter((w) => w.toLowerCase() === item.id.toLowerCase());
    const playerData = this.players[playerId]!;
    const mods = this.getPlayerRollMods(playerId);

    if (!!shopInfo.tags.find((t) => t.startsWith("WeaponType::"))) {
      if (
        mods.weaponFilter?.find(
          (mod) => mod.startsWith("WeaponType::Range::Limit::") || mod.startsWith("WeaponType::Melee::Limit::")
        )
      ) {
        const meleeCount = playerWeapons.filter((w) => {
          const weaponType = this.baseWeaponTagMap.get(w.toLowerCase())?.weaponType;
          return weaponType === "Melee";
        }).length;
        const rangeCount = playerWeapons.length - meleeCount;

        for (const mod of mods.weaponFilter) {
          if (
            mod.startsWith("WeaponType::Range::Limit::") &&
            shopInfo.tags.find((t) => t.startsWith("WeaponType::Range"))
          ) {
            const limit = parseInt(mod.split("::")[3]);
            if (rangeCount >= limit) {
              return false;
            }
          } else if (
            mod.startsWith("WeaponType::Melee::Limit::") &&
            shopInfo.tags.find((t) => t.startsWith("WeaponType::Melee"))
          ) {
            const limit = parseInt(mod.split("::")[3]);
            if (meleeCount >= limit) {
              return false;
            }
          }
        }
      }
    }

    const canCombine = !!(matchingWeapons.length >= 1 && shopInfo.tags.find((t) => t.startsWith("Combine::")));
    return (
      item.price <= this.players[playerId]!.inventory.coins.amount &&
      (!ef.getComponentFromEntity(item.id, "WeaponType") ||
        this.getPlayerWeaponLimit(playerId) > playerData.weapons.length ||
        canCombine)
    );
  };

  sendMessage(message: string) {
    // console.log("sending message", message);
    this.connection.sendMessage(message, true);
  }

  statsPromise: Promise<void> = Promise.resolve();
  stateGameModel: GameModel | null = null;
  recalculateStats = (playerId: string) => {
    this.playerStats = this.calculateStats();
    if (this.connection.localPlayers.find((p) => p.netId === playerId)) {
      this.renderUi();
    }
  };

  calculateStats() {
    console.time("calculateStats");
    if (!this.stateGameModel) {
      this.stateGameModel = new GameModel(GameCoordinator.GetInstance());
      this.stateGameModel.localNetIds = Object.keys(this.players).sort();
      this.stateGameModel.instance = this.instance;
    }
    const gameModel = this.stateGameModel;

    let playerEntities: { [key: string]: number } = {};
    let playerCharacters: { [key: string]: number } = {};

    Object.entries(this.players).forEach(([playerId, playerState]) => {
      const playerConfig = this.players[playerId]!;
      const [player, playerCharacter] = generatePlayer(playerConfig, playerId, gameModel, {
        includeWeapons: false,
        additionalItems: ["SpecialShopItem"],
      });

      playerEntities[playerId] = player;
      playerCharacters[playerId] = playerCharacter;
    });

    gameModel.run();
    // run a second time to ensure all entities settled
    gameModel.run();

    const statsPerPlayer: { [key: string]: any } = {};

    Object.entries(this.players).forEach(([playerId, playerState]) => {
      const playerCharacter = playerCharacters[playerId];
      const playerEntity = playerEntities[playerId];

      const damageStats = gameModel.getTypedUnsafe(playerCharacter, DamageStatsSchema);
      const damageStatsScaler = gameModel.getTyped(playerCharacter, DamageStatsScalerSchema);
      const damageMitigation = gameModel.getTypedUnsafe(playerCharacter, DamageMitigationSchema);
      const harvesting = gameModel.getTypedUnsafe(playerEntity, HarvestingSchema);
      const harvestingEnhancer = gameModel.getTyped(playerCharacter, HarvestingEnhancerSchema);

      let damageScale = damageStats.damageScale;
      if (damageStatsScaler?.damageScale) {
        damageScale -= damageStatsScaler.damageScale;
      }
      // remove the base damage scale
      damageScale -= 1;

      const harvestingAmount =
        harvesting.harvesting +
        (harvestingEnhancer?.harvesting ?? 0) * (1 + (harvestingEnhancer?.harvestingPercentScale ?? 0));

      const lifesteal = gameModel.getTyped(playerCharacter, LifestealSchema)?.lifesteal ?? 0;
      const lifestealEnhancer = gameModel.getTyped(playerCharacter, LifestealEnhancerSchema);

      const lifestealAmount = lifesteal + (lifestealEnhancer?.lifesteal ?? 0);

      const cappedHealth = getMaxHealth(playerCharacter, gameModel);
      const maxHealth = gameModel.getTypedUnsafe(playerCharacter, HealthSchema)?.maxHealth ?? 0;

      const speed = (gameModel.getTyped(playerCharacter, SpeedEnhancerSchema)?.speedScale ?? 0) * 100;
      const cappedSpeed = (gameModel.getTyped(playerCharacter, SpeedCapSchema)?.speedCap ?? speed / 100) * 100;

      const stats = {
        maxHealth: cappedHealth === maxHealth ? maxHealth : `${Math.round(maxHealth)} | ${Math.round(cappedHealth)}`,
        hpRegen: gameModel.getTypedUnsafe(playerCharacter, HpRegenSchema)?.regen,
        lifesteal: lifestealAmount,
        damage: damageScale,
        meleeDamage:
          Math.ceil((damageStats.minMeleeDamage + damageStats.maxMeleeDamage) / 2) * damageStats.meleeDamageScale,
        rangedDamage:
          Math.ceil((damageStats.minRangedDamage + damageStats.maxRangedDamage) / 2) * damageStats.rangedDamageScale,
        elementalDamage:
          Math.ceil((damageStats.minElementalDamage + damageStats.maxElementalDamage) / 2) *
          damageStats.elementalDamageScale,
        attackSpeed: damageStats.attackSpeedScale,
        critChance: damageStats.critChance,
        engineering:
          Math.ceil((damageStats.minAllyDamage + damageStats.maxAllyDamage) / 2) * damageStats.allyDamageScale,
        range: damageStats.range,
        armor: damageMitigation.defense,
        dodge:
          damageMitigation.dodgeChance >= damageMitigation.maxDodgeChance
            ? `${Math.round(damageMitigation.dodgeChance)} | ${Math.round(damageMitigation.maxDodgeChance)}`
            : damageMitigation.dodgeChance,
        speed: speed === cappedSpeed ? speed : `${Math.round(speed)} | ${Math.round(cappedSpeed)}`,
        luck: damageStats.chance,
        harvesting: harvestingAmount,
      };
      statsPerPlayer[playerId] = stats;

      const persistedComponents: ComponentData[] = [];
      const persistedPlayerComponents = gameModel.getComponentIdsByCategory(playerCharacter, ComponentCategory.PERSIST);
      for (const componentId of persistedPlayerComponents) {
        const ejectedComponent = gameModel.ejectComponent(playerCharacter, componentId);
        persistedComponents.push(ejectedComponent);
      }
      playerState.persistedComponents = persistedComponents;
    });

    Object.entries(playerEntities).forEach(([playerId, player]) => {
      gameModel.removeEntity(player, true);
    });

    gameModel.run();
    console.timeEnd("calculateStats");

    return statsPerPlayer;
  }

  generateContext = (playerIndex: number) => {
    const player = this.connection.localPlayers[playerIndex].netId;
    const playerCoins = this.players[player]!.inventory.coins.amount;

    const recycleContext = this.generateRecycleContext(playerIndex);

    const partialContext = {
      playerCoins: playerCoins ?? 0,
      wave: this.wave,
      readyLabel: this.players[player]?.readyState === PlayerReadyState.Ready ? "Unready" : "Ready",
      ...recycleContext,

      ...this.generateLevelUpContext(playerIndex, recycleContext),
      ...this.generateUserItemContext(playerIndex),
      ...this.generateUserWeaponContext(playerIndex),
      ...this.generateStatsContext(playerIndex),
    };

    console.log(partialContext);

    return {
      ...partialContext,
      ...this.generateShopItemsContext(playerIndex, partialContext),
    };
  };

  generateRecycleContext = (playerIndex: number) => {
    if (this.playerRecycleableItems[this.connection.localPlayers[playerIndex].netId]?.length) {
      return {
        showRecycleDisplay: true,
        recycleItem: {
          ...itemInfoContext(this.playerRecycleableItems[this.connection.localPlayers[playerIndex].netId][0].id),
          value: this.generateSellPrice(
            this.playerRecycleableItems[this.connection.localPlayers[playerIndex].netId][0].price,
            this.connection.localPlayers[playerIndex].netId
          ),
        },
      };
    }
    return {
      showRecycleDisplay: false,
    };
  };

  generateStatsContext = (playerIndex: number) => {
    const statLabels = {
      maxHealth: "💗 Max HP",
      hpRegen: "💖 HP Regen",
      lifesteal: "🩸 Lifesteal",
      damage: "👊 Damage",
      meleeDamage: "🗡️ Melee Damage",
      rangedDamage: "🏹 Ranged Damage",
      elementalDamage: "🔥 Elemental Damage",
      attackSpeed: "⌛ % Attack Speed",
      critChance: "💥 % Crit Chance",
      engineering: "🛠️ Engineering",
      range: "🎯 Range",
      armor: "🛡️ Armor",
      dodge: "🤺 % Dodge",
      speed: "🥾 Speed",
      luck: "🍀 Luck",
      harvesting: "🌾 Harvesting",
    };

    return {
      statsZIndex: this.interactingWeapon === -1 && !this.shownWeapon ? "5" : "3",
      stats: Object.entries(this.playerStats[this.connection.localPlayers[playerIndex].netId]!)
        .map(([key, value]) => {
          if (!statLabels[key as keyof typeof statLabels]) {
            return null;
          }
          if (key === "damage" || key === "attackSpeed" || key === "dodgeChance") {
            value = Math.round(value * 100);
          }
          return {
            label: statLabels[key as keyof typeof statLabels],
            value: typeof value === "number" ? Math.round(value) : value,
            valueColor: value > 0 ? "#00FF00" : value < 0 ? "#FF0000" : "white",
          };
        })
        .filter((x) => !!x),
    };
  };

  generateLevelUpContext = (playerIndex: number, recycleContext: { showRecycleDisplay: boolean }) => {
    const player = this.connection.localPlayers[playerIndex].netId;

    return {
      levelUpRerollPrice: this.calculateUpgradeRollPrice(player),
      levelUps: this.playerLevelUps[player]?.map((item) => itemInfoContext(item)),
      showLevelUps: !recycleContext.showRecycleDisplay && !!this.playerLevelUps[player],
    };
  };

  generateShopItemsContext = (playerIndex: number, context: any) => {
    const player = this.connection.localPlayers[playerIndex].netId;

    return {
      shopRerollPrice: this.calculateRollPrice(player),
      shopItems: this.playerShopItems[player]?.map((item, index) => {
        if (item.id === "__PURCHASED__") {
          return {
            item: "",
            label: "",
            image: "",
            description: "",
            buyLabel: "Purchased",
            lockLabel: "",
            visible: false,
            autoFocus: false,
          };
        }
        return {
          ...itemInfoContext(item.id),
          buyLabel: item.price.toString(),
          lockLabel: this.players[player]?.lockedItems[index] !== undefined ? "Unlock" : "Lock",
          opacity: 1,
          visible: true,
          autoFocus: !context.showLevelUps && !context.showRecycleDisplay,
        };
      }),
      showShopItems: !!this.playerShopItems[player],
    };
  };

  generateUserItemContext = (playerIndex: number) => {
    const player = this.connection.localPlayers[playerIndex].netId;
    const ef = EntityFactory.getInstance();

    const playerInventory = this.players[player]!.inventory;

    const shopItems = Object.keys(playerInventory)
      .filter((item) => item.startsWith("shopitem::"))
      .map((item) => {
        item = item.split("::")[1];
        const shopInfo = ef.getComponentFromEntity(item, "Shop")! as unknown as ShopSchema;
        if (!shopInfo || shopInfo?.tags.includes("Upgrade::") || playerInventory[item]?.amount <= 0) {
          return null;
        }
        return {
          item,
          shopInfo,
          count: playerInventory[`shopitem::${item}`].amount,
        };
      })
      .filter((x, index, items) => {
        if (!x) {
          return false;
        }
        if (x.item.endsWith("_consumed")) {
          const baseItem = x.item.replace("_consumed", "");
          const baseItemIndex = items.findIndex((i) => i?.item === baseItem);
          if (baseItemIndex !== -1) {
            items[baseItemIndex]!.count += x.count;
            return false;
          }
        }
        return true;
      }) as { item: string; count: number; shopInfo: ShopSchema }[];

    return {
      userItems: shopItems.map(({ item, count, shopInfo }, index) => {
        return {
          ...itemInfoContext(item),
          showDetails: this.shownItem === item,
          text: count > 1 ? `x${count}` : "",
        };
      }),
    };
  };

  generateUserWeaponContext = (playerIndex: number) => {
    const player = this.connection.localPlayers[playerIndex].netId;
    const ef = EntityFactory.getInstance();

    const playerWeapons = this.players[player]!.weapons;

    const weaponItems = playerWeapons
      .map((item) => {
        const shopInfo = ef.getComponentFromEntity(item, "Shop")! as unknown as ShopSchema;
        if (!shopInfo) {
          return null;
        }
        return {
          item,
          shopInfo,
        };
      })
      .filter((x) => !!x) as { item: string; shopInfo: ShopSchema }[];

    return {
      userWeaponCountLabel: `(${playerWeapons.length}/${this.getPlayerWeaponLimit(player)})`,
      userWeapons: weaponItems.map(({ item, shopInfo }, index) => {
        const matchingWeapons = playerWeapons.filter((w) => w.toLowerCase() === item.toLowerCase());
        return {
          ...itemInfoContext(item),
          pointerEvents: this.interactingWeapon === index ? "auto" : "none",
          showDetails: this.interactingWeapon === index || this.shownWeapon === `${item}_${index}`,
          zIndex: this.interactingWeapon === index ? "5" : "3",
          weaponInteracting: this.interactingWeapon === index,
          weaponInteractingPlayerIndex: this.interactingWeapon === index ? 0 : -1,
          canCombine: this.canCombineWeapon(player, index),
          recycleValue: this.generateSellPrice(shopInfo.basePrice, player),
        };
      }),
    };
  };

  canCombineWeapon = (playerId: string, index: number) => {
    const ef = EntityFactory.getInstance();
    const playerWeapons = this.players[playerId]!.weapons;
    const weaponItems = playerWeapons
      .map((item) => {
        const shopInfo = ef.getComponentFromEntity(item, "Shop")! as unknown as ShopSchema;
        if (!shopInfo) {
          return null;
        }
        return {
          item,
          shopInfo,
        };
      })
      .filter((x) => !!x) as { item: string; shopInfo: ShopSchema }[];

    const { item, shopInfo } = weaponItems[index];

    const matchingWeapons = playerWeapons.filter((w) => w.toLowerCase() === item.toLowerCase());
    const hasMatch = !!(matchingWeapons.length > 1 && shopInfo.tags.find((t) => t.startsWith("Combine::")));
    if (!hasMatch) {
      return false;
    }

    const mods = this.getPlayerRollMods(playerId);
    const tierMods = hasMatch ? [...mods.filter, ...mods.weaponFilter] : [];

    if (!tierMods) {
      return true;
    }

    const nextTier = parseInt(shopInfo.tags.find((t) => t.startsWith("Tier::"))?.split("::")[1] ?? "1") + 1;

    if (tierMods.includes(`Tier::${nextTier}`)) {
      return false;
    }

    return hasMatch;
  };

  calculateRollPrice = (playerId: string) => {
    if (this.playerShopRerollCount[playerId] <= 0) {
      return 0;
    }
    return Math.max(Math.floor(0.5 * this.wave), 1) * this.playerShopRerollCount[playerId];
  };

  calculateUpgradeRollPrice = (playerId: string) => {
    return Math.max(Math.floor(0.5 * this.wave), 1) * this.playerUpgradeRerollCount[playerId];
  };

  generateRoundPrice(price: number) {
    return Math.ceil(price + this.wave + price * 0.1 * this.wave);
  }

  calculateDiscountedPrice(price: number, playerId: string, isWeapon: boolean) {
    const mod = isWeapon ? "WeaponPriceModifier::" : "ItemPriceModifier::";
    const shopModifiers = this.findTag(mod, playerId)
      .map((tag) => parseInt(tag.split("::")[1]) || 0)
      .reduce((acc, x) => acc + x, 0);
    return Math.ceil(price * (1 + shopModifiers / 100));
  }

  generateBuyPrice(price: number, playerId: string, isWeapon: boolean) {
    return Math.ceil(this.calculateDiscountedPrice(this.generateRoundPrice(price), playerId, isWeapon));
  }

  calculateSellDiscount(price: number, playerId: string) {
    const sellModifiers = this.findTag("SellPriceModifier::", playerId)
      .map((tag) => parseInt(tag.split("::")[1]) || 0)
      .reduce((acc, x) => acc + x, 0);
    return Math.ceil(price * (1 + sellModifiers / 100));
  }

  generateSellPrice(price: number, playerId: string) {
    return Math.ceil(this.calculateSellDiscount(this.generateRoundPrice(price), playerId) / 4);
  }

  levelUpTier = (playerId: string, rand: Random, playerChance: number) => {
    const player = this.players[playerId]!;
    const nextPlayerLevel = (player.level + 1).toString();
    const specialLevels: { [key: string]: number } = {
      1: 1,
      5: 2,
      10: 3,
      15: 3,
      20: 3,
      25: 4,
    };

    if (specialLevels[nextPlayerLevel]) {
      return specialLevels[nextPlayerLevel];
    }

    const chanceMap = {
      1: {
        minLevel: 1,
        base: 1,
        chancePerLevel: 0,
        max: 1,
      },
      2: {
        minLevel: 1,
        base: 0,
        chancePerLevel: 0.06,
        max: 0.6,
      },
      3: {
        minLevel: 3,
        base: 0,
        chancePerLevel: 0.02,
        max: 0.25,
      },
      4: {
        minLevel: 7,
        base: 0,
        chancePerLevel: 0.023,
        max: 0.08,
      },
    };
    const check = (chanceObj: { minLevel: number; base: number; chancePerLevel: number; max: number }) => {
      // = ((Chance per Level * (Current Level - Min Level)) + Base Chance) * (1+Luck/100)
      const chance =
        (chanceObj.chancePerLevel * (player.level - chanceObj.minLevel) + chanceObj.base) * (1 + playerChance / 100);
      return rand.number() <= Math.min(chance, chanceObj.max);
    };

    if (check(chanceMap[4])) {
      return 4;
    }
    if (check(chanceMap[3])) {
      return 3;
    }
    if (check(chanceMap[2])) {
      return 2;
    }
    return 1;
  };

  rollLevelup = (playerId: string, reroll = false) => {
    const rand = this.playerRand[playerId];
    const ef = EntityFactory.getInstance();
    const player = this.players[playerId]!;

    const playerInventory = player.inventory;
    if (reroll || (playerInventory.levelUps?.amount && !this.playerLevelUps[playerId])) {
      if (reroll) {
        playerInventory.coins.amount -= this.calculateUpgradeRollPrice(playerId);
      } else {
        console.warn("remove level up?");
        playerInventory.levelUps.amount--;
      }
      this.playerUpgradeRerollCount[playerId] = this.playerUpgradeRerollCount[playerId] ?? 0;
      this.playerUpgradeRerollCount[playerId]++;

      const levelUps = ef.findEntitiesWithComponent(["LevelUpType", "Shop"]);
      const levelTiers = levelUps.reduce((acc, levelUp) => {
        const levelUpShopInfo = ef.getComponentFromEntity(levelUp, "Shop")! as unknown as ShopSchema;
        const tier = levelUpShopInfo.tags.find((t) => t.startsWith("Tier::"))?.split("::")[1] ?? "1";
        acc[tier] = acc[tier] ?? [];
        acc[tier].push(levelUp);
        return acc;
      }, {} as { [key: string]: string[] });

      const selectedLevelUps = [];
      for (let i = 0; i < 4; ++i) {
        const tier = this.levelUpTier(playerId, rand, this.playerStats[playerId]?.luck ?? 0).toString();
        const index = rand.int(0, levelTiers[tier].length - 1);
        const itemName = levelTiers[tier][index];
        const baseItem = itemName.replace(/\d+$/, "");
        selectedLevelUps.push(itemName);
        Object.entries(levelTiers).forEach(([key, value]) => {
          levelTiers[key] = value.filter((item) => item.replace(/\d+$/, "") !== baseItem);
        });
      }

      this.playerLevelUps[playerId] = selectedLevelUps;
    }
    this.renderUi();
  };

  handleCrates = (playerId: string) => {
    const player = this.players[playerId]!;
    const playerInventory = player.inventory;
    if (playerInventory.crates?.amount) {
      const mods = this.getPlayerRollMods(playerId);
      const rand = this.playerRand[playerId];

      const items = this.rollItem(playerInventory.crates.amount, playerId, mods, rand);
      delete playerInventory.crates;

      this.playerRecycleableItems[playerId] = items;
    }
  };

  unsubPlayerConnect: () => void;

  recycleWeapon(playerId: string, index: number) {
    const player = this.players[playerId]!;
    const ef = EntityFactory.getInstance();
    const weapon = player.weapons[index];
    const shopInfo = ef.getComponentFromEntity(weapon, "Shop")! as unknown as ShopSchema;
    const price = this.generateSellPrice(shopInfo.basePrice, playerId);

    player.inventory.coins.amount += price;

    player.inventory.soldWeapons = player.inventory.soldWeapons ?? {
      amount: 0,
    };
    player.inventory.soldWeapons.amount += 1;

    player.weapons.splice(index, 1);
    player.weaponMods.splice(index, 1);

    this.connection.updatePlayerConnect(
      {
        config: player,
      },
      playerId
    );
    this.recalculateStats(playerId);
  }

  recycleItem(playerId: string, index: number) {
    const player = this.players[playerId]!;
    const item = this.playerRecycleableItems[playerId][index];

    player.inventory.coins.amount += this.generateSellPrice(item.price, playerId);
    this.playerRecycleableItems[playerId].splice(index, 1);

    this.connection.updatePlayerConnect(
      {
        config: player,
      },
      playerId
    );
    this.recalculateStats(playerId);
  }

  keepItem(playerId: string, index: number) {
    const player = this.players[playerId]!;
    const item = this.playerRecycleableItems[playerId].splice(index, 1)[0];

    const shopKey = `shopitem::${item.id.toLowerCase()}`;
    player.inventory[shopKey] = player.inventory[shopKey] ?? {
      amount: 0,
    };
    player.inventory[shopKey].amount += 1;
    this.connection.updatePlayerConnect(
      {
        config: player,
      },
      playerId
    );
    this.recalculateStats(playerId);
  }

  combineWeapon(playerId: string, index: number) {
    const player = this.players[playerId]!;
    const ef = EntityFactory.getInstance();
    const weapon = player.weapons[index];
    const shopInfo = ef.getComponentFromEntity(weapon, "Shop")! as unknown as ShopSchema;

    const combineTag = shopInfo.tags.find((t) => t.startsWith("Combine::"))?.split("::")[1];

    let weaponToCombine = player.weapons.findIndex(
      (w, ind) => index !== ind && w.toLowerCase() === weapon.toLowerCase()
    )!;
    if (weaponToCombine !== -1 && combineTag) {
      if (weaponToCombine < index) {
        [index, weaponToCombine] = [weaponToCombine, index];
      }
      player.weapons.splice(weaponToCombine, 1);
      player.weapons.splice(index, 1, combineTag);
    }
    this.connection.updatePlayerConnect(
      {
        config: player,
      },
      playerId
    );
    this.recalculateStats(playerId);
  }

  selectUpgrade(playerId: string, index: number) {
    const player = this.players[playerId]!;
    const ef = EntityFactory.getInstance();
    const playerInventory = player.inventory;

    const levelUp = this.playerLevelUps[playerId]![index];

    player.level += 1;

    playerInventory[`shopitem::${levelUp}`] = playerInventory[`shopitem::${levelUp}`] ?? {
      amount: 0,
    };
    playerInventory[`shopitem::${levelUp}`].amount += 1;

    delete this.playerLevelUps[playerId];
    if (playerInventory.levelUps.amount !== 0 && this.connection.localPlayers.find((p) => p.netId === playerId)) {
      this.sendMessage(`/rerollupgrades ${playerId} false`);
    }

    this.connection.updatePlayerConnect(
      {
        config: player,
      },
      playerId
    );
    this.recalculateStats(playerId);
  }

  buyItem(playerId: string, index: number) {
    const player = this.players[playerId]!;
    const ef = EntityFactory.getInstance();
    const item = this.playerShopItems[playerId][index];
    const shopInfo = ef.getComponentFromEntity(item.id, "Shop")! as unknown as ShopSchema;
    const playerInventory = player.inventory;
    const playerWeapons = player.weapons;

    if (ef.getComponentFromEntity(item.id, "WeaponType")) {
      playerWeapons.push(item.id);
      player.weaponMods.push([]);
      if (this.getPlayerWeaponLimit(playerId) < playerWeapons.length) {
        this.combineWeapon(playerId, playerWeapons.length - 1);
      }
    } else if (ef.getComponentFromEntity(item.id, "ItemType")) {
      playerInventory[`shopitem::${item.id}`] = playerInventory[`shopitem::${item.id}`] ?? {
        amount: 0,
      };
      playerInventory[`shopitem::${item.id}`].amount += 1;
    }

    playerInventory.coins.amount -= item.price;

    this.playerShopItems[playerId][index] = { id: "__PURCHASED__", price: 0, roundPrice: 0 };

    if (this.hasTag("WeaponPriceModifier::", item.id) || this.hasTag("ItemPriceModifier::", item.id)) {
      for (let i = 0; i < 4; ++i) {
        if (this.playerShopItems[playerId][i].id !== "__PURCHASED__") {
          const isLocked = player.lockedItems[i];
          const isWeapon = ef.getComponentFromEntity(this.playerShopItems[playerId][i].id, "WeaponType") ? true : false;
          this.playerShopItems[playerId][i].price = this.calculateDiscountedPrice(
            this.playerShopItems[playerId][i].roundPrice,
            playerId,
            isWeapon
          );
        }
      }
    }
    if (player.lockedItems[index]) {
      player.lockedItems[index] = undefined;
    }
    this.connection.updatePlayerConnect(
      {
        config: player,
      },
      playerId
    );
    this.recalculateStats(playerId);
  }

  lockItem(playerId: string, index: number) {
    const player = this.players[playerId]!;
    if (player.lockedItems[index]) {
      player.lockedItems[index] = undefined;
    } else {
      player.lockedItems[index] = this.playerShopItems[playerId][index];
    }
    this.connection.updatePlayerConnect(
      {
        config: player,
      },
      playerId
    );
  }

  tierRoll(wave: number, rand: Random, playerChance: number, tierFilter: string[] = []) {
    const tierRoll = rand.number();
    let tier = 1;

    const tier4Chance = {
      waveChance: 0.0023,
      minWave: 8,
      maxChance: 0.08,
    };

    const tier3Chance = {
      waveChance: 0.02,
      minWave: 4,
      maxChance: 0.25,
    };

    const tier2Chance = {
      waveChance: 0.05,
      minWave: 2,
      maxChance: 0.5,
    };

    if (
      tierRoll <
      Math.min(tier4Chance.waveChance * (wave - tier4Chance.minWave) * (1 + playerChance), tier4Chance.maxChance)
    ) {
      tier = 4;
    } else if (
      tierRoll <
      Math.min(tier3Chance.waveChance * (wave - tier3Chance.minWave) * (1 + playerChance), tier3Chance.maxChance)
    ) {
      tier = 3;
    } else if (
      tierRoll <
      Math.min(tier2Chance.waveChance * (wave - tier2Chance.minWave) * (1 + playerChance), tier2Chance.maxChance)
    ) {
      tier = 2;
    }

    while (tierFilter.includes(`Tier::${tier}`) && tier > 0) {
      tier = tier - 1;
    }
    if (tier === 0) {
      tier = 2;
      while (tierFilter.includes(`Tier::${tier}`) && tier < 5) {
        tier = tier + 1;
      }
    }

    return tier;
  }

  getPlayerWeaponLimit = (playerId: string) => {
    const weaponLimitTags = this.findTag("WeaponLimit::", playerId);
    const smallestLimit = weaponLimitTags.reduce((acc, tag) => {
      const limit = parseInt(tag.split("::")[1]);
      if (isNaN(limit)) {
        return acc;
      }
      return Math.min(acc, limit);
    }, Infinity);
    return smallestLimit === Infinity ? 6 : smallestLimit;
  };

  getPlayerRollMods = (playerId: string) => {
    const player = this.players[playerId]!;
    const ef = EntityFactory.getInstance();

    const mods: RollMods = {
      filter: [],
      weaponFilter: [],
      itemFilter: [],
      alwaysSell: [],
    };

    Object.keys(player.inventory ?? {}).forEach((itemKey) => {
      if (itemKey.startsWith("shopitem::")) {
        const id = itemKey.split("::")[1];
        const tags = ef.getComponentFromEntity(id, "Shop")?.tags ?? [];
        const filters = tags
          .filter((tag: string) => tag.startsWith("Filter::"))
          .map((tag: string) => {
            const tagParts = tag.split("::");
            tagParts.shift();
            if (tagParts[0] === "WeaponFilter") {
              tagParts.shift();
              mods.weaponFilter.push(tagParts.join("::"));
              return null;
            }
            if (tagParts[0] === "ItemFilter") {
              tagParts.shift();
              mods.itemFilter.push(tagParts.join("::"));
              return null;
            }

            return tagParts.join("::");
          })
          .filter((x: any) => !!x) as string[];
        const alwaysSell = tags
          .filter((tag: string) => tag.startsWith("AlwaysSell::"))
          .map((tag: string) => {
            const tagParts = tag.split("::");
            tagParts.shift();
            return tagParts.join("::");
          });
        mods.filter.push(...filters);
        mods.alwaysSell.push(...alwaysSell);
      }
    });
    return mods;
  };

  baseWeaponTagMap = new Map<string, Omit<WeaponTag, "price" | "roundPrice">>();

  generateBaseWeaponTagMap = () => {
    const ef = EntityFactory.getInstance();

    const weaponIds = ef.findEntitiesWithComponent(["WeaponType", "Shop"]);
    weaponIds.forEach((weaponId) => {
      const weaponTags = ef.getComponentFromEntity(weaponId, "Shop")!;
      if (weaponTags) {
        const itemTier = parseInt(
          (weaponTags.tags.find((tag: string) => tag.startsWith("Tier::")) ?? "Tier::1").split("::")[1]
        );

        const itemTags = weaponTags.tags.filter((tag: string) => tag.startsWith("Tag::")) as string[];

        const weaponTag: Omit<WeaponTag, "price" | "roundPrice"> = {
          id: weaponId,
          baseId: weaponId.replace(itemTier.toString(), ""),
          tier: itemTier,
          itemTags,
          type: "weapon",
          weaponType: weaponTags.tags.find((tag: string) => tag.startsWith("WeaponType::"))?.split("::")[1] ?? "NONE",
        };

        this.baseWeaponTagMap.set(weaponId, weaponTag);
      }
    });
  };

  rollWeapon(weaponCount: number, playerId: string, mods: RollMods, rand: Random) {
    const possibleWeapons: ShopDetails[] = [];
    const ef = EntityFactory.getInstance();
    const player = this.players[playerId]!;

    const weaponIds = ef.findEntitiesWithComponent(["WeaponType", "Shop"]);
    const itemIds = ef.findEntitiesWithComponent(["ItemType", "Shop"]);

    if (weaponCount) {
      const weaponClasses = new Set<string>();

      const weaponsByTag = new Map<string, string[]>();
      const weaponsByTier = new Map<number, string[]>();
      const weaponTagMap = new Map<string, WeaponTag>();

      weaponIds.forEach((weaponId) => {
        const weaponTags = ef.getComponentFromEntity(weaponId, "Shop")!;
        if (this.baseWeaponTagMap.has(weaponId)) {
          const weaponTag: WeaponTag = {
            ...this.baseWeaponTagMap.get(weaponId)!,
            price: this.generateBuyPrice(weaponTags.basePrice, playerId, true),
            roundPrice: this.generateRoundPrice(weaponTags.basePrice),
          };
          const itemTags = weaponTag.itemTags;
          const itemTier = weaponTag.tier;

          if (mods.filter.length && weaponTags.tags.some((t: string) => mods.filter.includes(t))) {
            return;
          }
          if (mods.weaponFilter.length) {
            for (let i = 0; i < mods.weaponFilter.length; ++i) {
              if (weaponTags.tags.some((t: string) => mods.weaponFilter[i] === t)) {
                return;
              }
            }
          }
          if (mods.weaponFilter.length && weaponTags.tags.some((t: string) => mods.weaponFilter.includes(t))) {
            return;
          }

          weaponTagMap.set(weaponId, weaponTag);

          itemTags.forEach((c) => {
            if (!weaponsByTag.has(c)) {
              weaponsByTag.set(c, []);
            }
            weaponsByTag.get(c)!.push(weaponId);
          });
          if (!weaponsByTier.has(itemTier)) {
            weaponsByTier.set(itemTier, []);
          }
          weaponsByTier.get(itemTier)!.push(weaponId);
        }
      });

      const sameClassPool: string[] = [];

      const sameWeaponPool = Array.from(
        new Set(
          player.weapons
            .map((w) => {
              const weapons: string[] = [];
              const baseId = weaponTagMap.get(w)?.baseId;
              if (weaponTagMap.has(baseId!)) {
                weapons.push(baseId!);
              }
              for (let i = 2; i <= 4; ++i) {
                if (weaponTagMap.has(`${baseId}${i}`)) {
                  weapons.push(`${baseId}${i}`);
                }
              }
              return weapons;
            })
            .flat()
        )
      );

      sameWeaponPool.forEach((weaponId) => {
        const weaponTags = ef.getComponentFromEntity(weaponId, "Shop")!;
        if (weaponTags) {
          const classes = weaponTags.tags.filter((tag: string) => tag.startsWith("Tag::")) as string[];
          classes.forEach((c) => weaponClasses.add(c));
        }
      });

      weaponClasses.forEach((c) => {
        const weapons = weaponsByTag.get(c);
        if (weapons) {
          sameClassPool.push(...weapons);
        }
      });

      for (let i = 0; i < weaponCount; ++i) {
        const tier = this.tierRoll(this.wave, rand, this.playerStats[playerId]?.luck ?? 0, [
          ...mods.filter.filter((mod) => mod.startsWith("Tier::")),
          ...mods.weaponFilter.filter((mod) => mod.startsWith("Tier::")),
        ]);

        console.log(mods.weaponFilter, tier);

        let tieredAllWeaponsPool: string[] = weaponIds.filter((w) => {
          return weaponTagMap.get(w)?.tier === tier;
        });

        let tieredSameWeaponPool = sameWeaponPool.filter((w) => {
          return weaponTagMap.get(w)?.tier === tier;
        });

        let tieredSameClassPool = sameClassPool.filter((w) => {
          return weaponTagMap.get(w)?.tier === tier;
        });

        const randValue = rand.number();
        let pool: string[] = [];
        if (randValue < 0.2 && tieredSameClassPool.length) {
          pool = tieredSameClassPool;
        } else if (randValue < 0.35 && tieredSameWeaponPool.length) {
          pool = tieredSameWeaponPool;
        } else {
          pool = tieredAllWeaponsPool;
        }
        const weaponId = pool[rand.int(0, pool.length - 1)];
        possibleWeapons.push({
          id: weaponId,
          price: weaponTagMap.get(weaponId)!.price,
          roundPrice: weaponTagMap.get(weaponId)!.roundPrice,
        });
        if (tieredSameClassPool.includes(weaponId)) {
          sameClassPool.splice(sameClassPool.indexOf(weaponId), 1);
        }
        if (tieredSameWeaponPool.includes(weaponId)) {
          sameWeaponPool.splice(sameWeaponPool.indexOf(weaponId), 1);
        }
        if (tieredAllWeaponsPool.includes(weaponId)) {
          tieredAllWeaponsPool.splice(tieredAllWeaponsPool.indexOf(weaponId), 1);
        }
      }
    }
    return possibleWeapons;
  }

  rollItem(itemCount: number, playerId: string, mods: RollMods, rand: Random) {
    const player = this.players[playerId]!;
    const ef = EntityFactory.getInstance();

    const playerItemTags: string[] = [];

    const itemIds = ef.findEntitiesWithComponent(["ItemType", "Shop"]);

    Object.keys(player.inventory ?? {}).forEach((itemKey) => {
      if (itemKey.startsWith("shopitem::")) {
        const id = itemKey.split("::")[1];
        const tags = ef.getComponentFromEntity(id, "Shop")?.tags ?? [];
        tags.forEach((tag: string) => {
          if (tag.startsWith("Tag::") && !playerItemTags.includes(tag)) {
            playerItemTags.push(tag);
          }
        });
      }
    });

    let possibleItems: ShopDetails[] = [];

    if (itemCount) {
      type ItemTag = Tag & {
        type: "item";
        itemLimit: number;
      };

      const itemsByTag = new Map<string, string[]>();
      const itemsByTier = new Map<number, string[]>();
      const itemTagMap = new Map<string, ItemTag>();

      const validItems: string[] = [];

      itemIds.forEach((itemId) => {
        const itemItemTags = ef.getComponentFromEntity(itemId, "Shop")!;
        if (itemItemTags) {
          const itemTier = parseInt(
            (itemItemTags.tags.find((tag: string) => tag.startsWith("Tier::")) ?? "Tier::1").split("::")[1]
          );
          const itemLimit =
            parseInt(itemItemTags.tags.find((tag: string) => tag.startsWith("Limit::"))?.split("::")[1]) ?? 9999;

          const itemTags = itemItemTags.tags.filter((tag: string) => tag.startsWith("Tag::")) as string[];

          const itemTag: ItemTag = {
            id: itemId,
            tier: itemTier,
            itemTags,
            type: "item",
            itemLimit,
            price: this.generateBuyPrice(itemItemTags.basePrice, playerId, false),
            roundPrice: this.generateRoundPrice(itemItemTags.basePrice),
          };

          if (
            mods.filter.length &&
            (itemItemTags.tags.some((t: string) => mods.filter.includes(t)) || mods.filter.includes(itemId))
          ) {
            return;
          }

          if ((player.inventory[`shopitem::${itemId}`]?.amount ?? 0) >= itemLimit) {
            return;
          }
          validItems.push(itemId);

          itemTagMap.set(itemId, itemTag);

          itemTags.forEach((c) => {
            if (!itemsByTag.has(c)) {
              itemsByTag.set(c, []);
            }
            itemsByTag.get(c)!.push(itemId);
          });
          if (!itemsByTier.has(itemTier)) {
            itemsByTier.set(itemTier, []);
          }
          itemsByTier.get(itemTier)!.push(itemId);
        }
      });

      const sameClassPool: string[] = [];

      playerItemTags.forEach((c) => {
        const items = itemsByTag.get(c);
        if (items) {
          sameClassPool.push(...items);
        }
      });

      const allItemsPool = validItems;

      for (let i = 0; i < itemCount; ++i) {
        const tier = this.tierRoll(this.wave, rand, this.playerStats[playerId]?.luck ?? 0);

        const tieredAllItemsPool = allItemsPool.filter((w) => {
          return itemTagMap.get(w)!.tier === tier;
        });

        const tieredSameClassPool = sameClassPool.filter((w) => {
          return itemTagMap.get(w)!.tier === tier;
        });

        const randValue = rand.number();
        let pool: string[] = [];
        if (randValue < 0.05 && tieredSameClassPool.length) {
          pool = tieredSameClassPool;
        } else if (tieredAllItemsPool.length) {
          pool = tieredAllItemsPool;
        }
        const itemId = pool.splice(rand.int(0, pool.length - 1), 1)[0];
        possibleItems.push({
          id: itemId,
          price: itemTagMap.get(itemId)!.price,
          roundPrice: itemTagMap.get(itemId)!.roundPrice,
        });
        if (allItemsPool.includes(itemId)) {
          allItemsPool.splice(allItemsPool.indexOf(itemId), 1);
        }
        if (sameClassPool.includes(itemId)) {
          sameClassPool.splice(sameClassPool.indexOf(itemId), 1);
        }
      }
    }

    return possibleItems;
  }

  rollShop(playerId: string) {
    const rand = this.playerRand[playerId];
    const player = this.players[playerId]!;
    const ef = EntityFactory.getInstance();

    const playerItemTags: string[] = [];
    const playerWeaponTags: string[] = [];

    player.inventory.coins.amount -= this.calculateRollPrice(playerId);
    this.playerShopRerollCount[playerId]++;

    Object.keys(player.inventory ?? {}).forEach((itemKey) => {
      if (itemKey.startsWith("shopitem::")) {
        const id = itemKey.split("::")[1];
        const tags = ef.getComponentFromEntity(id, "Shop")?.tags ?? [];
        tags.forEach((tag: string) => {
          if (tag.startsWith("Tag::") && !playerItemTags.includes(tag)) {
            playerItemTags.push(tag);
          }
        });
      }
    });

    // weaponIds.forEach((weapon) => {
    //   const tags = ef.getComponentFromEntity(weapon, "Tags");
    //   if (tags) {
    //     tags.tags.forEach((tag: string) => {
    //       if (tag.startsWith("Tag::")) {
    //         playerItemTags.push(tag);
    //       }
    //     });
    //   }
    // });

    let itemCount = 0;
    let weaponCount = 0;

    switch (this.wave) {
      case 1:
        itemCount = 2;
        weaponCount = 2;
        break;
      case 2:
        itemCount = 2;
        weaponCount = 2;
        break;
      case 3:
        itemCount = 3;
        weaponCount = 1;
        break;
      case 4:
        weaponCount = 1;
        for (let i = 0; i < 3; ++i) {
          if (rand.number() < 0.35) {
            weaponCount += 1;
          }
        }
        itemCount = 4 - weaponCount;
        break;
      default:
        for (let i = 0; i < 4; ++i) {
          if (rand.number() < 0.35) {
            weaponCount += 1;
          }
        }
        itemCount = 4 - weaponCount;
        break;
    }

    const weaponLimit = this.getPlayerWeaponLimit(playerId);
    if (weaponLimit === 0) {
      weaponCount = 0;
      itemCount = 4;
    }

    const possibleLockedItems: ShopDetails[] = [];

    const mods: RollMods = this.getPlayerRollMods(playerId);

    if (mods.alwaysSell.length) {
      for (let i = 0; i < mods.alwaysSell.length; ++i) {
        const item = mods.alwaysSell[i];

        if (item.startsWith("Weapon::")) {
          let extraWeapons = parseInt(item.split("::")[1]);
          if (weaponCount < extraWeapons) {
            weaponCount += extraWeapons - weaponCount;
            itemCount -= extraWeapons - weaponCount;
          }
          continue;
        }
        const shopInfo = ef.getComponentFromEntity(item, "Shop")! as unknown as ShopSchema;
        const weapon = ef.getComponentFromEntity(item, "WeaponType");
        if (weapon) {
          weaponCount -= 1;

          if (weaponCount < 0) {
            itemCount -= 1;
            weaponCount = 0;
          }
        } else {
          itemCount -= 1;

          if (itemCount < 0) {
            weaponCount -= 1;
            itemCount = 0;
          }
        }
        possibleLockedItems.push({ id: item, price: shopInfo.basePrice, roundPrice: shopInfo.basePrice });
      }
    }

    const lockedItems = player.lockedItems.filter((item) => !!item);
    for (let i = 0; i < lockedItems.length; ++i) {
      const item = lockedItems[i]!;
      const weapon = ef.getComponentFromEntity(item.id, "WeaponType");
      if (weapon) {
        weaponCount -= 1;

        if (weaponCount < 0) {
          itemCount -= 1;
          weaponCount = 0;
        }
      } else {
        itemCount -= 1;

        if (itemCount < 0) {
          weaponCount -= 1;
          itemCount = 0;
        }
      }
      possibleLockedItems.push(item);
    }
    player.lockedItems = lockedItems;

    const possibleWeapons = this.rollWeapon(weaponCount, playerId, mods, rand);

    const possibleItems = this.rollItem(itemCount, playerId, mods, rand);

    // if (possibleItems.length) {
    //   possibleItems.pop();
    //   possibleItems.push({ id: "recyclingmachine", price: 0, roundPrice: 0 });
    // }

    this.playerShopItems[playerId] = [...possibleLockedItems, ...possibleWeapons, ...possibleItems];

    console.warn(playerId, "rolled", ...this.playerShopItems[playerId].map((i) => i.id));

    this.connection.updatePlayerConnect(
      {
        config: player,
      },
      playerId
    );
  }

  handleMessage = (message: string, self: boolean) => {
    console.log("HANDLING MESSAGE", message, self);
    if (message.startsWith("/")) {
      const parts = message.substring(1).split(" ");
      switch (parts[0]) {
        case "reroll": {
          const [event, playerId] = parts;
          this.rollShop(playerId);
          break;
        }
        case "rerollupgrades": {
          const [event, playerId, reroll] = parts;
          this.rollLevelup(playerId, reroll === "true");
          break;
        }
        case "selectupgrade": {
          const [event, index, playerId] = parts;
          this.selectUpgrade(playerId, parseInt(index));
          break;
        }
        case "buyitem": {
          const [event, index, playerId] = parts;
          this.buyItem(playerId, parseInt(index));
          break;
        }
        case "recycleweapon": {
          const [event, index, playerId] = parts;
          this.recycleWeapon(playerId, parseInt(index));
          break;
        }
        case "recycleitem": {
          const [event, index, playerId] = parts;
          this.recycleItem(playerId, parseInt(index));
          break;
        }
        case "keepitem": {
          const [event, index, playerId] = parts;
          this.keepItem(playerId, parseInt(index));
          break;
        }
        case "combineweapon": {
          const [event, index, playerId] = parts;
          this.combineWeapon(playerId, parseInt(index));
          break;
        }
        case "lockitem": {
          const [event, index, playerId] = parts;
          this.lockItem(playerId, parseInt(index));
          break;
        }
        case "start":
          this.changeScene("ProjectVGame", {
            instance: this.instance,
            hosting: false,
            wave: this.connection.localPlayers[0].config!.wave,
          });
          break;
      }
      return;
    }
    const label = this.ui.chatBox.config.label + "\n" + (self ? "You: " + message : "Thm: " + message);
    if (label.split("\n").length > 3) {
      this.ui.chatBox.config.label = label.split("\n").slice(1).join("\n");
    } else {
      this.ui.chatBox.config.label = label;
    }
  };

  run = () => {};

  public destroy = (): void => {
    super.destroy();
    this.unsub();
    this.unsubPlayerConnect();
  };
}

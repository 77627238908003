import { registerSystem } from "yage/components/ComponentRegistry";
import { Component, defaultValue, type, Schema } from "yage/decorators/type";
import type { GameModel } from "yage/game/GameModel";
import type { System } from "yage/components/System";
import { PlayerTypeSchema, EnemyTypeSchema } from "yage/components/entity/Types";
import { ChildSchema } from "yage/schemas/entity/Child";
import { FlatMapSchema } from "../core/Map";

@Component("MapScaleEnhancer")
export class MapScaleEnhancerSchema extends Schema {
  @type("number")
  @defaultValue(1)
  scale: number;
}

class MapScaleEnhancerSystem implements System {
  type = "MapScaleEnhancer";
  schema = MapScaleEnhancerSchema;

  dependencies = ["Child"];

  init(entity: number, gameModel: GameModel) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      gameModel.getTypedUnsafe(gameModel.coreEntity, FlatMapSchema).scale *= gameModel.getTypedUnsafe(
        entity,
        MapScaleEnhancerSchema
      ).scale;
    }
  }

  cleanup(entity: number, gameModel: GameModel, ejecting: boolean) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      gameModel.getTypedUnsafe(gameModel.coreEntity, FlatMapSchema).scale /= gameModel.getTypedUnsafe(
        entity,
        MapScaleEnhancerSchema
      ).scale;
    }
  }
}

registerSystem(MapScaleEnhancerSystem);

import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { Component, defaultValue, Schema, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";
import { LocomotionSchema } from "yage/schemas/entity/Locomotion";
import { EnemyTypeSchema, PlayerTypeSchema } from "yage/components/entity/Types";
import { DamageStatsSchema, invalidateDamageStats } from "../weapons/DamageStats";
import { SpeedScalerSchema } from "../scalers/SpeedScaler";
import { SpeedCapSchema } from "../core/SpeedCap";

@Component("SpeedEnhancer")
export class SpeedEnhancerSchema extends Schema {
  @type("number")
  @defaultValue(0)
  speedScale: number;

  @type("number")
  @defaultValue(0)
  speed: number;
}

class SpeedEnhancerSystem implements System {
  schema = SpeedEnhancerSchema;
  type = "SpeedEnhancer";
  dependencies = ["Child", "SpeedScaler"];
  init(entity: number, gameModel: GameModel) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (!gameModel.hasComponent(parent, "SpeedEnhancer")) {
        gameModel.addComponent(parent, SpeedEnhancerSchema, {
          speedScale: 0.0,
        });
      }
      let scale = 1;
      if (gameModel.hasComponent(parent, "SpeedScaler")) {
        scale += gameModel.getTypedUnsafe(parent, SpeedScalerSchema).speedScale;
      }
      gameModel.getTypedUnsafe(parent, SpeedEnhancerSchema).speedScale +=
        gameModel.getTypedUnsafe(entity, SpeedEnhancerSchema).speedScale * scale;
      gameModel.getTypedUnsafe(parent, SpeedEnhancerSchema).speed += gameModel.getTypedUnsafe(
        entity,
        SpeedEnhancerSchema
      ).speed;

      if (gameModel.hasComponent(parent, "InvalidateDamageStatsOnSpeedChange")) {
        invalidateDamageStats(parent, gameModel);
      }
    }
  }

  cleanup(entity: number, gameModel: GameModel, ejecting: boolean) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema) &&
      gameModel.hasComponent(entity, SpeedEnhancerSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (gameModel.hasComponent(parent, "SpeedEnhancer")) {
        const data = gameModel.getTypedUnsafe(entity, SpeedEnhancerSchema);
        let scale = 1;
        if (gameModel.hasComponent(parent, "SpeedScaler")) {
          let speedScale = gameModel.getTypedUnsafe(parent, SpeedScalerSchema).speedScale;
          if (speedScale) {
            scale += speedScale;
          }
        }
        if (data.speedScale) {
          gameModel.getTypedUnsafe(parent, SpeedEnhancerSchema).speedScale -= data.speedScale * scale;
        }
        if (data.speed) {
          gameModel.getTypedUnsafe(parent, SpeedEnhancerSchema).speed -= data.speed;
        }
      }
    }
  }
}
registerSystem(SpeedEnhancerSystem);

export const getSpeed = (entity: number, gameModel: GameModel) => {
  LocomotionSchema.id = entity;
  let speed = LocomotionSchema.speed;
  if (gameModel.hasComponent(entity, SpeedEnhancerSchema)) {
    const data = gameModel.getTypedUnsafe(entity, SpeedEnhancerSchema);
    speed = Math.max((data.speed ?? 0) + speed, 0);

    const speedScale = Math.min(
      gameModel.getTyped(entity, SpeedCapSchema)?.speedCap ?? data.speedScale ?? 0,
      data.speedScale
    );
    speed *= 1 + speedScale;
    speed = Math.max(speed, 0);
  }
  return speed;
};

import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { ComponentDataSchema } from "yage/components/types";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { OwnerSchema } from "yage/schemas/core/Owner";
import { ChildSchema } from "yage/schemas/entity/Child";
import { LocomotionSchema } from "yage/schemas/entity/Locomotion";
import { HealthSchema, getMaxHealth } from "../core/Health";

@Component("ComponentsWhileLowHealth")
export class ComponentsWhileLowHealthSchema extends Schema {
  @type([ComponentDataSchema])
  @defaultValue([])
  lowHealthComponents: ComponentDataSchema[];

  @type([ComponentDataSchema])
  @defaultValue([])
  highHealthComponents: ComponentDataSchema[];

  @type("boolean")
  lowHealth: boolean;

  @type("boolean")
  @defaultValue(true)
  ownerLowHealth: boolean;
}

export class ComponentsWhileLowHealthSystem implements System {
  schema = ComponentsWhileLowHealthSchema;
  type = "ComponentsWhileLowHealth";
  depth = DEPTHS.HEALTH + 10;
  run(entity: number, gameModel: GameModel) {
    const ComponentsWhileLowHealth = gameModel.getTypedUnsafe(entity, ComponentsWhileLowHealthSchema);
    let lowHealth = false;
    if (ComponentsWhileLowHealth.ownerLowHealth) {
      const owner = gameModel.getTyped(entity, OwnerSchema)?.owner ?? gameModel.getTyped(entity, ChildSchema)?.parent;
      if (owner) {
        HealthSchema.id = owner;
        lowHealth = HealthSchema.health / getMaxHealth(owner, gameModel) < 0.5;
      }
    } else {
      HealthSchema.id = entity;
      lowHealth = HealthSchema.health / getMaxHealth(entity, gameModel) < 0.5;
    }
    if (lowHealth !== ComponentsWhileLowHealth.lowHealth) {
      ComponentsWhileLowHealth.lowHealth = lowHealth;
      if (lowHealth) {
        for (const component of ComponentsWhileLowHealth.highHealthComponents) {
          if (gameModel.hasComponent(entity, component.type)) {
            gameModel.removeComponent(entity, component.type);
          }
        }
        for (const component of ComponentsWhileLowHealth.lowHealthComponents) {
          gameModel.addComponent(entity, component.type, component.data);
        }
      } else {
        for (const component of ComponentsWhileLowHealth.lowHealthComponents) {
          if (gameModel.hasComponent(entity, component.type)) {
            gameModel.removeComponent(entity, component.type);
          }
        }
        for (const component of ComponentsWhileLowHealth.highHealthComponents) {
          gameModel.addComponent(entity, component.type, component.data);
        }
      }
    }
  }
  cleanup(entity: number, gameModel: GameModel, ejecting: boolean) {
    if (!ejecting) {
      const ComponentsWhileLowHealth = gameModel.getTypedUnsafe(entity, ComponentsWhileLowHealthSchema);
      if (ComponentsWhileLowHealth.lowHealth) {
        for (const component of ComponentsWhileLowHealth.lowHealthComponents) {
          gameModel.removeComponent(entity, component.type);
        }
      } else {
        for (const component of ComponentsWhileLowHealth.highHealthComponents) {
          gameModel.removeComponent(entity, component.type);
        }
      }
    }
  }
}

registerSystem(ComponentsWhileLowHealthSystem);

import { registerSchema, registerSystem } from "yage/components/ComponentRegistry";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";

@Component("HpBlock")
export class HpBlockSchema extends Schema {
  @type(["string"])
  @defaultValue([])
  excludedTypes: string[];
}

registerSchema(HpBlockSchema);

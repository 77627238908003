import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import {
  applyDamageStats,
  createDamageStats,
  DamageStatsSchema,
  invalidateDamageStats,
  unapplyDamageStats,
} from "../weapons/DamageStats";
import { WaveSchema } from "./Wave";
import { HealthSchema } from "../core";

@Component("EnemyWaveEnhancer")
export class EnemyWaveEnhancerSchema extends Schema {
  @type("number")
  @defaultValue(0)
  damage: number;

  @type("number")
  @defaultValue(0)
  health: number;
}

class EnemyWaveEnhancerSystem implements System {
  schema = EnemyWaveEnhancerSchema;
  type = "EnemyWaveEnhancer";
  dependencies = ["DamageStats"];

  generateDamageStats(entity: number, gameModel: GameModel) {
    const enhancer = gameModel.getTypedUnsafe(entity, EnemyWaveEnhancerSchema);
    const wave = gameModel.getTypedUnsafe(gameModel.coreEntity, WaveSchema).wave;
    const stats = createDamageStats();
    stats.minDamage = enhancer.damage * (wave - 1) * (1 + gameModel.players.length / 2);
    stats.maxDamage = enhancer.damage * (wave - 1) * (1 + gameModel.players.length / 2);
    return stats;
  }

  enhanceHealth(entity: number, gameModel: GameModel) {
    const wave = gameModel.getTypedUnsafe(gameModel.coreEntity, WaveSchema).wave;

    const waveEnhancer = gameModel.getTypedUnsafe(entity, EnemyWaveEnhancerSchema);
    HealthSchema.id = entity;
    HealthSchema.health += waveEnhancer.health * (wave - 1) * (1 + gameModel.players.length / 2);
    HealthSchema.maxHealth += waveEnhancer.health * (wave - 1) * (1 + gameModel.players.length / 2);
  }

  dehanceHealth(entity: number, gameModel: GameModel) {
    const wave = gameModel.getTypedUnsafe(gameModel.coreEntity, WaveSchema).wave;

    const waveEnhancer = gameModel.getTypedUnsafe(entity, EnemyWaveEnhancerSchema);
    HealthSchema.id = entity;
    HealthSchema.health -= waveEnhancer.health * (wave - 1);
    HealthSchema.maxHealth -= waveEnhancer.health * (wave - 1);
  }

  init(entity: number, gameModel: GameModel) {
    const stats = gameModel.getTypedUnsafe(entity, DamageStatsSchema).initialDamageStats;
    const enhancer = this.generateDamageStats(entity, gameModel);
    applyDamageStats(stats, enhancer);
    invalidateDamageStats(entity, gameModel);

    this.enhanceHealth(entity, gameModel);
  }

  cleanup(entity: number, gameModel: GameModel) {
    if (gameModel.isActive(entity) && gameModel.hasComponent(entity, DamageStatsSchema)) {
      const stats = gameModel.getTypedUnsafe(entity, DamageStatsSchema).initialDamageStats;
      if (!stats) {
        return;
      }
      const enhancer = this.generateDamageStats(entity, gameModel);
      unapplyDamageStats(stats, enhancer);
      invalidateDamageStats(entity, gameModel);
      this.dehanceHealth(entity, gameModel);
    }
  }
}
registerSystem(EnemyWaveEnhancerSystem);

import * as PIXI from "pixi.js";

import type { PixiDrawSystem } from "yage/components/PixiDrawSystem";
import { ComponentCategory } from "yage/components/types";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { registerPixiComponent, registerSchema } from "yage/components/ComponentRegistry";
import type { GameModel } from "yage/game/GameModel";
import { RadiusSchema } from "yage/schemas/entity/Radius";
import { TransformSchema } from "yage/schemas/entity/Transform";
import { Viewport } from "pixi-viewport";

@Component("Shadow")
export class ShadowSchema extends Schema {
  @type("number")
  @defaultValue(0)
  height: number;

  @type("number")
  @defaultValue(0)
  heightOffset: number;

  @type("number")
  @defaultValue(0)
  shadowOffset: number;

  @type("number")
  radius: number;
}

registerSchema(ComponentCategory.RENDERING, ShadowSchema);

class ShadowDraw implements PixiDrawSystem {
  ids: Set<number> = new Set();
  entities: {
    [id: number]: { container: PIXI.Container };
  } = {};
  schema = ShadowSchema;

  init(entity: number, gameModel: GameModel, viewport: Viewport) {
    const container = new PIXI.Container();
    const data = gameModel.getTypedUnsafe(entity, ShadowSchema);

    container.zIndex = 100;
    RadiusSchema.id = entity;
    const radius = data.radius ?? RadiusSchema.radius;

    const graphics = new PIXI.Graphics();
    graphics.beginFill(0x000000, 0.15);
    graphics.drawEllipse(0, radius, radius, radius / 2);
    graphics.endFill();

    container.addChild(graphics);

    this.entities[entity] = { container };
    this.ids.add(entity);
    viewport.addChild(container);
  }

  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, ShadowSchema);
    let zoom = 1;
    if (data.height || data.heightOffset) {
      zoom = 1 + (1 - (data.height - data.heightOffset) / data.height) / 2;
    }

    TransformSchema.id = entity;
    const entityPosition = TransformSchema.position;
    const container = this.entities[entity].container;
    container.scale.set(zoom, zoom);
    container.position.set(entityPosition.x, entityPosition.y + data.shadowOffset);
  }

  cleanup(entity: number) {
    if (!this.entities[entity]) {
      return;
    }
    const container = this.entities[entity].container;
    container.children.forEach((child) => {
      container.removeChild(child);
      child.destroy();
    });

    container.destroy();
    delete this.entities[entity];
    this.ids.delete(entity);
  }
}

registerPixiComponent("Shadow", ShadowDraw);

import { registerUIComponent, registerSystem } from "yage/components/ComponentRegistry";
import type { System } from "yage/components/System";
import { ComponentCategory } from "yage/components/types";
import { FlingComponentPositionTypeEnum, PickupStateEnum } from "yage/constants/enums";
import { Component, defaultValue, mapType, required, Schema, type } from "yage/decorators/type";
import type { GameModel } from "yage/game/GameModel";
import { Position } from "yage/ui/Rectangle";
import { Text } from "yage/ui/Text";
import type { UIElement } from "yage/ui/UIElement";
import { UIService } from "yage/ui/UIService";
import { PickupStateSchema } from "../pickups/PickupState";
import type { StoreItemSchema } from "../pickups/StoreItem";
import { ImageBox } from "yage/ui/ImageBox";
import { getEntityController } from "../../utils/playerCharacter";
import { localIndex } from "../../utils/localIndex";

export class InventoryItemSchema extends Schema {
  @type("string")
  name?: string;

  @type("number")
  @required()
  amount: number;

  @type("string")
  spriteKey?: string;

  @type("boolean")
  carried?: boolean;

  @type("number")
  carriedEntity?: number;
}

@Component("Inventory")
export class InventorySchema extends Schema {
  @mapType(InventoryItemSchema)
  @defaultValue({})
  inventory: { [key: string]: InventoryItemSchema };
}
class InventorySystem implements System {
  type = "Inventory";
  category: ComponentCategory = ComponentCategory.BEHAVIOR;
  schema = InventorySchema;
  cleanup() {
    if (ui) {
      UIService.getInstance().removeFromUI(ui);
      ui = null;
    }
  }
}

registerSystem(InventorySystem);

export function consumeItem(entity: number, itemName: string, gameModel: GameModel) {
  const inventory = gameModel.getTypedUnsafe(entity, InventorySchema).inventory;
  if (inventory[itemName] === undefined) {
    return false;
  }
  inventory[itemName].amount -= 1;
  if (inventory[itemName].amount <= 0) {
    const item = inventory[itemName];
    if (item.carried) {
      gameModel.removeEntity(item.carriedEntity ?? -1);
    }
    delete inventory[itemName];
  }
  return true;
}

export function addToInventory(
  target: number,
  entity: number,
  data: StoreItemSchema,
  carried: boolean,
  gameModel: GameModel
) {
  let inventory = gameModel.getTyped(target, InventorySchema)?.inventory;
  if (!inventory) {
    inventory = gameModel.getTypedUnsafe(getEntityController(target, gameModel), InventorySchema).inventory;
  }

  if (carried) {
    const items = Object.keys(inventory);
    for (let i = 0; i < items.length; ++i) {
      const item = inventory[items[i]];
      if (item.carried && item.carriedEntity) {
        item.carried = false;
        const pickup = gameModel.getTypedUnsafe(item.carriedEntity, PickupStateSchema);
        pickup.state = PickupStateEnum.STUCK;
        gameModel.removeComponent(item.carriedEntity, "CarriedPickup");

        gameModel.addComponent(item.carriedEntity, "Fling", {
          positionType: FlingComponentPositionTypeEnum.CIRCLE,
          position: { x: 70, y: 100 },
        });
        delete inventory[items[i]];
        break;
      }
    }
  }

  if (!inventory[data.inventoryType]) {
    inventory[data.inventoryType] = {
      name: data.inventoryType,
      amount: 1,
      spriteKey: data.inventoryType,
      carried,
      carriedEntity: carried ? entity : 0,
    };
  } else {
    if (carried) {
      return false;
    }
    inventory[data.inventoryType].amount += 1;
  }
  if (data.pickupSound) {
    // SoundService.getInstance().playSound(data.pickupSound);
  }
  if (!carried) {
    const onPickups = gameModel.getComponentIdsByCategory(target, ComponentCategory.ON_ADD_TO_INVENTORY);
    const entityController = getEntityController(target, gameModel);
    const onEntityControllerPickups = gameModel.getComponentIdsByCategory(
      entityController,
      ComponentCategory.ON_ADD_TO_INVENTORY
    );
    if (onPickups.length > 0) {
      for (let i = 0; i < onPickups.length; ++i) {
        const pickupComponent = gameModel.getComponent(target, onPickups[i]) as {
          pickup: number;
          pickupData: StoreItemSchema;
        };
        if (pickupComponent.pickup !== undefined) {
          pickupComponent.pickup = entity;
        }

        pickupComponent.pickupData = data;
        gameModel.getSystem(onPickups[i])!.run?.(target, gameModel);
      }
    }
    if (onEntityControllerPickups.length > 0) {
      for (let i = 0; i < onEntityControllerPickups.length; ++i) {
        const pickupComponent = gameModel.getComponent(entityController, onEntityControllerPickups[i]) as {
          pickup: number;
          pickupData: StoreItemSchema;
        };
        if (pickupComponent.pickup !== undefined) {
          pickupComponent.pickup = entity;
        }
        pickupComponent.pickupData = data;
        gameModel.getSystem(onEntityControllerPickups[i])!.run?.(entityController, gameModel);
      }
    }
    return true;
  } else {
    const pickup = gameModel.getTypedUnsafe(entity, PickupStateSchema);
    pickup.state = PickupStateEnum.CARRIED;
    gameModel.addComponent(entity, "CarriedPickup", { owner: data.owner });
  }
  return false;
}

let ui: UIElement[] | null = null;

registerUIComponent("Inventory", (uiService, entity, gameModel) => {
  if (!ui) {
    ui = [];
    const position = new Position("left", "top", { width: 200, height: 40, xOffset: 20, yOffset: 10 });

    const coin = new ImageBox(new Position("left", "top", { width: 25, height: 25, xOffset: 20, yOffset: 13 }), {
      imageKey: "ui/exp",
      style: {
        backgroundColor: "transparent",
        borderColor: "transparent",
      },
    });
    ui.push(coin);
    uiService.addToUI(coin);
    const coinText = new Text(new Position("left", "top", { width: 1, height: 1, xOffset: 55, yOffset: 5 }), {
      label: "00000",
      fontSize: 32,
      style: {
        textTransform: "uppercase",
        color: "white",
        webkitTextStroke: "1px black",
        textShadow: `
        3px 3px 0 #000,
      -1px -1px 0 #000,  
       1px -1px 0 #000,
       -1px 1px 0 #000,
        1px 1px 0 #000`,
      },
    });
    ui.push(coinText);
    uiService.addToUI(coinText);
  }
  if (localIndex(entity, gameModel) !== -1) {
    const coins = gameModel.getTyped(entity, InventorySchema)?.inventory?.coins?.amount ?? 0;
    ui[1].config.label = (coins + "").padStart(5, "0");
  }
});

import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { Component, Schema, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { InventorySchema } from "../player/Inventory";
import { PlayerInputSchema } from "yage/schemas/core/PlayerInput";
import { WaveSchema } from "./Wave";
import { WeaponContainerSchema } from "../weapons/WeaponContainer";
import { PlayerReadyState, PlayerState } from "../../types/PlayerState.types";
import { HarvestingSchema, HarvestingSystem } from "../core/Harvesting";
import { ComponentCategory } from "yage/constants/enums";
import { ComponentData } from "yage/components/types";
import { ExpSchema } from "../core/Exp";
import { getPlayerCharacter } from "../../utils/playerCharacter";
import { StorePlayerStateOnDeathSchema } from "../player/StorePlayerStateOnDeath";
import { flags } from "yage/console/flags";
import { ShareOnRoundEndSchema } from "../core/ShareOnRoundEnd";
import { generateShareList } from "yage/utils/generateShareList";
import { RemoveWeaponsOnEndOfRoundSchema } from "../weapons/RemoveWeaponsOnEndOfRound";

@Component("EndRound")
class EndRoundSchema extends Schema {
  @type("string")
  nextScene: string;
}

class EndRoundSystem implements System {
  schema = EndRoundSchema;
  type: string = "EndRound";

  depth = DEPTHS.DRAW + 10000;

  run(entity: number, gameModel: GameModel) {
    const EndRound = gameModel.getTypedUnsafe(entity, EndRoundSchema);
    const seed = gameModel.rand.number();
    const players: { [key: string]: Partial<PlayerState> } = gameModel
      .getComponentActives("PlayerInput")
      .map((player) => {
        const playerCharacter = getPlayerCharacter(player, gameModel);

        let weapons: string[] = [];
        let weaponMods: ComponentData[][] = [];
        let persistedComponents: ComponentData[] = [];
        let inventory = gameModel.getTypedUnsafe(player, InventorySchema).inventory;

        if (gameModel.hasComponent(player, StorePlayerStateOnDeathSchema)) {
          // if you die you lose all your money
          inventory.coins.amount = Math.floor(inventory.coins.amount * 0.7);

          const state = gameModel.getTypedUnsafe(player, StorePlayerStateOnDeathSchema).state;

          if (!gameModel.hasComponent(playerCharacter, RemoveWeaponsOnEndOfRoundSchema)) {
            const weaponData = state.components.find((c) => c.type === "WeaponContainer")?.data as unknown as
              | { weapons: string[]; weaponIds: { entities: number[] } }
              | undefined;
            weapons = weaponData?.weapons ?? [];
            const weaponIds = weaponData?.weaponIds?.entities ?? [];
            for (const weaponId of weaponIds) {
              const weapon = state.children[weaponId];
              const mods = weapon.components.filter((c) => gameModel.getCategory(c.type) === ComponentCategory.PERSIST);
              weaponMods.push(mods);
            }
          }

          persistedComponents = state.components.filter(
            (c) => gameModel.getCategory(c.type) === ComponentCategory.PERSIST
          );
        } else {
          let shareList = generateShareList(player, ShareOnRoundEndSchema, ComponentCategory.ON_END, gameModel);
          for (let i = 0; i < shareList.length; i++) {
            const [component, entities] = shareList[i];
            for (let j = 0; j < entities.length; j++) {
              const entity = entities[j];

              const mod = gameModel.getComponentUnsafe(entity, component);
              if (mod.owner !== undefined) {
                mod.owner = player;
              }

              const system: System = gameModel.getSystem(component);
              system.run?.(entity, gameModel);
            }
          }
          shareList = generateShareList(playerCharacter, ShareOnRoundEndSchema, ComponentCategory.ON_END, gameModel);
          for (let i = 0; i < shareList.length; i++) {
            const [component, entities] = shareList[i];
            for (let j = 0; j < entities.length; j++) {
              const entity = entities[j];

              const mod = gameModel.getComponentUnsafe(entity, component);
              if (mod.owner !== undefined) {
                mod.owner = player;
              }

              const system: System = gameModel.getSystem(component);
              system.run?.(entity, gameModel);
            }
          }

          if (!gameModel.hasComponent(playerCharacter, RemoveWeaponsOnEndOfRoundSchema)) {
            for (const weapon of gameModel.getTypedUnsafe(playerCharacter, WeaponContainerSchema).weaponIds) {
              const modData = gameModel.getComponentIdsByCategory(weapon, ComponentCategory.PERSIST);
              const mods: ComponentData[] = [];
              weaponMods.push(mods);
              for (const weaponMod of modData) {
                const ejectedMod = gameModel.ejectComponent(weapon, weaponMod);
                mods.push(ejectedMod);
              }
            }
            weapons = gameModel.getTypedUnsafe(playerCharacter, WeaponContainerSchema).weapons;
          }
          const persistedPlayerComponents = gameModel.getComponentIdsByCategory(player, ComponentCategory.PERSIST);
          for (const componentId of persistedPlayerComponents) {
            const ejectedComponent = gameModel.ejectComponent(player, componentId);
            persistedComponents.push(ejectedComponent);
          }
        }
        let harvesting = gameModel.getTypedUnsafe(player, HarvestingSchema).harvesting;

        if (flags.SHORTCUT_WAVE) {
          gameModel.getTypedUnsafe(player, ExpSchema).level += 1;
          gameModel.getTypedUnsafe(player, ExpSchema).exp = 0;
          if (!inventory["levelUps"]) {
            inventory["levelUps"] = {
              amount: 0,
            };
          }
          inventory["levelUps"].amount += 1;
        }
        const { exp, level } = gameModel.getTypedUnsafe(player, ExpSchema);

        const playerState: Partial<PlayerState> & { id: string } = {
          id: gameModel.getTypedUnsafe(player, PlayerInputSchema).id,
          exp,
          level,
          inventory,
          weapons,
          weaponMods,
          wave: gameModel.getTypedUnsafe(gameModel.coreEntity, WaveSchema).wave,
          readyState: PlayerReadyState.NotReady,
          harvesting: harvesting ?? 0,
          persistedComponents,
        };
        return playerState;
      })
      .reduce((acc, player) => {
        acc[player.id] = player;
        return acc;
      }, {} as { [key: string]: Partial<PlayerState> });
    gameModel.destroyed = true;

    gameModel.removeComponent(entity, EndRoundSchema);
    gameModel.gameCoordinator.changeScene(EndRound.nextScene, {
      seed,
      players,
      instance: gameModel.instance,
    });
  }
}

registerSystem(EndRoundSystem);

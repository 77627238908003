import { Component, Schema, defaultValue, required, type } from "yage/decorators/type";
import { DamageStatsSchema, invalidateDamageStats } from "../weapons/DamageStats";
import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";

@Component("ScaleStatByActiveEntities")
export class ScaleStatByActiveEntitiesSchema extends Schema {
  @type("string")
  scalingStat: string;

  @type("string")
  scalingComponentType: string;

  @type("number")
  @defaultValue(1)
  scale: number;

  @type("number")
  @defaultValue(0)
  currentSourceValue: number;

  @type("string")
  @required()
  componentType: string;
}

class ScaleStatByActiveEntitiesSystem implements System {
  schema = ScaleStatByActiveEntitiesSchema;
  type = "ScaleStatByActiveEntities";
  depth = DEPTHS.ITEMS + 1;

  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, ScaleStatByActiveEntitiesSchema);
    const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
    if (!gameModel.hasComponent(parent, data.scalingComponentType)) {
      return;
    }

    const statValue = gameModel.getComponentActives(data.componentType).length;
    if (data.currentSourceValue !== statValue) {
      const stats =
        data.scalingComponentType === "DamageStats"
          ? gameModel.getTypedUnsafe(parent, DamageStatsSchema).initialDamageStats
          : gameModel.getComponentUnsafe(parent, data.scalingComponentType);

      const previousStatValue = data.currentSourceValue * data.scale;

      if (data.scalingStat.startsWith("min")) {
        const maxStat = "max" + data.scalingStat.substring(3);
        // @ts-ignore
        stats[maxStat] = stats[maxStat] + statValue * data.scale - previousStatValue;
      }

      // @ts-ignore
      stats[data.scalingStat] = stats[data.scalingStat] + statValue * data.scale - previousStatValue;

      invalidateDamageStats(parent, gameModel);

      data.currentSourceValue = statValue;
    }
  }
}

registerSystem(ScaleStatByActiveEntitiesSystem);

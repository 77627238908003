import { GameModel } from "yage/game/GameModel";
import { FlatMapSchema } from "../components/core/Map";
import { Vector2d, addVector2d, distanceSquaredVector2d, rotateVector2d } from "yage/utils/vector";
import { TransformSchema } from "yage/schemas/entity/Transform";

export const findSpawn = (gameModel: GameModel, playerRadius: number): Vector2d => {
  const mapSize = gameModel.getTypedUnsafe(gameModel.coreEntity, FlatMapSchema);
  const mapWidth = mapSize.width * mapSize.scale;
  const mapHeight = mapSize.height * mapSize.scale;
  const x = gameModel.rand.int(-mapWidth / 2, mapWidth / 2);
  const y = gameModel.rand.int(-mapHeight / 2, mapHeight / 2);

  const spawn = { x, y };

  for (let i = 0; i < gameModel.players.length; ++i) {
    const player = gameModel.players[i];
    TransformSchema.id = player;
    const playerPosition = TransformSchema.position;

    const distance = distanceSquaredVector2d(playerPosition, spawn);
    if (distance < playerRadius * playerRadius) {
      return findSpawn(gameModel, playerRadius);
    }
  }

  return spawn;
};

export const findSpawnNearEntity = (
  gameModel: GameModel,
  entity: number,
  innerRadius: number,
  outerRadius: number
): Vector2d => {
  const mapSize = gameModel.getTypedUnsafe(gameModel.coreEntity, FlatMapSchema);
  const mapWidth = mapSize.width * mapSize.scale;
  const mapHeight = mapSize.height * mapSize.scale;

  TransformSchema.id = entity;
  const entityPosition = TransformSchema.position;

  const theta = gameModel.rand.number() * Math.PI * 2;
  const spawn = addVector2d(
    rotateVector2d(
      {
        x: 0,
        y: gameModel.rand.int(innerRadius + 1, outerRadius),
      },
      theta
    ),
    entityPosition
  );

  if (spawn.x < -mapWidth / 2 || spawn.x > mapWidth / 2 || spawn.y < -mapHeight / 2 || spawn.y > mapHeight / 2) {
    return findSpawnNearEntity(gameModel, entity, innerRadius, outerRadius);
  }

  for (let i = 0; i < gameModel.players.length; ++i) {
    const player = gameModel.players[i];
    TransformSchema.id = player;
    const playerPosition = TransformSchema.position;

    const distance = distanceSquaredVector2d(playerPosition, spawn);
    if (distance < innerRadius * innerRadius) {
      return findSpawnNearEntity(gameModel, entity, innerRadius, outerRadius);
    }
  }

  return spawn;
};

import { System } from "yage/components/System";
import { Component, Schema, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { InventorySchema } from "./Inventory";
import { ChildSchema } from "yage/schemas/entity/Child";
import { registerSystem } from "yage/components/ComponentRegistry";
import { getEntityController } from "../../utils/playerCharacter";

@Component("ConvertShopItem")
export class ConvertShopItemSchema extends Schema {
  @type("string")
  initialItem: string;

  @type("string")
  swapItem: string;
}

class ConvertShopItemSystem implements System {
  schema = ConvertShopItemSchema;
  type = "ConvertShopItem";
  category = "ConvertShopItem";

  dependencies = ["Child"];

  init(entity: number, gameModel: GameModel) {
    const parent = getEntityController(gameModel.getTyped(entity, ChildSchema)?.parent ?? -1, gameModel);

    if (parent && gameModel.hasComponent(parent, "Inventory")) {
      const ConvertShopItemData = gameModel.getTypedUnsafe(entity, ConvertShopItemSchema);
      const inventory = gameModel.getTypedUnsafe(parent, InventorySchema).inventory;

      const initialItem = "shopitem::" + ConvertShopItemData.initialItem.toLowerCase();
      const swapItem = "shopitem::" + ConvertShopItemData.swapItem.toLowerCase();
      if (inventory[initialItem]?.amount > 0) {
        inventory[initialItem].amount -= 1;
        if (!inventory[initialItem].amount) {
          delete inventory[initialItem];
        }
        if (!inventory[swapItem]) {
          inventory[swapItem] = {
            amount: 0,
          };
        }
        inventory[swapItem].amount += 1;
      }
      console.log(initialItem, swapItem, inventory);
    }
  }
}

registerSystem(ConvertShopItemSystem);

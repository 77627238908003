import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import type { System } from "yage/components/System";
import { ComponentCategory } from "yage/components/types";
import { Component, Schema, type } from "yage/decorators/type";
import type { GameModel } from "yage/game/GameModel";
import { HealthSchema } from "../core/Health";

@Component("ChangeSceneOnDeath")
export class ChangeSceneOnDeathSchema extends Schema {
  @type("string")
  scene: string;
}

class ChangeSceneOnDeathSystem implements System {
  type = "ChangeSceneOnDeath";
  category: ComponentCategory = ComponentCategory.UI;
  schema = ChangeSceneOnDeathSchema;
  depth = DEPTHS.HEALTH - 0.0001;

  run(entity: number, gameModel: GameModel) {
    const healthData = gameModel.getTypedUnsafe(entity, HealthSchema);

    if (healthData.health > 0) {
      return;
    }
    const data = gameModel.getTypedUnsafe(entity, ChangeSceneOnDeathSchema);
    gameModel.gameCoordinator.changeScene(data.scene);
  }
}

registerSystem(ChangeSceneOnDeathSystem);

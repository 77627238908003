import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import type { System } from "yage/components/System";
import type { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";
import { LocomotionSchema } from "yage/schemas/entity/Locomotion";
import { normalizeSafeVector2d, scaleVector2d } from "yage/utils/vector";
import { type, Schema, Component, defaultValue } from "yage/decorators/type";

@Component("SpeedModInstance")
export class SpeedModInstanceSchema extends Schema {
  @type("number")
  @defaultValue(0.5)
  speedMod: number;

  @type("Entity")
  source: number;

  @type("number")
  duration?: number;
}

@Component("SpeedMod")
export class SpeedModSchema extends Schema {
  @type([SpeedModInstanceSchema])
  speedMods: SpeedModInstanceSchema[] = [];
}

class SpeedModSystem implements System {
  type: string = "SpeedMod";
  schema: typeof Schema = SpeedModSchema;
  depth = DEPTHS.COLLISION - 1;

  init?: ((entity: number, gameModel: GameModel) => void) | undefined;

  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, SpeedModSchema);

    if (data.speedMods.length === 0) {
      gameModel.removeComponent(entity, SpeedModSchema);
      return;
    }

    let speedMod = 1;
    let dt = gameModel.dt<number>(entity);

    for (let i = 0; i < data.speedMods.length; i++) {
      const duration = data.speedMods[i].duration;
      if (duration === undefined) {
        speedMod += data.speedMods[i].speedMod;
      } else if (duration > 0) {
        speedMod += data.speedMods[i].speedMod;
        data.speedMods[i].duration = duration - dt;
      } else {
        data.speedMods.splice(i, 1);
        i--;
      }
    }

    const parent = gameModel.getTyped(entity, ChildSchema)?.parent ?? entity;
    LocomotionSchema.id = parent;

    const velocity = scaleVector2d(
      normalizeSafeVector2d(LocomotionSchema.velocity),
      Math.max(speedMod, 0.01) * LocomotionSchema.speed
    );
    LocomotionSchema.velocityX = velocity.x;
    LocomotionSchema.velocityY = velocity.y;
  }
  cleanup?: ((entity: number, gameModel: GameModel, ejecting: boolean) => void) | undefined;
}

registerSystem(SpeedModSystem);

import { RadiusSchema } from "yage/schemas/entity/Radius";
import { TransformSchema } from "yage/schemas/entity/Transform";
import { Vector2d } from "yage/utils/vector";

export const ApproximateCollisionPosition = (entityId1: number, entityId2: number): Vector2d => {
  TransformSchema.id = entityId1;
  const position1 = TransformSchema.position;

  TransformSchema.id = entityId2;
  const position2 = TransformSchema.position;

  RadiusSchema.id = entityId1;
  const radius1 = RadiusSchema.radius;

  RadiusSchema.id = entityId2;
  const radius2 = RadiusSchema.radius;

  const radiusSum = radius1 + radius2;

  return {
    x: (position1.x * radius2 + position2.x * radius1) / radiusSum,
    y: (position1.y * radius2 + position2.y * radius1) / radiusSum,
  };
};

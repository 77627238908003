import { System } from "yage/components/System";
import { PlayerTypeSchema, EnemyTypeSchema } from "yage/components/entity/Types";
import { ComponentDataSchema } from "yage/components/types";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";
import { registerSystem } from "yage/components/ComponentRegistry";

@Component("OnHitInheritor")
export class OnHitInheritorSchema extends Schema {
  @type([ComponentDataSchema])
  @defaultValue([])
  components: ComponentDataSchema[];
}

class OnHitInheritorSystem implements System {
  schema = OnHitInheritorSchema;
  type = "OnHitInheritor";

  dependencies = ["Child"];

  init(entity: number, gameModel: GameModel) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      const data = gameModel.getTypedUnsafe(entity, OnHitInheritorSchema);
      for (const component of data.components) {
        if (!gameModel.hasComponent(parent, component.type)) {
          gameModel.addComponent(parent, component.type, component.data);
        }
      }
    }
  }
}
registerSystem(OnHitInheritorSystem);

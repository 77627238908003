import { DEPTHS, registerPixiComponent, registerSystem } from "yage/components/ComponentRegistry";
import { ComponentCategory } from "yage/components/types";
import { DamageTypeEnum } from "yage/constants/enums";
import { Component, defaultValue, type, Schema, nullable } from "yage/decorators/type";
import type { GameModel } from "yage/game/GameModel";
import type { System } from "yage/components/System";
import { takeDamage } from "./Damageable";
import type { PixiDrawSystem } from "yage/components/PixiDrawSystem";
import type * as PIXIType from "pixi.js";
import { PixiSpriteLoader } from "yage/loader/PixiSpriteLoader";
import { DamageStatsSchema } from "../weapons/DamageStats";
import { TransformSchema } from "yage/schemas/entity/Transform";
import { Viewport } from "pixi-viewport";
import { EnemyCollidersSchema } from "../enemy/EnemyColliders";
import { sortedByDistance } from "yage/utils/Collision";
import { cloneDeep } from "lodash";

// @ts-ignore
const PIXI = () => window.__YAGE__["PIXI"];

@Component("Burning")
export class BurningSchema extends Schema {
  @type("number")
  @defaultValue(0)
  spriteFrame: number;

  @type(DamageStatsSchema)
  damageStats: DamageStatsSchema;

  @type("Entity")
  @nullable()
  owner: number;

  @type(["number"])
  colliders: number[];

  @type("number")
  @defaultValue(DamageTypeEnum.FIRE)
  damageType: DamageTypeEnum;

  @type("string")
  @defaultValue("effects/flame")
  spriteKey: string;

  @type("number")
  @defaultValue(0)
  lastTick: number;

  @type("number")
  burnsLeft: number;

  @type("number")
  @defaultValue(300)
  spreadRange: number;

  @type("number")
  @defaultValue(0)
  spreadsLeft: number;

  @type("number")
  @defaultValue(0)
  burnInterval: number;
}

class BurningSystem implements System {
  type = "Burning";
  category: ComponentCategory = ComponentCategory.DAMAGEAPPLIER;
  schema = BurningSchema;
  depth = DEPTHS.DAMAGE + 1;

  init(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, BurningSchema);
    data.burnsLeft = data.damageStats.burnDuration;
    data.lastTick = gameModel.timeElapsed;
  }

  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, BurningSchema);
    data.spriteFrame = gameModel.increment(entity, data.spriteFrame, 8, 10);

    if (data.spreadsLeft > 0) {
      const spreadChance = data.burnsLeft === 1 ? 1 : 0.05;

      if (gameModel.rand.number() < spreadChance) {
        const colliders = gameModel.getTyped(data.owner, EnemyCollidersSchema)?.colliders;
        if (!colliders) {
          data.spreadsLeft = 0;
          return;
        }
        const allEnemies = gameModel.getEntities(colliders);
        const range = data.spreadRange;
        const entities = sortedByDistance(gameModel, TransformSchema.position, allEnemies, range);

        const candidates: number[] = [];
        for (let i = 0; i < entities.length; i++) {
          if (gameModel.hasComponent(entities[i], "Burning") || entities[i] === entity) {
            continue;
          }
          candidates.push(entities[i]);
        }
        if (candidates.length) {
          const candidate = candidates[gameModel.rand.int(0, candidates.length - 1)];
          const spreadData = cloneDeep(data);
          spreadData.burnsLeft = spreadData.damageStats.burnDuration;
          spreadData.spreadsLeft--;
          spreadData.lastTick = gameModel.timeElapsed;
          gameModel.addComponent(candidate, "Burning", spreadData);
          data.spreadsLeft = 0;
        }
      }
    }

    if (gameModel.timeElapsed - data.lastTick >= data.burnInterval * 1000) {
      data.lastTick = gameModel.timeElapsed;
      data.burnsLeft--;
      takeDamage(entity, gameModel, [
        {
          damageStats: data.damageStats,
          source: entity,
          owner: data.owner,
          frame: gameModel.timeElapsed,
          damageType: data.damageType,
        },
      ]);
    }
    if (data.burnsLeft <= 0) {
      gameModel.removeComponent(entity, "Burning");
    }
  }

  cleanup(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, BurningSchema);
    const colliders = gameModel.getTyped(data.owner, EnemyCollidersSchema)?.colliders;

    if (colliders && data.spreadsLeft > 0) {
      const allEnemies = gameModel.getEntities(colliders);
      const range = data.spreadRange;
      const entities = sortedByDistance(gameModel, TransformSchema.position, allEnemies, range);

      const candidates: number[] = [];
      for (let i = 0; i < entities.length; i++) {
        if (gameModel.hasComponent(entities[i], "Burning") || entities[i] === entity) {
          continue;
        }
        candidates.push(entities[i]);
      }
      if (candidates.length) {
        const candidate = candidates[gameModel.rand.int(0, candidates.length - 1)];
        const spreadData = cloneDeep(data);
        spreadData.burnsLeft = spreadData.damageStats.burnDuration;
        spreadData.spreadsLeft = data.spreadsLeft === 1 ? 0 : 1;
        spreadData.lastTick = gameModel.timeElapsed;
        gameModel.addComponent(candidate, "Burning", spreadData);
        data.spreadsLeft = 0;
      }
    }
  }
}

registerSystem(BurningSystem);

class RenderBurningSystem implements PixiDrawSystem {
  ids: Set<number> = new Set();
  schema = BurningSchema;

  instances: { [key: number]: { sprite: PIXIType.AnimatedSprite; container: PIXIType.Container } } = {};
  animationCache: { [key: string]: PIXIType.AnimatedSprite[] } = {};

  init(entity: number, gameModel: GameModel, viewport: Viewport) {
    this.ids.add(entity);
    const data = gameModel.getTypedUnsafe(entity, BurningSchema);

    let sprite;
    if (this.animationCache[data.spriteKey]?.length) {
      sprite = this.animationCache[data.spriteKey].pop() as PIXIType.AnimatedSprite;
    } else {
      const t = PixiSpriteLoader.getInstance().pixiSpriteLibrary.get(data.spriteKey) as PIXIType.Spritesheet<any>;
      sprite = new (PIXI().AnimatedSprite)(t.animations[data.spriteKey]);
    }

    sprite.zIndex = 10000;

    sprite.gotoAndStop(0);
    sprite.position.set(-sprite.width / 2, -sprite.height / 2);

    const container = new (PIXI().Container)();
    container.addChild(sprite);

    viewport.addChild(container);
    this.instances[entity] = { sprite, container };
  }

  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, BurningSchema);
    const { container, sprite } = this.instances[entity];
    TransformSchema.id = entity;

    container.zIndex = TransformSchema.y + 1000;

    container.position.set(TransformSchema.x, TransformSchema.y);
    sprite.gotoAndStop(Math.floor(data.spriteFrame));
  }

  cleanup(entity: number, gameModel: GameModel, viewport: Viewport) {
    this.ids.delete(entity);
    // @TODO: FIX THIS
    if (!this.instances[entity]) return;
    const { sprite, container } = this.instances[entity];
    const data = gameModel.getTypedUnsafe(entity, BurningSchema);

    viewport.removeChild(container);

    container.destroy();

    this.animationCache[data.spriteKey] = this.animationCache[data.spriteKey] || [];
    this.animationCache[data.spriteKey].push(sprite);

    delete this.instances[entity];
  }
}

registerPixiComponent("Burning", RenderBurningSystem);

import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import type { System } from "yage/components/System";
import { ComponentCategory } from "yage/components/types";
import type { GameModel } from "yage/game/GameModel";
import { OwnerSchema } from "yage/schemas/core/Owner";
import { OwnedSchema } from "yage/schemas/core/Owned";
import { DamageStatsSchema } from "../../schema/damage/Damage";
import { flags } from "yage/console/flags";
import { cloneDeep } from "lodash";
import { ChildSchema } from "yage/schemas/entity/Child";
import { DamageGeneratorSchema, DamageGeneratorSystem } from "../damage/DamageGenerator";
import { DamageStatsScalerSchema } from "../scalers/DamageStatsScaler";
import { ParentSchema } from "yage/schemas/entity/Parent";

const regenerateDamageStats = (entity: number, gameModel: GameModel) => {
  let damageStats = gameModel.getTypedUnsafe(entity, DamageStatsSchema);
  let damageStatsScaler = gameModel.hasComponent(entity, "DamageStatsScaler")
    ? gameModel.getTypedUnsafe(entity, DamageStatsScalerSchema)
    : null;
  let nextDamageStats = {
    ...damageStats.initialDamageStats,
    initialDamageStats: damageStats.initialDamageStats,
  };

  if (damageStats.inheritFromParent) {
    const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent;
    if (parent && gameModel.hasComponent(parent, DamageStatsSchema)) {
      applyDamageStats(nextDamageStats, gameModel.getTypedUnsafe(parent, DamageStatsSchema));
    }
  }
  if (damageStats.inheritFromOwner) {
    const owner = gameModel.getTypedUnsafe(entity, OwnerSchema).owner;
    if (owner && gameModel.hasComponent(owner, DamageStatsSchema)) {
      applyDamageStats(nextDamageStats, gameModel.getTypedUnsafe(owner, DamageStatsSchema));
    }
  }
  if (damageStatsScaler) {
    for (const key of Object.keys(nextDamageStats)) {
      if (typeof (nextDamageStats as any)[key] !== "number") {
        // @ts-ignore
        damageStats[key] = nextDamageStats[key];
        continue;
      }
      // @ts-ignore
      damageStats[key] = nextDamageStats[key] * ((damageStatsScaler?.[key] ?? 0) + 1);
    }
  } else {
    for (const key of Object.keys(nextDamageStats)) {
      // @ts-ignore
      damageStats[key] = nextDamageStats[key];
    }
  }
};

class DamageStatsSystem implements System {
  type = "DamageStats";
  category: ComponentCategory = ComponentCategory.BEHAVIOR;
  schema = DamageStatsSchema;
  dependencies = ["Owner", "Child"];

  depth = DEPTHS.ITEMS + 500;
  init(entity: number, gameModel: GameModel) {
    const damageStats = gameModel.getTypedUnsafe(entity, DamageStatsSchema);
    damageStats.initialDamageStats = cloneDeep(damageStats);

    regenerateDamageStats(entity, gameModel);
    damageStats.invalidated = false;

    if (gameModel.hasComponent(entity, DamageGeneratorSchema)) {
      gameModel.getSystem(DamageGeneratorSystem).run(entity, gameModel);
    }
  }

  run(entity: number, gameModel: GameModel) {
    if (gameModel.getTypedUnsafe(entity, DamageStatsSchema).invalidated) {
      const damageStats = gameModel.getTypedUnsafe(entity, DamageStatsSchema);

      regenerateDamageStats(entity, gameModel);

      const children = [
        ...(gameModel.getTyped(entity, ParentSchema)?.children ?? []),
        ...(gameModel.getTyped(entity, OwnedSchema)?.owned ?? []),
      ];
      for (const child of children) {
        if (gameModel.hasComponent(child, DamageStatsSchema)) {
          const childDamageStats = gameModel.getTypedUnsafe(child, DamageStatsSchema);
          childDamageStats.invalidated = true;
          this.run(child, gameModel);
        }
      }
      damageStats.invalidated = false;
      if (gameModel.hasComponent(entity, DamageGeneratorSchema)) {
        gameModel.getSystem(DamageGeneratorSystem).run(entity, gameModel);
      }
      gameModel.getTypedUnsafe(entity, DamageStatsSchema).invalidated = false;
    }
  }
}

registerSystem(DamageStatsSystem);

export const createDamageStats = (): DamageStatsSchema => {
  const damageStats: any = {
    type: "DamageStats",
  };
  // @ts-ignore
  DamageStatsSchema.__validate(damageStats);

  return damageStats;
};

export const unapplyDamageStats = (baseDamageStats: DamageStatsSchema, damageStats: DamageStatsSchema) => {
  Object.keys(damageStats).forEach((key) => {
    if (typeof (damageStats as any)[key] !== "number") {
      // @ts-ignore
      baseDamageStats[key] = damageStats[key];
      return;
    }
    // @ts-ignore
    baseDamageStats[key] -= damageStats[key] ?? 0;
  });
};

export const scaleDamageStats = (baseDamageStats: DamageStatsSchema, damageStats: DamageStatsSchema) => {
  Object.keys(damageStats).forEach((key) => {
    if (typeof (damageStats as any)[key] !== "number") {
      // @ts-ignore
      baseDamageStats[key] = damageStats[key];
      return;
    }
    // @ts-ignore
    baseDamageStats[key] *= damageStats[key] ?? 1;
  });
};

export const unscaleDamageStats = (baseDamageStats: DamageStatsSchema, damageStats: DamageStatsSchema) => {
  Object.keys(damageStats).forEach((key) => {
    if (typeof (damageStats as any)[key] !== "number") {
      // @ts-ignore
      baseDamageStats[key] = damageStats[key];
      return;
    }
    // @ts-ignore
    baseDamageStats[key] /= damageStats[key] ?? 1;
  });
};

export const applyDamageStats = (baseDamageStats: DamageStatsSchema, damageStats: DamageStatsSchema) => {
  if (flags.DEBUG_DAMAGE_STATS) {
    console.log("APPLYING", damageStats, "TO", baseDamageStats);
  }
  baseDamageStats.minDamage += damageStats.minDamage ?? 0;
  baseDamageStats.maxDamage += damageStats.maxDamage ?? 0;

  baseDamageStats.minRangedDamage += damageStats.minRangedDamage ?? 0;
  baseDamageStats.maxRangedDamage += damageStats.maxRangedDamage ?? 0;

  baseDamageStats.minAoeDamage += damageStats.minAoeDamage ?? 0;
  baseDamageStats.maxAoeDamage += damageStats.maxAoeDamage ?? 0;

  baseDamageStats.minMeleeDamage += damageStats.minMeleeDamage ?? 0;
  baseDamageStats.maxMeleeDamage += damageStats.maxMeleeDamage ?? 0;

  baseDamageStats.minChaosDamage += damageStats.minChaosDamage ?? 0;
  baseDamageStats.maxChaosDamage += damageStats.maxChaosDamage ?? 0;

  baseDamageStats.minLightningDamage += damageStats.minLightningDamage ?? 0;
  baseDamageStats.maxLightningDamage += damageStats.maxLightningDamage ?? 0;

  baseDamageStats.minElementalDamage += damageStats.minElementalDamage ?? 0;
  baseDamageStats.maxElementalDamage += damageStats.maxElementalDamage ?? 0;

  baseDamageStats.minFireDamage += damageStats.minFireDamage ?? 0;
  baseDamageStats.maxFireDamage += damageStats.maxFireDamage ?? 0;

  baseDamageStats.minColdDamage += damageStats.minColdDamage ?? 0;
  baseDamageStats.maxColdDamage += damageStats.maxColdDamage ?? 0;

  baseDamageStats.minAllyDamage += damageStats.minAllyDamage ?? 0;
  baseDamageStats.maxAllyDamage += damageStats.maxAllyDamage ?? 0;

  baseDamageStats.allyDamageScale += damageStats.allyDamageScale ?? 0;

  baseDamageStats.damageScale += damageStats.damageScale ?? 0;
  baseDamageStats.rangedDamageScale += damageStats.rangedDamageScale ?? 0;
  baseDamageStats.meleeDamageScale += damageStats.meleeDamageScale ?? 0;
  baseDamageStats.aoeDamageScale += damageStats.aoeDamageScale ?? 0;
  baseDamageStats.elementalDamageScale += damageStats.elementalDamageScale ?? 0;

  baseDamageStats.attackSpeed += damageStats.attackSpeed ?? 0;

  baseDamageStats.attackSpeedScale += damageStats.attackSpeedScale ?? 0;

  baseDamageStats.critChance += damageStats.critChance ?? 0;
  baseDamageStats.critScale += damageStats.critScale ?? 0;
  baseDamageStats.knockbackChance += damageStats.knockbackChance ?? 0;
  baseDamageStats.knockback += damageStats.knockback ?? 0;
  baseDamageStats.burnChance += damageStats.burnChance ?? 0;
  baseDamageStats.burnDuration += damageStats.burnDuration ?? 0;
  baseDamageStats.burnDamageScale += damageStats.burnDamageScale ?? 0;
  baseDamageStats.freezeChance += damageStats.freezeChance ?? 0;
  baseDamageStats.freezeDuration += damageStats.freezeDuration ?? 0;
  baseDamageStats.poisonChance += damageStats.poisonChance ?? 0;
  baseDamageStats.poisonDuration += damageStats.poisonDuration ?? 0;
  baseDamageStats.poisonInterval += damageStats.poisonInterval ?? 0;
  baseDamageStats.stunChance += damageStats.stunChance ?? 0;
  baseDamageStats.stunDuration += damageStats.stunDuration ?? 0;
  baseDamageStats.bleedChance += damageStats.bleedChance ?? 0;
  baseDamageStats.bleedDamage += damageStats.bleedDamage ?? 0;
  baseDamageStats.bleedDuration += damageStats.bleedDuration ?? 0;
  baseDamageStats.slowChance += damageStats.slowChance ?? 0;
  baseDamageStats.slowDuration += damageStats.slowDuration ?? 0;
  baseDamageStats.slowAmount += damageStats.slowAmount ?? 0;
  baseDamageStats.shockChance += damageStats.shockChance ?? 0;
  baseDamageStats.shockMultiplier += damageStats.shockMultiplier ?? 0;

  baseDamageStats.areaOfEffect += damageStats.areaOfEffect ?? 0;
  baseDamageStats.range += damageStats.range ?? 0;
  baseDamageStats.chance += damageStats.chance ?? 0;

  baseDamageStats.pierceScale += damageStats.pierceScale ?? 0;

  return baseDamageStats;
};

export const invalidateDamageStats = (entity: number, gameModel: GameModel) => {
  if (gameModel.hasComponent(entity, DamageStatsSchema)) {
    gameModel.getTypedUnsafe(entity, DamageStatsSchema).invalidated = true;
    gameModel.getSystem(DamageStatsSystem).run(entity, gameModel);
  }
};

export { DamageStatsSchema };

import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { EntityFactory } from "yage/entity/EntityFactory";
import { GameModel } from "yage/game/GameModel";
import { findSpawn } from "../../utils/spawn";

@Component("TreeSpawner")
export class TreeSpawnerSchema extends Schema {
  @type("number")
  @defaultValue(10)
  spawnFrequency: number;

  @type("number")
  @defaultValue(-1)
  spawnTimer: number;

  @type("number")
  @defaultValue(0)
  treeStat: number;
}

class TreeSpawnerSystem implements System {
  schema = TreeSpawnerSchema;
  type = "TreeSpawner";
  depth = DEPTHS.CORE + 1;

  init(entity: number, gameModel: GameModel) {
    const treeSpawnerData = gameModel.getTypedUnsafe(entity, TreeSpawnerSchema);
    if (treeSpawnerData.spawnTimer === -1) {
      treeSpawnerData.spawnTimer = treeSpawnerData.spawnFrequency * 1000;
    }
  }

  rollSpawn(data: TreeSpawnerSchema, gameModel: GameModel) {
    let mod = data.treeStat % 3;
    let rand = gameModel.rand.number();
    if (mod === 0) {
      if (rand < 0.5) {
        return Math.floor(data.treeStat / 3);
      } else {
        return Math.floor(data.treeStat / 3) + 1;
      }
    } else if (mod === 1) {
      if (rand < 0.17) {
        return Math.floor(data.treeStat / 3);
      } else {
        return Math.floor(data.treeStat / 3) + 1;
      }
    } else {
      if (rand < 0.83) {
        return Math.floor(data.treeStat / 3) + 1;
      } else {
        return Math.floor(data.treeStat / 3) + 2;
      }
    }
  }

  run(entity: number, gameModel: GameModel) {
    const treeSpawnerData = gameModel.getTypedUnsafe(entity, TreeSpawnerSchema);

    treeSpawnerData.spawnTimer -= gameModel.dt<number>(entity);
    // console.log(treeSpawnerData.spawnTimer);

    if (treeSpawnerData.spawnTimer <= 0) {
      const spawns = this.rollSpawn(treeSpawnerData, gameModel);

      if (spawns !== 0) {
        for (let i = 0; i < spawns; i++) {
          const spawnPosition = findSpawn(gameModel, 300);
          EntityFactory.getInstance().generateEntity(gameModel, "TreeEnemy", {
            Transform: spawnPosition,
          });
        }
      }
      treeSpawnerData.spawnTimer = treeSpawnerData.spawnFrequency * 1000;
    }
  }
}

registerSystem(TreeSpawnerSystem);

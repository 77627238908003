import { Viewport } from "pixi-viewport";
import { registerPixiComponent, registerSchema } from "yage/components/ComponentRegistry";
import { PixiDrawSystem } from "yage/components/PixiDrawSystem";
import { Component, Schema, defaultValue, required, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import * as PIXI from "pixi.js";
import { generate, random } from "yage/utils/rand";
import ImageLoader from "yage/loader/ImageLoader";
import { SpriteComponentPixi } from "yage/components/render/Sprite";
import { TransformSchema } from "yage/schemas/entity/Transform";

@Component("Stamps")
export class StampsSchema extends Schema {
  @type(["string"])
  @required()
  stamps: string[];

  @type("number")
  @required()
  width: number;

  @type("number")
  @required()
  height: number;

  @type("number")
  seed: number;

  @type("number")
  @defaultValue(0.1)
  frequency: number;

  @type("number")
  @required()
  gridSize: number;

  @type("string")
  @defaultValue("Sprite")
  inheritZIndex: string;
}

registerSchema(StampsSchema);

class PixiStamps implements PixiDrawSystem {
  ids: Set<number> = new Set();
  schema = StampsSchema;
  instances: {
    [id: number]: {
      container: PIXI.Container;
      stamps: PIXI.Sprite[];
      height: number;
      width: number;
    };
  } = {};
  imageCache: { [id: string]: PIXI.Sprite[] } = {};

  init(entity: number, gameModel: GameModel, viewport: Viewport) {
    const schema = gameModel.getTypedUnsafe(entity, StampsSchema);
    schema.seed = schema.seed || gameModel.rand.int(0);

    const rand = generate(schema.seed);

    const zIndex = 2;

    const instance: {
      container: PIXI.Container;
      stamps: PIXI.Sprite[];
      height: number;
      width: number;
    } = {
      container: new PIXI.Container(),
      stamps: [],
      height: schema.height,
      width: schema.width,
    };

    instance.container.zIndex = zIndex;

    const rows = Math.ceil(schema.height / schema.gridSize);
    const cols = Math.ceil(schema.width / schema.gridSize);

    for (let row = 0; row < rows; row++) {
      for (let col = 0; col < cols; col++) {
        if (rand.number() > schema.frequency) continue;
        const imageTexture = ImageLoader.getInstance().getPixiTexture(schema.stamps[rand.int(schema.stamps.length)]);

        const stamp = new PIXI.Sprite(imageTexture);
        stamp.x = col * schema.gridSize - schema.width / 2;
        stamp.y = row * schema.gridSize - schema.height / 2;
        stamp.width = schema.gridSize;
        stamp.height = schema.gridSize;
        instance.stamps.push(stamp);
        instance.container.addChild(stamp);
      }
    }

    this.instances[entity] = instance;
    viewport.addChild(instance.container!);
    this.ids.add(entity);
  }
  run(entity: number, gameModel: GameModel, viewport: Viewport) {
    const instanceData = this.instances[entity];
    if (!instanceData) {
      return;
    }
    TransformSchema.id = entity;
    const position = TransformSchema.position;
    instanceData.container.x = position.x;
    instanceData.container.y = position.y;
    const schema = gameModel.getTypedUnsafe(entity, StampsSchema);
    if (schema.inheritZIndex) {
      const parentSprite = gameModel.getPixiSystem<SpriteComponentPixi>(schema.inheritZIndex).instances[entity]
        ?.container;
      if (parentSprite) {
        instanceData.container.zIndex = parentSprite.zIndex + 1;
      }
    }
    if (schema.height !== instanceData.height || schema.width !== instanceData.width) {
      instanceData.container.scale.x = schema.width / instanceData.width;
      instanceData.container.scale.y = schema.height / instanceData.height;
    }
  }
  cleanup(entity: number, gameModel: GameModel, viewport: Viewport) {
    const instanceData = this.instances[entity];
    this.ids.delete(entity);
    if (!instanceData) {
      return;
    }
    instanceData.container.destroy();
    const stamps = this.instances[entity].stamps;
    delete this.instances[entity];
    for (const stamp of stamps) {
      stamp.destroy(false);
      // stamp.visible = false;
      // this.imageCache[stamp] = this.imageCache[instanceData.spriteKey] ?? [];
      // this.imageCache[instanceData.spriteKey].push(stamps);
      // this.ids.delete(entity);
    }
  }
}

registerPixiComponent("Stamps", PixiStamps);

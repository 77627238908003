import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { ComponentDataSchema } from "yage/components/types";
import { Component, Schema, defaultValue, required, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { HealthSchema } from "../core";

@Component("MutationStep")
class MutationStepSchema extends Schema {
  @type("number")
  health: number;

  @type("number")
  time: number;

  @type(["string"])
  @defaultValue([])
  removeComponents: string[];

  @type([ComponentDataSchema])
  @defaultValue([])
  addComponents: ComponentDataSchema[];
}

@Component("Mutation")
class MutationSchema extends Schema {
  @type([MutationStepSchema])
  @required()
  steps: MutationStepSchema[] = [];

  @type("number")
  @defaultValue(0)
  currentStep: number;

  @type("number")
  @defaultValue(10000)
  stepTimer: number;
}

class MutationSystem implements System {
  schema = MutationSchema;
  type = "Mutation";
  depth = DEPTHS.CORE + 1;

  init(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, MutationSchema);
    data.currentStep = -1;

    if (data.steps.length === 0) {
      gameModel.removeComponent(entity, MutationSchema);
      return;
    }
  }

  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, MutationSchema);
    const nextStep = data.steps[data.currentStep + 1];

    if (!nextStep) {
      gameModel.removeComponent(entity, MutationSchema);
      return;
    }
    data.stepTimer += gameModel.dt<number>(entity);

    HealthSchema.id = entity;

    if (
      (nextStep && nextStep.time < data.stepTimer) ||
      HealthSchema.health / HealthSchema.maxHealth < nextStep.health
    ) {
      data.currentStep++;
      data.stepTimer = 0;

      const step = data.steps[data.currentStep];
      for (let i = 0; i < step.removeComponents.length; i++) {
        gameModel.removeComponent(entity, step.removeComponents[i]);
      }
      for (let i = 0; i < step.addComponents.length; i++) {
        gameModel.addComponent(entity, step.addComponents[i].type, step.addComponents[i].data);
      }
    }
  }
}

registerSystem(MutationSystem);

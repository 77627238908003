import { DEPTHS, registerSchema, registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { Component, Schema, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { FlatMapSchema } from "./Map";
import { StampsSchema } from "../render";
import { GraphicSchema } from "yage/schemas/render/Graphic";

@Component("MapWalls")
export class MapWallsSchema extends Schema {
  @type("number")
  width: number;

  @type("number")
  height: number;
}

class MapWallsSystem implements System {
  schema = MapWallsSchema;
  type = "MapWalls";

  dependencies = ["Stamps", "Graphic"];

  depth = DEPTHS.PREDRAW;

  init(entity: number, gameModel: GameModel) {
    const coreData = gameModel.getTypedUnsafe(gameModel.coreEntity, FlatMapSchema);
    const stampData = gameModel.getTypedUnsafe(entity, StampsSchema);
    const graphicData = gameModel.getTypedUnsafe(entity, GraphicSchema);

    stampData.height = coreData.height;
    stampData.width = coreData.width;

    graphicData.rectangle.height = coreData.height + 150;
    graphicData.rectangle.width = coreData.width + 150;

    const data = gameModel.getTypedUnsafe(entity, MapWallsSchema);
    data.height = coreData.height * coreData.scale;
    data.width = coreData.width * coreData.scale;

    this.rescaleColliders(entity, data, gameModel);
  }

  rescaleColliders(entity: number, data: MapWallsSchema, gameModel: GameModel) {
    const halfWidth = data.width / 2;
    const halfWidthPlus = halfWidth + 300;
    const polyData = {
      collisionEvents: true,
      isStatic: true,
      shapes: [
        {
          points: [
            { x: -halfWidthPlus, y: -halfWidth },
            { x: halfWidthPlus, y: -halfWidth },
            { x: halfWidthPlus, y: -halfWidthPlus },
            { x: -halfWidthPlus, y: -halfWidthPlus },
          ],
        },
        {
          points: [
            { x: halfWidth, y: -halfWidthPlus },
            { x: halfWidth, y: halfWidthPlus },
            { x: halfWidthPlus, y: halfWidthPlus },
            { x: halfWidthPlus, y: -halfWidthPlus },
          ],
        },
        {
          points: [
            { x: halfWidthPlus, y: halfWidth },
            { x: -halfWidthPlus, y: halfWidth },
            { x: -halfWidthPlus, y: halfWidthPlus },
            { x: halfWidthPlus, y: halfWidthPlus },
          ],
        },
        {
          points: [
            { x: -halfWidth, y: halfWidthPlus },
            { x: -halfWidth, y: -halfWidthPlus },
            { x: -halfWidthPlus, y: -halfWidthPlus },
            { x: -halfWidthPlus, y: halfWidthPlus },
          ],
        },
      ],
    };

    if (gameModel.hasComponent(entity, "RigidPoly")) {
      gameModel.removeComponent(entity, "RigidPoly");
    }

    gameModel.addComponent(entity, "RigidPoly", polyData);
  }

  run(entity: number, gameModel: GameModel) {
    const coreData = gameModel.getTypedUnsafe(gameModel.coreEntity, FlatMapSchema);
    const data = gameModel.getTypedUnsafe(entity, MapWallsSchema);

    if (coreData.height * coreData.scale === data.height && coreData.width * coreData.scale === data.width) {
      return;
    }
    data.height = coreData.height * coreData.scale;
    data.width = coreData.width * coreData.scale;

    const stampData = gameModel.getTypedUnsafe(entity, StampsSchema);
    const graphicData = gameModel.getTypedUnsafe(entity, GraphicSchema);

    stampData.height = data.height;
    stampData.width = data.width;

    graphicData.rectangle.height = data.height + 150;
    graphicData.rectangle.width = data.width + 150;
    this.rescaleColliders(entity, data, gameModel);
  }
}

registerSystem(MapWallsSystem);

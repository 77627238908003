import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import type { System } from "yage/components/System";
import type { ComponentData } from "yage/components/types";
import { ComponentCategory, ComponentDataSchema } from "yage/components/types";
import { FlingComponentPositionTypeEnum, PickupStateEnum } from "yage/constants/enums";
import { Component, defaultValue, Schema, type } from "yage/decorators/type";
import type { GameModel } from "yage/game/GameModel";
import { LocomotionSchema } from "yage/schemas/entity/Locomotion";
import { TransformSchema } from "yage/schemas/entity/Transform";
import { RigidBoxSchema } from "yage/schemas/physics/RigidBox";
import { RigidCircleSchema } from "yage/schemas/physics/RigidCircle";
import type { Vector2d } from "yage/utils/vector";
import { addVector2d, lerpVector2d, normalizeVector2d, subtractVector2d, Vector2dSchema } from "yage/utils/vector";
// import { ShadowSchema } from "..";
// import { makePickupable } from "../../utils/pathfinding";
import { PickupStateSchema } from "./PickupState";
import { ShadowSchema } from "../player/Shadow";

@Component("Fling")
export class FlingSchema extends Schema {
  @type("number")
  @defaultValue(0)
  startFrame: number;

  @type("number")
  @defaultValue(1000)
  flingTimeMs: number;

  @type("number")
  @defaultValue(300)
  bounceTimeMs: number;

  @type(Vector2dSchema)
  @defaultValue({ x: 50, y: 130 })
  position: Vector2dSchema;

  @type("number")
  @defaultValue(0)
  radius: number;

  @type(FlingComponentPositionTypeEnum)
  @defaultValue(FlingComponentPositionTypeEnum.CIRCLE)
  positionType: FlingComponentPositionTypeEnum;

  @type(Vector2dSchema)
  @defaultValue({ x: 0, y: 0 })
  startPosition: Vector2d;

  @type("number")
  @defaultValue(200)
  height: number;

  @type("number")
  @defaultValue(0)
  heightOffset: number;

  @type([ComponentDataSchema])
  @defaultValue([])
  applyOnLandComponents: ComponentDataSchema[];
}
class FlingSystem implements System {
  type = "Fling";
  category: ComponentCategory = ComponentCategory.BEHAVIOR;
  schema = FlingSchema;
  depth = DEPTHS.PLAYER_MOVEMENT + 1;

  calculateInitialPositions(entity: number, data: FlingSchema, gameModel: GameModel) {
    TransformSchema.id = entity;
    data.startFrame = gameModel.timeElapsed;
    if (data.positionType === FlingComponentPositionTypeEnum.CIRCLE) {
      const radius = gameModel.rand.int(data.position.x, data.position.y);
      const angle = gameModel.rand.number() * Math.PI * 2;
      const x = Math.cos(angle) * radius;
      const y = Math.sin(angle) * radius;
      data.flingTimeMs =
        (0.5 + ((radius - data.position.x) / (data.position.y - data.position.x)) * 0.5) * data.flingTimeMs;
      data.position = { x, y };
    } else if (data.positionType === FlingComponentPositionTypeEnum.CIRCLE_POSITION) {
      const radius = gameModel.rand.int(0, data.radius);
      const angle = gameModel.rand.number() * Math.PI * 2;
      const x = Math.cos(angle) * radius;
      const y = Math.sin(angle) * radius;
      data.position = {
        x: data.position.x + x,
        y: data.position.y + y,
      };
    }
    if (gameModel.hasComponent(entity, "PickupState")) {
      data.position = addVector2d(TransformSchema.position, data.position); // makePickupable(addVector2d(TransformSchema.position, data.position), gameModel) ?? data.position;
      const pickupStateData = gameModel.getTypedUnsafe(entity, PickupStateSchema);
      pickupStateData.state = PickupStateEnum.STUCK;
    } else {
      data.position = addVector2d(TransformSchema.position, data.position);
    }
    data.startPosition = { ...TransformSchema.position };

    if (gameModel.hasComponent(entity, "RigidBox")) {
      gameModel.getTypedUnsafe(entity, RigidBoxSchema).disabled = true;
    }
    if (gameModel.hasComponent(entity, "RigidCircle")) {
      gameModel.getTypedUnsafe(entity, RigidCircleSchema).disabled = true;
    }
  }
  endFling(entity: number, data: FlingSchema, gameModel: GameModel) {
    TransformSchema.position = data.position;
    TransformSchema.z = 0;
    data.heightOffset = 0;

    if (gameModel.hasComponent(entity, "PickupState")) {
      const pickupStateData = gameModel.getTypedUnsafe(entity, PickupStateSchema);
      pickupStateData.state = PickupStateEnum.ON_THE_GROUND;
    }
    if (gameModel.hasComponent(entity, "RigidBox")) {
      gameModel.getTypedUnsafe(entity, RigidBoxSchema).disabled = false;
    }
    if (gameModel.hasComponent(entity, "RigidCircle")) {
      gameModel.getTypedUnsafe(entity, RigidCircleSchema).disabled = false;
    }
    gameModel.removeComponent(entity, "Fling");

    if (data.applyOnLandComponents.length > 0) {
      data.applyOnLandComponents.forEach((component: ComponentData) => {
        let componentData = component.data ?? {};
        if (component.inherit) {
          componentData = { ...(gameModel.getComponent(entity, component.type) ?? {}), ...componentData };
        }
        gameModel.addComponent(entity, component.type, componentData);
      });
    }
  }

  calculateFlingPosition(entity: number, timeElapsed: number, data: FlingSchema) {
    const time = timeElapsed / (data.flingTimeMs / 1000);
    const nextPosition = lerpVector2d(data.startPosition, data.position, time);
    const velocity = subtractVector2d(nextPosition, TransformSchema.position);
    const direction = normalizeVector2d(velocity);
    LocomotionSchema.id = entity;
    // LocomotionSchema.velocityX = velocity.x;
    // LocomotionSchema.velocityY = velocity.y;
    LocomotionSchema.directionX = direction.x;
    LocomotionSchema.directionY = direction.y;

    data.heightOffset = data.height * (1 - (Math.abs(0.5 - time) / 0.5) * (Math.abs(0.5 - time) / 0.5));
    // nextPosition.y = nextPosition.y - data.heightOffset;
    TransformSchema.position = nextPosition;
    TransformSchema.z = data.heightOffset;
  }

  calculateBouncePosition(entity: number, timeElapsed: number, data: FlingSchema) {
    const time = (timeElapsed - data.flingTimeMs / 1000) / (data.bounceTimeMs / 1000);
    const nextPosition = { ...data.position };
    data.heightOffset = (data.height / 4) * (1 - (Math.abs(0.5 - time) / 0.5) * (Math.abs(0.5 - time) / 0.5));
    // nextPosition.y = nextPosition.y - data.heightOffset;
    TransformSchema.position = nextPosition;
    TransformSchema.z = data.heightOffset;
  }

  init(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, FlingSchema);
    this.calculateInitialPositions(entity, data, gameModel);
  }

  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, FlingSchema);
    TransformSchema.id = entity;

    const timeElapsed = gameModel.timeElapsed - data.startFrame;
    if (timeElapsed >= data.flingTimeMs / 1000 + data.bounceTimeMs / 1000) {
      this.endFling(entity, data, gameModel);
    } else if (timeElapsed < data.flingTimeMs / 1000) {
      this.calculateFlingPosition(entity, timeElapsed, data);
    } else {
      this.calculateBouncePosition(entity, timeElapsed, data);
    }

    if (gameModel.hasComponent(entity, "Shadow")) {
      const shadow = gameModel.getTypedUnsafe(entity, ShadowSchema);
      shadow.height = data.height;
      shadow.heightOffset = data.heightOffset;
    }
  }
}

registerSystem(FlingSystem);

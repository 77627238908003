import type { SceneTimestep } from "yage/game/Scene";
import { Scene } from "yage/game/Scene";
import { EntityFactory } from "yage/entity/EntityFactory";
import type { MouseManager } from "yage/inputs/MouseManager";
import { UIService } from "yage/ui/UIService";
import { Text, TextConfig } from "yage/ui/Text";
import { Position, Rectangle } from "yage/ui/Rectangle";
import AssetLoader from "yage/loader/AssetLoader";
import { ConnectionInstance } from "yage/connection/ConnectionInstance";
import { Button, ButtonConfig } from "yage/ui/Button";
import { ImageBox } from "yage/ui/ImageBox";
import { TextInputConfig } from "yage/ui/TextInput";
import { flags } from "yage/console/flags";
import { InputManager } from "yage/inputs/InputManager";
import { KeyboardListener } from "yage/inputs/KeyboardListener";
import { GamepadListener, StandardGamepadRegions } from "yage/inputs/GamepadListener";

const BigText = (config: Partial<TextConfig>): Partial<TextConfig> => ({
  style: {
    lineHeight: "1.5",
  },
  fontSize: 32,
  ...config,
});

const BigTextInput = (config: Partial<TextInputConfig>): Partial<TextInputConfig> => ({
  style: {
    lineHeight: "1.5",
    borderColor: "white",
  },
  fontSize: 20,
  ...config,
});

const CallToAction = (config: Partial<ButtonConfig>): Partial<ButtonConfig> => ({
  style: {
    borderColor: "pink",
    backgroundColor: "green",
    textTransform: "uppercase",
  },
  fontSize: 32,
  ...config,
});

export class BruhtatoSplashScene extends Scene {
  static sceneName = "BruhtatoSplash";

  timestep: SceneTimestep = "continuous";
  dt = 4;

  paused = false;
  mouseManager: MouseManager;
  gameCanvasContext: CanvasRenderingContext2D;

  unsubKeyboard: () => void;
  unsubGampad: () => void;

  public initialize = async (args: any[]): Promise<void> => {
    UIService.configureUi(document.getElementById("uicanvas") as HTMLCanvasElement);
    await AssetLoader.getInstance().loadImage("lobby/background", "brotato/background.png");

    this.ui.background = new ImageBox(
      new Position("center", "center", {
        width: 1920,
        height: 1080,
      }),
      {
        imageKey: "lobby/background",
      }
    );
    this.load();
  };

  splashScreen() {
    this.ui.chatBox = new Text(
      [3, 3],
      BigText({
        label: "Bruhtato",
        style: {
          fontFamily: "Brotato",
        },
      })
    );

    const rect = new Rectangle(0, 0, 1920, 1080);
    rect.justify = "center";
    rect.align = "center";
    const inputManager = new InputManager();
    const keyboardListener = new KeyboardListener(inputManager);
    keyboardListener.init(["w", "a", "s", "d", "i", "j", "k", "l", "space"]);
    this.unsubKeyboard = () => keyboardListener.destroy();

    const gamepadListener = new GamepadListener(inputManager);
    gamepadListener.init(StandardGamepadRegions);
    this.unsubGampad = () => gamepadListener.destroy();

    const centerLine = 60;
    UIService.getInstance().enableKeyCapture(inputManager);

    function toggleFullScreen() {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
      } else if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }

    this.ui.fullscreen = new Button(
      new Position("left", "bottom", {
        width: 50,
        height: 50,
        yOffset: -20,
        xOffset: 20,
      }),
      {
        label: "⛶",
        onClick: toggleFullScreen,
        autoFocus: true,
        style: {
          backgroundColor: "transparent",
        },
      }
    );

    this.ui.initStart = new Button(
      new Position(50, centerLine, {
        width: 300,
        height: 100,
        yOffset: 150,
        xOffset: 150,
      }),
      CallToAction({
        label: "Start",
        onClick: () => {
          this.changeScene("ProjectVLobby");
        },
        autoFocus: true,
      })
    );

    this.ui.initTwintato = new Button(
      new Position(50, centerLine, {
        width: 300,
        height: 100,
        yOffset: 150,
        xOffset: -150,
      }),
      CallToAction({
        label: "Twintato",
        onClick: () => {
          this.changeScene("InputSelect");
        },
        autoFocus: true,
      })
    );
  }

  load = async () => {
    this.ui.loading = new Text(new Position("center", "center", { xOffset: -60, yOffset: -100 }), {
      label: "Loading",
      fontSize: 32,
      style: {
        textTransform: "uppercase",
        textAlign: "center",
      },
    });
    const loadingInterval = setInterval(() => {
      this.ui.loading!.config.label += ".";
      if (this.ui.loading!.config.label.length > 10) {
        this.ui.loading!.config.label = "Loading";
      }
    }, 300);

    await import("../components");
    const entityDefinitions = (await import("../entities")).default;
    EntityFactory.configureEntityFactory(entityDefinitions);

    await AssetLoader.getInstance().load();
    clearInterval(loadingInterval);
    delete this.ui.loading;

    if (flags.SKIP_MENU) {
      this.changeScene("ProjectVLobby");
    } else {
      this.splashScreen();
    }
  };

  run = () => {};
  connection: ConnectionInstance<any>;
  winner: string;

  public destroy() {
    super.destroy();
    console.log("MinMediator: destroy");
  }
}

import { registerSystem } from "yage/components/ComponentRegistry";
import { ComponentCategory } from "yage/components/types";
import { SoundType } from "yage/constants/enums";
import { Component, defaultValue, type, Schema, required } from "yage/decorators/type";
import type { GameModel } from "yage/game/GameModel";
import type { System } from "yage/components/System";
import { Vector2dSchema } from "yage/utils/vector";

@Component("SoundOnHit")
export class SoundOnHitSchema extends Schema {
  @type("string")
  @required()
  sound: string;

  @type(["string"])
  @defaultValue([])
  sounds: string[];

  @type("number")
  @defaultValue(SoundType.FX)
  soundType: SoundType;

  @type(Vector2dSchema)
  @defaultValue({ x: 0, y: 0 })
  hitPosition: Vector2dSchema;
}

class SoundOnHitSystem implements System {
  type = "SoundOnHit";
  category: ComponentCategory = ComponentCategory.ONHIT;
  schema = SoundOnHitSchema;
  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, SoundOnHitSchema);
    if (data.sound === "random" && data.sounds.length > 0) {
      // User specific sounds shouldn't modify the game random logic so use Math.random() directly
      gameModel.queueSound(data.sounds[Math.floor(Math.random() * data.sounds.length)], {
        type: data.soundType,
      });
    } else {
      gameModel.queueSound(data.sound, { type: data.soundType });
    }
  }
}

registerSystem(SoundOnHitSystem);

import { System } from "yage/components/System";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";
import { registerSystem } from "yage/components/ComponentRegistry";
import { EntityFactory } from "yage/entity/EntityFactory";
import { EnemyTypeSchema, PlayerTypeSchema } from "yage/components/entity/Types";

@Component("AlienEyes")
export class AlienEyesSchema extends Schema {
  @type("Entity")
  eyeEntity: number;

  @type("number")
  @defaultValue(0)
  projectiles: number;
}

class AlienEyesSystem implements System {
  schema = AlienEyesSchema;
  type = "AlienEyes";
  dependencies = ["Child"];
  init(entity: number, gameModel: GameModel) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      const data = gameModel.getTypedUnsafe(entity, AlienEyesSchema);

      gameModel.addIfMissing(parent, AlienEyesSchema);
      const parentData = gameModel.getTypedUnsafe(parent, AlienEyesSchema);
      parentData.projectiles += data.projectiles;

      if (gameModel.isActive(parentData.eyeEntity)) {
        gameModel.removeEntity(parentData.eyeEntity);
      }

      parentData.eyeEntity = EntityFactory.getInstance().generateEntity(gameModel, "AlienEyeInstance", {
        ProjectileCount: {
          projectileCount: parentData.projectiles,
        },
        Child: {
          parent: parent,
        },
        Owner: {
          owner: parent,
        },
      });
    }
  }
}

registerSystem(AlienEyesSystem);

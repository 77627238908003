import { EntityType } from "yage/constants/enums";
import { GameModel } from "yage/game/GameModel";
import { sortedByDistance } from "yage/utils/Collision";
import { Vector2d } from "yage/utils/vector";

export const getNextRandomEnemy = (currentTarget: number, colliders: EntityType[], gameModel: GameModel) => {
  const enemies = gameModel.getEntities(colliders);

  let randEnemy = gameModel.rand.int(0, enemies.length - 1);
  let enemy = enemies[randEnemy];
  if (enemy === currentTarget) {
    if (randEnemy === 0) {
      randEnemy = randEnemy + 1;
    } else {
      randEnemy = randEnemy - 1;
    }
    enemy = enemies[randEnemy] || -1;
  }
  return enemy;
};

export const getNextRandomEnemyInRange = (
  position: Vector2d,
  range: number,
  currentTarget: number,
  colliders: EntityType[],
  gameModel: GameModel
) => {
  const enemies = gameModel.getEntities(colliders);
  const damageables = gameModel.getComponentActives("Damageable");

  const targetableEnemies = sortedByDistance(
    gameModel,
    position,
    enemies.filter((e) => damageables.includes(e)),
    range
  );

  let randEnemy = gameModel.rand.int(0, targetableEnemies.length - 1);
  let enemy = targetableEnemies[randEnemy];
  if (enemy === currentTarget) {
    if (randEnemy === 0) {
      randEnemy = randEnemy + 1;
    } else {
      randEnemy = randEnemy - 1;
    }
    enemy = targetableEnemies[randEnemy] || -1;
  }
  return enemy;
};

import { System } from "yage/components/System";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { DamageStatsSchema, applyDamageStats, createDamageStats } from "../weapons/DamageStats";
import { takeDamage } from "../damage/Damageable";
import { OwnerSchema } from "yage/schemas/core/Owner";
import { ComponentCategory, DamageCategoryEnum, DamageTypeEnum } from "yage/constants/enums";
import { registerSystem } from "yage/components/ComponentRegistry";

@Component("DamageOnDodge")
export class DamageOnDodgeSchema extends Schema {
  @type("number")
  @defaultValue(0)
  damageScale: number;

  @type("number")
  @defaultValue(0)
  meleeDamageScale: number;

  @type("number")
  @defaultValue(0)
  rangedDamageScale: number;

  @type("number")
  @defaultValue(0)
  allyDamageScale: number;

  @type("number")
  @defaultValue(0)
  aoeDamageScale: number;

  @type("number")
  @defaultValue(0)
  elementalDamageScale: number;

  @type("number")
  @defaultValue(-1)
  maker: number;

  @type("number")
  @defaultValue(-1)
  dodgeSource: number;
}

class DamageOnDodgeSystem implements System {
  schema = DamageOnDodgeSchema;
  type = "DamageOnDodge";
  category = ComponentCategory.ONDODGE;

  run(entity: number, gameModel: GameModel) {
    const damageOnDodgeData = gameModel.getTypedUnsafe(entity, DamageOnDodgeSchema);
    const dodgeSource = damageOnDodgeData.dodgeSource;

    if (!gameModel.isActive(damageOnDodgeData.maker) || damageOnDodgeData.maker === damageOnDodgeData.dodgeSource) {
      return;
    }

    const damageStats = gameModel.getTypedUnsafe(dodgeSource, DamageStatsSchema);
    const owner = gameModel.getTyped(dodgeSource, OwnerSchema)?.owner ?? dodgeSource;

    const dodgeDamageStats = applyDamageStats(createDamageStats(), damageStats);
    dodgeDamageStats.minDamage = 1;
    dodgeDamageStats.maxDamage = 1;

    let damages = [];
    dodgeDamageStats.damageScale = damageOnDodgeData.damageScale;
    if (damageOnDodgeData.damageScale > 0) {
      damages.push({
        damageStats: dodgeDamageStats,
        source: dodgeSource,
        owner: owner,
        frame: gameModel.timeElapsed,
        damageType: DamageTypeEnum.NORMAL,
      });
    }

    dodgeDamageStats.allyDamageScale = damageOnDodgeData.allyDamageScale;
    if (damageOnDodgeData.allyDamageScale > 0) {
      damages.push({
        damageStats: dodgeDamageStats,
        source: dodgeSource,
        owner: owner,
        frame: gameModel.timeElapsed,
        damageType: DamageTypeEnum.ALLY,
      });
    }
    dodgeDamageStats.meleeDamageScale = damageOnDodgeData.meleeDamageScale;
    if (damageOnDodgeData.meleeDamageScale > 0) {
      damages.push({
        damageStats: dodgeDamageStats,
        source: dodgeSource,
        owner: owner,
        frame: gameModel.timeElapsed,
        damageType: DamageTypeEnum.NORMAL,
        damageCategory: DamageCategoryEnum.MELEE,
      });
    }
    dodgeDamageStats.rangedDamageScale = damageOnDodgeData.rangedDamageScale;
    if (damageOnDodgeData.rangedDamageScale > 0) {
      damages.push({
        damageStats: dodgeDamageStats,
        source: dodgeSource,
        owner: owner,
        frame: gameModel.timeElapsed,
        damageType: DamageTypeEnum.NORMAL,
        damageCategory: DamageCategoryEnum.RANGED,
      });
    }
    dodgeDamageStats.aoeDamageScale = damageOnDodgeData.aoeDamageScale;
    if (damageOnDodgeData.aoeDamageScale > 0) {
      damages.push({
        damageStats: dodgeDamageStats,
        source: dodgeSource,
        owner: owner,
        frame: gameModel.timeElapsed,
        damageType: DamageTypeEnum.NORMAL,
        damageCategory: DamageCategoryEnum.AOE,
      });
    }

    takeDamage(damageOnDodgeData.maker, gameModel, damages);
  }
}

registerSystem(DamageOnDodgeSystem);

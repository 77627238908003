import { Component, defaultValue, Schema, type } from "yage/decorators/type";
import { Vector2dSchema } from "yage/utils/vector";
import { CollisionCategoryEnum } from "yage/constants/enums";

import type { System } from "yage/components/System";
import type { GameModel } from "yage/game/GameModel";
import { ComponentCategory } from "yage/components/types";
import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import { PhysicsSystem } from "yage/components/physics/Physics";
import { angleOfVector2d, scaleVector2d } from "yage/utils/vector";
import type RAPIERType from "@dimforge/rapier2d-compat";
import { LocomotionSchema } from "yage/schemas/entity/Locomotion";
import { TransformSchema } from "yage/schemas/entity/Transform";
import { CollisionsSchema } from "yage/schemas/physics/Collisions";
// @ts-ignore
import polyDecomp from "poly-decomp";

// @ts-ignore
const RAPIER = () => window.__YAGE__["RAPIER"];

class ShapeSchema extends Schema {
  @type([Vector2dSchema])
  points: Vector2dSchema[];
}

@Component("RigidPoly")
export class RigidPolySchema extends Schema {
  @type([ShapeSchema])
  shapes: ShapeSchema[];

  @type("number")
  bodyId: number;

  @type("boolean")
  @defaultValue(false)
  isStatic: boolean;

  @type("boolean")
  @defaultValue(false)
  isSensor: boolean;

  @type("number")
  @defaultValue(0)
  restitution: number;

  @type("number")
  @defaultValue(0)
  mass: number;

  @type("number")
  @defaultValue(0)
  angle: number;

  @type("boolean")
  @defaultValue(false)
  disabled: boolean;

  @type(Vector2dSchema)
  @defaultValue({ x: 0, y: 0 })
  point: Vector2dSchema;

  @type("boolean")
  @defaultValue(false)
  velocityLock: boolean;

  @type("boolean")
  @defaultValue(false)
  directionLock: boolean;

  @type(CollisionCategoryEnum)
  @defaultValue(CollisionCategoryEnum.DEFAULT)
  collisionCategory: CollisionCategoryEnum;

  @type(["number"])
  collisionMask: CollisionCategoryEnum[];

  @type("boolean")
  @defaultValue(false)
  collisionEvents: boolean;
}

@Component("RigidPolyResolver")
export class RigidPolyResolverSchema extends Schema {
  @type("number")
  bodyId: number;
}

export class RigidPolySystem implements System {
  type = "RigidPoly";
  category: ComponentCategory = ComponentCategory.PHYSICS;
  schema = RigidPolySchema;
  depth = DEPTHS.COLLISION - 0.0001;

  bodies: { [key: number]: RAPIERType.RigidBody } = {};

  init(entity: number, gameModel: GameModel) {
    const rigidPoly = gameModel.getTypedUnsafe(entity, RigidPolySchema);

    const transformSchema = gameModel.getTypedUnsafe(entity, TransformSchema);
    const position = transformSchema.position;

    const physicsSystem = gameModel.getSystem(PhysicsSystem);

    const engine = physicsSystem.getEngine(gameModel);

    const prevBody = this.bodies[entity];
    if (prevBody) {
      engine.removeRigidBody(prevBody);
    }

    let filterMask = CollisionCategoryEnum.ALL as number;
    if (rigidPoly.collisionMask) {
      filterMask = rigidPoly.collisionMask.reduce((acc, val) => acc | val, 0);
    }
    const memberMask = rigidPoly.collisionCategory << 16;

    const rigidBodyDesc = rigidPoly.isStatic ? RAPIER().RigidBodyDesc.fixed() : RAPIER().RigidBodyDesc.dynamic();
    rigidBodyDesc.setTranslation(position.x, position.y);

    const rigidBody = engine.createRigidBody(rigidBodyDesc);

    rigidPoly.shapes.forEach((shape) => {
      const points = shape.points.map((v) => [v.x, v.y]);
      let decomp: number[][][] = [];
      if (polyDecomp.isSimple(points)) {
        polyDecomp.makeCCW(points);
        decomp = polyDecomp.quickDecomp(points);
      } else if (!decomp.length) {
        decomp.push(points);
      }

      decomp.forEach((pointSet) => {
        const pointsF32 = new Float32Array(pointSet.flat());
        let colliderDesc = RAPIER().ColliderDesc.convexHull(pointsF32);
        if (colliderDesc) {
          engine.createCollider(colliderDesc, rigidBody);
          colliderDesc.setCollisionGroups(memberMask | filterMask);

          if (rigidPoly.isSensor) {
            colliderDesc = colliderDesc.setSensor(true);
          }

          if (rigidPoly.collisionEvents) {
            colliderDesc.setActiveEvents(RAPIER().ActiveEvents.COLLISION_EVENTS);
          }
          const collider = engine.createCollider(colliderDesc, rigidBody);

          collider.setTranslationWrtParent(rigidPoly.point);
        }
      });
    });
    // physicsSystem.colliderHandleMap.entityToHandle[entity] = collider.handle;
    // physicsSystem.colliderHandleMap.handleToEntity[collider.handle] = entity;

    this.bodies[entity] = rigidBody;

    gameModel.addIfMissing(entity, RigidPolyResolverSchema);
  }

  runAll(gameModel: GameModel): void {
    const entities = gameModel.getComponentActives("RigidPoly");

    for (let i = 0; i < entities.length; i++) {
      const entity = entities[i];
      const rigidPoly = gameModel.getTypedUnsafe(entity, RigidPolySchema);

      let body = this.bodies[entity];

      if (rigidPoly.disabled) {
        if (body) {
          this.cleanup(entity, gameModel);
        }
        continue;
      } else {
        if (!body) {
          this.init(entity, gameModel);
          body = this.bodies[entity];
        }
      }

      const transformSchema = gameModel.getTypedUnsafe(entity, TransformSchema);
      const position = transformSchema.position;

      const locomotionSchema = gameModel.getTypedUnsafe(entity, LocomotionSchema);
      const velocity = locomotionSchema.velocity;

      body.setTranslation(position, true);

      if (velocity) {
        body.setLinvel(scaleVector2d(velocity, 60), true);
      }

      if (gameModel.hasComponent(entity, RigidPolySchema) && gameModel.hasComponent(entity, LocomotionSchema)) {
        rigidPoly.angle = angleOfVector2d({ x: locomotionSchema.directionX, y: locomotionSchema.directionY });
      }

      const rads = rigidPoly.angle * (Math.PI / 180);
      if (rads !== body.rotation()) {
        body.setRotation(rads, true);
      }
    }
  }

  cleanup(entity: number, gameModel: GameModel) {
    gameModel.removeComponent(entity, "RigidPolyResolver");

    const physicsSystem = gameModel.getSystem(PhysicsSystem);
    const engine = physicsSystem.getEngine(gameModel);
    const collisions = gameModel.getTypedUnsafe(gameModel.coreEntity, CollisionsSchema).collisionMap;
    if (collisions?.[entity]) {
      Object.keys(collisions[entity]).forEach((other) => {
        const otherKey = parseInt(other);
        if (collisions[otherKey]) {
          delete collisions[otherKey][entity];
        }
      });
      delete collisions[entity];
    }
    const handle = physicsSystem.colliderHandleMap.entityToHandle[entity];
    if (handle !== undefined) {
      const collider = engine.getCollider(handle);
      if (collider) engine.removeCollider(collider, false);
    }

    delete physicsSystem.colliderHandleMap.handleToEntity[handle];
    delete physicsSystem.colliderHandleMap.entityToHandle[entity];

    const body = this.bodies[entity];
    if (body) {
      try {
        engine.removeRigidBody(body);
      } catch (e) {}
      delete this.bodies[entity];
    }
  }
}

registerSystem(RigidPolySystem);

class RigidPolyResolverSystem implements System {
  type = "RigidPolyResolver";
  category: ComponentCategory = ComponentCategory.PHYSICS;
  depth = DEPTHS.COLLISION + 0.00001;
  schema = RigidPolyResolverSchema;

  runAll(gameModel: GameModel) {
    const rigidPolySystem = gameModel.getSystem(RigidPolySystem);
    const entities = gameModel.getComponentActives("RigidPolyResolver");

    for (let i = 0; i < entities.length; i++) {
      const entity = entities[i];

      const Poly = rigidPolySystem.bodies[entity];
      const rigidPoly = gameModel.getTypedUnsafe(entity, RigidPolySchema);
      if (!Poly) continue;

      const position = Poly.translation();

      const positionX = position.x - rigidPoly.point.x;
      const positionY = position.y - rigidPoly.point.y;

      if (rigidPoly.point.x == 0 && rigidPoly.point.y === 0) {
        const transformSchema = gameModel.getTypedUnsafe(entity, TransformSchema);
        transformSchema.x = positionX;
        transformSchema.y = positionY;
      }

      if (rigidPoly.velocityLock) {
        const velocity = Poly.linvel();
        const locomotionSchema = gameModel.getTypedUnsafe(entity, LocomotionSchema);

        locomotionSchema.velocityX = (velocity.x / 60) * (rigidPoly.restitution || 1);
        locomotionSchema.velocityY = (velocity.y / 60) * (rigidPoly.restitution || 1);
      }

      if (rigidPoly.directionLock) {
        const direction = Poly.rotation();
        const locomotionSchema = gameModel.getTypedUnsafe(entity, LocomotionSchema);
        locomotionSchema.directionX = Math.cos(direction);
        locomotionSchema.directionY = Math.sin(direction);
      }
    }
  }
}

registerSystem(RigidPolyResolverSystem);

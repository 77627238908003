import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { EntityType, DamageDirectionEnum } from "yage/constants/enums";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { EntityFactory } from "yage/entity/EntityFactory";
import { GameModel } from "yage/game/GameModel";
import { getWeaponAugmentComponents, applyComponentsToProjectile } from "../../utils/weapons";
import { EnemyCollidersSchema } from "../enemy/EnemyColliders";
import { findSpawnNearEntity } from "../../utils/spawn";

@Component("LandmineDropper")
export class LandmineDropperSchema extends Schema {
  @type("number")
  @defaultValue(0)
  spawnRate: number;

  @type("number")
  @defaultValue(0)
  mines: number;

  @type("number")
  @defaultValue(0)
  spawnTimer: number;

  @type(["number"])
  @defaultValue([])
  mineInstances: number[];
}

class LandmineDropperSystem implements System {
  schema = LandmineDropperSchema;
  type = "LandmineDropper";
  depth = DEPTHS.ITEMS;
  init(entity: number, gameModel: GameModel) {
    const landmineDropperData = gameModel.getTypedUnsafe(entity, LandmineDropperSchema);
    if (landmineDropperData.spawnRate > 0 && landmineDropperData.mines > 0) {
      landmineDropperData.spawnTimer = landmineDropperData.spawnRate;
    }
  }
  run(entity: number, gameModel: GameModel) {
    const landmineDropperData = gameModel.getTypedUnsafe(entity, LandmineDropperSchema);
    if (landmineDropperData.spawnRate > 0 && landmineDropperData.mines > 0) {
      landmineDropperData.spawnTimer -= gameModel.dt<number>(entity);
      if (landmineDropperData.spawnTimer <= 0) {
        this.spawnMines(entity, landmineDropperData, entity, gameModel);

        landmineDropperData.spawnTimer = landmineDropperData.spawnRate * 1000;
      }
    }
    for (const mine of landmineDropperData.mineInstances) {
      if (!gameModel.isActive(mine)) {
        landmineDropperData.mineInstances = landmineDropperData.mineInstances.filter((m) => m !== mine);
      }
    }
  }

  spawnMines(entity: number, data: LandmineDropperSchema, owner: number, gameModel: GameModel) {
    const [augmentComponents, auraComponents] = getWeaponAugmentComponents(entity, owner, gameModel);

    for (let i = 0; i < data.mines; i++) {
      const position = findSpawnNearEntity(gameModel, owner, 200, 500);

      const projectile = EntityFactory.getInstance().generateEntity(gameModel, "landmine", {
        Transform: { ...position },
        colliders: EntityType.ENEMY,
        Owner: { owner },
      });

      data.mineInstances.push(projectile);

      applyComponentsToProjectile(projectile, DamageDirectionEnum.OWNER, augmentComponents, auraComponents, gameModel);
    }

    // if (gameModel.hasComponent(projectile, "Targeting")) {
    //   let targetingData = gameModel.getTypedUnsafe(projectile, TargetingSchema);
    //   targetingData.shouldChase = true;
    //   targetingData.radius = 100;
    // }

    // gameModel.logEntity(projectile);
  }

  cleanup(entity: number, gameModel: GameModel, ejecting: boolean) {
    const landmineDropperData = gameModel.getTypedUnsafe(entity, LandmineDropperSchema);
    for (const mine of landmineDropperData.mineInstances) {
      gameModel.removeEntity(mine);
    }
    landmineDropperData.mineInstances = [];
  }
}

registerSystem(LandmineDropperSystem);

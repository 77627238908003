import { registerSchema } from "yage/components/ComponentRegistry";
import { Component, Schema } from "yage/decorators/type";

@Component("ItemType")
export class ItemTypeSchema extends Schema {}

registerSchema(ItemTypeSchema);

@Component("LevelUpType")
export class LevelUpTypeSchema extends Schema {}

registerSchema(LevelUpTypeSchema);

import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import { ComponentCategory, ComponentDataSchema } from "yage/components/types";
import { DamageTypeEnum, DamageDirectionEnum, EntityType, DamageCategoryEnum } from "yage/constants/enums";
import { Component, defaultValue, required, type, Schema } from "yage/decorators/type";
import type { GameModel } from "yage/game/GameModel";
import type { System } from "yage/components/System";
import { normalizeVector2d, subtractVector2d } from "yage/utils/vector";
import { takeDamage } from "./Damageable";
import { HealthSchema } from "../core/Health";
import { EnemyCollidersSchema } from "../enemy/EnemyColliders";
import { DamageStatsSchema } from "../weapons/DamageStats";
import { OwnerSchema } from "yage/schemas/core/Owner";
import { LocomotionSchema } from "yage/schemas/entity/Locomotion";
import { TransformSchema } from "yage/schemas/entity/Transform";
import { CollisionsSchema } from "yage/schemas/physics/Collisions";
import { ApproximateCollisionPosition } from "../../utils/physics";
import { getSourceWeapon } from "../../utils/weapons";
import { EntityTypeSchema } from "yage/components/entity/Types";
import { ParentSchema } from "yage/schemas/entity/Parent";
import { ChildSchema } from "yage/schemas/entity/Child";

export class DamageApplicationSchema extends Schema {
  @type("number")
  @defaultValue(DamageTypeEnum.NORMAL)
  damageType: DamageTypeEnum;

  @type("number")
  @defaultValue(DamageCategoryEnum.NONE)
  damageCategory: DamageCategoryEnum;

  @type([ComponentDataSchema])
  onHit: ComponentDataSchema[];

  @type("Entity")
  @required()
  owner: number;

  @type(DamageStatsSchema)
  damageStats: DamageStatsSchema;
}

@Component("DamageApplier")
export class DamageApplierSchema extends Schema {
  @type([DamageApplicationSchema])
  @defaultValue([])
  damages: DamageApplicationSchema[];

  @type("number")
  owner: number;

  @type("object")
  @defaultValue({})
  previousCollisions: { [key: number]: number };

  @type(DamageDirectionEnum)
  @defaultValue(DamageDirectionEnum.OWNER)
  damageDirection: DamageDirectionEnum;

  @type("boolean")
  @defaultValue(false)
  applyOnce: boolean;

  @type("number")
  @defaultValue(0)
  damageDelay: number;

  @type("boolean")
  @defaultValue(false)
  useParentDamageCollisions: boolean;

  @type([ComponentDataSchema])
  onHit: ComponentDataSchema[];
}

class DamageApplierSystem implements System {
  type = "DamageApplier";
  category: ComponentCategory = ComponentCategory.DAMAGEAPPLIER;
  schema = DamageApplierSchema;
  depth = DEPTHS.DAMAGE;
  run(entity: number, gameModel: GameModel) {
    if (!gameModel.hasComponent(entity, EnemyCollidersSchema)) {
      return;
    }
    const enemyColliders = gameModel.getTyped(entity, EnemyCollidersSchema)?.colliders;
    if (!enemyColliders || enemyColliders.length === 0 || !gameModel.isActive(entity)) {
      return;
    }
    const damageData = gameModel.getTypedUnsafe(entity, DamageApplierSchema);

    if (!damageData.damages.length) {
      return;
    }
    const previousCollisions = damageData.useParentDamageCollisions
      ? gameModel.getTypedUnsafe(gameModel.getTypedUnsafe(entity, ChildSchema).parent!, DamageApplierSchema)
          .previousCollisions
      : damageData.previousCollisions;

    if (!damageData.applyOnce) {
      // TODO: Fix for high latency
      const previousCollisionKeys = Object.keys(previousCollisions);
      for (let i = 0; i < previousCollisionKeys.length; i++) {
        const collision = parseInt(previousCollisionKeys[i]);
        if (previousCollisions[collision] < gameModel.timeElapsed) {
          delete previousCollisions[collision];
        }
      }
    }

    if (damageData.damages.length === 0) {
      const damageComponents = gameModel.getComponentIdsByCategory(entity, ComponentCategory.DAMAGE);

      for (let i = 0; i < damageComponents.length; i++) {
        const component = gameModel.getComponent(entity, damageComponents[i]);
        const system: (p: number, g: GameModel) => void = (gameModel.getSystem((component as any).type) as any).run;
        system(entity, gameModel);
      }
    }

    const owner = gameModel.getTyped(entity, OwnerSchema)?.owner ?? damageData.owner ?? entity;

    const collisionData = gameModel.getTyped(gameModel.coreEntity, CollisionsSchema)?.collisions[entity]?.filters ?? {};
    const collisions = Object.keys(collisionData ?? {});
    const baseEntityType = EntityTypeSchema.store.entityType[entity];

    const source = getSourceWeapon(entity, gameModel);

    const damageDirection = damageData.damageDirection;

    for (let i = 0; i < collisions.length; i++) {
      const entityType = parseInt(collisions[i]);

      if (baseEntityType === EntityType.ALLY) {
        gameModel.logEntity(entity, true);
      }

      if (enemyColliders.includes(entityType)) {
        for (let j = 0; j < (collisionData[entityType as number]?.length ?? 0); j++) {
          const collider = collisionData[entityType as number][j] as number;
          if (
            gameModel.hasComponent(collider, "Invincibility") ||
            collider === owner ||
            !gameModel.isActive(collider) ||
            !gameModel.hasComponent(collider, "Damageable") ||
            !!previousCollisions[collider]
          ) {
            continue;
          }

          LocomotionSchema.id = entity;

          let direction = { x: LocomotionSchema.directionX, y: LocomotionSchema.directionY };
          if (damageDirection === DamageDirectionEnum.OWNER && gameModel.isActive(damageData.owner)) {
            TransformSchema.id = owner;
            const ownerPosition = TransformSchema.position;

            TransformSchema.id = collider;
            const colliderPosition = TransformSchema.position;

            direction = normalizeVector2d(subtractVector2d(colliderPosition, ownerPosition));
          }

          const damages = damageData.damages.map((damage: DamageApplicationSchema) => ({
            ...damage,
            frame: gameModel.timeElapsed,
            direction: direction,
            owner: owner,
            source,
          }));

          const [damageTaken, invulnerabilityMs, damagesTaken] = takeDamage(collider, gameModel, damages);

          if (damageTaken > 0) {
            const ownerOnHitComponents = gameModel.getComponentIdsByCategory(owner, ComponentCategory.ONHIT);
            const entityOnHitComponents = gameModel.getComponentIdsByCategory(entity, ComponentCategory.ONHIT);
            const hitPosition = ApproximateCollisionPosition(entity, collider);

            for (let i = 0; i < ownerOnHitComponents.length; i++) {
              const component = gameModel.getComponentUnsafe(owner, ownerOnHitComponents[i]);
              if (component.hitPosition !== undefined) {
                component.hitPosition = hitPosition;
              }
              if (component.damage !== undefined) {
                component.damage = damageTaken;
              }
              if (component.damages !== undefined) {
                component.damages = damagesTaken;
              }
              if (component.collider !== undefined) {
                component.collider = collider;
              }

              const system = gameModel.getSystem((component as any).type);
              system.run?.(owner, gameModel);
              if (component.damages !== undefined) {
                component.damages = [];
              }
            }

            for (let i = 0; i < entityOnHitComponents.length; i++) {
              const component = gameModel.getComponentUnsafe(entity, entityOnHitComponents[i]);
              if (component.hitPosition !== undefined) {
                component.hitPosition = hitPosition;
              }
              if (component.damage !== undefined) {
                component.damage = damageTaken;
              }
              if (component.damages !== undefined) {
                component.damages = damagesTaken;
              }
              if (component.collider !== undefined) {
                component.collider = collider;
              }

              const system = gameModel.getSystem((component as any).type);
              system.run?.(entity, gameModel);
              if (component.damages !== undefined) {
                component.damages = [];
              }
            }
          }
          previousCollisions[collider] = gameModel.timeElapsed + (invulnerabilityMs + damageData.damageDelay);

          if (baseEntityType === EntityType.PROJECTILE && damageTaken && damageData.onHit) {
            for (let k = 0; k < damageData.onHit.length; k++) {
              const onHit = damageData.onHit[i];
              if (onHit.data.damage !== undefined) {
                onHit.data.damage = damageTaken;
              }
              onHit.data.owner = owner;
              gameModel.addComponent(collider, onHit.type, onHit.data);
              const system = gameModel.getSystem(onHit.type) as System;
              if (system.run) system.run(collider, gameModel);
            }
          }

          if (baseEntityType === EntityType.PROJECTILE && damageTaken && gameModel.hasComponent(entity, HealthSchema)) {
            const healthData = gameModel.getTypedUnsafe(entity, HealthSchema);
            if (healthData.health <= 0) {
              return;
            }
            healthData.health--;

            for (let k = 0; k < damages.length; k++) {
              const stats = damages[k].damageStats;
              if (stats.pierceScale) {
                stats.damageScale = Math.max(
                  (stats.damageScale || 1) * Math.min((stats.pierceScale ?? 0) + 1, 1),
                  0.000001
                );
              }
            }
          }
        }
      }
    }
  }
}

registerSystem(DamageApplierSystem);

import { registerSystem } from "yage/components/ComponentRegistry";
import { ComponentCategory } from "yage/components/types";
import { Component, defaultValue, type, Schema } from "yage/decorators/type";
import type { GameModel } from "yage/game/GameModel";
import type { System } from "yage/components/System";
import { EnemyCollidersSchema } from "../enemy/EnemyColliders";
import { OwnerSchema } from "yage/schemas/core/Owner";
import { DestroyOnTimeoutSchema } from "yage/schemas/timeouts/DestroyOnTimeoutComponent";
import { HealthSchema } from "../core";
import { DamageSchema, DamageStatsSchema } from "../../schema/damage/Damage";
import { FollowSchema } from "../weapons/Follow";
import { getNextRandomEnemy, getNextRandomEnemyInRange } from "../../utils/target";
import { Vector2dSchema } from "yage/utils/vector";
import { PlayerTypeSchema, EnemyTypeSchema } from "yage/components/entity/Types";
import { ChildSchema } from "yage/schemas/entity/Child";
import { MaterialsOnCratePickupSchema } from "../effects/MaterialsOnCratePickup";
import { MaterialsOnCratePickupEnhancerSchema } from "./MaterialsOnCratePickupEnhancer";
import { RetargetOnHitSchema } from "../onhit/RetargetOnHit";

@Component("RetargetOnHitEnhancer")
export class RetargetOnHitEnhancerSchema extends Schema {
  @type("number")
  @defaultValue(1)
  maxRetargets: number;
}

class RetargetOnHitEnhancerSystem implements System {
  type = "RetargetOnHitEnhancer";
  schema = RetargetOnHitEnhancerSchema;

  dependencies = ["Child"];

  init(entity: number, gameModel: GameModel) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (!gameModel.hasComponent(parent, "RetargetOnHitEnhancer")) {
        gameModel.addComponent(parent, RetargetOnHitEnhancerSchema, {
          maxRetargets: 0,
        });
      }
      if (!gameModel.hasComponent(parent, RetargetOnHitSchema)) {
        gameModel.addComponent(parent, RetargetOnHitSchema, {
          maxRetargets: 0,
        });
      }
      gameModel.getTypedUnsafe(parent, RetargetOnHitEnhancerSchema).maxRetargets += gameModel.getTypedUnsafe(
        entity,
        RetargetOnHitEnhancerSchema
      ).maxRetargets;
    }
  }

  cleanup(entity: number, gameModel: GameModel, ejecting: boolean) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (gameModel.hasComponent(parent, "RetargetOnHitEnhancer")) {
        gameModel.getTypedUnsafe(parent, RetargetOnHitEnhancerSchema).maxRetargets -= gameModel.getTypedUnsafe(
          entity,
          RetargetOnHitEnhancerSchema
        ).maxRetargets;
      }
    }
  }
}

registerSystem(RetargetOnHitEnhancerSystem);

import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { AttachSchema } from "yage/schemas/entity/Attach";

@Component("OrbitParent")
class OrbitParentSchema extends Schema {
  @type("number")
  @defaultValue(250)
  radius: number;

  @type("number")
  @defaultValue(2)
  speed: number;

  @type("number")
  @defaultValue(0)
  maxRadius: number;

  @type("number")
  @defaultValue(0)
  angle: number;
}

class OrbitParentSystem implements System {
  schema = OrbitParentSchema;
  type = "OrbitParent";
  category = "Orbit";
  depth = DEPTHS.LOCOMOTION - 1;

  init(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, OrbitParentSchema);
    data.angle = Math.random() * Math.PI * 2;
    data.speed = Math.random() * 0.5 + data.speed;
  }

  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, OrbitParentSchema);
    const attach = gameModel.getTypedUnsafe(entity, AttachSchema);

    if (!attach) {
      return;
    }
    const angle = data.angle + ((data.speed / Math.PI) * 2 * gameModel.dt<number>(entity)) / 1000;

    data.angle = angle;
    const x = Math.cos(angle) * data.radius;
    const y = Math.sin(angle) * data.radius;
    attach.offset = {
      x,
      y,
    };
  }
}

registerSystem(OrbitParentSystem);

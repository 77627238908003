import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";
import { ChanceDamageOnPickupSchema } from "../effects/ChanceDamageOnPickup";
import { EnemyTypeSchema, PlayerTypeSchema } from "yage/components/entity/Types";

@Component("ChanceDamageOnPickupEnhancer")
export class ChanceDamageOnPickupEnhancerSchema extends Schema {
  @type("number")
  @defaultValue(0.25)
  chance: number;

  @type("number")
  @defaultValue(0.25)
  chanceDamageScale: number;
}

class ChanceDamageOnPickupSystem implements System {
  schema = ChanceDamageOnPickupEnhancerSchema;
  type = "ChanceDamageOnPickupEnhancer";
  dependencies = ["Child"];
  init(entity: number, gameModel: GameModel) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (!gameModel.hasComponent(parent, "ChanceDamageOnPickup")) {
        gameModel.addComponent(parent, ChanceDamageOnPickupSchema);
      }
      gameModel
        .getTypedUnsafe(parent, ChanceDamageOnPickupSchema)
        .chance.push(gameModel.getTypedUnsafe(entity, ChanceDamageOnPickupEnhancerSchema));
    }
  }

  cleanup(entity: number, gameModel: GameModel, ejecting: boolean) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (gameModel.hasComponent(parent, "ChanceDamageOnPickup")) {
        const index = gameModel
          .getTypedUnsafe(parent, ChanceDamageOnPickupSchema)
          .chance.findIndex(
            (chance) =>
              chance.chance === gameModel.getTypedUnsafe(entity, ChanceDamageOnPickupEnhancerSchema).chance &&
              chance.chanceDamageScale ===
                gameModel.getTypedUnsafe(entity, ChanceDamageOnPickupEnhancerSchema).chanceDamageScale
          );
        if (index !== -1) {
          gameModel.getTypedUnsafe(parent, ChanceDamageOnPickupSchema).chance.splice(index, 1);
        }
      }
    }
  }
}
registerSystem(ChanceDamageOnPickupSystem);

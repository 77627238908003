import { registerSchema } from "yage/components/ComponentRegistry";
import { DamageCategoryEnum } from "yage/constants/enums";
import { Component, type, defaultValue, Schema } from "yage/decorators/type";

@Component("DamageCategory")
export class DamageCategorySchema extends Schema {
  @type("number")
  @defaultValue(DamageCategoryEnum.NONE)
  damageCategory: DamageCategoryEnum;
}

registerSchema(DamageCategorySchema);

import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { Component } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";
import { applyDamageStats, DamageStatsSchema, invalidateDamageStats, unapplyDamageStats } from "../weapons/DamageStats";
import { EnemyTypeSchema, PlayerTypeSchema } from "yage/components/entity/Types";

@Component("DamageStatsEnhancer")
export class DamageStatsEnhancerSchema extends DamageStatsSchema {}

class DamageStatsEnhancerSystem implements System {
  schema = DamageStatsEnhancerSchema;
  type = "DamageStatsEnhancer";
  dependencies = ["Child"];
  init(entity: number, gameModel: GameModel) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (!gameModel.hasComponent(parent, DamageStatsSchema)) {
        return;
      }
      const stats = gameModel.getTypedUnsafe(parent, DamageStatsSchema).initialDamageStats;
      const enhancer = gameModel.getTypedUnsafe(entity, DamageStatsEnhancerSchema);
      applyDamageStats(stats, enhancer);
      invalidateDamageStats(parent, gameModel);
    }
  }
  cleanup(entity: number, gameModel: GameModel) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (gameModel.isActive(parent)) {
        if (!gameModel.hasComponent(parent, DamageStatsSchema)) {
          return;
        }
        const stats = gameModel.getTypedUnsafe(parent, DamageStatsSchema).initialDamageStats;
        const enhancer = gameModel.getTypedUnsafe(entity, DamageStatsEnhancerSchema);
        unapplyDamageStats(stats, enhancer);
        invalidateDamageStats(parent, gameModel);
      }
    }
  }
}
registerSystem(DamageStatsEnhancerSystem);

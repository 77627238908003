import { DEPTHS, registerSystem, registerUIComponent } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { EntityFactory } from "yage/entity/EntityFactory";
import { GameModel } from "yage/game/GameModel";
import { Position } from "yage/ui/Rectangle";
import { UIElement } from "yage/ui/UIElement";
import { Text } from "yage/ui/Text";
import { strokeStyle } from "../../utils/ui-styles";
import { CountdownSchema, HealthSchema } from "../core";
import { OfferReviveOnDeathSchema } from "../player/OfferReviveOnDeath";
import { DestroyOnTimeoutSchema } from "yage/schemas/timeouts/DestroyOnTimeoutComponent";
import { flags } from "yage/console/flags";
import { GlobalSpawnIntervalEnhancerSchema } from "../enhancers/GlobalSpawnIntervalEnhancer";

@Component("Wave")
export class WaveSchema extends Schema {
  @type("number")
  wave: number;

  @type("Entity")
  waveEntity: number;

  @type("number")
  timeElapsed: number;

  @type("number")
  @defaultValue(1)
  playerCount: number;

  @type("boolean")
  countdownReset: boolean;

  @type("number")
  @defaultValue(0)
  difficulty: number;
}

class WaveSystem implements System {
  schema = WaveSchema;
  type = "Wave";

  depth = DEPTHS.HEALTH + 1;

  init(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, WaveSchema);
    data.wave++;

    switch (data.difficulty) {
      case 0:
        {
          const entity = gameModel.addEntity();
          gameModel.addComponent(entity, GlobalSpawnIntervalEnhancerSchema, {
            intervalScale: 0.5,
          });
        }
        break;
      case 1:
        {
          const entity = gameModel.addEntity();
          gameModel.addComponent(entity, GlobalSpawnIntervalEnhancerSchema, {
            intervalScale: 0.25,
          });
        }
        break;
      case 2:
        {
          const entity = gameModel.addEntity();
          gameModel.addComponent(entity, GlobalSpawnIntervalEnhancerSchema, {
            intervalScale: 0.05,
          });
        }
        break;
      case 3:
        {
          const entity = gameModel.addEntity();
          gameModel.addComponent(entity, GlobalSpawnIntervalEnhancerSchema, {
            intervalScale: -0.25,
          });
        }
        break;
      case 4:
        {
          const entity = gameModel.addEntity();
          gameModel.addComponent(entity, GlobalSpawnIntervalEnhancerSchema, {
            intervalScale: -0.5,
          });
        }
        break;
    }

    const wave = data.wave;
    let countdown = flags.SHORTCUT_WAVE ? 5 : Math.min((wave - 1) * 5 + 20, 60);
    if (Math.abs(wave - 20) % 10 === 0 && !flags.SHORTCUT_WAVE) {
      countdown = 90;
    }
    const overrides = {
      Countdown: {
        seconds: countdown,
        type: "Countdown",
        verticalOffset: 50,
        render: true,
        fontSize: 30,
        suffix: "s",
      },
    };

    gameModel.addComponent(entity, DestroyOnTimeoutSchema, {
      timeout: countdown * 1000,
      applyOnTimeout: [
        {
          type: "EndRound",
          data: {
            nextScene: "Shop",
          },
        },
      ],
    });

    if (EntityFactory.getInstance().hasEntity(`Wave${wave}`)) {
      data.waveEntity = EntityFactory.getInstance().generateEntity(gameModel, `Wave${wave}`, overrides);
    } else {
      data.waveEntity = EntityFactory.getInstance().generateEntity(gameModel, `WaveX`, overrides);
    }
  }

  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, WaveSchema);
    const revivables = gameModel.getComponentActives("OfferReviveOnDeath");

    if (!data.countdownReset) {
      const Countdown = gameModel.getTypedUnsafe(data.waveEntity, CountdownSchema);

      const timeLeft = Math.ceil(Countdown.seconds - (gameModel.timeElapsed - Countdown.startTime) / 1000);

      if (timeLeft < 6) {
        data.countdownReset = true;
        gameModel.removeComponent(data.waveEntity, CountdownSchema);

        gameModel.addComponent(data.waveEntity, CountdownSchema, {
          seconds: 5,
          type: "Countdown",
          vertical: "center",
          verticalOffset: -50,
          opacity: 0.6,
          render: true,
          fontSize: 70,
          suffix: "s",
          color: "red",
        });
      }
    }

    if (revivables.length === 0) {
      const players = gameModel.players;
      let allDead = true;
      for (let i = 0; i < players.length; i++) {
        if (HealthSchema.store.health[players[i]] > 0) {
          allDead = false;
          break;
        }
      }
      if (allDead) {
        // const lobby = gameModel.instance?.options.connection.localPlayers.length > 1 ? "ProjectVLobby" : "ProjectVLobbySingle";
        gameModel.gameCoordinator.changeScene("ProjectVLobby", {
          instance: gameModel.instance,
        });
      }
      return;
    }

    let actualDeadCount = 0;
    let reviveVisibleCount = 0;
    for (let i = 0; i < revivables.length; i++) {
      const reviveData = gameModel.getTypedUnsafe(revivables[i], OfferReviveOnDeathSchema);
      if (reviveData.reviveTriggered && reviveData.offerRevive) {
        actualDeadCount++;
      } else if (reviveData.offerRevive) {
        reviveVisibleCount++;
      }
    }
    if (actualDeadCount === revivables.length) {
      gameModel.gameCoordinator.changeScene("ProjectVLobby", {
        instance: gameModel.instance,
      });
    } else if (reviveVisibleCount === revivables.length - actualDeadCount) {
      gameModel.getTypedUnsafe(data.waveEntity, CountdownSchema).paused = true;
      if (data.timeElapsed === 0) {
        data.timeElapsed = gameModel.getTypedUnsafe(entity, DestroyOnTimeoutSchema).timeElapsed;
      }
      gameModel.getTypedUnsafe(entity, DestroyOnTimeoutSchema).timeElapsed = data.timeElapsed;
    } else {
      gameModel.getTypedUnsafe(data.waveEntity, CountdownSchema).paused = false;
      data.timeElapsed = 0;
    }
  }
}

registerSystem(WaveSystem);

let ui: UIElement | null = null;

registerUIComponent(
  "Wave",
  (uiService, entity, gameModel) => {
    if (!ui) {
      const waveDescription = new Text(new Position("center", "top", { yOffset: 12, width: 120, height: 1 }), {
        fontSize: 32,
        label: "",
        style: {
          textAlign: "center",
          ...strokeStyle(true),
        },
      });
      waveDescription.config.label = `Wave ${gameModel.getTypedUnsafe(entity, WaveSchema).wave}`;

      uiService.addToUI(waveDescription);
      ui = waveDescription;
    }
  },
  {
    cleanup: (uiService, entity, gameModel) => {
      uiService.removeFromUI(ui!);
      ui = null;
    },
  }
);

import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { ComponentData } from "yage/components/types";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";

@Component("WaveOrchestratorTrigger")
export class WaveOrchestratorTriggerSchema extends Schema {
  @type("string")
  triggerType: "TIME" | "KILLS";

  @type("number")
  value: number;

  @type("string")
  entityDescription: string;

  @type("number")
  @defaultValue(0)
  minimumDifficulty: number;

  @type("object")
  @defaultValue({})
  overrideProperties: any;
}

@Component("WaveOrchestrator")
export class WaveOrchestratorSchema extends Schema {
  @type([WaveOrchestratorTriggerSchema])
  triggers: WaveOrchestratorTriggerSchema[];

  @type(["number"])
  @defaultValue([])
  triggerEntities: number[];
}

class WaveOrchestratorSystem implements System {
  schema = WaveOrchestratorSchema;
  type = "WaveOrchestrator";

  init(entity: number, gameModel: GameModel) {
    const waveOrchestrator = gameModel.getTypedUnsafe(entity, WaveOrchestratorSchema);
    const triggers = waveOrchestrator.triggers;
    for (let i = 0; i < triggers.length; i++) {
      const trigger = triggers[i];

      const triggerEntity = gameModel.addEntity();

      const type = trigger.triggerType === "TIME" ? "TimeTrigger" : "KillStatsTrigger";

      gameModel.addComponent(triggerEntity, type, {
        value: trigger.value,
        triggerEvent: [
          {
            location: { x: 0, y: 0 },
            width: 0,
            height: 0,
            event: "ENTITY",
            name: trigger.entityDescription,
            overrideProperties: trigger.overrideProperties,
            components: [],
            triggerEntities: [],
          },
        ],
        disableOnHidden: false,
        destroyOnTrigger: true,
      });
    }
  }
}

registerSystem(WaveOrchestratorSystem);

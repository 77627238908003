import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { PlayerTypeSchema } from "yage/components/entity/Types";
import { Component, Schema, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import DescriptionSchema from "yage/schemas/core/Description";
import { OwnerSchema } from "yage/schemas/core/Owner";
import { ChildSchema } from "yage/schemas/entity/Child";
import { TransformSchema } from "yage/schemas/entity/Transform";

@Component("SpawnAlliesCloser")
export class SpawnAlliesCloserSchema extends Schema {
  @type("number")
  distance: number;
}

class SpawnAlliesCloserSystem implements System {
  schema = SpawnAlliesCloserSchema;
  type = "SpawnAlliesCloser";
  dependencies = ["Transform"];

  init(entity: number, gameModel: GameModel) {
    console.log(
      gameModel.getTypedUnsafe(entity, SpawnAlliesCloserSchema),
      gameModel.getTyped(entity, DescriptionSchema),
      gameModel.hasComponent(entity, PlayerTypeSchema)
    );

    const player = gameModel.hasComponent(entity, PlayerTypeSchema)
      ? entity
      : gameModel.getTypedUnsafe(entity, OwnerSchema)?.owner;

    if (!player) {
      return;
    }

    const data = gameModel.getTypedUnsafe(player, SpawnAlliesCloserSchema);
    if (!data) {
      return;
    }

    const allies = gameModel
      .getComponentActives("SpawnAlliesCloser")
      .filter((ally) => ally !== entity && !gameModel.hasComponent(ally, PlayerTypeSchema));
    console.log(allies, gameModel.getComponentActives("SpawnAlliesCloser"));
    if (!allies.length) {
      return;
    }
    const center = allies.reduce(
      (acc, ally) => {
        TransformSchema.id = ally;
        acc.x += TransformSchema.x;
        acc.y += TransformSchema.y;
        return acc;
      },
      { x: 0, y: 0 }
    );
    center.x /= allies.length;
    center.y /= allies.length;
    TransformSchema.id = entity;
    const randX = gameModel.rand.int(-data.distance, data.distance) + center.x;
    const randY = gameModel.rand.int(-data.distance, data.distance) + center.y;
    TransformSchema.x = randX;
    TransformSchema.y = randY;
    console.log(randX, randY);
  }
}

registerSystem(SpawnAlliesCloserSystem);

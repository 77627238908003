import { System } from "yage/components/System";
import { ComponentCategory } from "yage/constants/enums";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { DamageStatsSchema } from "./DamageStats";
import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import { AttackCooldownSchema } from "./AttackCooldown";

@Component("ExtendedAttackCooldown")
export class ExtendedAttackCooldownSchema extends Schema {
  @type("number")
  @defaultValue(6)
  mod: number;

  @type("number")
  @defaultValue(0)
  internalMod: number;

  @type("boolean")
  @defaultValue(false)
  lastReady: boolean;

  @type("number")
  @defaultValue(5)
  scale: number;
}

class ExtendedAttackCooldownSystem implements System {
  type = "ExtendedAttackCooldown";
  category: ComponentCategory = ComponentCategory.BEHAVIOR;
  depth = DEPTHS.DAMAGE + 1;
  schema = ExtendedAttackCooldownSchema;

  run(entity: number, gameModel: GameModel) {
    const damageStats = gameModel.getTypedUnsafe(entity, DamageStatsSchema);
    const extendedCooldownData = gameModel.getTypedUnsafe(entity, ExtendedAttackCooldownSchema);
    const cooldownData = gameModel.getTypedUnsafe(entity, AttackCooldownSchema);

    // console.log(cooldownData.ready);
    if (!cooldownData.ready && extendedCooldownData.lastReady) {
      extendedCooldownData.internalMod++;

      if (extendedCooldownData.internalMod === extendedCooldownData.mod) {
        extendedCooldownData.internalMod = 0;
        const speed = damageStats.attackSpeed / (1 + damageStats.attackSpeedScale);
        const calculatedCooldown = cooldownData.internalRandomCooldown + speed * 1000;
        const scaledCalculatedCooldown = calculatedCooldown * extendedCooldownData.scale;
        const diff = scaledCalculatedCooldown - calculatedCooldown;
        cooldownData.internalCooldown -= diff;
      }
    }
    extendedCooldownData.lastReady = cooldownData.ready;
  }
}
registerSystem(ExtendedAttackCooldownSystem);

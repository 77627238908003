import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { checkCondition, unlockAchievement } from "./Achievements";
import { System } from "yage/components/System";
import { ComponentCategory } from "yage/constants/enums";
import { PlayerTypeSchema } from "yage/components/entity/Types";
import { GameModel } from "yage/game/GameModel";
import { getPlayerCharacter } from "../../utils/playerCharacter";
import { Achievement } from "../../schema/achievements/Achievements";
import { registerSystem } from "yage/components/ComponentRegistry";
import { ChildSchema } from "yage/schemas/entity/Child";

@Component("TrackAchievementsOnRoundEnd")
export class TrackAchievementsOnRoundEndSchema extends Schema {
  @type([Achievement])
  @defaultValue([])
  achievements: Achievement[];
}

class TrackAchievementsOnRoundEndSystem implements System {
  schema = TrackAchievementsOnRoundEndSchema;
  type = "TrackAchievementsOnRoundEnd";
  category = ComponentCategory.ON_END;

  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, TrackAchievementsOnRoundEndSchema);

    for (const achievement of data.achievements) {
      if (
        checkCondition(gameModel.getTypedUnsafe(entity, ChildSchema)?.parent ?? -1, achievement.condition, gameModel)
      ) {
        unlockAchievement(entity, achievement, gameModel);
        data.achievements = data.achievements.filter((a) => a.name !== achievement.name);
        if (!data.achievements.length) {
          gameModel.removeComponent(entity, this.type);
        }
      }
    }
  }
}

registerSystem(TrackAchievementsOnRoundEndSystem);

import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { PlayerTypeSchema, EnemyTypeSchema } from "yage/components/entity/Types";
import { ComponentCategory } from "yage/constants/enums";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";

@Component("ShareOnAddToParent")
export class ShareOnAddToParentSchema extends Schema {
  @type(["number"])
  @defaultValue([])
  entities: number[];

  @type("number")
  @defaultValue(-1)
  owner: number;

  @type("number")
  @defaultValue(0)
  parent: number;

  @type("number")
  @defaultValue(0)
  child: number;
}

class ShareOnAddToParentSystem implements System {
  schema = ShareOnAddToParentSchema;
  type = "ShareOnAddToParent";

  category = ComponentCategory.ON_ADD_TO_PARENT;
  dependencies = ["Child"];

  init(entity: number, gameModel: GameModel) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      gameModel.addIfMissing(parent, ShareOnAddToParentSchema);
      const parentData = gameModel.getTypedUnsafe(parent, ShareOnAddToParentSchema);
      parentData.entities.push(entity);
    }
  }

  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, ShareOnAddToParentSchema);

    for (let i = 0; i < data.entities.length; i++) {
      const entityId = data.entities[i];
      if (!gameModel.isActive(entityId)) {
        data.entities.splice(i, 1);
        i--;
        continue;
      }
      const onAddToParentMods = gameModel.getComponentIdsByCategory(entityId, ComponentCategory.ON_ADD_TO_PARENT);

      if (onAddToParentMods.length) {
        for (let j = 0; j < onAddToParentMods.length; j++) {
          const mod = gameModel.getComponentUnsafe(entityId, onAddToParentMods[j]);
          if (mod.child !== undefined) {
            mod.child = data.child;
          }
          if (mod.parent !== undefined) {
            mod.parent = data.parent;
          }
          const system = gameModel.getSystem((mod as any).type);
          system.run?.(entityId, gameModel);
        }
      }
    }
  }
}

registerSystem(ShareOnAddToParentSystem);

import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { Component, defaultValue, Schema, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";
import { getMaxHealth, HealthSchema } from "../core";
import { HpScalerSchema } from "../scalers/HpScaler";
import { EnemyTypeSchema, PlayerTypeSchema } from "yage/components/entity/Types";
import { HpBlockSchema } from "../player/HpBlock";
import { FirstFrameSchema } from "../core/FirstFrame";

@Component("HpEnhancer")
export class HpEnhancerSchema extends Schema {
  @type("number")
  health: number;
}

class HpEnhancerSystem implements System {
  schema = HpEnhancerSchema;
  type = "HpEnhancer";
  dependencies = ["Child", "HpScaler"];
  init(entity: number, gameModel: GameModel) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;

      let scale = 1;
      if (gameModel.hasComponent(parent, "HpScaler")) {
        scale += gameModel.getTypedUnsafe(parent, HpScalerSchema).healthScale;
      }

      HealthSchema.id = parent;
      HealthSchema.maxHealth += gameModel.getTypedUnsafe(entity, HpEnhancerSchema).health * scale;
      if (
        gameModel.hasComponent(parent, HpBlockSchema) &&
        gameModel.getTyped(parent, FirstFrameSchema)?.firstFrame !== gameModel.frame &&
        !gameModel.getTypedUnsafe(parent, HpBlockSchema).excludedTypes.includes("HpEnhancer")
      ) {
        return;
      }
      const maxHealth = getMaxHealth(parent, gameModel);
      const additionalHealth = gameModel.getTypedUnsafe(entity, HpEnhancerSchema).health * scale;
      if (HealthSchema.health + additionalHealth <= maxHealth) {
        HealthSchema.health += additionalHealth;
      } else {
        HealthSchema.health = maxHealth;
      }
    }
  }

  cleanup(entity: number, gameModel: GameModel, ejecting: boolean) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;

      let scale = 1;
      if (gameModel.hasComponent(parent, "HpScaler")) {
        scale = gameModel.getTypedUnsafe(parent, HpScalerSchema).healthScale;
      }

      HealthSchema.id = parent;
      HealthSchema.maxHealth -= gameModel.getTypedUnsafe(entity, HpEnhancerSchema).health * scale;
      HealthSchema.health -= gameModel.getTypedUnsafe(entity, HpEnhancerSchema).health * scale;
    }
  }
}
registerSystem(HpEnhancerSystem);

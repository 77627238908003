import { Schema, Component, type } from "yage/decorators/type";

type AchievementValue = {
  entityType: "CORE" | "CONTROLLER" | "PLAYERCHARACTER" | "EVENTENTITY" | "EVENTSOURCE" | "EVENTOWNER" | string;
  [value: string]: string | number | boolean | "__SUM__";
};

@Component("AchievementCondition")
export class AchievementCondition extends Schema {
  @type("string")
  type: string;

  @type("object")
  data: {
    [component: string]: AchievementValue;
  };

  @type("string")
  operator: "equal" | "exists" | "gte" | "lte" | "gt" | "lt" | "neq" | "contains";

  @type("number")
  sum: number;
}

@Component("Achievement")
export class Achievement extends Schema {
  @type("string")
  name: string;

  @type("string")
  description: string;

  @type("number")
  target: number;

  @type(AchievementCondition)
  condition: AchievementCondition;
}

@Component("Achievements")
export class AchievementsSchema extends Schema {
  @type([Achievement])
  list: Achievement[];

  @type(["string"])
  unlocked: string[];

  @type("object")
  stats: any;
}

import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import type { System } from "yage/components/System";
import { ComponentCategory, ComponentDataSchema } from "yage/components/types";
import { TriggerTypeEnum } from "yage/constants/enums";
import { Component, defaultValue, required, Schema, type } from "yage/decorators/type";
import { EntityFactory } from "yage/entity/EntityFactory";
import type { GameModel } from "yage/game/GameModel";
import { TransformSchema } from "yage/schemas/entity/Transform";
import type { Vector2d } from "yage/utils/vector";
import { Vector2dSchema, addVector2d, scaleVector2d } from "yage/utils/vector";
// import { makePickupable } from "../../utils/pathfinding";
import { HealthSchema } from "../core/Health";
import { FlingSchema } from "./Fling";
import { getLastDamage } from "../../utils/getLastDamage";
import { DamageStatsSchema } from "../weapons/DamageStats";

@Component("ItemDropper")
export class ItemDropperSchema extends Schema {
  @type("string")
  @required()
  entityDescription: string;

  @type("number")
  @defaultValue(TriggerTypeEnum.DEATH)
  triggerType: TriggerTypeEnum;

  @type("number")
  @defaultValue(1)
  triggerValue: number;

  @type("Entity")
  owner: number;

  @type("boolean")
  @defaultValue(false)
  divvy: boolean;

  @type("boolean")
  @defaultValue(false)
  triggered: boolean;

  @type("boolean")
  @defaultValue(false)
  canRetrigger: boolean;

  @type(Vector2dSchema)
  offset: Vector2d;

  @type(FlingSchema)
  @defaultValue({})
  fling: FlingSchema;

  @type("number")
  @defaultValue(1)
  copies: number;

  @type([ComponentDataSchema])
  @defaultValue([])
  overrideComponents: Array<ComponentDataSchema>;

  @type("number")
  previousValue: number;

  @type("boolean")
  @defaultValue(false)
  affectedByChance: boolean;

  @type("number")
  @defaultValue(0)
  groupId: number;
}

class ItemDropperSystem implements System {
  type = "ItemDropper";
  category: ComponentCategory = ComponentCategory.BEHAVIOR;
  schema = ItemDropperSchema;
  depth = DEPTHS.PREDRAW - 0.000001;
  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, ItemDropperSchema);
    checkTrigger(entity, data, gameModel);
  }
}

registerSystem(ItemDropperSystem);

export function checkTrigger(entity: number, data: ItemDropperSchema, gameModel: GameModel) {
  const healthData = gameModel.getTypedUnsafe(entity, HealthSchema);
  if (!data.triggered) {
    if (data.triggerType === TriggerTypeEnum.ONATTACH) {
      data.triggered = true;
      trigger(entity, data, gameModel);
    } else if (data.triggerType === TriggerTypeEnum.HEALTH_INCREASE) {
      if (data.previousValue < healthData.health) {
        if (healthData.health / healthData.maxHealth >= data.triggerValue) {
          data.triggered = true;
          trigger(entity, data, gameModel);
        }
      } else {
        data.previousValue = healthData.health;
      }
    } else if (data.triggerType === TriggerTypeEnum.HEALTH_DECREASE) {
      if (data.previousValue > healthData.health) {
        if (healthData.health / healthData.maxHealth <= data.triggerValue) {
          data.triggered = true;
          trigger(entity, data, gameModel);
        }
      } else {
        data.previousValue = healthData.health;
      }
    } else if (data.triggerType === TriggerTypeEnum.DEATH && healthData.health <= 0) {
      const lastDamage = getLastDamage(healthData.health, entity, gameModel);
      let rand = data.triggerValue;

      if (lastDamage?.owner !== undefined) {
        if (data.affectedByChance) {
          const chance = gameModel.getTypedUnsafe(lastDamage.owner, DamageStatsSchema)?.chance ?? -100;
          rand = rand * ((100 + chance) / 100);
        }
        if (rand <= 0) {
          return;
        }

        const owner = lastDamage.owner;
        const onDropMods = gameModel.getComponentIdsByCategory(owner, ComponentCategory.ONITEMDROP_MOD);
        for (let i = 0; i < onDropMods.length; i++) {
          const component = gameModel.getComponentUnsafe(owner, onDropMods[i]);
          component.itemDropper = data;
          const system = gameModel.getSystem((component as any).type);
          system.run?.(owner, gameModel);
        }
      }

      if (gameModel.rand.number() < rand) {
        data.triggered = true;

        trigger(entity, data, gameModel);
      }
    } else if (data.triggerType === TriggerTypeEnum.TIME) {
      if (data.previousValue === undefined) {
        data.previousValue = 0;
      }
      data.previousValue += gameModel.dt<number>(entity);
      if (data.previousValue > data.triggerValue) {
        data.triggered = true;
        trigger(entity, data, gameModel);

        if (data.canRetrigger) {
          data.previousValue = 0;
          data.triggered = false;
        }
      }
    }
  }
}

function trigger(entity: number, data: ItemDropperSchema, gameModel: GameModel) {
  const players = gameModel.players;
  const divvyStart = gameModel.rand.int(0, players.length - 1); // don't always start at the same player
  for (let i = 0; i < data.copies; ++i) {
    if (data.divvy) {
      if (players.length > 1) {
        const player = players[(i + divvyStart) % players.length];
        data.owner = player;
      }
    }
    let pickupPosition: Vector2d | undefined = undefined;

    if (data.owner != undefined) {
      if (data.owner === -1) {
        // use -1 to place the average of player locations
        const players = gameModel.players;
        let offset = { x: 0, y: 0 };
        if (data.offset) {
          offset = { ...data.offset };
        } else {
          offset = {
            x: (gameModel.rand.int(0, 1) ? 1 : -1) * gameModel.rand.int(20, 30),
            y: (gameModel.rand.int(0, 1) ? 1 : -1) * gameModel.rand.int(20, 30),
          };
        }
        if (players.length === 1) {
          TransformSchema.id = players[0];
          const playerPosition = TransformSchema.position;

          pickupPosition = addVector2d(playerPosition, offset);
        } else {
          const position = players.reduce((pos, p) => {
            TransformSchema.id = p;
            const playerPosition = TransformSchema.position;

            pos = scaleVector2d(addVector2d(pos, playerPosition), 1 / players.length);
            return pos;
          }, offset);
          pickupPosition = new Vector2dSchema(position);
        }
      } else {
        TransformSchema.id = data.owner;
        const ownerPosition = TransformSchema.position;

        pickupPosition = {
          x: ownerPosition.x + 30,
          y: ownerPosition.y + 30,
        };
      }
    } else {
      TransformSchema.id = entity;
      const entityPosition = TransformSchema.position;

      pickupPosition = entityPosition;
    }

    const pickup = EntityFactory.getInstance().generateEntity(gameModel, data.entityDescription, {
      Transform: pickupPosition,
    });

    if (data.overrideComponents.length > 0) {
      for (let i = 0; i < data.overrideComponents.length; i++) {
        const override = data.overrideComponents[i];
        let overrideData = override.data ?? {};
        if (override.inherit) {
          overrideData = { ...(gameModel.getComponent(entity, override.type) ?? {}), ...overrideData };
        }

        gameModel.addComponent(pickup, override.type, overrideData);
      }
    }

    TransformSchema.id = pickup;

    if (data.fling) {
      gameModel.addComponent(pickup, FlingSchema, data.fling);
    } else {
      const pickupPosition = TransformSchema.position; //makePickupable(TransformSchema.position, gameModel);
      if (pickupPosition) {
        TransformSchema.position = pickupPosition;
      }
    }
  }
}

import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";

@Component("GlobalSpawnIntervalEnhancer")
export class GlobalSpawnIntervalEnhancerSchema extends Schema {
  @type("number")
  @defaultValue(1)
  intervalScale: number;
}

class GlobalSpawnIntervalEnhancerSystem implements System {
  schema = GlobalSpawnIntervalEnhancerSchema;
  type = "GlobalSpawnIntervalEnhancer";

  init(entity: number, gameModel: GameModel) {
    if (entity !== gameModel.coreEntity) {
      if (!gameModel.hasComponent(gameModel.coreEntity, "GlobalSpawnIntervalEnhancer")) {
        gameModel.addComponent(gameModel.coreEntity, GlobalSpawnIntervalEnhancerSchema);
      }
      gameModel.getTypedUnsafe(gameModel.coreEntity, GlobalSpawnIntervalEnhancerSchema).intervalScale +=
        gameModel.getTypedUnsafe(entity, GlobalSpawnIntervalEnhancerSchema).intervalScale;
    }
  }
}

registerSystem(GlobalSpawnIntervalEnhancerSystem);

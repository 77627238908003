import { EntityTypeSchema } from "yage/components/entity/Types";
import { registerSystem } from "yage/components/ComponentRegistry";
import type { ComponentData } from "yage/components/types";
import { ComponentCategory, ComponentDataSchema } from "yage/components/types";
import { DamageDirectionEnum } from "yage/constants/enums";
import { Component, defaultValue, type, Schema } from "yage/decorators/type";
import type { GameModel } from "yage/game/GameModel";
import type { System } from "yage/components/System";
import { EntityFactory } from "yage/entity/EntityFactory";
import { BV2, Vector2dSchema, normalizeVector2d, subtractVector2d } from "yage/utils/vector";
import { getWeaponAugmentComponents, applyComponentsToProjectile, directionFromTarget } from "../../utils/weapons";
import { EnemyCollidersSchema } from "../enemy/EnemyColliders";
import { OwnerSchema } from "yage/schemas/core/Owner";
import { ChildSchema } from "yage/schemas/entity/Child";
import { LocomotionSchema } from "yage/schemas/entity/Locomotion";
import { TransformSchema } from "yage/schemas/entity/Transform";
import { getNextRandomEnemy } from "../../utils/target";

@Component("SpawnBulletOnHit")
export class SpawnBulletOnHitSchema extends Schema {
  @type("number")
  @defaultValue(1)
  spawnChance: number;

  @type("string")
  @defaultValue("Spark")
  entityDescription: string;

  @type([ComponentDataSchema])
  @defaultValue([])
  overrideComponents: ComponentData[];

  @type(Vector2dSchema)
  @defaultValue({ x: 0, y: 0 })
  hitPosition: Vector2dSchema;

  @type("Entity")
  @defaultValue(-1)
  collider: number;
}

class SpawnBulletOnHitSystem implements System {
  type = "SpawnBulletOnHit";
  category: ComponentCategory = ComponentCategory.ONHIT;
  schema = SpawnBulletOnHitSchema;
  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, SpawnBulletOnHitSchema);

    if (gameModel.rand.number() > data.spawnChance) {
      return;
    }

    const owner = gameModel.getTypedUnsafe(entity, OwnerSchema).owner!;
    const parent = gameModel.getTyped(entity, ChildSchema)?.parent ?? entity;

    const position = data.hitPosition;
    const colliders = [...gameModel.getTypedUnsafe(owner, EnemyCollidersSchema).colliders];

    const followTarget = getNextRandomEnemy(data.collider, colliders, gameModel);

    const overrides: any = {
      Transform: position,
      EnemyColliders: {
        colliders,
      },
      Owner: {
        owner,
      },
      Child: {
        parent,
      },
      Follow: {
        target: followTarget,
      },
    };
    const projectile = EntityFactory.getInstance().generateEntity(gameModel, data.entityDescription, overrides);

    TransformSchema.id = projectile;
    TransformSchema.position = position;
    const ownerPosition = TransformSchema.position;
    TransformSchema.id = followTarget;
    const colliderPosition = TransformSchema.position;

    const direction = normalizeVector2d(subtractVector2d(colliderPosition, ownerPosition));

    LocomotionSchema.id = projectile;
    LocomotionSchema.directionX = direction.x;
    LocomotionSchema.directionY = direction.y;
    LocomotionSchema.velocityX = direction.x * LocomotionSchema.speed;
    LocomotionSchema.velocityY = direction.y * LocomotionSchema.speed;

    const [augmentComponents, auraComponents] = getWeaponAugmentComponents(projectile, owner, gameModel);
    // damageStats.minDamage += data.damage;
    applyComponentsToProjectile(
      projectile,
      DamageDirectionEnum.PROJECTILE,
      augmentComponents,
      auraComponents,
      gameModel
    );

    if (data.overrideComponents.length > 0) {
      for (let i = 0; i < data.overrideComponents.length; i++) {
        const override = data.overrideComponents[i];
        gameModel.addComponent(projectile, override.type, override.data ?? override);
      }
    }
  }
}

registerSystem(SpawnBulletOnHitSystem);

import { registerSchema, registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { Component, Schema } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";

@Component("FlatMap")
export class FlatMapSchema extends Schema {
  width: number;
  height: number;
  scale: number;
}

registerSchema(FlatMapSchema);

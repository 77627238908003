import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import type { System } from "yage/components/System";
import { ComponentCategory, ComponentDataSchema } from "yage/components/types";
import { OrbPatternEnum, EntityType, DamageDirectionEnum } from "yage/constants/enums";
import { Component, defaultValue, nullable, Schema, type } from "yage/decorators/type";
import { EntityFactory } from "yage/entity/EntityFactory";
import type { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";
import { LocomotionSchema } from "yage/schemas/entity/Locomotion";
import { TransformSchema } from "yage/schemas/entity/Transform";
import type { ByteArray } from "yage/utils/byteArray";
import { getPosAroundCircle, getPositionOfNextUpdate } from "yage/utils/position";
import { normalizeVector2d, subtractVector2d } from "yage/utils/vector";
import { applyComponentsToProjectile, calculateProjectileCount, getWeaponAugmentComponents } from "../../utils/weapons";
import { EnemyCollidersSchema } from "../enemy/EnemyColliders";
import { OwnerSchema } from "yage/schemas/core/Owner";

@Component("Orbs")
export class OrbsSchema extends Schema {
  @type("number")
  @defaultValue(1)
  scale: number;

  @type("number")
  @defaultValue(0)
  currAngle: number;

  @type("EntityArray")
  @defaultValue([])
  projectiles: number[];

  @type("string")
  @defaultValue("")
  projectileDescription: string;

  @type("number")
  @defaultValue(20)
  radius: number;

  @type("number")
  @defaultValue(0)
  cooldown: number;

  @type("number")
  @defaultValue(0)
  lastUpdate: number;

  @type("number")
  @defaultValue(30)
  initialRadius: number;

  @type("number")
  @defaultValue(0)
  spawnCooldown: number;

  @type("number")
  @defaultValue(OrbPatternEnum.NONE)
  pattern: OrbPatternEnum;

  @type("object")
  @nullable()
  damageTypeIds: ByteArray | null;

  @type("number")
  speed: number;

  @type("boolean")
  @defaultValue(false)
  pointTowardsOwner: boolean;

  @type([ComponentDataSchema])
  @defaultValue([])
  additionalComponents: ComponentDataSchema[];
}

class OrbsComponent implements System {
  type = "Orbs";
  depth: number = DEPTHS.LOCOMOTION - 100;
  category: ComponentCategory = ComponentCategory.WEAPON;
  schema = OrbsSchema;

  dependencies = ["Transform", "Locomotion", "Child", "Targeting"];

  checkDamageTypes(entity: number, data: OrbsSchema, owner: number, gameModel: GameModel) {
    // if (reset) {
    //   for (let i = 0; i < data.projectiles.length; ++i) {
    //     gameModel.removeEntity(data.projectiles[i]);
    //   }
    //   data.projectiles = [];
    // }
  }

  verifyActive(entity: number, data: OrbsSchema, gameModel: GameModel) {
    for (let i = 0; i < data.projectiles.length; ++i) {
      if (!gameModel.isActive(data.projectiles[i])) {
        data.projectiles.splice(i, 1);
      }
    }
  }

  updateOrbPositions(entity: number, data: OrbsSchema, gameModel: GameModel) {
    TransformSchema.id = entity;
    const entityPosition = TransformSchema.position;
    LocomotionSchema.id = entity;
    const entityVelocity = { x: LocomotionSchema.velocityX, y: LocomotionSchema.velocityY };
    const entityDirection = { x: LocomotionSchema.directionX, y: LocomotionSchema.directionY };
    const projectileCount = calculateProjectileCount(entity, gameModel);

    const speed = data.speed;

    if (data.projectiles.length) {
      const theta = (Math.PI * 2) / projectileCount;
      data.currAngle += gameModel.dt(entity, speed) * 0.001;
      if (data.currAngle > 360) {
        data.currAngle -= 360;
      }
      data.lastUpdate = gameModel.timeElapsed;
      if (projectileCount !== 1) {
        data.radius = Math.sin(gameModel.timeElapsed * speed) * 10 + data.initialRadius;
      } else {
        data.radius = data.initialRadius;
      }
      for (let i = 0; i < data.projectiles.length; ++i) {
        const angle = (theta * i + data.currAngle) % 360;
        const position = getPosAroundCircle(
          getPositionOfNextUpdate(entityPosition, gameModel.dt(entity, entityVelocity)),
          angle,
          data.radius
        );
        const projectile = data.projectiles[i];

        TransformSchema.id = projectile;
        TransformSchema.position = position;
        if (gameModel.hasComponent(projectile, "Locomotion")) {
          LocomotionSchema.id = projectile;
          if (data.pointTowardsOwner) {
            const direction = normalizeVector2d(subtractVector2d(entityPosition, position));
            LocomotionSchema.directionX = direction.x;
            LocomotionSchema.directionY = direction.y;
          } else {
            LocomotionSchema.directionX = entityDirection.x;
            LocomotionSchema.directionY = entityDirection.y;
          }
        }
      }
    }
  }

  spawnOrbs(entity: number, data: OrbsSchema, owner: number, gameModel: GameModel) {
    data.spawnCooldown = gameModel.increment(entity, data.spawnCooldown, 1, 3);
    TransformSchema.id = entity;
    const entityPosition = TransformSchema.position;
    const projectileCount = calculateProjectileCount(entity, gameModel);
    if (data.projectiles.length !== projectileCount && gameModel.isActive(owner)) {
      while (data.projectiles.length > projectileCount) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        gameModel.removeEntity(data.projectiles.pop()!);
      }
      const [augmentComponents, auraComponents] = getWeaponAugmentComponents(entity, owner, gameModel);

      const theta = (Math.PI * 2) / projectileCount;
      for (let i = data.projectiles.length; i < projectileCount; i++) {
        const angle = (theta * i + data.currAngle) % 360;
        const position = getPosAroundCircle(entityPosition, angle, data.radius);
        const projectile = EntityFactory.getInstance().generateEntity(gameModel, data.projectileDescription, {
          Transform: { ...position },
          colliders: EntityType.ENEMY,
          Child: { parent: entity },
          Owner: { owner },
        });
        data.projectiles.push(projectile);

        if (data.additionalComponents?.length) {
          data.additionalComponents.forEach((component) => {
            augmentComponents.push({
              type: component.type,
              ...component.data,
            });
          });
        }

        applyComponentsToProjectile(
          projectile,
          DamageDirectionEnum.OWNER,
          augmentComponents,
          auraComponents,
          gameModel
        );

        // if (gameModel.hasComponent(projectile, "Targeting")) {
        //   let targetingData = gameModel.getTypedUnsafe(projectile, TargetingSchema);
        //   targetingData.shouldChase = true;
        //   targetingData.radius = 100;
        // }

        // gameModel.logEntity(projectile);
      }
    }
  }

  run(entity: number, gameModel: GameModel) {
    const owner =
      gameModel.getTyped(entity, OwnerSchema)?.owner ?? gameModel.getTyped(entity, ChildSchema)?.parent ?? entity;

    if (owner == null) {
      return;
    }

    const data = gameModel.getComponent(entity, this.type) as OrbsSchema;

    this.checkDamageTypes(entity, data, owner, gameModel);
    this.verifyActive(entity, data, gameModel);
    this.spawnOrbs(entity, data, owner, gameModel);
    this.updateOrbPositions(entity, data, gameModel);
  }
  cleanup(entity: number, gameModel: GameModel, ejecting: boolean) {
    if (ejecting) return;
    const data = gameModel.getComponent(entity, this.type) as unknown as OrbsSchema;
    for (let i = 0; i < data.projectiles.length; ++i) {
      gameModel.removeEntity(data.projectiles[i]);
    }
    data.lastUpdate = 0;
    data.projectiles = [];
    data.damageTypeIds = null;
    data.spawnCooldown = 100;
    data.cooldown = 100;
  }
}

registerSystem(OrbsComponent);

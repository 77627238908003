import { GameModel } from "yage/game/GameModel";
import { PlayerState } from "../../types/PlayerState.types";
import { EntityFactory } from "yage/entity/EntityFactory";
import { ParentSchema } from "yage/schemas/entity/Parent";
import { SpriteSchema } from "yage/schemas/render/Sprite";
import { ExpSchema } from "../../components/core/Exp";
import { HarvestingSchema } from "../../components/core/Harvesting";
import { ShopSchema } from "../../components/core/Shop";
import { InventorySchema } from "../../components/player/Inventory";
import { WeaponContainerSchema } from "../../components/weapons/WeaponContainer";
import { getShopInfo } from "./renderItemInfo";
import { cloneDeep } from "lodash";
import { InputManager } from "yage/inputs/InputManager";

export const generatePlayer = (
  playerConfig: PlayerState,
  netId: string,
  gameModel: GameModel,
  {
    includeItems = true,
    includeWeapons = true,
    additionalItems = [],
  }: {
    includeItems?: boolean;
    includeWeapons?: boolean;
    additionalItems?: string[];
  } = {}
) => {
  const player = EntityFactory.getInstance().generateEntity(gameModel, "player", {
    PlayerInput: {
      id: netId,
      keyMap: InputManager.buildKeyMap(),
    },
  });

  const playerCharacter = gameModel.getTypedUnsafe(player, ParentSchema).children[0];

  for (let i = 0; i < playerConfig.persistedComponents.length; ++i) {
    gameModel.addComponent(
      playerCharacter,
      playerConfig.persistedComponents[i].type,
      playerConfig.persistedComponents[i].data
    );
  }

  gameModel.getTypedUnsafe(player, HarvestingSchema).harvesting += playerConfig.harvesting;
  gameModel.setTyped(player, ExpSchema, {
    exp: playerConfig.exp,
    level: playerConfig.level,
  });

  // if (includeWeapons) {
  gameModel.setTyped(playerCharacter, WeaponContainerSchema, {
    weapons: playerConfig.weapons,
  });

  const container = gameModel.getTypedUnsafe(playerCharacter, WeaponContainerSchema);
  for (let i = 0; i < container.weaponIds.length; ++i) {
    if (playerConfig.weaponMods[i]) {
      playerConfig.weaponMods[i].forEach((mod) => {
        gameModel.addComponent(container.weaponIds[i], mod.type, mod.data);
      });
    }
  }
  // }

  if (includeItems) {
    const inventory = gameModel.getTypedUnsafe(player, InventorySchema);
    inventory.inventory = cloneDeep(playerConfig.inventory);

    let items: [string, number, number, ShopSchema][] = [];
    additionalItems.forEach((item) => {
      items.push([item, 0, 1, getShopInfo(item)!]);
    });
    Object.keys(inventory.inventory).forEach((key) => {
      if (key.toLowerCase().startsWith("shopitem::") || key.toLowerCase().startsWith("stat::")) {
        const item = key.split("::")[1];
        const shopData = getShopInfo(item)!;
        const zIndex = shopData?.zIndex ?? 0;
        items.push([item, zIndex, inventory.inventory[key].amount, shopData]);
      }
    });
    items = items.sort((a, b) => a[1] - b[1]);
    items.forEach((item) => {
      for (let i = 0; i < item[2]; i++) {
        EntityFactory.getInstance().generateEntity(gameModel, item[0], {
          Child: {
            parent: playerCharacter,
            autoAttach: false,
          },
          Owner: {
            owner: playerCharacter,
          },
        });
        if (item[3]?.tags.includes("Character::")) {
          const spriteSchema = gameModel.getTypedUnsafe(playerCharacter, SpriteSchema);
          spriteSchema.imageKey = "shopitem::" + item[0].toLowerCase();
        }
      }
    });
  }

  return [player, playerCharacter];
};

import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { checkCondition, incrementAchievement } from "./Achievements";
import { System } from "yage/components/System";
import { ComponentCategory } from "yage/constants/enums";
import { GameModel } from "yage/game/GameModel";
import { Achievement } from "../../schema/achievements/Achievements";
import { registerSystem } from "yage/components/ComponentRegistry";

@Component("TrackAchievementsOnPickup")
export class TrackAchievementsOnPickupSchema extends Schema {
  @type([Achievement])
  @defaultValue([])
  achievements: Achievement[];

  @type("number")
  @defaultValue(0)
  owner: number;

  @type("number")
  @defaultValue(0)
  pickedup: number;
}

class TrackAchievementsOnPickupSystem implements System {
  schema = TrackAchievementsOnPickupSchema;
  type = "TrackAchievementsOnPickup";
  category = ComponentCategory.ON_PICKUP;

  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, TrackAchievementsOnPickupSchema);
    for (const achievement of data.achievements) {
      if (
        checkCondition(entity, achievement.condition, gameModel, {
          entity: data.pickedup,
          owner: data.owner,
        })
      ) {
        const unlocked = incrementAchievement(entity, achievement, gameModel, 1);
        if (unlocked) {
          data.achievements = data.achievements.filter((a) => a.name !== achievement.name);
        }
      }
    }
  }
}

registerSystem(TrackAchievementsOnPickupSystem);

import { System } from "yage/components/System";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { OwnerSchema } from "yage/schemas/core/Owner";
import { DamageStatsSchema } from "../weapons/DamageStats";
import { registerSystem } from "yage/components/ComponentRegistry";

@Component("AoeDamageScale")
export class AoeDamageScaleSchema extends Schema {
  @type("number")
  @defaultValue(0)
  damageScale: number;
}

class AoeDamageScaleSystem implements System {
  schema = AoeDamageScaleSchema;
  type = "AoeDamageScale";
  dependencies = ["DamageStats"];
  init(entity: number, gameModel: GameModel) {
    if (gameModel.hasComponent(entity, OwnerSchema)) {
      const owner = gameModel.getTypedUnsafe(entity, OwnerSchema).owner;
      if (owner !== null) {
        if (gameModel.hasComponent(owner, AoeDamageScaleSchema)) {
          const ownerAoeDamageScale = gameModel.getTypedUnsafe(owner, AoeDamageScaleSchema);
          gameModel.getTypedUnsafe(entity, DamageStatsSchema).damageScale += ownerAoeDamageScale.damageScale ?? 0;
        }
      }
    }
  }
}

registerSystem(AoeDamageScaleSystem);

import { System } from "yage/components/System";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { HealthSchema } from "../core";
import { ComponentCategory } from "yage/constants/enums";
import { registerSystem } from "yage/components/ComponentRegistry";
import { OwnerSchema } from "yage/schemas/core/Owner";
import { ProjectilePierceEnhancerSchema } from "../enhancers/ProjectilePierceEnhancer";
import { ProjectilePierceSchema } from "../../schema/damage/ProjectilePierce";

class PierceProjectileSystem implements System {
  schema = ProjectilePierceSchema;
  type = "ProjectilePierce";
  dependencies = ["Health", "Owner"];
  category = ComponentCategory.WEAPON_AUGMENT;

  init(entity: number, gameModel: GameModel) {
    const pierceData = gameModel.getTypedUnsafe(entity, ProjectilePierceSchema);

    let pierceCount = pierceData.pierceCount;
    const owner = gameModel.getTypedUnsafe(entity, OwnerSchema).owner;
    if (owner && gameModel.hasComponent(owner, ProjectilePierceEnhancerSchema)) {
      pierceCount += gameModel.getTypedUnsafe(owner, ProjectilePierceEnhancerSchema).pierceCount;
    }

    if (pierceData.pierceCount) {
      HealthSchema.id = entity;
      HealthSchema.health += pierceData.pierceCount;
      HealthSchema.maxHealth += pierceData.pierceCount;
    }
  }
}

registerSystem(PierceProjectileSystem);

import { System } from "yage/components/System";
import { Component, Schema, type } from "yage/decorators/type";
import { DamageStatsSchema } from "../weapons/DamageStats";
import { GameModel } from "yage/game/GameModel";
import { OwnerSchema } from "yage/schemas/core/Owner";
import { ChildSchema } from "yage/schemas/entity/Child";
import { registerSystem } from "yage/components/ComponentRegistry";
import { ComponentCategory } from "yage/constants/enums";

@Component("PercentRangeDamage")
export class PercentRangeDamageSchema extends Schema {
  @type("number")
  percentRangeDamage: number;

  @type(DamageStatsSchema)
  damageStats: DamageStatsSchema;
}

class PercentRangeDamageSystem implements System {
  schema = PercentRangeDamageSchema;
  type = "PercentRangeDamage";
  category = ComponentCategory.DAMAGEMOD;

  run(entity: number, gameModel: GameModel) {
    const percentRangeDamage = gameModel.getTypedUnsafe(entity, PercentRangeDamageSchema);
    const damageStats = percentRangeDamage.damageStats;
    const owner =
      gameModel.getTypedUnsafe(entity, OwnerSchema).owner ?? gameModel.getTypedUnsafe(entity, ChildSchema).parent;
    if (!owner) return;

    const ownerDamageStats = gameModel.getTypedUnsafe(owner, DamageStatsSchema);

    damageStats.minDamage += ownerDamageStats.range * (percentRangeDamage.percentRangeDamage / 100);
    damageStats.maxDamage += ownerDamageStats.range * (percentRangeDamage.percentRangeDamage / 100);
  }
}

registerSystem(PercentRangeDamageSystem);

import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { Component, defaultValue, Schema, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";
import { EnemyTypeSchema, PlayerTypeSchema } from "yage/components/entity/Types";
import { GlobalEnemySpeedModSchema } from "../auras/GlobalEnemySpeedMod";

@Component("GlobalEnemySpeedModEnhancer")
export class GlobalEnemySpeedModEnhancerSchema extends Schema {
  @type("number")
  @defaultValue(0)
  speedMod: number;
}

class GlobalEnemySpeedModEnhancerSystem implements System {
  schema = GlobalEnemySpeedModEnhancerSchema;
  type = "GlobalEnemySpeedModEnhancer";
  dependencies = ["Child"];
  init(entity: number, gameModel: GameModel) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      if (!gameModel.hasComponent(gameModel.coreEntity, "GlobalEnemySpeedMod")) {
        gameModel.addComponent(gameModel.coreEntity, GlobalEnemySpeedModSchema);
      }
      gameModel.getTypedUnsafe(gameModel.coreEntity, GlobalEnemySpeedModSchema).speedMod += gameModel.getTypedUnsafe(
        entity,
        GlobalEnemySpeedModEnhancerSchema
      ).speedMod;
    }
  }

  cleanup(entity: number, gameModel: GameModel, ejecting: boolean) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      if (gameModel.hasComponent(gameModel.coreEntity, "GlobalEnemySpeedMod")) {
        gameModel.getTypedUnsafe(gameModel.coreEntity, GlobalEnemySpeedModSchema).speedMod -= gameModel.getTypedUnsafe(
          entity,
          GlobalEnemySpeedModEnhancerSchema
        ).speedMod;
      }
    }
  }
}
registerSystem(GlobalEnemySpeedModEnhancerSystem);

import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { Component, Schema, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";

@Component("FirstFrame")
export class FirstFrameSchema extends Schema {
  @type("number")
  firstFrame: number;
}

class FirstFrameSystem implements System {
  schema = FirstFrameSchema;
  type = "FirstFrame";

  init(entity: number, gameModel: GameModel) {
    gameModel.getTypedUnsafe(entity, FirstFrameSchema).firstFrame = gameModel.frame;
  }
}

registerSystem(FirstFrameSystem);

import { registerSchema } from "yage/components/ComponentRegistry";
import { ComponentCategory } from "yage/components/types";
import { PickupStateEnum } from "yage/constants/enums";
import { Component, defaultValue, nullable, Schema, type } from "yage/decorators/type";

@Component("PickupState")
export class PickupStateSchema extends Schema {
  @type("number")
  @defaultValue(PickupStateEnum.ON_THE_GROUND)
  state: PickupStateEnum;

  @type("Entity")
  @nullable()
  @defaultValue(null)
  owner: number | null;
}

registerSchema(ComponentCategory.BEHAVIOR, PickupStateSchema);

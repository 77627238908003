import { SwapComponentsSystem } from "yage/components/entity/SwapComponents";
import { registerSystem } from "yage/components/ComponentRegistry";
import type { System } from "yage/components/System";
import type { ComponentData } from "yage/components/types";
import { ComponentCategory, ComponentDataSchema } from "yage/components/types";
import { Component, defaultValue, Schema, type } from "yage/decorators/type";
import { EntityFactory } from "yage/entity/EntityFactory";
import type { GameModel } from "yage/game/GameModel";
import { SwapComponentsSchema } from "yage/schemas/entity/SwapComponents";
import { TransformSchema } from "yage/schemas/entity/Transform";
import { closestEntity } from "yage/utils/Collision";
import type { Vector2d } from "yage/utils/vector";
import { Vector2dSchema } from "yage/utils/vector";

@Component("TriggerEvent")
export class TriggerEventSchema extends Schema {
  @type(Vector2dSchema)
  location: Vector2d;

  @type("number")
  @defaultValue(0)
  width: number;

  @type("number")
  @defaultValue(0)
  height: number;

  @type("number")
  @defaultValue(0)
  count: number;

  @type("string")
  event: "ENTITY" | "MAPENTITY" | "SPAWN" | "UNKNOWN" | "TELEPORT" | "MOVE" | "SWAPONCOMPONENTS" | "SWAPOFFCOMPONENTS";

  @type("string")
  name: string;

  @type("object")
  overrideProperties: any;

  @type([ComponentDataSchema])
  @defaultValue([])
  components: ComponentData[];

  @type(["number"])
  @defaultValue([])
  triggerEntities: number[];
}

export class TriggerEventSystem implements System {
  type = "TriggerEvent";
  category: ComponentCategory = ComponentCategory.MAP;
  schema = TriggerEventSchema;

  trigger(data: TriggerEventSchema, gameModel: GameModel) {
    switch (data.event) {
      case "MAPENTITY": {
        let entity: number;
        if (EntityFactory.getInstance().hasEntity(data.name)) {
          let location = { ...data.location };
          if (data.width || data.height) {
            location = {
              x: location.x + gameModel.rand.int(-data.width / 2, data.width / 2),
              y: location.y + gameModel.rand.int(-data.height / 2, data.height / 2),
            };
          }
          entity = EntityFactory.getInstance().generateEntity(gameModel, data.name, {
            Transform: location,
            ...data.overrideProperties,
          });
        } else {
          entity = gameModel.addEntity();
          gameModel.addComponent(entity, "Transform", data.location);
        }
        gameModel.addComponent(entity, "MapEntityType", {
          width: data.width,
          height: data.height,
        });
        if (data.components) {
          data.components.forEach((component) => {
            if (Object.keys(component).length === 2 && component.inherit !== undefined) {
              gameModel.addComponent(entity, component.type);
            } else {
              gameModel.addComponent(entity, component.type, component);
            }
          });
        }
        break;
      }
      case "ENTITY": {
        for (let i = 0; i < (data.count || 1); i++) {
          let location = { ...data.location };
          if (data.width || data.height) {
            location = {
              x: location.x + gameModel.rand.int(-data.width / 2, data.width / 2),
              y: location.y + gameModel.rand.int(-data.height / 2, data.height / 2),
            };
          }
          const entity = EntityFactory.getInstance().generateEntity(gameModel, data.name, {
            Transform: location,
            ...data.overrideProperties,
          });
          if (data.components) {
            data.components.forEach((component) => {
              if (Object.keys(component).length === 2 && component.inherit !== undefined) {
                gameModel.addComponent(entity, component.type);
              } else {
                gameModel.addComponent(entity, component.type, component.data);
              }
            });
          }
        }
        break;
      }
      case "SWAPONCOMPONENTS":
      case "SWAPOFFCOMPONENTS": {
        const swapOn = data.event === "SWAPONCOMPONENTS";
        let entities = (!data.name && data.triggerEntities) || [
          closestEntity(
            gameModel,
            data.location,
            gameModel.getComponentActives(data.name[0].toUpperCase() + data.name.slice(1).toLowerCase() + "Type")
          ),
        ];
        if (
          !entities.some((entity) => {
            if (entity !== undefined) {
              if (gameModel.hasComponent(entity, SwapComponentsSchema)) {
                if (gameModel.getTypedUnsafe(entity, SwapComponentsSchema).swapped === !swapOn) {
                  gameModel.getSystem(SwapComponentsSystem).run(entity, gameModel);
                  return true;
                } else {
                  return false;
                }
              }
            }
          })
        ) {
          return false;
        }
        break;
      }

      case "MOVE": {
        // data.triggerEntities.forEach((entity) => {
        //   gameModel.addComponent(entity, "MoveOnMap", {
        //     location: data.name,
        //   });
        //   const moveOnMapSystem = gameModel.getSystem(MoveOnMapSystem);
        //   moveOnMapSystem.run(entity, gameModel);
        // });
        break;
      }
    }
    return true;
  }

  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, TriggerEventSchema);
    if (!data.location) {
      TransformSchema.id = entity;
      data.location = TransformSchema.position;
    }
    return this.trigger(data, gameModel);
  }
}

registerSystem(TriggerEventSystem);

import { System } from "yage/components/System";
import { Component, Schema, type } from "yage/decorators/type";
import { DamageStatsSchema, invalidateDamageStats } from "../weapons/DamageStats";
import { GameModel } from "yage/game/GameModel";
import { OwnerSchema } from "yage/schemas/core/Owner";
import { ChildSchema } from "yage/schemas/entity/Child";
import { registerSystem } from "yage/components/ComponentRegistry";
import { ComponentCategory } from "yage/constants/enums";
import { InvalidateDamageStatsOnMaxHealthChangeSchema } from "./PercentMaxHealthDamage";
import { HealthSchema } from "../core";

@Component("PercentHealthDamage")
export class PercentHealthDamageSchema extends Schema {
  @type("number")
  percentHealthDamage: number;

  @type(DamageStatsSchema)
  damageStats: DamageStatsSchema;
}

class PercentHealthDamageSystem implements System {
  schema = PercentHealthDamageSchema;
  type = "PercentHealthDamage";
  category = ComponentCategory.DAMAGEMOD;
  dependencies = ["Child", "DamageStats"];

  init(entity: number, gameModel: GameModel) {
    if (gameModel.hasComponent(entity, ChildSchema)) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      gameModel.addComponent(parent, InvalidateDamageStatsOnMaxHealthChangeSchema);
      invalidateDamageStats(parent, gameModel);
    } else {
      gameModel.addComponent(entity, InvalidateDamageStatsOnMaxHealthChangeSchema);
      invalidateDamageStats(entity, gameModel);
    }
  }

  run(entity: number, gameModel: GameModel) {
    const PercentHealthDamage = gameModel.getTypedUnsafe(entity, PercentHealthDamageSchema);
    const damageStats = PercentHealthDamage.damageStats;
    const owner = gameModel.getTyped(entity, OwnerSchema)?.owner ?? gameModel.getTyped(entity, ChildSchema)?.parent;
    if (!owner) return;

    HealthSchema.id = owner;
    let health = HealthSchema.maxHealth;

    damageStats.minDamage += health * (PercentHealthDamage.percentHealthDamage / 100);
    damageStats.maxDamage += health * (PercentHealthDamage.percentHealthDamage / 100);
  }
}

registerSystem(PercentHealthDamageSystem);

import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { Component, Schema, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";
import { HpRegenSchema } from "../player/HpRegen";
import { EnemyTypeSchema, PlayerTypeSchema } from "yage/components/entity/Types";
import { HpRegenScalerSchema } from "../scalers/HpRegenScaler";

@Component("HpRegenEnhancer")
export class HpRegenEnhancerSchema extends Schema {
  @type("number")
  regen: number;

  @type("number")
  regenAmountScale: number;
}

class HpRegenEnhancerSystem implements System {
  schema = HpRegenEnhancerSchema;
  type = "HpRegenEnhancer";
  dependencies = ["Child", "HpRegenScaler"];
  init(entity: number, gameModel: GameModel) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (gameModel.hasComponent(parent, "HpRegen")) {
        let scaler = 1;
        const data = gameModel.getTypedUnsafe(entity, HpRegenEnhancerSchema);
        if (gameModel.hasComponent(parent, "HpRegenScaler")) {
          scaler += gameModel.getTypedUnsafe(parent, HpRegenScalerSchema).regenScale;
        }
        gameModel.getTypedUnsafe(parent, HpRegenSchema).regen += data.regen;
        if (data.regenAmountScale) {
          gameModel.getTypedUnsafe(parent, HpRegenSchema).regenAmountScale += data.regenAmountScale;
        }
      }
    }
  }

  cleanup(entity: number, gameModel: GameModel, ejecting: boolean) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (gameModel.hasComponent(parent, "HpRegen")) {
        let scaler = 1;
        if (gameModel.hasComponent(parent, "HpRegenScaler")) {
          scaler += gameModel.getTypedUnsafe(parent, HpRegenScalerSchema).regenScale;
        }

        gameModel.getTypedUnsafe(parent, HpRegenSchema).regen -=
          gameModel.getTypedUnsafe(entity, HpRegenEnhancerSchema).regen * scaler;
      }
    }
  }
}
registerSystem(HpRegenEnhancerSystem);

import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { checkCondition, unlockAchievement } from "./Achievements";
import { System } from "yage/components/System";
import { GameModel } from "yage/game/GameModel";
import { Achievement } from "../../schema/achievements/Achievements";
import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import { ChildSchema } from "yage/schemas/entity/Child";

@Component("TrackAchievementsActiveStat")
export class TrackAchievementsActiveStatSchema extends Schema {
  @type([Achievement])
  @defaultValue([])
  achievements: Achievement[];
}

class TrackAchievementsActiveStatSystem implements System {
  schema = TrackAchievementsActiveStatSchema;
  type = "TrackAchievementsActiveStat";

  depth = DEPTHS.TRIGGERS;

  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, TrackAchievementsActiveStatSchema);

    for (const achievement of data.achievements) {
      if (
        checkCondition(gameModel.getTypedUnsafe(entity, ChildSchema)?.parent ?? -1, achievement.condition, gameModel)
      ) {
        unlockAchievement(entity, achievement, gameModel);
        data.achievements = data.achievements.filter((a) => a.name !== achievement.name);
        if (!data.achievements.length) {
          gameModel.removeComponent(entity, this.type);
        }
      }
    }
  }
}

registerSystem(TrackAchievementsActiveStatSystem);

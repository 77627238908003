import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { checkCondition, incrementAchievement } from "./Achievements";
import { System } from "yage/components/System";
import { ComponentCategory } from "yage/constants/enums";
import { GameModel } from "yage/game/GameModel";
import { Achievement } from "../../schema/achievements/Achievements";
import { registerSystem } from "yage/components/ComponentRegistry";

@Component("TrackAchievementsOnDeath")
export class TrackAchievementsOnDeathSchema extends Schema {
  @type([Achievement])
  @defaultValue([])
  achievements: Achievement[];

  @type("number")
  @defaultValue(0)
  killedEntity: number;
}

class TrackAchievementsOnDeathSystem implements System {
  schema = TrackAchievementsOnDeathSchema;
  type = "TrackAchievementsOnDeath";
  category = ComponentCategory.ONDEATH;

  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, TrackAchievementsOnDeathSchema);

    for (const achievement of data.achievements) {
      if (
        checkCondition(entity, achievement.condition, gameModel, {
          entity: data.killedEntity,
        })
      ) {
        const unlocked = incrementAchievement(entity, achievement, gameModel, 1);
        if (unlocked) {
          data.achievements = data.achievements.filter((a) => a.name !== achievement.name);
          if (!data.achievements.length) {
            gameModel.removeComponent(entity, this.type);
          }
        }
      }
    }
  }
}

registerSystem(TrackAchievementsOnDeathSystem);

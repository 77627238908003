import { GameModel } from "yage/game/GameModel";
import { DamageableSchema } from "../schema/damage/Damage";
import { createDamageStats } from "../components/weapons/DamageStats";
import { DamageCategoryEnum } from "yage/constants/enums";

export const generateHealDamage = (healAmount: number, entity: number, gameModel: GameModel) => {
  const damages = gameModel.getTypedUnsafe(entity, DamageableSchema).damages;
  if (!damages) {
    console.log("No damages on entity", entity);
    gameModel.logEntity(entity, true);
  }
  damages.push({
    damage: -healAmount,
    damageType: 0,
    direction: { x: 0, y: 0 },
    frame: gameModel.timeElapsed,
    knockback: 0,
    owner: entity,
    source: entity,
    critChance: 0,
    critHit: false,
    damageScale: 1,
    damageStats: createDamageStats(),
    damageCategory: DamageCategoryEnum.NONE,
  });
};

export const generateDodgeDamage = (entity: number, gameModel: GameModel) => {
  const damages = gameModel.getTypedUnsafe(entity, DamageableSchema).damages;
  if (!damages) {
    console.log("No damages on entity", entity);
    gameModel.logEntity(entity, true);
  }
  damages.push({
    damage: 0,
    damageType: 0,
    direction: { x: 0, y: 0 },
    frame: gameModel.timeElapsed,
    knockback: 0,
    owner: entity,
    source: entity,
    critChance: 0,
    critHit: false,
    damageScale: 1,
    damageStats: createDamageStats(),
    damageCategory: DamageCategoryEnum.NONE,
  });
};

import { System } from "yage/components/System";
import { ComponentCategory } from "yage/constants/enums";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { registerSystem } from "yage/components/ComponentRegistry";
import { TransformSchema } from "yage/schemas/entity/Transform";
import DescriptionSchema from "yage/schemas/core/Description";
import { EntityFactory } from "yage/entity/EntityFactory";

@Component("SpawnOnKill")
export class SpawnOnKillSchema extends Schema {
  @type("string")
  entityDescription: string;

  @type("string")
  @defaultValue("")
  killDescription: string;

  @type("Entity")
  @defaultValue(-1)
  owner: number;

  @type("Entity")
  @defaultValue(-1)
  killedEntity: number;
}

class SpawnOnKillSystem implements System {
  type = "SpawnOnKill";
  category = ComponentCategory.ONKILL;
  schema = SpawnOnKillSchema;

  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, SpawnOnKillSchema);
    if (gameModel.getTypedUnsafe(data.killedEntity, DescriptionSchema).description !== data.killDescription) {
      return;
    }
    const position = gameModel.getTypedUnsafe(data.killedEntity, TransformSchema).position;
    EntityFactory.getInstance().generateEntity(gameModel, data.entityDescription, {
      Transform: position,
      Owner: { owner: data.owner },
    });
  }
}

registerSystem(SpawnOnKillSystem);

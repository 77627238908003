import { DamageCategoryEnum } from "yage/constants/enums";
import { EntityFactory } from "yage/entity/EntityFactory";
import { Text } from "yage/ui/Text";
import { ShopSchema, HtmlSchema } from "../../components/core/Shop";
import { DamageStatsSchema } from "../../schema/damage/Damage";
import { HtmlSchemaToHtml } from "../../utils/HtmlSchemaToHtml";
import { ImageBox } from "yage/ui/ImageBox";

const itemColor = (shopInfo: ShopSchema, tier1Color?: string) => {
  const tier = shopInfo.tags.find((t) => t.startsWith("Tier::"))?.split("::")[1];
  if (tier === "1") {
    return tier1Color ?? "#404040";
  } else if (tier === "2") {
    return "#3b5464";
  } else if (tier === "3") {
    return "#4d3862";
  } else if (tier === "4") {
    return "#633232";
  }
  return tier1Color ?? "#404040";
};

const itemFontColor = (shopInfo: ShopSchema, tier1Color?: string) => {
  const tier = shopInfo.tags.find((t) => t.startsWith("Tier::"))?.split("::")[1];
  if (tier === "1") {
    return tier1Color ?? "#404040";
  } else if (tier === "2") {
    return "#537c8f";
  } else if (tier === "3") {
    return "#6c4e8a";
  } else if (tier === "4") {
    return "#a85858";
  }
  return tier1Color ?? "#404040";
};

export const getShopInfo = (item: string, filter?: string, wildcardFilter: boolean = false): ShopSchema | undefined => {
  const shop = EntityFactory.getInstance().getComponentFromEntity(item, "Shop") as unknown as ShopSchema;
  if (filter) {
    if (wildcardFilter) {
      return (shop.tags?.some((tag) => tag.includes(filter)) && shop) || undefined;
    }
    return (shop.tags?.includes(filter) && shop) || undefined;
  }
  return shop;
};

export const itemInfoContext = (item: string) => {
  if (!item) {
    return {
      item: "",
      label: "",
      image: "",
      description: "",
      tierColor: "",
    };
  }
  const ef = EntityFactory.getInstance();

  const shopInfo = ef.getComponentFromEntity(item, "Shop")! as unknown as ShopSchema;

  let weaponStats: DamageStatsSchema | undefined;
  let damageCategory: DamageCategoryEnum | undefined;
  let lifesteal: number | undefined;
  if (ef.getComponentFromEntity(item, "WeaponType")) {
    weaponStats = ef.getComponentFromEntity(item, "DamageStats") as unknown as DamageStatsSchema;
    lifesteal = ef.getComponentFromEntity(item, "Lifesteal")?.lifesteal;
    damageCategory = ef.getComponentFromEntity(item, "Damage")?.damageCategory;
  }

  const label = HtmlSchemaToHtml({
    tag: "div",
    children: [
      {
        tag: "div",
        innerText: shopInfo.title,
      },
      {
        tag: "div",
        innerText: shopInfo.tagline,
        style: {
          fontSize: "20px",
          color: "#D6BF86",
        },
      },
    ],
  });
  const image = `shopitem::${item}`;
  let description: string;
  if (weaponStats) {
    let damage: string = weaponStats.minDamage.toString();
    if (weaponStats.minDamage !== weaponStats.maxDamage) {
      damage = `${weaponStats.minDamage} - ${weaponStats.maxDamage}`;
    }
    let critDamage: string = weaponStats.critChance
      ? `x${weaponStats.critScale} (${weaponStats.critChance}% chance)`
      : "";
    let cooldown = weaponStats.attackSpeed + "s";
    let knockback = weaponStats.knockback.toString();
    let range =
      damageCategory === DamageCategoryEnum.MELEE ? `${weaponStats.range} (Melee)` : weaponStats.range.toString();

    let items: [string, string][] = [
      ["Damage", damage],
      lifesteal ? ["Lifesteal", `${lifesteal}%`] : null,
      critDamage ? ["Crit Damage", critDamage] : null,
      ["Cooldown", cooldown],
      knockback ? ["Knockback", knockback] : null,
      ["Range", range],
      ["", shopInfo.description],
    ].filter((i) => i) as [string, string][];

    const weaponSchema: HtmlSchema = {
      tag: "div",
      children: items.reduce((acc, [key, value]) => {
        if (key === "") {
          acc.push({
            tag: "div",
            innerText: value,
          });
          return acc;
        }
        acc.push({
          tag: "div",
          children: [
            {
              tag: "span",
              innerText: `${key}: `,
              style: {
                color: "#D6BF86",
              },
            },
            {
              tag: "span",
              innerText: value,
            },
          ],
        });
        return acc;
      }, [] as HtmlSchema[]),
    };

    description = HtmlSchemaToHtml(weaponSchema);
  } else {
    description = shopInfo.content ? HtmlSchemaToHtml(shopInfo.content) : shopInfo.description;
  }
  return {
    label,
    image,
    description,
    item,
    tierColor: itemColor(shopInfo),
    fontColor: itemFontColor(shopInfo, "#FFFFFF"),
  };
};

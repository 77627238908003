import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { Component, defaultValue, Schema, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";
import { EnemyTypeSchema, PlayerTypeSchema } from "yage/components/entity/Types";

@Component("MagnetEnhancer")
export class MagnetEnhancerSchema extends Schema {
  @type("number")
  @defaultValue(25)
  radius: number;
}

class MagnetEnhancerSystem implements System {
  schema = MagnetEnhancerSchema;
  type = "MagnetEnhancer";
  dependencies = ["Child"];
  init(entity: number, gameModel: GameModel) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (!gameModel.hasComponent(parent, "MagnetEnhancer")) {
        gameModel.addComponent(parent, MagnetEnhancerSchema);
      }
      gameModel.getTypedUnsafe(parent, MagnetEnhancerSchema).radius += gameModel.getTypedUnsafe(
        entity,
        MagnetEnhancerSchema
      ).radius;
    }
  }

  cleanup(entity: number, gameModel: GameModel, ejecting: boolean) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (gameModel.hasComponent(parent, "MagnetEnhancer")) {
        gameModel.getTypedUnsafe(parent, MagnetEnhancerSchema).radius -= gameModel.getTypedUnsafe(
          entity,
          MagnetEnhancerSchema
        ).radius;
      }
    }
  }
}
registerSystem(MagnetEnhancerSystem);

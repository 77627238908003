import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { ComponentCategory, DamageDirectionEnum } from "yage/constants/enums";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { ComponentDataSchema, ComponentData } from "yage/components/types";
import { EntityFactory } from "yage/entity/EntityFactory";
import { OwnerSchema } from "yage/schemas/core/Owner";
import { LocomotionSchema } from "yage/schemas/entity/Locomotion";
import { TransformSchema } from "yage/schemas/entity/Transform";
import { BV2 } from "yage/utils/vector";
import { getWeaponAugmentComponents, applyComponentsToProjectile } from "../../utils/weapons";
import { EnemyCollidersSchema } from "../enemy/EnemyColliders";

@Component("SpawnProjectileOnDamage")
class SpawnProjectileOnDamageSchema extends Schema {
  @type("number")
  @defaultValue(1)
  projectileCount: number;

  @type("number")
  @defaultValue(1)
  spawnChance: number;

  @type("boolean")
  @defaultValue(false)
  augmentFromParent: boolean;

  @type("string")
  @defaultValue("Bullet")
  entityDescription: string;

  @type([ComponentDataSchema])
  @defaultValue([])
  overrideComponents: ComponentData[];

  @type("number")
  @defaultValue(0)
  currentDamageScale: number;

  @type("number")
  @defaultValue(0)
  damage: number;
}

class SpawnProjectileOnDamageSystem implements System {
  schema = SpawnProjectileOnDamageSchema;
  type = "SpawnProjectileOnDamage";
  category = ComponentCategory.ONDAMAGE;
  depth = DEPTHS.NORUN + 100000;

  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, SpawnProjectileOnDamageSchema);

    if (data.currentDamageScale * data.damage <= 0) {
      return;
    }
    if (gameModel.rand.number() > data.spawnChance) {
      return;
    }

    const owner = gameModel.getTypedUnsafe(entity, OwnerSchema).owner!;
    const parent = entity; //gameModel.getTyped(entity, ChildSchema)?.parent ?? entity;

    const angles = [];
    for (let i = 0; i < data.projectileCount; i++) {
      angles.push(gameModel.rand.int(0, 359));
    }

    TransformSchema.id = owner;
    const position = TransformSchema.position;
    const colliders = [...gameModel.getTypedUnsafe(owner, EnemyCollidersSchema).colliders];

    angles.forEach((angle) => {
      const overrides: any = {
        Transform: position,
        EnemyColliders: {
          colliders,
        },
        Owner: {
          owner,
        },
        Child: {
          parent: parent,
        },
      };
      const projectile = EntityFactory.getInstance().generateEntity(gameModel, data.entityDescription, overrides);

      TransformSchema.id = projectile;
      TransformSchema.position = position;

      LocomotionSchema.id = projectile;
      const rotatedVelocity = BV2.rotateDegVector2d(0, LocomotionSchema.speed, angle);
      LocomotionSchema.velocityX = rotatedVelocity[0];
      LocomotionSchema.velocityY = rotatedVelocity[1];

      const [augmentComponents, auraComponents] = getWeaponAugmentComponents(
        projectile,
        data.augmentFromParent ? parent : owner,
        gameModel
      );
      applyComponentsToProjectile(
        projectile,
        DamageDirectionEnum.PROJECTILE,
        augmentComponents,
        auraComponents,
        gameModel
      );

      if (data.overrideComponents.length > 0) {
        for (let i = 0; i < data.overrideComponents.length; i++) {
          const override = data.overrideComponents[i];
          gameModel.addComponent(projectile, override.type, override.data ?? override);
        }
      }
    });
  }
}

registerSystem(SpawnProjectileOnDamageSystem);

import { registerSchema } from "yage/components/ComponentRegistry";
import { Component, Schema, type } from "yage/decorators/type";

@Component("AppliedWeaponTag")
export class AppliedWeaponTagsSchema extends Schema {
  @type(["string"])
  tags: string[];
}

registerSchema(AppliedWeaponTagsSchema);

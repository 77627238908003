import { DEPTHS, registerSchema, registerSystem } from "yage/components/ComponentRegistry";
import type { System } from "yage/components/System";
import type { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";
import { LocomotionSchema } from "yage/schemas/entity/Locomotion";
import { scaleVector2d } from "yage/utils/vector";
import { type, Schema, Component, defaultValue } from "yage/decorators/type";
import { CollisionsSchema } from "yage/schemas/physics/Collisions";
import { OwnerSchema } from "yage/schemas/core/Owner";
import { SpeedEnhancerSchema } from "../enhancers/SpeedEnhancer";
import { DamageStatsEnhancerSchema } from "../enhancers/DamageStatsEnhancer";
import { HealthSchema } from "../core";
import { BuffAuraSchema } from "./BuffAura";
import { addToDestroyOnTimeout } from "yage/components/timeouts/DestroyOnTimeoutComponent";
import { DestroyOnTimeoutSchema } from "yage/schemas/timeouts/DestroyOnTimeoutComponent";

@Component("SpeedAura")
export class SpeedAuraSchema extends Schema {
  @type("number")
  @defaultValue(-1.5)
  speed: number;

  @type(["number"])
  @defaultValue([])
  affectedEntities: number[];
}

@Component("SpeedAuraApplied")
export class SpeedAuraAppliedSchema extends Schema {
  @type(["number"])
  @defaultValue([])
  source: number[];
}

registerSchema(SpeedAuraAppliedSchema);

class SpeedAuraSystem implements System {
  type: string = "SpeedAura";
  schema: typeof Schema = SpeedAuraSchema;
  depth = DEPTHS.COLLISION + 1;

  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, SpeedAuraSchema);

    const collisionData = gameModel.getTyped(gameModel.coreEntity, CollisionsSchema)?.collisions[entity] ?? {};
    const owner = gameModel.getTypedUnsafe(entity, OwnerSchema).owner!;
    const colliders = Object.keys(collisionData)
      .filter((key) => key !== owner.toString())
      .map((key) => parseInt(key));

    for (let i = 0; i < colliders.length; i++) {
      const collider = colliders[i];
      if (
        gameModel.hasComponent(collider, SpeedAuraAppliedSchema) &&
        gameModel.getTypedUnsafe(collider, SpeedAuraAppliedSchema).source.includes(entity)
      ) {
        continue;
      }
      const buffEntity = gameModel.addEntity();
      gameModel.addComponent(buffEntity, ChildSchema, { parent: collider });
      gameModel.addComponent(buffEntity, SpeedEnhancerSchema, {
        speed: data.speed,
        speedScale: 0.0,
      });
      addToDestroyOnTimeout(buffEntity, "SpeedEnhancer", 1000, gameModel);
      addToDestroyOnTimeout(buffEntity, "", 1100, gameModel);

      gameModel.addIfMissing(collider, SpeedAuraAppliedSchema);
      gameModel.getTypedUnsafe(collider, SpeedAuraAppliedSchema).source.push(entity);
      addToDestroyOnTimeout(collider, "SpeedAuraApplied", 1000, gameModel);
    }
  }
  cleanup?: ((entity: number, gameModel: GameModel, ejecting: boolean) => void) | undefined;
}

registerSystem(SpeedAuraSystem);

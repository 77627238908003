import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { ComponentCategory } from "yage/constants/enums";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { StoreItemSchema } from "../pickups/StoreItem";
import { getEntityController } from "../../utils/playerCharacter";
import { InventorySchema } from "../player/Inventory";
import { HealthSchema, getMaxHealth } from "../core";
import { InvalidateDamageStatsOnMaxHealthChangeSchema } from "../damagemods/PercentMaxHealthDamage";
import { DamageStatsSchema, invalidateDamageStats } from "../weapons/DamageStats";
import { HpBlockSchema } from "../player/HpBlock";

export class StatOnHordeSchema extends Schema {
  @type("number")
  @defaultValue(1)
  scale: number;

  @type("string")
  scalingStat: string;

  @type("string")
  scalingComponentType: string;

  @type("number")
  @defaultValue(1)
  divisor: number;

  @type("number")
  @defaultValue(0)
  currentStatValue: number;
}

@Component("StatOnHorde")
export class StatOnHordeContainerSchema extends Schema {
  @type([StatOnHordeSchema])
  @defaultValue([])
  horde: StatOnHordeSchema[];

  @type(StoreItemSchema)
  pickupData: StoreItemSchema;
}

export class StatOnHordeSystem implements System {
  schema = StatOnHordeContainerSchema;
  type = "StatOnHorde";
  category = ComponentCategory.ON_ADD_TO_INVENTORY;

  run(entity: number, gameModel: GameModel) {
    const StatOnHordeContainer = gameModel.getTypedUnsafe(entity, StatOnHordeContainerSchema);

    if (StatOnHordeContainer.pickupData.inventoryType === "coins") {
      for (let i = 0; i < StatOnHordeContainer.horde.length; i++) {
        const data = StatOnHordeContainer.horde[i];
        const inventoryOwner = getEntityController(entity, gameModel);
        const inventory = gameModel.getTypedUnsafe(inventoryOwner, InventorySchema).inventory;

        if (data.scalingComponentType === "Health") {
          HealthSchema.id = entity;
          const prevMaxHealth = HealthSchema.maxHealth;
          const nextHealth = Math.floor((inventory.coins.amount / data.divisor) * data.scale);
          const additionalHealth = nextHealth - data.currentStatValue;

          if (additionalHealth == 0) {
            continue;
          }

          HealthSchema.maxHealth += additionalHealth;
          if (
            additionalHealth > 0 &&
            gameModel.hasComponent(entity, HpBlockSchema) &&
            !gameModel.getTypedUnsafe(entity, HpBlockSchema).excludedTypes.includes("StatOnHorde")
          ) {
            // no-op
          } else if (HealthSchema.health + additionalHealth <= getMaxHealth(entity, gameModel)) {
            HealthSchema.health += additionalHealth;
          }

          data.currentStatValue = nextHealth;

          if (
            HealthSchema.maxHealth !== prevMaxHealth &&
            gameModel.hasComponent(entity, InvalidateDamageStatsOnMaxHealthChangeSchema)
          ) {
            invalidateDamageStats(entity, gameModel);
          }
        } else if (data.scalingComponentType === "DamageStats") {
          const prevStatValue = data.currentStatValue;
          const nextStatValue = (inventory.coins.amount / data.divisor) * data.scale;
          const additionalStatValue = nextStatValue - prevStatValue;

          if (additionalStatValue == 0) {
            continue;
          }
          const stats = gameModel.getTypedUnsafe(entity, DamageStatsSchema).initialDamageStats;

          if (data.scalingStat.startsWith("min")) {
            const maxStat = "max" + data.scalingStat.substring(3);
            // @ts-ignore
            stats[maxStat] = stats[maxStat] + additionalStatValue;
          }

          // @ts-ignore
          stats[data.scalingStat] = stats[data.scalingStat] + additionalStatValue;

          data.currentStatValue = nextStatValue;
          gameModel.getTypedUnsafe(entity, DamageStatsSchema).invalidated = true;
        } else {
          const prevStatValue = data.currentStatValue;
          const nextStatValue = (inventory.coins.amount / data.divisor) * data.scale;
          const additionalStatValue = nextStatValue - prevStatValue;

          if (additionalStatValue == 0) {
            continue;
          }

          const stats = gameModel.getComponentUnsafe(entity, data.scalingComponentType);

          if (data.scalingStat.startsWith("min")) {
            const maxStat = "max" + data.scalingStat.substring(3);
            // @ts-ignore
            stats[maxStat] = stats[maxStat] + additionalStatValue;
          }

          // @ts-ignore
          stats[data.scalingStat] = stats[data.scalingStat] + additionalStatValue;

          data.currentStatValue = nextStatValue;
        }
      }
    }
  }
}

registerSystem(StatOnHordeSystem);

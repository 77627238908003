import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { PlayerTypeSchema, EnemyTypeSchema } from "yage/components/entity/Types";
import { ComponentCategory } from "yage/constants/enums";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";
import { BossTypeSchema } from "../core/BossType";

@Component("BossPercentageDamageMod")
export class BossPercentageDamageModSchema extends Schema {
  @type("number")
  @defaultValue(0)
  additionalDamageScale: number;

  @type("number")
  @defaultValue(0)
  damageScale: number;

  @type("number")
  @defaultValue(0)
  maker: number;

  @type("number")
  @defaultValue(0)
  taker: number;
}

class BossPercentageDamageModSystem implements System {
  schema = BossPercentageDamageModSchema;
  type = "BossPercentageDamageMod";
  category = ComponentCategory.ON_APPLY_DAMAGE;
  dependencies = ["Child"];
  init(entity: number, gameModel: GameModel) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      gameModel.addIfMissing(parent, BossPercentageDamageModSchema);
    }
  }

  run(entity: number, gameModel: GameModel) {
    const bossDamageModDamage = gameModel.getTypedUnsafe(entity, BossPercentageDamageModSchema);
    if (gameModel.hasComponent(bossDamageModDamage.taker, BossTypeSchema)) {
      bossDamageModDamage.damageScale = 1 + bossDamageModDamage.additionalDamageScale;
    } else {
      bossDamageModDamage.damageScale = 1;
    }
  }
}

registerSystem(BossPercentageDamageModSystem);

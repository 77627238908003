import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { Component, defaultValue, Schema, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";

@Component("SpeedScaler")
export class SpeedScalerSchema extends Schema {
  @type("number")
  @defaultValue(0)
  speedScale: number;
}

class SpeedScalerSystem implements System {
  schema = SpeedScalerSchema;
  type = "SpeedScaler";
  dependencies = ["Child"];
  init(entity: number, gameModel: GameModel) {
    if (gameModel.hasComponent(entity, ChildSchema)) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (!gameModel.hasComponent(parent, "SpeedScaler")) {
        gameModel.addComponent(parent, SpeedScalerSchema);
      }
      gameModel.getTypedUnsafe(parent, SpeedScalerSchema).speedScale += gameModel.getTypedUnsafe(
        entity,
        SpeedScalerSchema
      ).speedScale;
    }
  }
}
registerSystem(SpeedScalerSystem);

import { registerSystem } from "yage/components/ComponentRegistry";
import type { System } from "yage/components/System";
import { ComponentCategory, ComponentDataSchema } from "yage/components/types";
import { Component, Schema, type } from "yage/decorators/type";
import type { GameModel } from "yage/game/GameModel";
import { DamageApplicationSchema, DamageApplierSchema } from "./DamageApplier";
import { DamageCategoryEnum, DamageTypeEnum } from "yage/constants/enums";
import { OwnerSchema } from "yage/schemas/core/Owner";
import { EnemyCollidersSchema } from "../enemy/EnemyColliders";
import { DamageStatsSchema, applyDamageStats, createDamageStats } from "../weapons/DamageStats";
import { DamageCategorySchema } from "./DamageCategory";
import { FireDamageOnHitSchema } from "./FireDamageOnHit";
import DescriptionSchema from "yage/schemas/core/Description";
import { ChildSchema } from "yage/schemas/entity/Child";

@Component("DamageGenerator")
export class DamageGeneratorSchema extends Schema {
  @type(DamageTypeEnum)
  damageType?: DamageTypeEnum;
}

export class DamageGeneratorSystem implements System {
  type = "DamageGenerator";
  category: ComponentCategory = ComponentCategory.DAMAGE;
  schema = DamageGeneratorSchema;

  dependencies = ["DamageApplier", "DamageStats"];

  init(entity: number, gameModel: GameModel) {
    const damageApplier = gameModel.getTypedUnsafe(entity, DamageApplierSchema);
    damageApplier.damages = [];

    generateDamages(entity, gameModel);
  }

  run(entity: number, gameModel: GameModel) {
    const damageApplier = gameModel.getTypedUnsafe(entity, DamageApplierSchema);
    damageApplier.damages = [];
    generateDamages(entity, gameModel);
  }
}
registerSystem(DamageGeneratorSystem);

export const generateDamages = (entity: number, gameModel: GameModel) => {
  const damageCategory = gameModel.getTyped(entity, DamageCategorySchema)?.damageCategory ?? DamageCategoryEnum.NONE;
  const damageStats = applyDamageStats(createDamageStats(), gameModel.getTypedUnsafe(entity, DamageStatsSchema));
  const owner = gameModel.getTyped(entity, OwnerSchema)?.owner ?? entity;
  const colliders = gameModel.getTypedUnsafe(owner, EnemyCollidersSchema)?.colliders;
  const damageMods = gameModel.getComponentIdsByCategory(entity, ComponentCategory.DAMAGEMOD);
  const onHitMods = gameModel.getComponentIdsByCategory(entity, ComponentCategory.ONHIT_MOD);
  const damageGenerator = gameModel.getTypedUnsafe(entity, DamageGeneratorSchema);

  for (let i = 0; i < damageMods.length; i++) {
    const damageMod = gameModel.getComponentUnsafe(entity, damageMods[i]);
    damageMod.damageStats = damageStats;
    gameModel.getSystem(damageMods[i]).run?.(entity, gameModel);
  }

  if (isNaN(damageStats.knockback)) {
    gameModel.logEntity(entity, true);
    throw new Error("DamageStats.knockback is NaN");
  }
  const damageApplier = gameModel.getTypedUnsafe(entity, DamageApplierSchema);

  const generateByType = (type: DamageTypeEnum) => {
    const damageApplication: DamageApplicationSchema = {
      damageType: damageGenerator.damageType ?? type,
      damageCategory: damageCategory,
      onHit: [],
      owner: owner && owner > 0 ? owner : entity,
      damageStats: damageStats,
    };

    if (damageStats.burnChance > 0) {
      let elementalDamageScale = damageStats.burnDamageScale || damageStats.elementalDamageScale;
      let minElementalDamage = damageStats.minElementalDamage;
      let maxElementalDamage = damageStats.maxElementalDamage;
      if (elementalDamageScale === 0 && damageStats.allyDamageScale) {
        elementalDamageScale = damageStats.allyDamageScale;
        minElementalDamage = damageStats.minAllyDamage;
        maxElementalDamage = damageStats.maxAllyDamage;
      }

      const onHit: ComponentDataSchema<FireDamageOnHitSchema> = {
        type: "FireDamageOnHit",
        data: {
          colliders,
          owner: owner,
          burnDuration: damageStats.burnDuration || 3,
          burnChance: damageStats.burnChance,
          minFireDamage: (damageStats.minFireDamage || 1) + minElementalDamage * elementalDamageScale,
          maxFireDamage: (damageStats.maxFireDamage || 1) + maxElementalDamage * elementalDamageScale,
        },
      };

      damageApplication.onHit.push(onHit);
    }
    if (damageStats.bleedChance) {
      damageApplication.onHit.push({
        type: "BleedOnHit",
        data: {
          colliders,
          owner: owner,
          bleedChance: damageStats.bleedChance,
          duration: damageStats.bleedDuration,
          interval: damageStats.bleedInterval,
        },
      });
    }
    if (damageStats.poisonChance && (damageStats.minChaosDamage !== 0 || damageStats.maxChaosDamage !== 0)) {
      damageApplication.onHit.push({
        type: "PoisonOnHit",
        data: {
          colliders,
          owner: owner,
          damage: damageStats.minChaosDamage,
          poisonChance: damageStats.poisonChance,
          duration: damageStats.poisonDuration,
          interval: damageStats.poisonInterval,
        },
      });
    }
    if (damageApplication.onHit.length !== 0) {
      if (onHitMods.length > 0) {
        for (let i = 0; i < onHitMods.length; i++) {
          const onHitMod = gameModel.getComponentUnsafe(entity, onHitMods[i]);
          for (let j = 0; j < damageApplication.onHit.length; j++) {
            const onHit = damageApplication.onHit[j];
            onHitMod.onHit = onHit;
            gameModel.getSystem(onHitMods[i]).run?.(entity, gameModel);
          }
        }
      }
    }
    return damageApplication;
  };

  if (damageStats.minDamage !== 0 || damageStats.maxDamage !== 0) {
    damageApplier.damages.push(generateByType(DamageTypeEnum.NORMAL));
  }
  // if (damageStats.minFireDamage !== 0 || damageStats.maxFireDamage !== 0) {
  //   damageApplier.damages.push(generateByType(DamageTypeEnum.FIRE));
  // }
  // if (damageStats.minChaosDamage !== 0 || damageStats.maxChaosDamage !== 0) {
  //   damageApplier.damages.push(generateByType(DamageTypeEnum.CHAOS));
  // }
  // if (damageStats.minLightningDamage !== 0 || damageStats.maxLightningDamage !== 0) {
  //   damageApplier.damages.push(generateByType(DamageTypeEnum.SHOCK));
  // }
  // if (damageStats.minColdDamage !== 0 || damageStats.maxColdDamage !== 0) {
  //   damageApplier.damages.push(generateByType(DamageTypeEnum.ICE));
  // }
};

import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { PlayerTypeSchema, EnemyTypeSchema } from "yage/components/entity/Types";
import { ComponentCategory } from "yage/constants/enums";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";

@Component("ShareOnRemoveFromParent")
export class ShareOnRemoveFromParentSchema extends Schema {
  @type(["number"])
  @defaultValue([])
  entities: number[];

  @type("number")
  @defaultValue(-1)
  owner: number;

  @type("number")
  @defaultValue(0)
  pickedup: number;
}

class ShareOnRemoveFromParentSystem implements System {
  schema = ShareOnRemoveFromParentSchema;
  type = "ShareOnRemoveFromParent";

  category = ComponentCategory.ON_REMOVE_FROM_PARENT;
  dependencies = ["Child"];

  init(entity: number, gameModel: GameModel) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      gameModel.addIfMissing(parent, ShareOnRemoveFromParentSchema);
      const parentData = gameModel.getTypedUnsafe(parent, ShareOnRemoveFromParentSchema);
      parentData.entities.push(entity);
    }
  }

  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, ShareOnRemoveFromParentSchema);
    for (let i = 0; i < data.entities.length; i++) {
      const entityId = data.entities[i];
      if (!gameModel.isActive(entityId)) {
        data.entities.splice(i, 1);
        i--;
        continue;
      }
      const onRemoveFromParentMods = gameModel.getComponentIdsByCategory(
        entityId,
        ComponentCategory.ON_REMOVE_FROM_PARENT
      );

      if (onRemoveFromParentMods.length) {
        for (let j = 0; j < onRemoveFromParentMods.length; j++) {
          const mod = gameModel.getComponentUnsafe(entityId, onRemoveFromParentMods[j]);
          if (mod.owner !== undefined) {
            mod.owner = data.owner;
          }
          if (mod.pickedup !== undefined) {
            mod.pickedup = data.pickedup;
          }
          const system = gameModel.getSystem((mod as any).type);
          system.run?.(entityId, gameModel);
        }
      }
    }
  }
}

registerSystem(ShareOnRemoveFromParentSystem);

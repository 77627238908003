import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { PlayerTypeSchema, EnemyTypeSchema } from "yage/components/entity/Types";
import { ComponentCategory } from "yage/constants/enums";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";

@Component("ShareOnDodge")
export class ShareOnDodgeSchema extends Schema {
  @type(["number"])
  @defaultValue([])
  entities: number[];

  @type("number")
  @defaultValue(-1)
  maker: number;

  @type("number")
  @defaultValue(-1)
  dodgeSource: number;
}

class ShareOnDodgeSystem implements System {
  schema = ShareOnDodgeSchema;
  type = "ShareOnDodge";

  category = ComponentCategory.ONDODGE;
  dependencies = ["Child"];

  init(entity: number, gameModel: GameModel) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      gameModel.addIfMissing(parent, ShareOnDodgeSchema);
      const parentData = gameModel.getTypedUnsafe(parent, ShareOnDodgeSchema);
      parentData.entities.push(entity);
    }
  }

  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, ShareOnDodgeSchema);
    for (let i = 0; i < data.entities.length; i++) {
      const entityId = data.entities[i];
      if (!gameModel.isActive(entityId)) {
        data.entities.splice(i, 1);
        i--;
        continue;
      }
      const onDodgeMods = gameModel.getComponentIdsByCategory(entityId, ComponentCategory.ONDODGE);

      if (onDodgeMods.length) {
        for (let j = 0; j < onDodgeMods.length; j++) {
          const mod = gameModel.getComponentUnsafe(entityId, onDodgeMods[j]);
          if (mod.maker !== undefined) {
            mod.maker = data.maker;
          }
          if (mod.dodgeSource !== undefined) {
            mod.dodgeSource = data.dodgeSource;
          }
          const system = gameModel.getSystem((mod as any).type);
          system.run?.(entityId, gameModel);
        }
      }
    }
  }
}

registerSystem(ShareOnDodgeSystem);

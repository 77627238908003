import { registerSystem } from "yage/components/ComponentRegistry";
import { ComponentCategory } from "yage/components/types";
import { Component, defaultValue, type, Schema } from "yage/decorators/type";
import type { GameModel } from "yage/game/GameModel";
import type { System } from "yage/components/System";
import { ChildSchema } from "yage/schemas/entity/Child";
import { DamageSchema } from "../../schema/damage/Damage";
import { PlayerTypeSchema } from "yage/components/entity/Types";
import { InventorySchema } from "../player/Inventory";
import { ShopSchema } from "../core/Shop";
import { getEntityController } from "../../utils/playerCharacter";

@Component("MaterialOnCrit")
export class MaterialOnCritSchema extends Schema {
  @type("number")
  @defaultValue(0)
  materialChance: number;

  @type([DamageSchema])
  @defaultValue([])
  damages: DamageSchema[];
}

class MaterialOnCritSystem implements System {
  type = "MaterialOnCrit";
  category: ComponentCategory = ComponentCategory.ONHIT;
  schema = MaterialOnCritSchema;

  dependencies = ["Shop", "Child"];

  init(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, MaterialOnCritSchema);
    if (gameModel.hasComponent(entity, ShopSchema) && gameModel.hasComponent(entity, ChildSchema)) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (!gameModel.hasComponent(parent, MaterialOnCritSchema)) {
        gameModel.addComponent(parent, MaterialOnCritSchema);
      }
      const parentData = gameModel.getTypedUnsafe(parent, MaterialOnCritSchema);
      parentData.materialChance += data.materialChance;
    }
  }

  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, MaterialOnCritSchema);

    if (data.damages.length === 0) {
      return;
    }
    if (
      !(data.materialChance > 100 || data.materialChance === 0 || gameModel.rand.number() < data.materialChance / 100)
    ) {
      return;
    }
    if (data.damages.find((damage) => damage.critHit)) {
      const owner = data.damages[0].owner;
      if (gameModel.hasComponent(owner, PlayerTypeSchema)) {
        const inventory = gameModel.getTypedUnsafe(getEntityController(owner, gameModel), InventorySchema);
        if (!inventory.inventory.coins) {
          inventory.inventory.coins = {
            amount: 0,
          };
        }
        inventory.inventory.coins.amount += 1;
      }
    }
  }
}

registerSystem(MaterialOnCritSystem);

import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { ComponentCategory } from "yage/constants/enums";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { StoreItemSchema } from "../pickups/StoreItem";
import { healEntity } from "../core/Health";
import { HpBlockSchema } from "../player/HpBlock";

@Component("ChanceHealOnPickup")
export class ChanceHealOnPickupSchema extends Schema {
  @type("number")
  @defaultValue(0.0)
  chance: number;

  @type(StoreItemSchema)
  pickupData: StoreItemSchema;
}

class ChanceHealOnPickupSystem implements System {
  schema = ChanceHealOnPickupSchema;
  type = "ChanceHealOnPickup";
  category = ComponentCategory.ON_ADD_TO_INVENTORY;

  run(entity: number, gameModel: GameModel) {
    const chanceHealOnPickupData = gameModel.getTypedUnsafe(entity, ChanceHealOnPickupSchema);

    if (
      gameModel.hasComponent(entity, "HpBlock") &&
      !gameModel.getTypedUnsafe(entity, HpBlockSchema).excludedTypes.includes("ChanceHealOnPickup")
    ) {
      return;
    }

    if (chanceHealOnPickupData.pickupData.inventoryType === "coins") {
      if (gameModel.hasComponent(entity, "Health") && gameModel.rand.number() < chanceHealOnPickupData.chance) {
        healEntity(entity, 1, gameModel);
      }
    }
  }
}

registerSystem(ChanceHealOnPickupSystem);

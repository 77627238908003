import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { healEntity } from "../core";
import { HpBlockSchema } from "./HpBlock";

@Component("HpRegen")
export class HpRegenSchema extends Schema {
  @type("number")
  @defaultValue(0)
  regen: number;

  @type("number")
  @defaultValue(0)
  regenAmountScale: number;

  @type("number")
  @defaultValue(0)
  lastRegenTime: number;
}

class HpRegenSystem implements System {
  schema = HpRegenSchema;
  type = "HpRegen";
  depth = DEPTHS.HEALTH - 0.000001;

  dependencies?: string[] | undefined;

  run(entity: number, gameModel: GameModel) {
    const regenData = gameModel.getTypedUnsafe(entity, HpRegenSchema);
    if (regenData.regen <= 0) return;

    if (gameModel.hasComponent(entity, "HpRegenPlus")) {
      return;
    }

    if (
      gameModel.hasComponent(entity, "HpBlock") &&
      !gameModel.getTypedUnsafe(entity, HpBlockSchema).excludedTypes.includes("HpRegen")
    ) {
      return;
    }

    // REPLACE FORMULA
    const HPEveryXSeconds = 5.0 / (1.0 + (regenData.regen - 1) / 2.25);

    if (HPEveryXSeconds > 0 && regenData.lastRegenTime <= gameModel.timeElapsed - HPEveryXSeconds * 1000) {
      if (regenData.lastRegenTime > 0) {
        let amount = 1 * (regenData.regenAmountScale + 1);
        healEntity(entity, amount, gameModel);
      }
      regenData.lastRegenTime = gameModel.timeElapsed;
    }
  }
  cleanup?: ((entity: number, gameModel: GameModel, ejecting: boolean) => void) | undefined;
}

registerSystem(HpRegenSystem);

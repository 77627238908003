import { System } from "yage/components/System";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { ComponentCategory } from "yage/constants/enums";
import { registerSystem } from "yage/components/ComponentRegistry";
import { HealthSchema, healEntity } from "../core";
import { HpBlockSchema } from "../player/HpBlock";

@Component("ChanceHealOnDodge")
export class ChanceHealOnDodgeSchema extends Schema {
  @type("number")
  @defaultValue(0)
  chance: number;

  @type("number")
  @defaultValue(0)
  amount: number;

  @type("number")
  @defaultValue(0)
  dodgeSource: number;
}

class ChanceHealOnDodgeSystem implements System {
  schema = ChanceHealOnDodgeSchema;
  type = "ChanceHealOnDodge";
  category = ComponentCategory.ONDODGE;

  run(entity: number, gameModel: GameModel) {
    if (
      gameModel.hasComponent(entity, "HpBlock") &&
      !gameModel.getTypedUnsafe(entity, HpBlockSchema).excludedTypes.includes("ChanceHealOnDodge")
    ) {
      return;
    }

    const chanceHealOnDodgeData = gameModel.getTypedUnsafe(entity, ChanceHealOnDodgeSchema);
    const dodgeEntity = chanceHealOnDodgeData.dodgeSource;

    if (gameModel.hasComponent(dodgeEntity, "Health") && gameModel.rand.number() < chanceHealOnDodgeData.chance) {
      HealthSchema.id = dodgeEntity;

      let health = chanceHealOnDodgeData.amount;
      if (HealthSchema.maxHealth <= HealthSchema.health + chanceHealOnDodgeData.amount) {
        health = HealthSchema.maxHealth - HealthSchema.health;
      }

      if (health > 0) {
        healEntity(dodgeEntity, 1, gameModel);
      }
    }
  }
}

registerSystem(ChanceHealOnDodgeSystem);

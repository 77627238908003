import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { ComponentCategory } from "yage/constants/enums";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";

enum RechargeState {
  IDLE,
  RECHARGING,
  DELAYED,
}

@Component("Shield")
class ShieldSchema extends Schema {
  @type("number")
  @defaultValue(-1)
  health: number;

  @type("number")
  @defaultValue(-1)
  maxHealth: number;

  @type("boolean")
  @defaultValue(false)
  mitigateAllDamage: boolean;

  @type("boolean")
  @defaultValue(false)
  removeAtZero: boolean;

  @type("boolean")
  rechargeable: boolean;

  @type("number")
  @defaultValue(-1)
  rechargeDelay: number;

  @type("number")
  @defaultValue(0)
  rechargeElapsed: number;

  @type("number")
  @defaultValue(-1)
  rechargeRate: number;

  @type(RechargeState)
  @defaultValue(RechargeState.IDLE)
  rechargeState: RechargeState;

  @type("number")
  @defaultValue(0)
  duration: number;

  @type("number")
  @defaultValue(0)
  durationElapsed: number;

  @type("number")
  damage: number;
}

class ShieldModSystem implements System {
  schema = ShieldSchema;
  type = "Shield";
  category = ComponentCategory.ONDAMAGE;

  run(entity: number, gameModel: GameModel) {
    const shieldData = gameModel.getTypedUnsafe(entity, ShieldSchema);
    if (shieldData.health === 0 || shieldData.damage < 0) {
      return;
    }
    if (shieldData.mitigateAllDamage) {
      shieldData.health -= 1;
      shieldData.damage = 0;
      return;
    } else {
      if (shieldData.damage > shieldData.health) {
        shieldData.damage -= shieldData.health;
        shieldData.health = 0;
        return;
      } else {
        shieldData.health -= shieldData.damage;
        shieldData.damage = 0;
        return;
      }
    }
  }
}

registerSystem(ShieldModSystem);

class ShieldWatchSystem implements System {
  schema = ShieldSchema;
  type = "ShieldWatch";
  depth = DEPTHS.DAMAGE + 10;

  run(entity: number, gameModel: GameModel) {
    const shieldData = gameModel.getTypedUnsafe(entity, ShieldSchema);

    if (shieldData.duration > 0) {
      shieldData.durationElapsed += gameModel.dt<number>(entity);
      if (shieldData.durationElapsed >= shieldData.duration) {
        gameModel.removeComponent(entity, "Shield");
        return;
      }
    }

    if (shieldData.health === 0) {
      if (shieldData.rechargeable) {
        if (shieldData.rechargeState === RechargeState.IDLE) {
          shieldData.rechargeState = RechargeState.DELAYED;
          shieldData.rechargeElapsed = 0;
        } else if (shieldData.rechargeState === RechargeState.DELAYED) {
          shieldData.rechargeElapsed += gameModel.dt<number>(entity);
          if (shieldData.rechargeElapsed >= shieldData.rechargeDelay) {
            shieldData.rechargeState = RechargeState.RECHARGING;
            shieldData.rechargeElapsed = 0;
          }
        } else if (shieldData.rechargeState === RechargeState.RECHARGING) {
          shieldData.rechargeElapsed += gameModel.dt<number>(entity);
          if (shieldData.rechargeElapsed >= shieldData.rechargeRate) {
            shieldData.health += 1;
            if (shieldData.health >= shieldData.maxHealth) {
              shieldData.health = shieldData.maxHealth;
              shieldData.rechargeState = RechargeState.IDLE;
            }
            shieldData.rechargeElapsed = 0;
          }
        }
      }
      if (shieldData.removeAtZero) {
        gameModel.removeComponent(entity, "Shield");
      }
    }
  }
}

registerSystem(ShieldWatchSystem);

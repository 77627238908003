import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { Component, Schema, type } from "yage/decorators/type";
import { EntityFactory } from "yage/entity/EntityFactory";
import { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";

@Component("WeaponTag")
export class WeaponTagSchema extends Schema {
  @type(["string"])
  tags: string[];
}

class WeaponTagSystem implements System {
  schema = WeaponTagSchema;
  type = "WeaponTag";
  dependencies = ["Child"];
  init(entity: number, gameModel: GameModel) {
    const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
    let tags = gameModel.getTyped(entity, WeaponTagSchema)?.tags ?? [];
    for (let i = 0; i < tags.length; i++) {
      EntityFactory.getInstance().generateEntity(gameModel, tags[i], {
        Child: {
          parent: parent,
        },
      });
    }

    gameModel.removeComponent(entity, WeaponTagSchema);
  }
}
registerSystem(WeaponTagSystem);

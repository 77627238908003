import { registerUIComponent, registerSchema } from "yage/components/ComponentRegistry";
import { ComponentCategory } from "yage/components/types";
import { Component, defaultValue, Schema, type } from "yage/decorators/type";
import { TransformSchema } from "yage/schemas/entity/Transform";
import { PlayerInputSchema } from "yage/schemas/core/PlayerInput";
import { ChildSchema } from "yage/schemas/entity/Child";
import { getEntityController, getPlayerCharacter } from "../../utils/playerCharacter";
import { keyPressed } from "yage/utils/keys";
import { HealthSchema } from "../core";
import { GameCoordinator } from "yage/game/GameCoordinator";
import { UIService } from "yage/ui/UIService";
import { Viewport } from "pixi-viewport";
import { GameModel } from "yage/game/GameModel";
import { FlatMapSchema } from "../core/Map";

@Component("TrackCamera")
export class TrackCameraSchema extends Schema {
  @type("number")
  @defaultValue(1)
  zoom: number;

  @type("number")
  width: number;

  @type("number")
  height: number;

  @type("number")
  x: number;

  @type("number")
  y: number;

  @type("number")
  @defaultValue(0.1)
  trackDelay: number;

  @type("Entity")
  @defaultValue(-1)
  target: number;
}

registerSchema(ComponentCategory.CORE, TrackCameraSchema);

const gameCoordinator = GameCoordinator.GetInstance();

registerUIComponent("TrackCamera", (uiService, entity, gameModel, viewport) => {
  if (gameModel.localNetIds.length === 1) {
    singleplayerTrackCamera(entity, gameModel, viewport);
  } else {
    multiplayerTrackCamera(entity, gameModel, viewport);
  }
});

const multiplayerTrackCamera = (entity: number, gameModel: GameModel, viewport: Viewport) => {
  const playerInput = gameModel.getTypedUnsafe(entity, PlayerInputSchema);
  const selfId = playerInput.id;
  if (selfId !== gameModel.localNetIds[0]) {
    return;
  }

  const data = gameModel.getTypedUnsafe(entity, TrackCameraSchema);
  const mapData = gameModel.getTypedUnsafe(gameModel.coreEntity, FlatMapSchema);

  const playerInputs = gameModel.getComponentActives("PlayerInput");

  const localEntities = playerInputs.filter((id) => {
    const playerInput = gameModel.getTypedUnsafe(entity, PlayerInputSchema);
    return gameModel.localNetIds.includes(playerInput.id);
  });

  const playerCharacters = localEntities.map((id) => getPlayerCharacter(id, gameModel)).filter((id) => id !== -1);

  if (playerCharacters.length === 1) {
    const parent = getEntityController(playerCharacters[0], gameModel);
    singleplayerTrackCamera(parent, gameModel, viewport);
    return;
  }

  const positions = playerCharacters.map((id) => {
    const transformSchema = gameModel.getTypedUnsafe(id, TransformSchema);
    return transformSchema.position;
  });

  const averagePosition = {
    x: positions.reduce((acc, pos) => acc + pos.x, 0) / positions.length,
    y: positions.reduce((acc, pos) => acc + pos.y, 0) / positions.length,
  };

  let positionX = averagePosition.x;
  let positionY = averagePosition.y;

  const mapWidth = mapData.width * mapData.scale + 50;
  const mapHeight = mapData.height * mapData.scale + 50;

  const top = -mapHeight / 2;
  const left = -mapWidth / 2;
  const bottom = mapHeight / 2;
  const right = mapWidth / 2;
  viewport.setZoom(gameCoordinator.baseZoom);
  const { width: viewportWidth, height: viewportHeight } = viewport.getVisibleBounds();

  const targetX =
    mapWidth < viewportWidth ? 0 : Math.min(Math.max(positionX, left + viewportWidth / 2), right - viewportWidth / 2);
  const targetY =
    mapHeight < viewportHeight
      ? 0
      : Math.min(Math.max(positionY, top + viewportHeight / 2), bottom - viewportHeight / 2);

  // Get current viewport center
  const currentX = data.x ?? targetX;
  const currentY = data.y ?? targetY;

  // Interpolate current position to target position
  const x = currentX + (targetX - currentX) * data.trackDelay;
  const y = currentY + (targetY - currentY) * data.trackDelay;

  viewport.moveCenter(x, y);

  const maxDistance = Math.max(
    ...positions.map((pos) => {
      const dx = pos.x - averagePosition.x;
      const dy = pos.y - averagePosition.y;
      return Math.sqrt(dx * dx + dy * dy);
    })
  );

  const viewportDiagonal = Math.sqrt(viewportWidth * viewportWidth + viewportHeight * viewportHeight);

  if (maxDistance * 2 > viewportDiagonal / 2) {
    const zoom = viewportDiagonal / (4 * maxDistance);
    viewport.setZoom(zoom * gameCoordinator.baseZoom * data.zoom);
  } else {
    viewport.setZoom(gameCoordinator.baseZoom * data.zoom); // Reset zoom if all players are within view
  }

  // Update camera position
  data.x = x;
  data.y = y;
};

const singleplayerTrackCamera = (entity: number, gameModel: GameModel, viewport: Viewport) => {
  const data = gameModel.getTypedUnsafe(entity, TrackCameraSchema);
  const playerInput = gameModel.getTypedUnsafe(entity, PlayerInputSchema);
  let playerCharacter = data.target === -1 ? getPlayerCharacter(entity, gameModel) : data.target;
  const mapData = gameModel.getTypedUnsafe(gameModel.coreEntity, FlatMapSchema);

  if (playerCharacter === -1 || HealthSchema.store.health[playerCharacter] <= 0) {
    if (keyPressed(["w", "a"], playerInput.keyMap, playerInput.prevKeyMap)) {
      const players = gameModel
        .getComponentActives("PlayerInput")
        .map((id) => getPlayerCharacter(id, gameModel))
        .filter((id) => {
          const health = gameModel.getTypedUnsafe(id, HealthSchema);
          return health.health > 0;
        });

      const targetIndex = players.indexOf(data.target);
      const prevTarget = targetIndex - 1 < 0 ? players.length - 1 : targetIndex - 1;
      data.target = players[prevTarget];
    } else if (keyPressed(["s", "d"], playerInput.keyMap, playerInput.prevKeyMap)) {
      const players = gameModel
        .getComponentActives("PlayerInput")
        .map((id) => getPlayerCharacter(id, gameModel))
        .filter((id) => {
          const health = gameModel.getTypedUnsafe(id, HealthSchema);
          return health.health > 0;
        });

      const targetIndex = players.indexOf(data.target);
      const nextTarget = (targetIndex + 1) % players.length;
      data.target = players[nextTarget];
    }
  }

  // console.log(playerInput.keyMap);
  const selfId = playerInput.id;
  if (selfId === gameModel.localNetIds[0]) {
    let positionX = data.x;
    let positionY = data.y;
    if (playerCharacter !== -1) {
      const transformSchema = gameModel.getTypedUnsafe(playerCharacter, TransformSchema);
      const position = transformSchema.position;
      positionX = position.x;
      positionY = position.y;
    }

    const mapWidth = mapData.width * mapData.scale + 100;
    const mapHeight = mapData.height * mapData.scale + 100;

    const top = -mapHeight / 2;
    const left = -mapWidth / 2;
    const bottom = mapHeight / 2;
    const right = mapWidth / 2;
    const { width: viewportWidth, height: viewportHeight } = viewport.getVisibleBounds();

    const targetX =
      mapWidth < viewportWidth ? 0 : Math.min(Math.max(positionX, left + viewportWidth / 2), right - viewportWidth / 2);
    const targetY =
      mapHeight < viewportHeight
        ? 0
        : Math.min(Math.max(positionY, top + viewportHeight / 2), bottom - viewportHeight / 2);

    // Get current viewport center
    const currentX = data.x ?? targetX;
    const currentY = data.y ?? targetY;

    // Interpolate current position to target position
    const x = currentX + (targetX - currentX) * data.trackDelay;
    const y = currentY + (targetY - currentY) * data.trackDelay;

    viewport.moveCenter(x, y);

    // Update camera position
    data.x = x;
    data.y = y;
    viewport.setZoom(gameCoordinator.baseZoom * data.zoom);
  }
};

import { EntityTypeSchema } from "yage/components/entity/Types";
import { registerSystem } from "yage/components/ComponentRegistry";
import type { ComponentData } from "yage/components/types";
import { ComponentCategory, ComponentDataSchema } from "yage/components/types";
import { DamageDirectionEnum } from "yage/constants/enums";
import { Component, defaultValue, type, Schema } from "yage/decorators/type";
import type { GameModel } from "yage/game/GameModel";
import type { System } from "yage/components/System";
import { EntityFactory } from "yage/entity/EntityFactory";
import { BV2, Vector2dSchema, normalizeVector2d, rotateDegVector2d, subtractVector2d } from "yage/utils/vector";
import { getWeaponAugmentComponents, applyComponentsToProjectile, directionFromTarget } from "../../utils/weapons";
import { EnemyCollidersSchema } from "../enemy/EnemyColliders";
import { OwnerSchema } from "yage/schemas/core/Owner";
import { ChildSchema } from "yage/schemas/entity/Child";
import { LocomotionSchema } from "yage/schemas/entity/Locomotion";
import { TransformSchema } from "yage/schemas/entity/Transform";
import { getNextRandomEnemy } from "../../utils/target";
import DescriptionSchema from "yage/schemas/core/Description";

@Component("SpawnProjectileOnKill")
export class SpawnProjectileOnKillSchema extends Schema {
  @type("number")
  @defaultValue(1)
  spawnChance: number;

  @type("number")
  @defaultValue(1)
  projectileCount: number;

  @type("string")
  @defaultValue("BabyBullet")
  entityDescription: string;

  @type("boolean")
  @defaultValue(false)
  augmentFromParent: boolean;

  @type([ComponentDataSchema])
  @defaultValue([])
  overrideComponents: ComponentData[];

  @type("Entity")
  @defaultValue(-1)
  killedEntity: number;

  @type("Entity")
  @defaultValue(-1)
  killSource: number;
}

class SpawnProjectileOnKillSystem implements System {
  type = "SpawnProjectileOnKill";
  category: ComponentCategory = ComponentCategory.ONKILL;
  schema = SpawnProjectileOnKillSchema;
  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, SpawnProjectileOnKillSchema);
    const killSource = data.killSource;

    if (gameModel.rand.number() > data.spawnChance) {
      return;
    }

    const owner = gameModel.getTyped(killSource, OwnerSchema)?.owner ?? killSource;

    const position = gameModel.getTypedUnsafe(data.killedEntity, TransformSchema).position;
    const colliders = [...gameModel.getTypedUnsafe(owner, EnemyCollidersSchema).colliders];

    const followTarget = getNextRandomEnemy(data.killedEntity, colliders, gameModel);

    const angles = [0];
    for (let i = 1; i < data.projectileCount; i++) {
      angles.push(gameModel.rand.int(0, 359));
    }

    angles.forEach((angle) => {
      const overrides: any = {
        Transform: position,
        EnemyColliders: {
          colliders,
        },
        Owner: {
          owner,
        },
        Child: {
          parent: entity,
        },
        Follow: {
          target: followTarget,
        },
      };
      const projectile = EntityFactory.getInstance().generateEntity(gameModel, data.entityDescription, overrides);

      TransformSchema.id = projectile;
      TransformSchema.position = position;
      const ownerPosition = TransformSchema.position;
      TransformSchema.id = followTarget;
      const colliderPosition = TransformSchema.position;

      const direction = subtractVector2d(colliderPosition, ownerPosition);
      const rotatedDirection = normalizeVector2d(rotateDegVector2d(direction, angle));

      LocomotionSchema.id = projectile;
      LocomotionSchema.directionX = rotatedDirection.x;
      LocomotionSchema.directionY = rotatedDirection.y;
      LocomotionSchema.velocityX = rotatedDirection.x * LocomotionSchema.speed;
      LocomotionSchema.velocityY = rotatedDirection.y * LocomotionSchema.speed;

      const [augmentComponents, auraComponents] = getWeaponAugmentComponents(
        projectile,
        data.augmentFromParent ? entity : owner,
        gameModel
      );

      applyComponentsToProjectile(
        projectile,
        DamageDirectionEnum.PROJECTILE,
        augmentComponents,
        auraComponents,
        gameModel
      );

      if (data.overrideComponents.length > 0) {
        for (let i = 0; i < data.overrideComponents.length; i++) {
          const override = data.overrideComponents[i];
          gameModel.addComponent(projectile, override.type, override.data ?? override);
        }
      }
    });
  }
}

registerSystem(SpawnProjectileOnKillSystem);

import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";
import { ScaleCoinOnDropSchema } from "../ondrop/ScaleCoinOnDrop";
import { EnemyTypeSchema, PlayerTypeSchema } from "yage/components/entity/Types";

@Component("ScaleCoinOnDropEnhancer")
export class ScaleCoinOnDropEnhancerSchema extends Schema {
  @type("number")
  @defaultValue(0.0)
  scaleCoin: number;
}

class ScaleCoinOnDropSystem implements System {
  schema = ScaleCoinOnDropEnhancerSchema;
  type = "ScaleCoinOnDropEnhancer";
  dependencies = ["Child"];
  init(entity: number, gameModel: GameModel) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (!gameModel.hasComponent(parent, "ScaleCoinOnDrop")) {
        gameModel.addComponent(parent, ScaleCoinOnDropSchema);
      }
      gameModel.getTypedUnsafe(parent, ScaleCoinOnDropSchema).scaleCoin += gameModel.getTypedUnsafe(
        entity,
        ScaleCoinOnDropEnhancerSchema
      ).scaleCoin;
    }
  }

  cleanup(entity: number, gameModel: GameModel, ejecting: boolean) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (gameModel.hasComponent(parent, "ScaleCoinOnDrop")) {
        gameModel.getTypedUnsafe(parent, ScaleCoinOnDropSchema).scaleCoin -= gameModel.getTypedUnsafe(
          entity,
          ScaleCoinOnDropEnhancerSchema
        ).scaleCoin;
      }
    }
  }
}
registerSystem(ScaleCoinOnDropSystem);

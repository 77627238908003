import { System } from "yage/components/System";
import { ComponentCategory, DamageTypeEnum } from "yage/constants/enums";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { registerSystem } from "yage/components/ComponentRegistry";
import { EnemyCollidersSchema } from "../enemy/EnemyColliders";
import { takeDamage } from "../damage/Damageable";
import { DamageStatsSchema, createDamageStats } from "../weapons/DamageStats";
import { PlayerTypeSchema, EnemyTypeSchema } from "yage/components/entity/Types";
import { ChildSchema } from "yage/schemas/entity/Child";

@Component("DamageOnKill")
class DamageOnKillSchema extends Schema {
  @type("number")
  @defaultValue(0.25)
  damageChance: number;

  @type("number")
  @defaultValue(0.25)
  chanceDamageScale: number;

  @type("number")
  @defaultValue(1)
  rolls: number;

  @type("Entity")
  @defaultValue(-1)
  owner: number;
}

class DamageOnKillSystem implements System {
  type = "DamageOnKill";
  category = ComponentCategory.ONKILL;
  schema = DamageOnKillSchema;

  dependencies = ["Child"];
  init(entity: number, gameModel: GameModel) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (!gameModel.hasComponent(parent, "DamageOnKill")) {
        gameModel.addComponent(parent, DamageOnKillSchema, {
          ...gameModel.getTypedUnsafe(entity, DamageOnKillSchema),
        });
      } else {
        gameModel.getTypedUnsafe(parent, DamageOnKillSchema).rolls += gameModel.getTypedUnsafe(
          entity,
          DamageOnKillSchema
        ).rolls;
      }
    }
  }

  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, DamageOnKillSchema);
    const enemies = gameModel.getTypedUnsafe(data.owner, EnemyCollidersSchema).colliders;
    const allEnemies = gameModel.getEntities(enemies);
    if (allEnemies.length < 2) {
      return;
    }
    let count = 0;
    let enemy = allEnemies[gameModel.rand.int(0, allEnemies.length - 1)];
    while (count !== allEnemies.length && !gameModel.isActive(enemy)) {
      enemy = allEnemies[gameModel.rand.int(0, allEnemies.length - 1)];
      count++;
    }
    if (count === allEnemies.length || !gameModel.isActive(enemy)) {
      return;
    }
    for (let i = 0; i < data.rolls; ++i) {
      if (gameModel.rand.number() < data.damageChance) {
        const damageStats = gameModel.getTypedUnsafe(data.owner, DamageStatsSchema);
        const chance = damageStats.chance;

        const damage = chance * data.chanceDamageScale;
        const randDamageStats = createDamageStats();
        randDamageStats.minDamage = damage;
        randDamageStats.maxDamage = damage;
        takeDamage(enemy, gameModel, [
          {
            damageStats: randDamageStats,
            source: data.owner,
            owner: data.owner,
            frame: gameModel.timeElapsed,
            damageType: DamageTypeEnum.NORMAL,
          },
        ]);
      }
    }
  }
}

registerSystem(DamageOnKillSystem);

import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { DamageStatsSchema, invalidateDamageStats } from "../weapons/DamageStats";
import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";
import { DamageMitigationSchema } from "../damagemods/DamageMitigation";
import { HealthSchema, getMaxHealth } from "../core";
import { InvalidateDamageStatsOnMaxHealthChangeSchema } from "../damagemods/PercentMaxHealthDamage";

@Component("ScaleInitialStatByStat")
export class ScaleInitialStatByStatSchema extends Schema {
  @type("number")
  @defaultValue(1)
  scale: number;

  @type("string")
  sourceStat: string;

  @type("string")
  sourceComponentType: string;

  @type("string")
  scalingStat: string;

  @type("string")
  scalingComponentType: string;
}

class ScaleInitialStatByStatSystem implements System {
  schema = ScaleInitialStatByStatSchema;
  type = "ScaleInitialStatByStat";
  dependencies = ["Child", "DamageMitigationEnhancer"];
  init(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, ScaleInitialStatByStatSchema);
    const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
    if (
      !gameModel.hasComponent(parent, data.scalingComponentType) ||
      !gameModel.hasComponent(parent, data.sourceComponentType)
    ) {
      return;
    }

    // @ts-ignore
    const statValue = gameModel.getComponentUnsafe(parent, data.sourceComponentType)[data.sourceStat] as number;

    if (data.scalingStat === "maxHealth") {
      HealthSchema.id = parent;

      HealthSchema.maxHealth = HealthSchema.maxHealth + statValue * data.scale;
      const maxHealth = getMaxHealth(parent, gameModel);

      if (HealthSchema.health > maxHealth) {
        HealthSchema.health = maxHealth;
      } else if (statValue * data.scale > 0) {
        HealthSchema.health = HealthSchema.health + statValue * data.scale;
      }
    } else {
      const stats =
        data.scalingComponentType === "DamageStats"
          ? gameModel.getTypedUnsafe(parent, DamageStatsSchema).initialDamageStats
          : gameModel.getComponentUnsafe(parent, data.scalingComponentType);

      if (data.scalingStat.startsWith("min")) {
        const maxStat = "max" + data.scalingStat.substring(3);
        // @ts-ignore
        stats[maxStat] = stats[maxStat] + statValue * data.scale;
      }
      // @ts-ignore
      stats[data.scalingStat] = stats[data.scalingStat] + statValue * data.scale;
    }
    invalidateDamageStats(parent, gameModel);
  }
}

registerSystem(ScaleInitialStatByStatSystem);

import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { ComponentCategory, DamageTypeEnum } from "yage/constants/enums";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { StoreItemSchema } from "../pickups/StoreItem";
import { InventorySchema } from "../player/Inventory";
import { getEntityController } from "../../utils/playerCharacter";

@Component("MaterialsOnCratePickup")
export class MaterialsOnCratePickupSchema extends Schema {
  @type("number")
  @defaultValue(1)
  chance: number;

  @type("number")
  @defaultValue(0)
  materials: number;

  @type(StoreItemSchema)
  pickupData: StoreItemSchema;
}

class MaterialsOnCratePickupSystem implements System {
  schema = MaterialsOnCratePickupSchema;
  type = "MaterialsOnCratePickup";
  category = ComponentCategory.ON_ADD_TO_INVENTORY;

  run(entity: number, gameModel: GameModel) {
    const materialsOnCratePickup = gameModel.getTypedUnsafe(entity, MaterialsOnCratePickupSchema);
    if (materialsOnCratePickup.pickupData.inventoryType === "crates") {
      if (gameModel.rand.number() < materialsOnCratePickup.chance) {
        const inventory = gameModel.getTypedUnsafe(getEntityController(entity, gameModel), InventorySchema).inventory;
        if (inventory) {
          inventory.coins.amount += materialsOnCratePickup.materials;
        }
      }
    }
  }
}

registerSystem(MaterialsOnCratePickupSystem);

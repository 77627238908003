import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import type { System } from "yage/components/System";
import type { ComponentDataSchema } from "yage/components/types";
import { ComponentCategory } from "yage/components/types";
import { Component, defaultValue, Schema, type } from "yage/decorators/type";
import type { GameModel } from "yage/game/GameModel";
import type { ItemDropperSchema } from "./ItemDropper";
import { checkTrigger } from "./ItemDropper";

@Component("MultiItemDropper")
export class MultiItemDropperSchema extends Schema {
  @type(["object"])
  @defaultValue([])
  items: (ComponentDataSchema | ItemDropperSchema)[];
}

class MultiItemDropperSystem implements System {
  type = "MultiItemDropper";
  category: ComponentCategory = ComponentCategory.BEHAVIOR;
  schema = MultiItemDropperSchema;
  depth = DEPTHS.PREDRAW - 0.000001;

  init(entity: number, gameModel: GameModel) {
    const multiData = gameModel.getTypedUnsafe(entity, MultiItemDropperSchema);
    const ItemDropperSchema = gameModel.getComponentSchema("ItemDropper");
    for (let i = 0; i < multiData.items.length; ++i) {
      multiData.items[i] = gameModel.validateComponent(
        entity,
        ItemDropperSchema,
        multiData.items[i] as any
      ) as ItemDropperSchema;
    }
  }

  run(entity: number, gameModel: GameModel) {
    const multiData = gameModel.getTypedUnsafe(entity, MultiItemDropperSchema);
    if (multiData.items.length > 0) {
      let groups: {
        [key: number]: ItemDropperSchema[];
      } = {};
      for (let i = 0; i < multiData.items.length; ++i) {
        const data = multiData.items[i] as ItemDropperSchema;
        if (!groups[data.groupId]) {
          groups[data.groupId] = [];
        }
        groups[data.groupId].push(data);
      }

      for (let key in groups) {
        const group = groups[key];
        let triggered = false;
        for (let i = 0; i < group.length; ++i) {
          if (group[i].triggered) {
            triggered = true;
            break;
          }
        }
        if (key === "0" || !triggered) {
          for (let i = 0; i < group.length; ++i) {
            checkTrigger(entity, group[i], gameModel);
            if (key !== "0" && group[i].triggered) {
              triggered = true;
              break;
            }
          }
        }
      }

      // for (let i = 0; i < multiData.items.length; ++i) {
      //   const data = multiData.items[i] as ItemDropperSchema;
      //   if (data.triggered) {
      //     continue;
      //   }
      //   checkTrigger(entity, data, gameModel);
      // }
    }
  }
}

registerSystem(MultiItemDropperSystem);

import { GameModel } from "yage/game/GameModel";
import { PlayerInputSchema } from "yage/schemas/core/PlayerInput";
import { ChildSchema } from "yage/schemas/entity/Child";

export const localIndex = (entity: number, gameModel: GameModel): number => {
  if (!gameModel.hasComponent(entity, PlayerInputSchema)) {
    if (gameModel.hasComponent(entity, ChildSchema)) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      return localIndex(parent, gameModel);
    }
    return -1;
  }
  const selfId = gameModel.getTypedUnsafe(entity, PlayerInputSchema).id;
  return gameModel.localNetIds.indexOf(selfId);
};

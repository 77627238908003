import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import { ComponentCategory } from "yage/components/types";
import { Component } from "yage/decorators/type";
import type { GameModel } from "yage/game/GameModel";
import { HealthSchema } from "../core/Health";
import { BaseTriggerSchema, BaseTriggerSystem } from "./BaseTrigger";

@Component("DeathTrigger")
export class DeathTriggerSchema extends BaseTriggerSchema {}

export class DeathTriggerSystem extends BaseTriggerSystem {
  type = "DeathTrigger";
  category: ComponentCategory = ComponentCategory.TRIGGER;
  schema = DeathTriggerSchema;
  depth = DEPTHS.DAMAGE + 10;

  dependencies = ["Transform"];

  shouldTrigger(entity: number, gameModel: GameModel): false | number[] {
    if (!super.shouldTrigger(entity, gameModel)) {
      return false;
    }

    const health = gameModel.getTypedUnsafe(entity, HealthSchema);

    if (health.health > 0) {
      return false;
    }
    return gameModel.players;
  }
}

registerSystem(DeathTriggerSystem);

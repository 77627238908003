import { System } from "yage/components/System";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { ItemDropperSchema } from "../pickups/ItemDropper";
import { GameModel } from "yage/game/GameModel";
import { registerSystem } from "yage/components/ComponentRegistry";
import { ComponentCategory } from "yage/constants/enums";

@Component("ScaleCoinOnDrop")
export class ScaleCoinOnDropSchema extends Schema {
  @type("number")
  @defaultValue(1)
  scaleCoin: number;

  @type(ItemDropperSchema)
  itemDropper: ItemDropperSchema;
}

class ScaleCoinOnDropSystem implements System {
  type = "ScaleCoinOnDrop";
  schema = ScaleCoinOnDropSchema;

  category = ComponentCategory.ONITEMDROP_MOD;

  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, ScaleCoinOnDropSchema);
    if (data.itemDropper.entityDescription === "Exp") {
      data.itemDropper.copies *= data.scaleCoin;
      if (Math.floor(data.itemDropper.copies) !== data.itemDropper.copies) {
        const remainder = data.itemDropper.copies - Math.floor(data.itemDropper.copies);
        data.itemDropper.copies = Math.floor(data.itemDropper.copies) + (gameModel.rand.number() < remainder ? 1 : 0);
      }
    }
  }
}

registerSystem(ScaleCoinOnDropSystem);

import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { Component, Schema, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { LocomotionSchema } from "yage/schemas/entity/Locomotion";
import { SpriteSchema } from "yage/schemas/render/Sprite";
import { lengthVector2d } from "yage/utils/vector";

@Component("VelocityRotate")
class VelocityRotateSchema extends Schema {
  @type("number")
  lastRotation: number;
}

export class VelocityRotateSystem implements System {
  schema = VelocityRotateSchema;
  type: string = "VelocityRotate";
  depth = DEPTHS.PREDRAW;
  dependencies = ["Sprite", "Locomotion"];

  run(entity: number, gameModel: GameModel) {
    const sprite = gameModel.getTypedUnsafe(entity, SpriteSchema);
    const velocityRotate = gameModel.getTypedUnsafe(entity, VelocityRotateSchema);

    LocomotionSchema.id = entity;

    const rotationDirection = Math.sign(LocomotionSchema.velocityX);
    const rotationSpeed = lengthVector2d({
      x: LocomotionSchema.velocityX,
      y: LocomotionSchema.velocityY,
    });

    velocityRotate.lastRotation = sprite.rotation;
    sprite.rotation += rotationDirection * rotationSpeed;
  }
  cleanup(entity: number, gameModel: GameModel, ejecting: boolean) {
    if (gameModel.hasComponent(entity, "Sprite")) {
      const sprite = gameModel.getTypedUnsafe(entity, SpriteSchema);
      sprite.rotation = 0;
    }
  }
}

registerSystem(VelocityRotateSystem);

import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { Component, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";
import { DamageMitigationSchema } from "../damagemods/DamageMitigation";
import { EnemyTypeSchema, PlayerTypeSchema } from "yage/components/entity/Types";
import { InvalidateDamageStatsOnDefenseChangeSchema } from "../damagemods/PercentDefenseDamage";
import { DamageStatsSchema, invalidateDamageStats } from "../weapons/DamageStats";
import { DamageMitigationScalerSchema } from "../scalers/DamageMitigationScaler";

@Component("DamageMitigationEnhancer")
export class DamageMitigationEnhancerSchema extends DamageMitigationSchema {
  @type("number")
  @defaultValue(0)
  declare maxDodgeChance: number;
}

class DamageMitigationEnhancerSystem implements System {
  schema = DamageMitigationEnhancerSchema;
  type = "DamageMitigationEnhancer";
  dependencies = ["Child", "DamageMitigationScaler"];
  init(entity: number, gameModel: GameModel) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      const stats = gameModel.getTypedUnsafe(parent, DamageMitigationSchema);
      const enhancer = gameModel.getTypedUnsafe(entity, DamageMitigationEnhancerSchema);

      let defenseScaler = 1;
      let dodgeChanceScaler = 1;
      if (gameModel.hasComponent(parent, "DamageMitigationScaler")) {
        defenseScaler += gameModel.getTypedUnsafe(parent, DamageMitigationScalerSchema).defenseScale;
        dodgeChanceScaler += gameModel.getTypedUnsafe(parent, DamageMitigationScalerSchema).dodgeChanceScale;
      }

      stats.defense += enhancer.defense * defenseScaler;
      stats.dodgeChance += enhancer.dodgeChance * dodgeChanceScaler;
      if (enhancer.maxDodgeChance) {
        stats.maxDodgeChance = enhancer.maxDodgeChance;
      }

      if (gameModel.hasComponent(parent, InvalidateDamageStatsOnDefenseChangeSchema)) {
        invalidateDamageStats(parent, gameModel);
      }
    }
  }

  cleanup(entity: number, gameModel: GameModel, ejecting: boolean) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (gameModel.isActive(parent)) {
        const stats = gameModel.getTypedUnsafe(parent, DamageMitigationSchema);
        const enhancer = gameModel.getTypedUnsafe(entity, DamageMitigationEnhancerSchema);

        let defenseScaler = 1;
        let dodgeChanceScaler = 1;
        if (gameModel.hasComponent(parent, "DamageMitigationScaler")) {
          defenseScaler += gameModel.getTypedUnsafe(parent, DamageMitigationScalerSchema).defenseScale;
          dodgeChanceScaler += gameModel.getTypedUnsafe(parent, DamageMitigationScalerSchema).dodgeChanceScale;
        }

        stats.defense -= enhancer.defense * defenseScaler;
        stats.dodgeChance -= enhancer.dodgeChance * dodgeChanceScaler;
        stats.maxDodgeChance -= enhancer.maxDodgeChance;
        if (gameModel.hasComponent(parent, InvalidateDamageStatsOnDefenseChangeSchema)) {
          invalidateDamageStats(parent, gameModel);
        }
      }
    }
  }
}
registerSystem(DamageMitigationEnhancerSystem);

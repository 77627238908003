import { Query, IWorld } from "bitecs";
import { cloneDeep } from "lodash";
import { DEPTHS, registerPixiComponent, registerSchema, registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { ComponentCategory, EnemyTypeEnum } from "yage/constants/enums";
import { Bitecs, BitecsSchema, Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { TransformSchema } from "yage/schemas/entity/Transform";
import { KillStatsSchema } from "../player/KillStats";
import { Damage, DamageableSchema } from "../../schema/damage/Damage";
import { Viewport } from "pixi-viewport";
import { PixiDrawSystem } from "yage/components/PixiDrawSystem";
import * as PIXI from "pixi.js";
import { Vector2d } from "yage/utils/vector";
import { getLastDamage } from "../../utils/getLastDamage";
import { EnemyTypeSchema } from "yage/components/entity/Types";
import { ShareOnKillSchema } from "./ShareOnKill";
import { generateShareList } from "yage/utils/generateShareList";
import { HealthCapSchema } from "./HealthCap";
import { ShareOnDeathSchema } from "./ShareOnDeath";
import { generateHealDamage } from "../../utils/generateSpecialDamage";
import { ShareOnHealSchema } from "./ShareOnHeal";

@Bitecs()
@Component("Health")
export class HealthSchema extends BitecsSchema {
  @type("int32")
  @defaultValue(100)
  _health: number;

  @type("int32")
  @defaultValue(0)
  _maxHealth: number;

  get health() {
    return HealthSchema.health;
  }

  set health(value: number) {
    HealthSchema.health = value;
  }

  get maxHealth() {
    return HealthSchema.maxHealth;
  }

  set maxHealth(value: number) {
    HealthSchema.maxHealth = value;
  }

  static get health() {
    return HealthSchema.store.health[this.id];
  }

  static set health(value: number) {
    HealthSchema.store.health[this.id] = value;
  }

  static get maxHealth() {
    return HealthSchema.store.maxHealth[this.id];
  }

  static set maxHealth(value: number) {
    HealthSchema.store.maxHealth[this.id] = value;
  }
}

class HealthSystem implements System {
  type = "Health";
  category: ComponentCategory = ComponentCategory.TARGET;
  schema = HealthSchema;
  depth = DEPTHS.HEALTH;

  query: Query<IWorld>;

  constructor(gameModel: GameModel) {
    this.query = gameModel.defineQuery([HealthSchema]) as Query<IWorld>;
  }

  init(entity: number, gameModel: GameModel) {
    const health = HealthSchema.store.health[entity];
    const maxHealth = HealthSchema.store.maxHealth[entity];
    if (!maxHealth) {
      HealthSchema.store.maxHealth[entity] = health;
    }
  }

  incrementKill(
    enemyType: EnemyTypeEnum,
    lastDamage: Damage,
    killedEntity: number,
    killPosition: Vector2d,
    entity: number,
    gameModel: GameModel
  ) {
    const shareList = generateShareList(entity, ShareOnKillSchema, ComponentCategory.ONKILL, gameModel);
    if (shareList.length > 0) {
      for (let i = 0; i < shareList.length; i++) {
        const [component, entities] = shareList[i];
        for (let j = 0; j < entities.length; j++) {
          const entityId = entities[j];
          const mod = gameModel.getComponentUnsafe(entityId, component);
          if (mod.owner !== undefined) {
            mod.owner = lastDamage.owner;
          }
          if (mod.killedEntity !== undefined) {
            mod.killedEntity = killedEntity;
          }
          if (mod.killSource !== undefined) {
            mod.killSource = entity;
          }
          const system = gameModel.getSystem((mod as any).type);
          system.run?.(entityId, gameModel);
        }
      }
    }

    const killStats = gameModel.hasComponent(entity, "KillStats")
      ? gameModel.getTypedUnsafe(entity, KillStatsSchema)
      : undefined;
    if (killStats) {
      killStats.kills[enemyType] = (killStats.kills[enemyType] || 0) + 1;

      killStats.killsThisFrame.push({
        description: gameModel.getComponent(killedEntity, "Description")?.description || "",
        type: enemyType,
        position: killPosition,
        owner: lastDamage.owner,
        source: entity,
      });
      killStats.kills[EnemyTypeEnum.ALL] = (killStats.kills[EnemyTypeEnum.ALL] || 0) + 1;
    }
  }

  runAll(gameModel: GameModel) {
    const entities = this.query(gameModel.world);

    for (let i = 0; i < entities.length; i++) {
      const entity = entities[i];
      const health = HealthSchema.store.health[entity];
      const maxHealth = HealthSchema.store.maxHealth[entity];

      if (health <= 0) {
        if (gameModel.hasComponent(entity, "EnemyType")) {
          TransformSchema.id = entity;
          const position = TransformSchema.position;
          const enemyType = gameModel.getTypedUnsafe(entity, EnemyTypeSchema).enemyType;

          const lastDamage = getLastDamage(health, entity, gameModel);
          if (lastDamage?.owner !== undefined) {
            this.incrementKill(enemyType, lastDamage, entity, position, lastDamage.owner, gameModel);
          }
          if (lastDamage?.source) {
            this.incrementKill(enemyType, lastDamage, entity, position, lastDamage.source, gameModel);
          }
        }
        const shareList = generateShareList(entity, ShareOnDeathSchema, ComponentCategory.ONDEATH, gameModel);
        if (shareList.length > 0) {
          for (let i = 0; i < shareList.length; i++) {
            const [component, entities] = shareList[i];
            for (let j = 0; j < entities.length; j++) {
              const entityId = entities[j];
              const mod = gameModel.getComponentUnsafe(entityId, component);
              if (mod.killedEntity !== undefined) {
                mod.killedEntity = entity;
              }

              const system = gameModel.getSystem((mod as any).type);
              system.run?.(entityId, gameModel);
            }
          }
        }
        gameModel.removeEntity(entity);
      }
      if (health > maxHealth) {
        HealthSchema.store.health[entity] = maxHealth;
      }
    }
  }
}

export const getMaxHealth = (entity: number, gameModel: GameModel) => {
  if (!gameModel.hasComponent(entity, "HealthCap")) {
    return HealthSchema.store.maxHealth[entity];
  }
  const healthCap = gameModel.getTypedUnsafe(entity, HealthCapSchema).healthCap;
  return Math.min(healthCap, HealthSchema.store.maxHealth[entity]);
};

export const healEntity = (entity: number, amount: number, gameModel: GameModel) => {
  HealthSchema.id = entity;
  if (HealthSchema.health < getMaxHealth(entity, gameModel)) {
    HealthSchema.health = Math.min(amount + HealthSchema.health, getMaxHealth(entity, gameModel));
    generateHealDamage(amount, entity, gameModel);
    const shareList = generateShareList(entity, ShareOnHealSchema, ComponentCategory.ONHEAL, gameModel);
    if (shareList.length > 0) {
      for (let i = 0; i < shareList.length; i++) {
        const [component, entities] = shareList[i];
        for (let j = 0; j < entities.length; j++) {
          const entityId = entities[j];
          const mod = gameModel.getComponentUnsafe(entityId, component);
          if (mod.owner !== undefined) {
            mod.owner = entity;
          }
          const system = gameModel.getSystem((mod as any).type);
          system.run?.(entityId, gameModel);
        }
      }
    }
  }
};

registerSystem(HealthSystem);

@Component("HealthBar")
class HealthBarSchema extends Schema {
  @type("boolean")
  @defaultValue(true)
  hideOnFull: boolean;
}

registerSchema(HealthBarSchema);

class HealthBarDraw implements PixiDrawSystem {
  ids: Set<number> = new Set();
  entites: {
    [id: number]: {
      container: PIXI.Container;
      bar: PIXI.Graphics;
      damageBar: PIXI.Graphics;
      barBorder: PIXI.Graphics;
    };
  } = {};
  schema = HealthBarSchema;

  init(entity: number, gameModel: GameModel, viewport: Viewport) {
    const container = new PIXI.Container();
    const bar = new PIXI.Graphics();
    const damageBar = new PIXI.Graphics();
    const barBorder = new PIXI.Graphics();
    container.addChild(bar);
    container.addChild(damageBar);
    container.addChild(barBorder);
    container.zIndex = 10;

    barBorder.lineStyle(1, 0x000000);
    barBorder.moveTo(-7, 11.5);
    barBorder.lineTo(8, 11.5);
    barBorder.moveTo(-7, 16.5);
    barBorder.lineTo(8, 16.5);
    barBorder.moveTo(-7.5, 12);
    barBorder.lineTo(-7.5, 16);
    barBorder.moveTo(8.5, 12);
    barBorder.lineTo(8.5, 16);

    container.zIndex = Number.MAX_SAFE_INTEGER;
    container.scale.set(4);

    this.entites[entity] = { container, bar, damageBar, barBorder };
    this.ids.add(entity);
    viewport.addChild(container);
  }
  run(entity: number, gameModel: GameModel) {
    if (!this.entites[entity]) {
      return;
    }
    const { container, bar, damageBar } = this.entites[entity];
    TransformSchema.id = entity;
    const pos = TransformSchema.position;

    const health = gameModel.getTypedUnsafe(entity, HealthSchema);
    const healthBar = gameModel.getTypedUnsafe(entity, HealthBarSchema);
    const maxHealth = getMaxHealth(entity, gameModel);

    if (healthBar.hideOnFull && health.health === maxHealth) {
      container.visible = false;
      return;
    }
    container.visible = true;

    const damageable = gameModel.getTypedUnsafe(entity, DamageableSchema);
    const damages = ((damageable.damages as Damage[]) || [])
      .filter((d) => d.damage > 0 && d.frame > gameModel.timeElapsed - damageable.invulnerabilityMs)
      .reduce((a, b) => a + b.damage, 0);

    container.position.set(pos.x, pos.y + 30);
    bar.clear();
    bar.beginFill(0x880808);
    const barLength = Math.max(Math.min(Math.floor(Math.max(health.health / health.maxHealth, 0.01) * 15), 15), 1);
    bar.drawRect(-7, 12, barLength, 5);
    bar.endFill();
    damageBar.clear();
    if (damages && health.health + damages < health.maxHealth) {
      damageBar.beginFill(0xffff00);
      damageBar.drawRect(
        -7 + barLength,
        12,
        Math.max(Math.min(Math.floor(Math.max(damages / health.maxHealth, 0.01) * 15), 15), 1),
        4
      );
    }
  }
  cleanup(entity: number, gameModel: GameModel, viewport: Viewport) {
    if (!this.entites[entity]) return;
    const { container, bar, damageBar, barBorder } = this.entites[entity];
    bar.clear();
    damageBar.clear();
    barBorder.clear();
    viewport.removeChild(container);
    container.destroy();
    bar.destroy();
    damageBar.destroy();
    barBorder.destroy();
    delete this.entites[entity];
    this.ids.delete(entity);
  }
}

registerPixiComponent("HealthBar", HealthBarDraw);

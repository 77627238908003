import { EntityPlacerSchema } from "../ally/EntityPlacer";

import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { Component, Schema } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";
import { EnemyTypeSchema, PlayerTypeSchema } from "yage/components/entity/Types";

@Component("EntityPlacerEnhancer")
class EntityPlacerEnhancerSchema extends EntityPlacerSchema {}

class EntityPlacerSystem implements System {
  schema = EntityPlacerEnhancerSchema;
  type = "EntityPlacerEnhancer";
  dependencies = ["Child"];
  init(entity: number, gameModel: GameModel) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (!gameModel.hasComponent(parent, "EntityPlacer")) {
        gameModel.addComponent(parent, EntityPlacerSchema);
      }
      const data = gameModel.getTypedUnsafe(entity, EntityPlacerEnhancerSchema);
      const dropper = gameModel.getTypedUnsafe(parent, EntityPlacerSchema);
      for (let i = 0; i < data.entityDescriptions.length; i++) {
        dropper.entityDescriptions.push(data.entityDescriptions[i]);
      }
    }
  }

  cleanup(entity: number, gameModel: GameModel, ejecting: boolean) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (gameModel.hasComponent(parent, "EntityPlacer")) {
        const data = gameModel.getTypedUnsafe(entity, EntityPlacerEnhancerSchema);
        const dropper = gameModel.getTypedUnsafe(parent, EntityPlacerSchema);
        for (let i = 0; i < data.entityDescriptions.length; i++) {
          const index = dropper.entityDescriptions.indexOf(data.entityDescriptions[i]);
          if (index !== -1) {
            dropper.entityDescriptions.splice(dropper.entityDescriptions.indexOf(data.entityDescriptions[i]), 1);
          }
        }
      }
    }
  }
}
registerSystem(EntityPlacerSystem);

import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import type { ComponentData } from "yage/components/types";
import { ComponentCategory, ComponentDataSchema } from "yage/components/types";
import { DamageDirectionEnum } from "yage/constants/enums";
import { Component, defaultValue, type, Schema } from "yage/decorators/type";
import type { GameModel } from "yage/game/GameModel";
import type { System } from "yage/components/System";
import { EntityFactory } from "yage/entity/EntityFactory";
import { BV2 } from "yage/utils/vector";
import { getWeaponAugmentComponents, applyComponentsToProjectile } from "../../utils/weapons";
import { HealthSchema } from "../core/Health";
import { EnemyCollidersSchema } from "../enemy/EnemyColliders";
import { OwnerSchema } from "yage/schemas/core/Owner";
import { ChildSchema } from "yage/schemas/entity/Child";
import { LocomotionSchema } from "yage/schemas/entity/Locomotion";
import { TransformSchema } from "yage/schemas/entity/Transform";
import { DamageStatsSchema } from "../../schema/damage/Damage";
import { AuraTypeSchema, EntityTypeSchema } from "yage/components/entity/Types";

@Component("ExplodeOnDeath")
export class ExplodeOnDeathSchema extends Schema {
  @type("number")
  @defaultValue(1)
  projectileCount: number;

  @type("number")
  @defaultValue(5)
  maxExplosionTime: number;

  @type("string")
  @defaultValue("Blast")
  entityDescription: string;

  @type("number")
  @defaultValue(35)
  damage: number;

  @type(["number"])
  @defaultValue([])
  colliders: number[];

  @type("Entity")
  owner: number;

  @type(DamageStatsSchema)
  damageStats: DamageStatsSchema;

  @type([ComponentDataSchema])
  @defaultValue([])
  overrideComponents: ComponentData[];
}

class ExplodeOnDeathSystem implements System {
  type = "ExplodeOnDeath";
  category: ComponentCategory = ComponentCategory.AURA;
  schema = ExplodeOnDeathSchema;
  depth = DEPTHS.DAMAGE + 100;
  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, ExplodeOnDeathSchema);

    const owner = data.owner ?? gameModel.getTypedUnsafe(entity, OwnerSchema).owner;
    const parent = gameModel.getTyped(entity, ChildSchema)?.parent ?? entity;

    const healthData = gameModel.getTypedUnsafe(parent, HealthSchema);

    if (!gameModel.hasComponent(parent, "Health") || healthData.health > 0) {
      return;
    }

    const angles = [];
    for (let i = 0; i < data.projectileCount; i++) {
      angles.push((360 / data.projectileCount) * i);
    }
    TransformSchema.id = parent;
    const position = TransformSchema.position;
    const colliders = [...gameModel.getTypedUnsafe(owner, EnemyCollidersSchema).colliders];

    const spawnTime = gameModel.getTypedUnsafe(entity, AuraTypeSchema).spawnTime;
    const percentRadius = Math.max(
      0.4,
      Math.min(gameModel.timeElapsed - spawnTime, data.maxExplosionTime) / data.maxExplosionTime
    );

    angles.forEach((angle) => {
      const overrides: any = {
        Transform: position,
        EnemyColliders: {
          colliders,
        },
        Owner: {
          owner,
        },
        Radius: (component: { radius: number }) => {
          component.radius *= percentRadius;
          return component;
        },
        Sprite: (component: { scale: number }) => {
          component.scale *= percentRadius;
          return component;
        },
      };
      if (data.damageStats) {
        overrides.DamageStats = data.damageStats;
      }
      const projectile = EntityFactory.getInstance().generateEntity(gameModel, data.entityDescription, overrides);

      TransformSchema.id = projectile;
      TransformSchema.position = position;

      LocomotionSchema.id = projectile;
      const rotatedVelocity = BV2.rotateDegVector2d(0, LocomotionSchema.speed, angle);
      LocomotionSchema.velocityX = rotatedVelocity[0];
      LocomotionSchema.velocityY = rotatedVelocity[1];

      const [augmentComponents, auraComponents] = getWeaponAugmentComponents(projectile, owner, gameModel);
      // damageStats.minDamage += data.damage;
      applyComponentsToProjectile(
        projectile,
        DamageDirectionEnum.PROJECTILE,
        augmentComponents,
        auraComponents,
        gameModel
      );

      if (data.overrideComponents.length > 0) {
        for (let i = 0; i < data.overrideComponents.length; i++) {
          const override = data.overrideComponents[i];
          gameModel.addComponent(projectile, override.type, override.data ?? override);
        }
      }
    });
  }
}

registerSystem(ExplodeOnDeathSystem);

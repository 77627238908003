import { RigidBoxSystem } from "yage/components/physics/RigidBox";
import { RigidCircleSystem } from "yage/components/physics/RigidCircle";
import { EntityType, DamageDirectionEnum, ComponentCategory } from "yage/constants/enums";
import { Component, Schema, required, type } from "yage/decorators/type";
import { EntityFactory } from "yage/entity/EntityFactory";
import { GameModel } from "yage/game/GameModel";
import { LocomotionSchema } from "yage/schemas/entity/Locomotion";
import { RigidBoxSchema } from "yage/schemas/physics/RigidBox";
import { RigidCircleSchema } from "yage/schemas/physics/RigidCircle";
import { Vector2dSchema, Vector2d, BV2 } from "yage/utils/vector";
import { directionFromTarget, getWeaponAugmentComponents, applyComponentsToProjectile } from "../../utils/weapons";
import { System } from "yage/components/System";
import { OwnerSchema } from "yage/schemas/core/Owner";
import { TransformSchema } from "yage/schemas/entity/Transform";
import { EnemyCollidersSchema } from "../enemy/EnemyColliders";
import { registerSystem } from "yage/components/ComponentRegistry";

@Component("SpawnProjectileOnTrigger")
class SpawnProjectileOnTriggerSchema extends Schema {
  @type("string")
  @required()
  projectileDescription: string;
}

class SpawnProjectileOnTriggerSystem implements System {
  schema = SpawnProjectileOnTriggerSchema;
  type = "SpawnProjectileOnTrigger";
  category = ComponentCategory.TRIGGER;
  run(entity: number, gameModel: GameModel) {
    const spawnData = gameModel.getTypedUnsafe(entity, SpawnProjectileOnTriggerSchema);
    const enemies = gameModel.getTypedUnsafe(entity, EnemyCollidersSchema).colliders;
    TransformSchema.id = entity;
    this.generateProjectile(
      entity,
      gameModel.getTypedUnsafe(entity, OwnerSchema).owner!,

      spawnData,
      enemies,
      { x: 0, y: 1 },
      TransformSchema.position,
      gameModel
    );
  }

  generateProjectile(
    entity: number,
    owner: number,
    data: { projectileDescription: string },
    enemyColliders: EntityType[],
    direction: Vector2dSchema,
    position: Vector2d,
    gameModel: GameModel
  ): number {
    const baseDirection = directionFromTarget(entity, gameModel, direction);
    const projectile = EntityFactory.getInstance().generateEntity(gameModel, data.projectileDescription, {
      Transform: {
        ...position,
      },
      EnemyColliders: {
        colliders: enemyColliders,
      },
      Owner: {
        owner,
      },
      Child: {
        parent: entity,
      },
    });
    LocomotionSchema.id = projectile;

    const velocity = BV2.scaleVector2d(baseDirection[0], baseDirection[1], LocomotionSchema.speed);
    LocomotionSchema.velocityX = velocity[0];
    LocomotionSchema.velocityY = velocity[1];
    LocomotionSchema.directionX = baseDirection[0];
    LocomotionSchema.directionY = baseDirection[1];

    if (gameModel.hasComponent(entity, RigidBoxSchema)) {
      gameModel.getSystem(RigidBoxSystem).init(entity, gameModel);
    } else if (gameModel.hasComponent(entity, RigidCircleSchema)) {
      gameModel.getSystem(RigidCircleSystem).init(entity, gameModel);
    }

    const [augmentComponents, auraComponents] = getWeaponAugmentComponents(entity, owner, gameModel);

    applyComponentsToProjectile(projectile, DamageDirectionEnum.OWNER, augmentComponents, auraComponents, gameModel);

    return projectile;
  }
}

registerSystem(SpawnProjectileOnTriggerSystem);

import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import type { System } from "yage/components/System";
import { ComponentCategory } from "yage/components/types";
import { Component, defaultValue, Schema, type } from "yage/decorators/type";
import type { GameModel } from "yage/game/GameModel";
import { LocomotionSchema } from "yage/schemas/entity/Locomotion";
import { SpineSchema } from "yage/schemas/render/Spine";

const HITLENMS = 500;

@Component("ActiveSpine")
class ActiveSpineSchema extends Schema {
  @type("number")
  @defaultValue(1)
  runningSpeed: number;

  @type("number")
  @defaultValue(1)
  idleSpeed: number;
}

class ActiveSpineSystem implements System {
  type = "ActiveSpine";
  category: ComponentCategory = ComponentCategory.RENDERING;
  schema = ActiveSpineSchema;
  depth = DEPTHS.PREDRAW + 1;

  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, ActiveSpineSchema);
    const spriteData = gameModel.getTypedUnsafe(entity, SpineSchema);
    LocomotionSchema.id = entity;

    let nextAnimation = "idle";

    if (LocomotionSchema.velocityX || LocomotionSchema.velocityY) {
      nextAnimation = "running";
    }

    if (nextAnimation !== spriteData.currentAnimation) {
      spriteData.currentAnimation = nextAnimation;
      spriteData.animationSpeed = nextAnimation === "running" ? data.runningSpeed : data.idleSpeed;
    }
  }
}

registerSystem(ActiveSpineSystem);

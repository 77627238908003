import { DEPTHS, registerSystem, registerUIComponent } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { Component, Schema, defaultValue, required, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { Position, Rectangle } from "yage/ui/Rectangle";
import { Text } from "yage/ui/Text";
import { UIElement } from "yage/ui/UIElement";
import { strokeStyle } from "../../utils/ui-styles";

@Component("Countdown")
export class CountdownSchema extends Schema {
  @type("number")
  @required()
  seconds: number;

  @type("number")
  startTime: number;

  @type("boolean")
  @defaultValue(true)
  render: boolean;

  @type("boolean")
  @defaultValue(true)
  stroke: boolean;

  @type("string")
  @defaultValue("top")
  vertical: "top" | "bottom" | "center";

  @type("string")
  @defaultValue("center")
  horizontal: "left" | "right" | "center";

  @type("number")
  @defaultValue(0)
  verticalOffset: number;

  @type("number")
  @defaultValue(0)
  horizontalOffset: number;

  @type("string")
  suffix: string;

  @type("number")
  fontSize: number;

  @type("string")
  color: string;

  @type("boolean")
  paused: boolean;

  @type("number")
  opacity: number;
}

export class CountdownSystem implements System {
  type: string = "Countdown";
  schema: typeof Schema = CountdownSchema;
  depth = DEPTHS.PREDRAW;
  init(entity: number, gameModel: GameModel) {
    const Countdown = gameModel.getTypedUnsafe(entity, CountdownSchema);
    Countdown.startTime = gameModel.timeElapsed;
  }
  run(entity: number, gameModel: GameModel) {
    const Countdown = gameModel.getTypedUnsafe(entity, CountdownSchema);

    if (Countdown.paused) {
      return;
    }

    const timeElapsed = gameModel.timeElapsed - Countdown.startTime;

    if (timeElapsed >= Countdown.seconds * 1000) {
      gameModel.removeComponent(entity, this.type);
    }
  }
}

registerSystem(CountdownSystem);

const ui: { [key: number]: UIElement } = {};

registerUIComponent(
  "Countdown",
  (uiService, entity, gameModel) => {
    const Countdown = gameModel.getTypedUnsafe(entity, CountdownSchema);
    if (!ui[entity]) {
      ui[entity] = new Text(
        new Position(Countdown.horizontal, Countdown.vertical, {
          width: 32,
          height: 32,
          yOffset: Countdown.verticalOffset,
          xOffset: Countdown.horizontalOffset,
        }),
        {
          label: "",
          fontSize: gameModel.getTypedUnsafe(entity, CountdownSchema).fontSize,
          style: {
            color: Countdown.color ?? "white",
            opacity: (Countdown.opacity ?? 1).toString(),
            ...(gameModel.getTypedUnsafe(entity, CountdownSchema).stroke ? strokeStyle(true) : {}),
          },
        }
      );
      uiService.addToUI(ui[entity]);
    }
    const timeElapsed = gameModel.timeElapsed - Countdown.startTime;

    const timeLeft = Math.ceil(Countdown.seconds - timeElapsed / 1000);
    if (timeLeft > 0) {
      ui[entity].config.label = timeLeft + (Countdown.suffix || "");
    }
  },
  {
    cleanup: (uiService, entity, gameModel) => {
      if (ui[entity]) {
        uiService.removeFromUI(ui[entity]);
        delete ui[entity];
      }
    },
  }
);

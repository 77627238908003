import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { Component } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";
import { applyDamageStats, DamageStatsSchema, invalidateDamageStats } from "../weapons/DamageStats";

@Component("DamageStatsScaler")
export class DamageStatsScalerSchema extends DamageStatsSchema {}

class DamageStatsScalerSystem implements System {
  schema = DamageStatsScalerSchema;
  type = "DamageStatsScaler";
  dependencies = ["Child"];
  init(entity: number, gameModel: GameModel) {
    if (gameModel.hasComponent(entity, ChildSchema)) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (!gameModel.hasComponent(parent, "DamageStatsScaler")) {
        gameModel.addComponent(parent, DamageStatsScalerSchema);
      }
      const stats = gameModel.getTypedUnsafe(parent, DamageStatsScalerSchema);
      const enhancer = gameModel.getTypedUnsafe(entity, DamageStatsScalerSchema);
      applyDamageStats(stats, enhancer);
      invalidateDamageStats(parent, gameModel);
    }
  }
}
registerSystem(DamageStatsScalerSystem);

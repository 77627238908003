import type { SceneTimestep } from "yage/game/Scene";
import { Scene } from "yage/game/Scene";
import { EntityFactory } from "yage/entity/EntityFactory";
import type { MouseManager } from "yage/inputs/MouseManager";
import { UIService } from "yage/ui/UIService";
import { Text } from "yage/ui/Text";
import type { UIElement } from "yage/ui/UIElement";
import { Position, Rectangle } from "yage/ui/Rectangle";
import { GameCoordinator } from "yage/game/GameCoordinator";
import AssetLoader from "yage/loader/AssetLoader";
import { SingleplayerConnectionInstance } from "yage/connection/SingleplayerConnectionInstance";
import { ConnectionInstance } from "yage/connection/ConnectionInstance";
import isMobile from "is-mobile";
import { InputEventType, InputManager } from "yage/inputs/InputManager";
import { KeyboardListener } from "yage/inputs/KeyboardListener";
import { GamepadListener, StandardGamepadRegions } from "yage/inputs/GamepadListener";

export class InputSelectScene extends Scene {
  static sceneName = "InputSelect";

  timestep: SceneTimestep = "continuous";
  dt = 4;

  paused = false;
  mouseManager: MouseManager;
  gameCanvasContext: CanvasRenderingContext2D;

  unsubKeyboard: () => void;
  unsubGampad: () => void;

  public initialize = async (args: any[]): Promise<void> => {
    UIService.configureUi(document.getElementById("uicanvas") as HTMLCanvasElement);

    if (isMobile()) {
      this.changeScene("TwintatoLobby", [
        [InputEventType.TOUCH, 0],
        [InputEventType.TOUCH, 1],
      ]);
    } else {
      this.ui.pickInput = new Text(
        new Position("center", "center", {
          xOffset: -100,
        }),
        {
          label: "Player 1 Pick your input",
          fontSize: 32,
          style: {
            textTransform: "uppercase",
          },
        }
      );

      const inputManager = new InputManager();
      const keyboardListener = new KeyboardListener(inputManager);
      keyboardListener.init(["w", "a", "s", "d", "i", "j", "k", "l", "space"]);
      this.unsubKeyboard = () => keyboardListener.destroy();

      const gamepadListener = new GamepadListener(inputManager);
      gamepadListener.init(StandardGamepadRegions);
      this.unsubGampad = () => gamepadListener.destroy();

      let currentPlayerSelectIndex = 0;
      let players: [InputEventType, number][] = [];
      let confirmingSelection = false;
      let selectedType: InputEventType = InputEventType.ANY;

      inputManager.addKeyListener((key, pressed, eventType, index) => {
        for (let i = 0; i < players.length; ++i) {
          if (players[i][0] === eventType && players[i][1] === index) {
            return;
          }
        }

        if (!confirmingSelection || selectedType !== eventType) {
          confirmingSelection = true;
          let label = "";
          if (eventType === InputEventType.KEYBOARD) {
            label = "Keyboard Selected. Press Space to confirm.";
            selectedType = InputEventType.KEYBOARD;
          } else if (eventType === InputEventType.GAMEPAD) {
            label = `Gamepad ${index + 1} Selected. Press A to confirm.`;
            selectedType = InputEventType.GAMEPAD;
          } else if (eventType === InputEventType.TOUCH) {
            label = `Touch Selected. Tap to confirm.`;
            selectedType = InputEventType.TOUCH;
          }
          this.ui.confirmInput = new Text(
            new Position("center", "center", {
              xOffset: -100,
              yOffset: 100,
            }),
            {
              label,
              fontSize: 32,
              style: {
                textTransform: "uppercase",
              },
            }
          );
        } else if (confirmingSelection && pressed && key === "space") {
          players.push([selectedType, index]);
          currentPlayerSelectIndex++;
          confirmingSelection = false;
          if (currentPlayerSelectIndex === 2) {
            this.changeScene("TwintatoLobby", players);
          } else {
            delete this.ui.confirmInput;
            this.ui.pickInput.config.label = `Player ${currentPlayerSelectIndex + 1} Pick your input`;
          }
        }
      });
    }
  };

  run = () => {};
  connection: ConnectionInstance<any>;
  winner: string;

  public destroy() {
    super.destroy();
    this.unsubKeyboard();
    this.unsubGampad();
    console.log("MinMediator: destroy");
  }
}

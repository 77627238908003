import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { Component, defaultValue, Schema, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";
import { HealthSchema } from "../core";
import { LocomotionSchema } from "yage/schemas/entity/Locomotion";
import { EnemyTypeSchema, PlayerTypeSchema } from "yage/components/entity/Types";

@Component("HarvestingEnhancer")
export class HarvestingEnhancerSchema extends Schema {
  @type("number")
  @defaultValue(0)
  harvesting: number;

  @type("number")
  @defaultValue(0)
  harvestingPercent: number;

  @type("number")
  @defaultValue(0)
  harvestingPercentScale: number;
}

class HarvestingEnhancerSystem implements System {
  schema = HarvestingEnhancerSchema;
  type = "HarvestingEnhancer";
  dependencies = ["Child"];
  init(entity: number, gameModel: GameModel) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (!gameModel.hasComponent(parent, "HarvestingEnhancer")) {
        gameModel.addComponent(parent, HarvestingEnhancerSchema, {
          harvesting: 0,
          harvestingPercent: 0,
          harvestingPercentScale: 0,
        });
      }
      gameModel.getTypedUnsafe(parent, HarvestingEnhancerSchema).harvesting += gameModel.getTypedUnsafe(
        entity,
        HarvestingEnhancerSchema
      ).harvesting;
      gameModel.getTypedUnsafe(parent, HarvestingEnhancerSchema).harvestingPercent += gameModel.getTypedUnsafe(
        entity,
        HarvestingEnhancerSchema
      ).harvestingPercent;
      gameModel.getTypedUnsafe(parent, HarvestingEnhancerSchema).harvestingPercentScale += gameModel.getTypedUnsafe(
        entity,
        HarvestingEnhancerSchema
      ).harvestingPercentScale;
    }
  }

  cleanup(entity: number, gameModel: GameModel, ejecting: boolean) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (gameModel.hasComponent(parent, "HarvestingEnhancer")) {
        gameModel.getTypedUnsafe(parent, HarvestingEnhancerSchema).harvesting -= gameModel.getTypedUnsafe(
          entity,
          HarvestingEnhancerSchema
        ).harvesting;
        gameModel.getTypedUnsafe(parent, HarvestingEnhancerSchema).harvestingPercent -= gameModel.getTypedUnsafe(
          entity,
          HarvestingEnhancerSchema
        ).harvestingPercent;
        gameModel.getTypedUnsafe(parent, HarvestingEnhancerSchema).harvestingPercentScale -= gameModel.getTypedUnsafe(
          entity,
          HarvestingEnhancerSchema
        ).harvestingPercentScale;
      }
    }
  }
}
registerSystem(HarvestingEnhancerSystem);

import { System } from "yage/components/System";
import { Component, Schema, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { WeaponContainerSchema } from "./WeaponContainer";
import { ChildSchema } from "yage/schemas/entity/Child";
import { HealthSchema, getMaxHealth } from "../core";
import { DamageStatsSchema, invalidateDamageStats } from "./DamageStats";
import { registerSystem } from "yage/components/ComponentRegistry";

@Component("ScaleStatOnWeaponProperty")
class ScaleStatOnWeaponPropertySchema extends Schema {
  @type("string")
  weaponProperty: "COUNT" | "UNIQUE";

  @type("string")
  scalingStat: string;

  @type("string")
  scalingComponentType: string;

  @type("number")
  scale: number;
}

class ScaleStatOnWeaponPropertySystem implements System {
  schema = ScaleStatOnWeaponPropertySchema;
  type = "ScaleStatOnWeaponProperty";
  dependencies = ["Child"];

  init(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, ScaleStatOnWeaponPropertySchema);
    const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
    if (!gameModel.hasComponent(parent, data.scalingComponentType)) {
      return;
    }
    const weaponContainer = gameModel.getTypedUnsafe(parent, WeaponContainerSchema);

    let statValue = 0;

    if (data.weaponProperty === "COUNT") {
      statValue = weaponContainer.weapons.length;
    } else if (data.weaponProperty === "UNIQUE") {
      statValue = weaponContainer.weapons.length;
      const seen: string[] = [];
      for (const weapon of weaponContainer.weapons) {
        const baseName = weapon.replace(/\d+$/, "");
        if (seen.includes(baseName)) {
          statValue--;
        }
        seen.push(baseName);
      }
    }

    if (data.scalingStat === "maxHealth") {
      HealthSchema.id = parent;

      HealthSchema.maxHealth = HealthSchema.maxHealth + statValue * data.scale;
      const maxHealth = getMaxHealth(parent, gameModel);

      if (HealthSchema.health > maxHealth) {
        HealthSchema.health = maxHealth;
      } else if (statValue * data.scale > 0) {
        HealthSchema.health = HealthSchema.health + statValue * data.scale;
      }
    } else {
      const stats =
        data.scalingComponentType === "DamageStats"
          ? gameModel.getTypedUnsafe(parent, DamageStatsSchema).initialDamageStats
          : gameModel.getComponentUnsafe(parent, data.scalingComponentType);

      if (data.scalingStat.startsWith("min")) {
        const maxStat = "max" + data.scalingStat.substring(3);
        // @ts-ignore
        stats[maxStat] = stats[maxStat] + statValue * data.scale;
      }
      // @ts-ignore
      stats[data.scalingStat] = stats[data.scalingStat] + statValue * data.scale;
    }
    invalidateDamageStats(parent, gameModel);
  }
}

registerSystem(ScaleStatOnWeaponPropertySystem);

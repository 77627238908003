import { WeaponTypeSchema, EntityTypeSchema } from "yage/components/entity/Types";
import { ComponentCategory } from "yage/components/types";
import type { DamageDirectionEnum } from "yage/constants/enums";
import { EntityType, WeaponTypeEnum } from "yage/constants/enums";
import type { GameModel } from "yage/game/GameModel";
import { clone } from "lodash";
import { WeaponContainerSchema } from "../components/weapons/WeaponContainer";
import { createDamageStats, DamageStatsSchema } from "../components/weapons/DamageStats";
import { DamageApplierSchema } from "../components/damage/DamageApplier";
import type { Vector2d } from "yage/utils/vector";
import { lengthVector2d, normalizeVector2d, subtractVector2d } from "yage/utils/vector";
import { AuraApplicatorSystem } from "../components/auras/AuraApplicator";
import { OwnerSchema } from "yage/schemas/core/Owner";
import { ChildSchema } from "yage/schemas/entity/Child";
import { LocomotionSchema } from "yage/schemas/entity/Locomotion";
import { TransformSchema } from "yage/schemas/entity/Transform";
import { TargetingSchema } from "../components/weapons/Targeting";
import { DamageGeneratorSystem } from "../components/damage/DamageGenerator";

const getWeaponAugmentComponentsForEntity = (entity: number, gameModel: GameModel): [any[], any[]] => {
  const augmentComponents = gameModel.getComponentIdsByCategory(entity, ComponentCategory.WEAPON_AUGMENT);
  augmentComponents.push(...(gameModel.getComponentIdsByCategory(entity, ComponentCategory.ONHIT) ?? []));
  augmentComponents.push(...(gameModel.getComponentIdsByCategory(entity, ComponentCategory.ONHIT_MOD) ?? []));
  augmentComponents.push(...(gameModel.getComponentIdsByCategory(entity, ComponentCategory.DAMAGEMOD) ?? []));
  const auraComponents = gameModel.getComponentIdsByCategory(entity, ComponentCategory.AURA);

  const augmentComponentData = [];
  const auraComponentData: any[] = [];

  for (let i = 0; i < augmentComponents.length; i++) {
    const augmentComponent = augmentComponents[i];
    const augmentData = gameModel.getComponent(entity, augmentComponent) as any;
    augmentComponentData.push(augmentData);
  }

  for (let i = 0; i < auraComponents.length; i++) {
    const auraComponent = auraComponents[i];
    const auraData = gameModel.getComponent(entity, auraComponent) as any;
    if (auraData.components) {
      auraData.components.forEach((component: any) => {
        auraComponentData.push({
          type: component.type,
          ...component.data,
        });
      });
    } else {
      auraComponentData.push(auraData);
    }
  }

  return [augmentComponentData, auraComponentData];
};

export const getWeaponAugmentComponents = (entity: number, owner: number, gameModel: GameModel): [any[], any[]] => {
  const [augmentComponents, auraComponentData] = getWeaponAugmentComponentsForEntity(owner, gameModel);

  if (owner != entity) {
    const [entityAugmentComponents, entityAuraComponentData] = getWeaponAugmentComponentsForEntity(entity, gameModel);
    augmentComponents.push(...entityAugmentComponents);
    auraComponentData.push(...entityAuraComponentData);
  }

  return [augmentComponents, auraComponentData];
};

export const applyComponentsToProjectile = (
  projectile: number,
  damageDirection: DamageDirectionEnum,
  augmentComponents: any[],
  auraComponents: any[],
  gameModel: GameModel
) => {
  const owner = gameModel.getTyped(projectile, OwnerSchema)?.owner ?? projectile;

  for (let i = 0; i < augmentComponents.length; i++) {
    // don't like this but needs to be done for now
    let component = augmentComponents[i];
    component = {
      ...clone(component),
    };
    switch (component.type) {
      case "ExplodeOnDeath":
        if (gameModel.hasComponent(projectile, augmentComponents[i].type)) {
          const existingComponent = gameModel.getComponent(projectile, augmentComponents[i].type);
          component = {
            ...existingComponent,
            ...component,
            owner,
          };
        }
        gameModel.addComponent(projectile, augmentComponents[i].type, component);
        break;
      default:
        if (gameModel.hasComponent(projectile, augmentComponents[i].type)) {
          const existingComponent = gameModel.getComponent(projectile, augmentComponents[i].type);
          component = {
            ...existingComponent,
            ...component,
          };
        }
        gameModel.addComponent(projectile, augmentComponents[i].type, component);
        break;
    }
  }

  if (
    gameModel.hasComponent(projectile, "Targeting") &&
    gameModel.getTypedUnsafe(projectile, TargetingSchema).inheritable
  ) {
    const direction = directionFromTarget(projectile, gameModel);
    LocomotionSchema.directionX = direction[0];
    LocomotionSchema.directionY = direction[1];
    const velocityScale = lengthVector2d(LocomotionSchema.velocity);
    LocomotionSchema.velocityX = direction[0] * velocityScale;
    LocomotionSchema.velocityY = direction[1] * velocityScale;
  }
  const baseEntityType = EntityTypeSchema.store.entityType[projectile];

  if (gameModel.hasComponent(projectile, "DamageApplier")) {
    if (!gameModel.hasComponent(projectile, "DamageStats")) {
      gameModel.logEntity(projectile, true);
      throw new Error("Projectile must have DamageStats component");
    }
    gameModel.getTypedUnsafe(projectile, DamageApplierSchema).damageDirection = damageDirection;
  }

  if (baseEntityType === EntityType.ALLY && auraComponents.length) {
    gameModel.addComponent(projectile, "AuraApplicator", {
      auraComponents: auraComponents,
      owner: owner,
    });
    gameModel.getSystem(AuraApplicatorSystem).run?.(projectile, gameModel);
  }
  if (gameModel.hasComponent(projectile, "DamageApplier")) {
    if (baseEntityType === EntityType.PROJECTILE && auraComponents.length) {
      const damageApplier = gameModel.getTypedUnsafe(projectile, DamageApplierSchema);
      damageApplier.onHit = damageApplier.onHit ?? [];
      damageApplier.onHit.push({
        type: "AuraApplicator",
        data: {
          auraComponents: auraComponents,
          owner: owner,
        },
      });
    }
    if (!gameModel.hasComponent(projectile, "DamageGenerator")) {
      if (gameModel.hasComponent(owner, "DamageGenerator")) {
        gameModel.addComponent(projectile, "DamageGenerator", gameModel.getComponentUnsafe(owner, "DamageGenerator"));
      } else {
        gameModel.addComponent(projectile, "DamageGenerator");
      }
    }
    gameModel.getSystem(DamageGeneratorSystem).run!(projectile, gameModel);
  }
};

export const getWeapon = (entity: number, gameModel: GameModel) => {
  let owner = null;
  if (gameModel.hasComponent(entity, "WeaponContainer")) {
    owner = entity;
  } else if (gameModel.hasComponent(entity, "Owner")) {
    owner = gameModel.getTypedUnsafe(entity, OwnerSchema).owner;
  } else if (gameModel.hasComponent(entity, "Child")) {
    owner = gameModel.getTypedUnsafe(entity, ChildSchema).parent;
  }
  if (owner === null) {
    console.error("Owner is null", new Error().stack);
    gameModel.logEntity(entity, true);
    return undefined;
  }

  const weaponContainer = gameModel.getTypedUnsafe(owner, WeaponContainerSchema);

  return weaponContainer.weaponIds?.[0];
};

export const getWeaponType = (entity: number, gameModel: GameModel) => {
  const weapon = getWeapon(entity, gameModel);
  if (weapon !== undefined && gameModel.hasComponent(weapon, "WeaponType")) {
    const weaponType = gameModel.getTypedUnsafe(weapon, WeaponTypeSchema).weaponType;
    return weaponType;
  }

  return WeaponTypeEnum.NONE;
};

export const getDamageStats = (entity: number, gameModel: GameModel, inheritFromWeapon = true) => {
  const weapon = inheritFromWeapon ? getWeapon(entity, gameModel) : 0;
  if (weapon && gameModel.hasComponent(weapon, DamageStatsSchema)) {
    return gameModel.getTypedUnsafe(weapon, DamageStatsSchema);
  }
  if (gameModel.hasComponent(entity, DamageStatsSchema)) {
    return gameModel.getTypedUnsafe(entity, DamageStatsSchema);
  }
  return createDamageStats();
};

export const calculateProjectileCount = (entity: number, gameModel: GameModel): number => {
  const ids = gameModel.getComponentIdsByCategory(entity, ComponentCategory.PROJECTILECOUNTMOD);

  if (ids.length) {
    let projectileCount = 1;
    for (let i = 0; i < ids.length; i++) {
      const component = gameModel.getComponent(entity, ids[0]) as any;
      projectileCount += component.projectiles;
    }

    return Math.max(projectileCount, 0);
  }
  return 1;
};

export const directionFromTarget = (entity: number, gameModel: GameModel, defaultDirection?: Vector2d) => {
  if (gameModel.hasComponent(entity, "Targeting")) {
    const target = gameModel.getTypedUnsafe(entity, TargetingSchema).target;
    if (target > -1) {
      TransformSchema.id = entity;
      const position = TransformSchema.position;
      TransformSchema.id = target;
      const targetPosition = TransformSchema.position;
      const direction = normalizeVector2d(subtractVector2d(targetPosition, position));
      return [direction.x, direction.y];
    }
  }
  if (defaultDirection) {
    return [defaultDirection.x, defaultDirection.y];
  }
  LocomotionSchema.id = entity;
  return [LocomotionSchema.directionX, LocomotionSchema.directionY];
};

export const getSourceWeapon = (entity: number, gameModel: GameModel): number => {
  if (gameModel.hasComponent(entity, "WeaponType")) {
    return entity;
  }
  if (gameModel.hasComponent(entity, ChildSchema)) {
    const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent;
    if (!parent) {
      return gameModel.getTypedUnsafe(entity, OwnerSchema).owner ?? entity;
    }
    return getSourceWeapon(parent, gameModel);
  }
  return entity;
};

import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { PlayerTypeSchema, EnemyTypeSchema } from "yage/components/entity/Types";
import { ComponentCategory } from "yage/constants/enums";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";

@Component("LumberjackMod")
class LumberjackModSchema extends Schema {
  @type("number")
  @defaultValue(0)
  damageScale: number;

  @type("number")
  @defaultValue(0)
  maker: number;

  @type("number")
  @defaultValue(0)
  taker: number;
}

class LumberjackModSystem implements System {
  schema = LumberjackModSchema;
  type = "LumberjackMod";
  category = ComponentCategory.ONDAMAGE;
  dependencies = ["Child"];
  init(entity: number, gameModel: GameModel) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      gameModel.addIfMissing(parent, LumberjackModSchema);
    }
  }

  run(entity: number, gameModel: GameModel) {
    const lumberjackModData = gameModel.getTypedUnsafe(entity, LumberjackModSchema);
    if (
      gameModel.hasComponent(lumberjackModData.maker, LumberjackModSchema) &&
      !gameModel.hasComponent(lumberjackModData.maker, PlayerTypeSchema)
    ) {
      lumberjackModData.damageScale = 1000;
    } else {
      lumberjackModData.damageScale = 1;
    }
  }
}

registerSystem(LumberjackModSystem);

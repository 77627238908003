import { registerSystem } from "yage/components/ComponentRegistry";
import { Component, Schema } from "yage/decorators/type";
import type { GameModel } from "yage/game/GameModel";
import type { System } from "yage/components/System";
import { PlayerTypeSchema, EnemyTypeSchema } from "yage/components/entity/Types";
import { ChildSchema } from "yage/schemas/entity/Child";

@Component("RemoveWeaponsOnEndOfRound")
export class RemoveWeaponsOnEndOfRoundSchema extends Schema {}

class RemoveWeaponsOnEndOfRoundSystem implements System {
  type = "RemoveWeaponsOnEndOfRound";
  schema = RemoveWeaponsOnEndOfRoundSchema;

  dependencies = ["Child"];

  init(entity: number, gameModel: GameModel) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (!gameModel.hasComponent(parent, "RemoveWeaponsOnEndOfRound")) {
        gameModel.addComponent(parent, RemoveWeaponsOnEndOfRoundSchema);
      }
    }
  }

  cleanup(entity: number, gameModel: GameModel, ejecting: boolean) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema) &&
      !ejecting
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (gameModel.hasComponent(parent, "RemoveWeaponsOnEndOfRound")) {
        gameModel.removeComponent(parent, "RemoveWeaponsOnEndOfRound");
      }
    }
  }
}

registerSystem(RemoveWeaponsOnEndOfRoundSystem);

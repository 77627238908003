import { System } from "yage/components/System";
import { ComponentCategory } from "yage/constants/enums";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { EntityFactory } from "yage/entity/EntityFactory";
import { GameModel } from "yage/game/GameModel";
import { InventorySchema } from "../player/Inventory";
import { registerSystem } from "yage/components/ComponentRegistry";
import { getEntityController } from "../../utils/playerCharacter";
import { TextBloopSchema, addBloop } from "../render/TextBloop";
import { TransformSchema } from "yage/schemas/entity/Transform";

@Component("AddItemOnLevelUp")
class AddItemOnLevelUpSchema extends Schema {
  @type("Entity")
  @defaultValue(-1)
  owner: number;

  @type("string")
  @defaultValue("Stat")
  inventoryPrefix: string;

  @type("string")
  entityDescription: string;

  @type(TextBloopSchema)
  bloop: Partial<TextBloopSchema>;

  @type("string")
  @defaultValue("")
  bloopTemplate: string;
}

class AddItemOnLevelUpSystem implements System {
  type = "AddItemOnLevelUp";
  category = ComponentCategory.ONLEVELUP;
  schema = AddItemOnLevelUpSchema;
  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, AddItemOnLevelUpSchema);

    const player = gameModel.getTypedUnsafe(entity, AddItemOnLevelUpSchema).owner!;
    TransformSchema.id = player;
    const startingPosition = TransformSchema.position;

    if (data.bloop) {
      addBloop(
        {
          ...data.bloop,
          startFrame: gameModel.timeElapsed,
          timeout: gameModel.timeElapsed + (data.bloop.timeout || 500),
          position: {
            x: startingPosition.x + gameModel.rand.int(0, 100) - 50,
            y: startingPosition.y + gameModel.rand.int(0, 100) - 50,
          },
          yVelocity: data.bloop.yVelocity || -400,
          xVelocity: data.bloop.xVelocity || gameModel.rand.int(30, 200) * (gameModel.rand.int(0, 1) ? 1 : -1),
          text: data.bloopTemplate.replace("$$VALUE", "1"),
        },
        gameModel
      );
    }

    EntityFactory.getInstance().generateEntity(gameModel, data.entityDescription, {
      Child: {
        parent: player,
        autoAttach: false,
      },
    });
    if (data.inventoryPrefix) {
      const inventory = gameModel.getTypedUnsafe(getEntityController(player, gameModel), InventorySchema);
      if (!inventory.inventory[data.inventoryPrefix + "::" + data.entityDescription]) {
        inventory.inventory[data.inventoryPrefix + "::" + data.entityDescription] = {
          amount: 0,
        };
      }
      inventory.inventory[data.inventoryPrefix + "::" + data.entityDescription].amount += 1;
    }
  }
}

registerSystem(AddItemOnLevelUpSystem);

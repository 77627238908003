import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { System } from "yage/components/System";
import { GameModel } from "yage/game/GameModel";
import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import AssetLoader from "yage/loader/AssetLoader";
import { isPromise } from "yage/utils/isPromise";
import type { IMediaInstance } from "@pixi/sound";
import { SoundType } from "yage/constants/enums";

import { Tween } from "@tweenjs/tween.js";
import { SoundOptions } from "yage/loader/SoundLoader";

@Component("Sounds")
class SoundsSchema extends Schema {
  @type("number")
  @defaultValue(1)
  masterVolume: number;

  @type("number")
  @defaultValue(1)
  musicVolume: number;

  @type("number")
  @defaultValue(1)
  fxVolume: number;

  @type("number")
  @defaultValue(1)
  voiceVolume: number;

  @type("number")
  @defaultValue(1)
  ambientVolume: number;

  @type("number")
  @defaultValue(1)
  uiVolume: number;
}

class SoundsSystem implements System {
  schema = SoundsSchema;
  type = "Sounds";
  depth = DEPTHS.PREDRAW;

  playingAssets: [string, IMediaInstance, Tween<{ volume: 0 }>[]][] = [];

  handleSound(
    instance: IMediaInstance,
    {
      sound,
      filters,
    }: {
      sound: string;
      filters?: any[];
    },
    soundOptions: SoundOptions,
    gameModel: GameModel
  ) {
    let assetControl: [string, IMediaInstance, Tween<any>[]] = [sound, instance, []];
    this.playingAssets.push(assetControl);
    instance.on("end", () => {
      this.playingAssets = this.playingAssets.filter(([_sound, _instance]) => instance !== _instance);
    });

    const fadeOut = filters?.find((filter) => filter.type === "fadeOut");
    const fadeIn = filters?.find((filter) => filter.type === "fadeIn");

    if (fadeIn) {
      const tween = new Tween({ volume: 0 })
        .to({ volume: fadeIn.volume ?? 1 }, fadeIn.duration)
        .onUpdate((object) => {
          instance.volume = object.volume;
        })
        .start();
      assetControl[2].push(tween);
    }
    if (fadeOut) {
      const tween = new Tween({ volume: instance.volume })
        .to({ volume: fadeOut.volume ?? 0 }, fadeOut.duration)
        .onUpdate((object) => {
          instance.volume = object.volume;
        })
        .onComplete(() => {
          instance.stop();
          this.playingAssets = this.playingAssets.filter(([_sound, _instance]) => instance !== _instance);
        })
        .start();
      assetControl[2].push(tween);
    }
  }

  runAll(gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(gameModel.coreEntity, SoundsSchema);
    let sounds = gameModel.soundQueue;
    const dequeues = gameModel.soundDequeue;
    const assetLoader = AssetLoader.getInstance();

    for (let i = 0; i < dequeues.length; i++) {
      const sound = dequeues[i];
      const playing = this.playingAssets.filter(([soundName]) => soundName === sound);
      if (playing.length) {
        for (let j = 0; j < playing.length; j++) {
          playing[j][1].stop();
        }
      }
      this.playingAssets = this.playingAssets.filter(([soundName]) => soundName !== sound);
      sounds = sounds.filter((queuedSound) => queuedSound.sound !== sound);
    }
    gameModel.soundDequeue = [];
    gameModel.soundQueue = [];

    for (let i = 0; i < sounds.length; i++) {
      const sound = sounds[i];
      const [soundAsset, soundOptions] = assetLoader.getSound(sound.sound);

      if (soundAsset) {
        let soundVolume = data.masterVolume;
        switch (sound.type) {
          case SoundType.AMBIENT:
            soundVolume *= data.ambientVolume;
            break;
          case SoundType.FX:
            soundVolume *= data.fxVolume;
            break;
          case SoundType.MUSIC:
            soundVolume *= data.musicVolume;
            break;
          case SoundType.UI:
            soundVolume *= data.uiVolume;
            break;
          case SoundType.VOICE:
            soundVolume *= data.voiceVolume;
            break;
        }
        soundVolume *= soundOptions.baseVolume ?? 1;
        soundVolume *= sound.volume;

        if (soundVolume === 0) {
          continue;
        }
        soundAsset.volume = Math.pow(Math.min(soundVolume, 1), 3);
        const asset = soundAsset.play();

        if (isPromise(asset)) {
          asset.then((instance) => this.handleSound(instance, sound, soundOptions, gameModel));
        } else {
          this.handleSound(asset, sound, soundOptions, gameModel);
        }
      }
    }
    for (let i = 0; i < this.playingAssets.length; i++) {
      const [sound, instance, tweens] = this.playingAssets[i];
      tweens.forEach((tween) => tween.update(gameModel.dt<number>(gameModel.coreEntity)));
    }
  }
  cleanup(entity: number, gameModel: GameModel, ejecting: boolean) {
    for (let i = 0; i < this.playingAssets.length; i++) {
      this.playingAssets[i][1].stop();
    }
    this.playingAssets = [];
  }
}

registerSystem(SoundsSystem);

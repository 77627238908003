import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { Component, defaultValue, Schema, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";
import { EnemyTypeSchema, PlayerTypeSchema } from "yage/components/entity/Types";
import { MaxHealthDamageScaleSchema } from "../damagemods/MaxHealthDamageScale";

@Component("MaxHealthDamageScaleEnhancer")
export class MaxHealthDamageScaleEnhancerSchema extends Schema {
  @type("number")
  @defaultValue(0)
  damageScale: number;

  @type("number")
  @defaultValue(1)
  perHealth: number;
}

class MaxHealthDamageScaleEnhancerSystem implements System {
  schema = MaxHealthDamageScaleEnhancerSchema;
  type = "MaxHealthDamageScaleEnhancer";
  dependencies = ["Child"];
  init(entity: number, gameModel: GameModel) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (!gameModel.hasComponent(parent, "MaxHealthDamageScale")) {
        gameModel.addComponent(parent, MaxHealthDamageScaleSchema);
      }
      gameModel
        .getTypedUnsafe(parent, MaxHealthDamageScaleSchema)
        .instances.push(gameModel.getTypedUnsafe(entity, MaxHealthDamageScaleEnhancerSchema));
    }
  }

  cleanup(entity: number, gameModel: GameModel, ejecting: boolean) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (gameModel.hasComponent(parent, "MaxHealthDamageScale")) {
        gameModel.getTypedUnsafe(parent, MaxHealthDamageScaleSchema).instances = gameModel
          .getTypedUnsafe(parent, MaxHealthDamageScaleSchema)
          .instances.filter(
            (instance: MaxHealthDamageScaleEnhancerSchema) =>
              instance.damageScale !==
                gameModel.getTypedUnsafe(entity, MaxHealthDamageScaleEnhancerSchema).damageScale &&
              instance.perHealth !== gameModel.getTypedUnsafe(entity, MaxHealthDamageScaleEnhancerSchema).perHealth
          );
      }
    }
  }
}
registerSystem(MaxHealthDamageScaleEnhancerSystem);

import { registerSchema } from "yage/components/ComponentRegistry";
import { ComponentCategory } from "yage/constants/enums";
import { Schema, Component, type, defaultValue } from "yage/decorators/type";

@Component("TrackAddStatStat")
export class TrackAddStatStatSchema extends Schema {
  @type("number")
  @defaultValue(0)
  statIncrements: number;
}

registerSchema(ComponentCategory.PERSIST, TrackAddStatStatSchema);

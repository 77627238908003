import { GameCoordinator } from "yage/game/GameCoordinator";
import { ProjectVGameScene } from "./scenes/GameScene";
import { ProjectVLobbyScene } from "./scenes/LobbyScene";
import { ProjectWinScene } from "./scenes/WinScene";
import { ProjectVShopScene } from "./scenes/ShopScene";
import { BruhtatoSplashScene } from "./scenes/SplashScene";
import { TwintatoLobbyScene } from "./scenes/TwintatoLobbyScene";
import { InputSelectScene } from "./scenes/InputSelectScene";

//@ts-ignore
window.useHTML = true;

export const initialize = (gameCoordinator: GameCoordinator) => {
  gameCoordinator.registerScene(ProjectVGameScene);
  gameCoordinator.registerScene(ProjectVLobbyScene);
  gameCoordinator.registerScene(ProjectWinScene);
  gameCoordinator.registerScene(ProjectVShopScene);
  gameCoordinator.registerScene(BruhtatoSplashScene);
  gameCoordinator.registerScene(TwintatoLobbyScene);
  gameCoordinator.registerScene(InputSelectScene);
  gameCoordinator.initialize("BruhtatoSplash");
};

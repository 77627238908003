import TWEEN, { Tween } from "@tweenjs/tween.js";
import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { ComponentCategory } from "yage/constants/enums";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { SpriteSchema } from "yage/schemas/render/Sprite";
import { HealthSchema } from ".";

@Component("GlobalIFrame")
export class GlobalIFrameSchema extends Schema {
  @type("number")
  @defaultValue(750)
  duration: number;

  @type("number")
  @defaultValue(1000)
  cooldownDuration: number;

  @type("number")
  @defaultValue(0)
  lastHitTime: number;

  @type("number")
  @defaultValue(5)
  minimumHealth: number;

  @type("number")
  @defaultValue(1)
  damageScale: number;

  @type("number")
  @defaultValue(0)
  damage: number;
}

@Component("GlobalIFrameDraw")
export class GlobalIFrameDrawSchema extends Schema {}

export class GlobalIframeSystem implements System {
  type = "GlobalIFrame";
  schema = GlobalIFrameSchema;
  category = ComponentCategory.ONDAMAGE;

  init(entity: number, gameModel: any) {
    gameModel.addComponent(entity, GlobalIFrameDrawSchema);
  }

  run(entity: number, gameModel: any) {
    const iframe = gameModel.getComponent(entity, GlobalIFrameSchema);
    if (iframe.damage <= 0) {
      return;
    }
    iframe.damageScale = 1;
    if (iframe.lastHit && gameModel.timeElapsed - iframe.lastHit < iframe.duration) {
      iframe.damageScale = 0;
      return;
    }
    if (iframe.lastHit && gameModel.timeElapsed - iframe.lastHit < iframe.cooldown + iframe.duration) {
      return;
    }
    iframe.lastHit = 0;
    if (HealthSchema.health > iframe.minimumHealth && HealthSchema.health - iframe.damage <= iframe.minimumHealth) {
      const previousDamage = iframe.damage;
      iframe.damage = HealthSchema.health - iframe.minimumHealth;
      iframe.lastHit = gameModel.timeElapsed;
      console.log("dropping damage to", previousDamage, iframe.damage);
    }
  }
}

export class GlobalIframeDrawSystem implements System {
  type = "GlobalIFrameDraw";
  schema = GlobalIFrameDrawSchema;
  depth = DEPTHS.PREDRAW;

  activeTweens: {
    [key: number]: [Tween<{ alpha: number }>, Tween<{ alpha: number }>, number];
  } = {};

  run(entity: number, gameModel: GameModel) {
    const iframe = gameModel.getComponent(entity, GlobalIFrameSchema)!;
    if (iframe.lastHit && gameModel.timeElapsed - iframe.lastHit < iframe.duration) {
      if (!this.activeTweens[entity]) {
        const startTween = new Tween({ alpha: 1 }).to({ alpha: 0.5 }, 100).easing(TWEEN.Easing.Quadratic.InOut);
        const endTween = new Tween({ alpha: 0.5 }).to({ alpha: 1 }, 100).easing(TWEEN.Easing.Quadratic.InOut);
        startTween.chain(endTween);
        endTween.chain(startTween);
        startTween.onUpdate(({ alpha }) => {
          this.activeTweens[entity][2] = alpha;
        });
        endTween.onUpdate(({ alpha }) => {
          this.activeTweens[entity][2] = alpha;
        });
        this.activeTweens[entity] = [startTween, endTween, 1];
        startTween.start(gameModel.timeElapsed);
      }

      const tweens = this.activeTweens[entity];
      tweens[0].update(gameModel.timeElapsed);
      tweens[1].update(gameModel.timeElapsed);

      const spriteData = gameModel.getTypedUnsafe(entity, SpriteSchema);
      spriteData.opacity = tweens[2];
    } else if (this.activeTweens[entity]) {
      this.activeTweens[entity][0].stop();
      this.activeTweens[entity][1].stop();
      delete this.activeTweens[entity];
      const spriteData = gameModel.getTypedUnsafe(entity, SpriteSchema);

      spriteData.opacity = 1;
    }
  }
}

registerSystem(GlobalIframeSystem);
registerSystem(GlobalIframeDrawSystem);

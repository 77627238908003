import { System } from "yage/components/System";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { HealthSchema, getMaxHealth } from "../core";
import { PlayerTypeSchema, EnemyTypeSchema } from "yage/components/entity/Types";
import { ChildSchema } from "yage/schemas/entity/Child";
import { registerSystem } from "yage/components/ComponentRegistry";

@Component("SetHpStart")
export class SetHpStartSchema extends Schema {
  @type("number")
  @defaultValue(0)
  health: number;

  @type("number")
  @defaultValue(0)
  healthScale: number;
}

class SetHpStartSystem implements System {
  schema = SetHpStartSchema;
  type = "SetHpStart";

  dependencies = ["Child", "HpEnhancer"];

  init(entity: number, gameModel: any) {
    const data = gameModel.getTypedUnsafe(entity, SetHpStartSchema);
    let affectedEntity = entity;
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      affectedEntity = parent;
    }
    HealthSchema.id = affectedEntity;
    if (data.health) {
      HealthSchema.health = data.health;
    } else if (data.healthScale) {
      HealthSchema.health = data.healthScale * getMaxHealth(affectedEntity, gameModel);
    }
  }
}

registerSystem(SetHpStartSystem);

import { DEPTHS, registerPixiComponent, registerSystem } from "yage/components/ComponentRegistry";
import type { PixiDrawSystem } from "yage/components/PixiDrawSystem";
import type { System } from "yage/components/System";
import { ComponentCategory } from "yage/components/types";
import { type, Component, Schema, defaultValue, required } from "yage/decorators/type";
import type { GameModel } from "yage/game/GameModel";
import {
  distanceVector2d,
  lengthVector2d,
  normalizeVector2d,
  scaleVector2d,
  subtractVector2d,
} from "yage/utils/vector";
import * as PIXI from "pixi.js";
import { LocomotionSchema } from "yage/schemas/entity/Locomotion";
import { RadiusSchema } from "yage/schemas/entity/Radius";
import { TransformSchema } from "yage/schemas/entity/Transform";
import { Viewport } from "pixi-viewport";
import { closestEntity } from "yage/utils/Collision";
import { getSpeed } from "../enhancers/SpeedEnhancer";

@Component("MoveRandom")
export class MoveRandomSchema extends Schema {
  @type("number")
  @defaultValue(2000)
  repositionTime: number;

  @type("number")
  @defaultValue(0)
  repositionTimer: number;
}

class MoveRandomSystem implements System {
  type = "MoveRandom";
  category: ComponentCategory = ComponentCategory.TARGET;
  schema = MoveRandomSchema;
  depth = DEPTHS.LOCOMOTION + 10;

  init(entity: number, gameModel: GameModel): void {
    const MoveRandom = gameModel.getTypedUnsafe(entity, MoveRandomSchema);
    MoveRandom.repositionTimer = MoveRandom.repositionTime;
  }

  runAll?(gameModel: GameModel): void {
    const entities = gameModel.getComponentActives("MoveRandom");

    for (let i = 0; i < entities.length; i++) {
      const entity = entities[i];
      const MoveRandom = gameModel.getTypedUnsafe(entity, MoveRandomSchema);

      if (MoveRandom.repositionTimer <= MoveRandom.repositionTime) {
        MoveRandom.repositionTimer += gameModel.dt<number>(entity);
        continue;
      }

      TransformSchema.id = entity;
      const position = TransformSchema.position;

      MoveRandom.repositionTimer = 0;

      const randomX = gameModel.rand.int(-30, 30);
      const randomY = gameModel.rand.int(-30, 30);
      const targetPosition = { x: position.x + randomX, y: position.y + randomY };
      LocomotionSchema.id = entity;

      const direction = normalizeVector2d(subtractVector2d(targetPosition, position));
      const speed = getSpeed(entity, gameModel);

      const velocity = scaleVector2d(direction, speed);
      LocomotionSchema.velocityX = velocity.x;
      LocomotionSchema.velocityY = velocity.y;
      LocomotionSchema.directionX = direction.x;
      LocomotionSchema.directionY = direction.y;
    }
  }
}

registerSystem(MoveRandomSystem);

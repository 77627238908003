import { registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { PlayerTypeSchema, EnemyTypeSchema } from "yage/components/entity/Types";
import { ComponentCategory, ComponentDataSchema } from "yage/components/types";
import { Component, defaultValue, Schema, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";

@Component("BurnSpreader")
export class BurnSpreaderSchema extends Schema {
  @type("number")
  @defaultValue(0)
  spread: number;

  @type(ComponentDataSchema)
  onHit: ComponentDataSchema;
}

class BurnSpreaderSystem implements System {
  schema = BurnSpreaderSchema;
  type = "BurnSpreader";
  dependencies = ["Child"];

  category = ComponentCategory.ONHIT_MOD;

  init(entity: number, gameModel: GameModel) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      gameModel.addIfMissing(parent, BurnSpreaderSchema);
      gameModel.getTypedUnsafe(parent, BurnSpreaderSchema).spread += gameModel.getTypedUnsafe(
        entity,
        BurnSpreaderSchema
      ).spread;
    }
  }

  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, BurnSpreaderSchema);

    if (data.onHit.type === "FireDamageOnHit") {
      data.onHit.data.spreadDuration = (data.onHit.data.spreadDuration ?? 0) + data.spread;
    }
  }
}
registerSystem(BurnSpreaderSystem);

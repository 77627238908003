import { DEPTHS, registerPixiComponent, registerSystem } from "yage/components/ComponentRegistry";
import type { System } from "yage/components/System";
import { ComponentCategory } from "yage/components/types";
import { Component, defaultValue, Schema, type } from "yage/decorators/type";
import type { GameModel } from "yage/game/GameModel";
import { LocomotionSchema } from "yage/schemas/entity/Locomotion";
import { RigidBoxSchema } from "yage/schemas/physics/RigidBox";
import type { Vector2d } from "yage/utils/vector";
import { Vector2dSchema } from "yage/utils/vector";
import { directionFromTarget } from "../../utils/weapons";
import { DamageTypeEnum, StabStateEnum } from "yage/constants/enums";
import { TransformSchema } from "yage/schemas/entity/Transform";
import { AttackCooldownSchema } from "./AttackCooldown";
import { EnemyCollidersSchema } from "../enemy/EnemyColliders";
import { closestEntity } from "yage/utils/Collision";
import { DamageApplierSchema } from "../damage/DamageApplier";
import { TargetingSchema } from "./Targeting";
import { OwnerSchema } from "yage/schemas/core/Owner";
import { RigidCircleSchema } from "yage/schemas/physics/RigidCircle";
import { PixiDrawSystem } from "yage/components/PixiDrawSystem";
import * as PIXI from "pixi.js";
import { Viewport } from "pixi-viewport";
@Component("Stab")
export class StabSchema extends Schema {
  @type("number")
  @defaultValue(100)
  stabLengthMs: number;

  @type("number")
  @defaultValue(200)
  returnLengthMs: number;

  @type("number")
  @defaultValue(0)
  currentStabDistance: number;

  @type("number")
  @defaultValue(StabStateEnum.NONE)
  state: StabStateEnum;

  @type(Vector2dSchema)
  stabDirection: Vector2d;

  @type("number")
  @defaultValue(0)
  range: number;

  @type("boolean")
  @defaultValue(true)
  enemyInRange: boolean;

  @type("boolean")
  @defaultValue(false)
  collideOnReturn: boolean;
}

const calculateStabRange = (entity: number, gameModel: GameModel) => {
  const stabData = gameModel.getTypedUnsafe(entity, StabSchema);
  const damages = gameModel.getTypedUnsafe(entity, DamageApplierSchema).damages;
  const damage = damages.find((d) => d.damageType === DamageTypeEnum.NORMAL) ?? damages[0];
  const range = (stabData.range + (damage?.damageStats.range ?? 0) / 2) * 2;
  return range;
};

class StabSystem implements System {
  type = "Stab";
  category: ComponentCategory = ComponentCategory.RENDERING;
  schema = StabSchema;
  depth = DEPTHS.COLLISION - 1;
  run(entity: number, gameModel: GameModel) {
    const stabData = gameModel.getTypedUnsafe(entity, StabSchema);
    const cooldown = gameModel.getTypedUnsafe(entity, AttackCooldownSchema);
    gameModel.getTypedUnsafe(entity, DamageApplierSchema).damageDelay = stabData.stabLengthMs + stabData.returnLengthMs;
    const damages = gameModel.getTypedUnsafe(entity, DamageApplierSchema).damages;
    const damage = damages.find((d) => d.damageType === DamageTypeEnum.NORMAL) ?? damages[0];

    // const range = (stabData.range + (damage?.damageStats.range ?? 100) / 100) * 2;
    const range = calculateStabRange(entity, gameModel);

    if (!cooldown) {
      return;
    }
    LocomotionSchema.id = entity;
    if (stabData.state === StabStateEnum.STAB) {
      stabData.currentStabDistance += (gameModel.dt<number>(entity) / stabData.stabLengthMs) * range;
      if (stabData.currentStabDistance >= range) {
        stabData.state = StabStateEnum.RETURN;
        stabData.currentStabDistance = range;
        gameModel.getTypedUnsafe(entity, TargetingSchema).target = -1;
      }
    } else if (stabData.state === StabStateEnum.RETURN) {
      stabData.currentStabDistance -= (gameModel.dt<number>(entity) / stabData.returnLengthMs) * range;
      if (stabData.currentStabDistance <= 0) {
        stabData.currentStabDistance = 0;
        stabData.state = StabStateEnum.NONE;
        stabData.currentStabDistance = 0;
      }
    } else if (cooldown.ready) {
      if (stabData.enemyInRange) {
        const damageables = gameModel.getComponentActives("Damageable");

        const enemies = gameModel.getTypedUnsafe(entity, EnemyCollidersSchema).colliders;
        const allEnemies = gameModel.getEntities(enemies).filter((e) => damageables.includes(e));

        TransformSchema.id = entity;
        const closest = closestEntity(gameModel, TransformSchema.position, allEnemies, range);
        if (closest !== undefined) {
          cooldown.ready = false;
          stabData.state = StabStateEnum.STAB;
          gameModel.getTypedUnsafe(entity, TargetingSchema).target = closest;
          const targetDirection = directionFromTarget(entity, gameModel, stabData.stabDirection);
          stabData.stabDirection = { x: targetDirection[0], y: targetDirection[1] };
        }
      } else {
        cooldown.ready = false;
        stabData.state = StabStateEnum.STAB;
      }
    }

    if (gameModel.hasComponent(entity, RigidBoxSchema)) {
      const rigidBox = gameModel.getTypedUnsafe(entity, RigidBoxSchema);
      if (stabData.collideOnReturn) {
        rigidBox.disabled = stabData.state === StabStateEnum.NONE;
      } else {
        rigidBox.disabled = stabData.state !== StabStateEnum.STAB;
      }
    } else if (gameModel.hasComponent(entity, RigidCircleSchema)) {
      const rigidCircle = gameModel.getTypedUnsafe(entity, RigidCircleSchema);
      if (stabData.collideOnReturn) {
        rigidCircle.disabled = stabData.state === StabStateEnum.NONE;
      } else {
        rigidCircle.disabled = stabData.state !== StabStateEnum.STAB;
      }
    }

    if (stabData.state !== StabStateEnum.NONE) {
      TransformSchema.id = entity;
      TransformSchema.y += stabData.currentStabDistance * stabData.stabDirection.y;
      TransformSchema.x += stabData.currentStabDistance * stabData.stabDirection.x;
      LocomotionSchema.directionX = stabData.stabDirection.x;
      LocomotionSchema.directionY = stabData.stabDirection.y;
    } else {
      LocomotionSchema.directionX =
        LocomotionSchema.store.directionX[gameModel.getTypedUnsafe(entity, OwnerSchema).owner!] || 1;
      LocomotionSchema.directionY = 0;
    }
  }
}

registerSystem(StabSystem);

class DebugStabDraw implements PixiDrawSystem {
  ids: Set<number> = new Set();
  entities: {
    [id: number]: { container: PIXI.Container; radiusGraphic: PIXI.Graphics };
  } = {};
  debug = true;

  init(entity: number, gameModel: GameModel, viewport: Viewport) {
    const container = new PIXI.Container();
    container.zIndex = 100;

    const radius = 10;
    const radiusGraphic = new PIXI.Graphics();
    radiusGraphic.lineStyle(5, 0xff0000);
    radiusGraphic.drawCircle(0, 0, radius);

    container.addChild(radiusGraphic as any);
    container.zIndex = 100000;

    const entityObj: any = {
      container,
      radiusGraphic,
    };

    viewport.addChild(container as any);
    this.entities[entity] = entityObj;
    this.ids.add(entity);
  }

  run(entity: number, gameModel: GameModel) {
    const stabData = gameModel.getTypedUnsafe(entity, StabSchema);

    const transformSchema = gameModel.getTypedUnsafe(entity, TransformSchema);
    const entityPosition = transformSchema.position;
    if (stabData.state !== StabStateEnum.NONE) {
      entityPosition.x -= stabData.currentStabDistance * stabData.stabDirection.x;
      entityPosition.y -= stabData.currentStabDistance * stabData.stabDirection.y;
    }
    const container = this.entities[entity].container;
    container.position.set(entityPosition.x, entityPosition.y);

    const cooldown = gameModel.getTypedUnsafe(entity, AttackCooldownSchema);
    const range = calculateStabRange(entity, gameModel);

    const radiusGraphic = this.entities[entity].radiusGraphic;
    radiusGraphic.clear();

    radiusGraphic.lineStyle(5, 0xff0000);
    radiusGraphic.drawCircle(0, 0, range);
  }

  cleanup(entity: number) {
    if (!this.entities[entity]) {
      return;
    }
    const container = this.entities[entity].container;
    container.children.forEach((child) => {
      container.removeChild(child);
      child.destroy();
    });

    container.destroy();
    delete this.entities[entity];
    this.ids.delete(entity);
  }
}

registerPixiComponent("Stab", DebugStabDraw);

import { Component, Schema, defaultValue, required, type } from "yage/decorators/type";
import { System } from "yage/components/System";
import { ComponentCategory } from "yage/constants/enums";
import { GameModel } from "yage/game/GameModel";
import { registerSystem } from "yage/components/ComponentRegistry";
import { ExpSchema, ExpSystem } from "../core/Exp";
import { ChildSchema } from "yage/schemas/entity/Child";
import { getEntityController } from "../../utils/playerCharacter";
import { InventorySchema } from "../player/Inventory";

@Component("GainMaterialForActiveEntitiesOnRoundEnd")
export class GainMaterialForActiveEntitiesOnRoundEndSchema extends Schema {
  @type("number")
  @defaultValue(0.65)
  amount: number;

  @type("string")
  @required()
  componentType: string;
}

class GainMaterialForActiveEntitiesOnRoundEndSystem implements System {
  schema = GainMaterialForActiveEntitiesOnRoundEndSchema;
  type = "GainMaterialForActiveEntitiesOnRoundEnd";
  category = ComponentCategory.ON_END;

  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, GainMaterialForActiveEntitiesOnRoundEndSchema);

    const enemies = gameModel.getComponentActives(data.componentType);
    const material = enemies.length * data.amount;

    const exp = gameModel.getSystem(ExpSystem);
    const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
    const controller = getEntityController(parent, gameModel);

    const expData = gameModel.getTypedUnsafe(controller, ExpSchema);
    expData.pickupData = {
      owner: controller,
      inventoryType: "coins",
      carried: false,
      inventorySpriteKey: "",
      pickupSound: "",
      destroyOnPickup: false,
      share: false,
    };
    for (let i = 0; i < material; i++) {
      exp.run(controller, gameModel);
    }
    gameModel.getTypedUnsafe(controller, InventorySchema).inventory.coins.amount += Math.floor(material);
  }
}

registerSystem(GainMaterialForActiveEntitiesOnRoundEndSystem);

import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { DamageStatsSchema, invalidateDamageStats } from "../weapons/DamageStats";
import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";

@Component("ScaleStatsTogether")
export class ScaleStatsTogetherSchema extends Schema {
  @type("string")
  scalingStat: string;

  @type("string")
  scalingComponentType: string;

  @type("string")
  sourceStat: string;

  @type("string")
  sourceComponentType: string;

  @type("number")
  @defaultValue(1)
  scale: number;

  @type("number")
  @defaultValue(0)
  currentSourceValue: number;
}

class ScaleStatsTogetherSystem implements System {
  schema = ScaleStatsTogetherSchema;
  type = "ScaleStatsTogether";
  depth = DEPTHS.ITEMS + 1;

  run(entity: number, gameModel: GameModel) {
    const data = gameModel.getTypedUnsafe(entity, ScaleStatsTogetherSchema);
    const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
    if (!gameModel.hasComponent(parent, data.sourceComponentType)) {
      return;
    }

    const statValue = gameModel.getComponentUnsafe(parent, data.sourceComponentType)[data.sourceStat] as number;
    if (data.currentSourceValue !== statValue) {
      const stats =
        data.scalingComponentType === "DamageStats"
          ? gameModel.getTypedUnsafe(parent, DamageStatsSchema).initialDamageStats
          : gameModel.getComponentUnsafe(parent, data.scalingComponentType);

      const previousStatValue = data.currentSourceValue * data.scale;

      if (data.scalingStat.startsWith("min")) {
        const maxStat = "max" + data.scalingStat.substring(3);
        // @ts-ignore
        stats[maxStat] = stats[maxStat] + statValue * data.scale - previousStatValue;
      }

      // @ts-ignore
      stats[data.scalingStat] = stats[data.scalingStat] + statValue * data.scale - previousStatValue;

      invalidateDamageStats(parent, gameModel);

      data.currentSourceValue = statValue;
    }
  }
}

registerSystem(ScaleStatsTogetherSystem);

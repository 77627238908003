import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { ComponentCategory, EntityType } from "yage/constants/enums";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { CollisionsSchema } from "yage/schemas/physics/Collisions";
import { SpriteSchema } from "yage/schemas/render/Sprite";

@Component("ProximitySensor")
class ProximitySensorSchema extends Schema {
  @type("boolean")
  @defaultValue(false)
  armed: boolean;

  @type("boolean")
  @defaultValue(false)
  armOnPlayer: boolean;
}

class ProximitySensorSystem implements System {
  schema = ProximitySensorSchema;
  type = "ProximitySensor";
  depth = DEPTHS.COLLISION + 100;

  playerColliding(entities: number[], gameModel: GameModel) {
    for (let i = 0; i < entities.length; i++) {
      if (gameModel.hasComponent(entities[i], "PlayerType")) {
        return true;
      }
    }
    return false;
  }

  run(entity: number, gameModel: GameModel) {
    const collisionData = gameModel.getTyped(gameModel.coreEntity, CollisionsSchema)?.collisions[entity]?.filters ?? {};
    const proximitySensorData = gameModel.getTypedUnsafe(entity, ProximitySensorSchema);
    if (proximitySensorData.armed) {
      if (!proximitySensorData.armOnPlayer || !this.playerColliding(collisionData[EntityType.ALLY] ?? [], gameModel)) {
        const modIds = gameModel.getComponentIdsByCategory(entity, ComponentCategory.TRIGGER);
        modIds.forEach((modId) => {
          const system: System = gameModel.getSystem(modId);
          system.run?.(entity, gameModel);
        });

        gameModel.removeEntity(entity);
      }
    } else {
      if (
        (proximitySensorData.armOnPlayer && this.playerColliding(collisionData[EntityType.ALLY] ?? [], gameModel)) ||
        collisionData[EntityType.ENEMY]?.length
      ) {
        proximitySensorData.armed = true;
        const spriteData = gameModel.getTypedUnsafe(entity, SpriteSchema);
        spriteData.frame = 1;
      }
    }
  }
}

registerSystem(ProximitySensorSystem);

import { registerSchema, registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { PlayerTypeSchema, EnemyTypeSchema } from "yage/components/entity/Types";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { GameModel } from "yage/game/GameModel";
import { ChildSchema } from "yage/schemas/entity/Child";

@Component("AutoPickupChance")
export class AutoPickupChanceSchema extends Schema {
  @type("number")
  @defaultValue(0)
  chance: number;
}

export class AutoPickupChanceSystem implements System {
  schema = AutoPickupChanceSchema;
  type = "AutoPickupChance";
  dependencies = ["Child"];
  init(entity: number, gameModel: GameModel) {
    if (
      !gameModel.hasComponent(entity, PlayerTypeSchema) &&
      !gameModel.hasComponent(entity, EnemyTypeSchema) &&
      gameModel.hasComponent(entity, ChildSchema)
    ) {
      const parent = gameModel.getTypedUnsafe(entity, ChildSchema).parent!;
      if (!gameModel.hasComponent(parent, "AutoPickupChance")) {
        gameModel.addComponent(parent, AutoPickupChanceSchema);
      }
      gameModel.getTypedUnsafe(parent, AutoPickupChanceSchema).chance += gameModel.getTypedUnsafe(
        entity,
        AutoPickupChanceSchema
      ).chance;
    }
  }
}

registerSystem(AutoPickupChanceSystem);

import { DEPTHS, registerSystem } from "yage/components/ComponentRegistry";
import { System } from "yage/components/System";
import { EntityType, DamageDirectionEnum } from "yage/constants/enums";
import { Component, Schema, defaultValue, type } from "yage/decorators/type";
import { EntityFactory } from "yage/entity/EntityFactory";
import { GameModel } from "yage/game/GameModel";
import { getWeaponAugmentComponents, applyComponentsToProjectile } from "../../utils/weapons";
import { EnemyCollidersSchema } from "../enemy/EnemyColliders";
import { findSpawnNearEntity } from "../../utils/spawn";
import { cloneDeep } from "lodash";
import DescriptionSchema from "yage/schemas/core/Description";

@Component("EntityPlacer")
export class EntityPlacerSchema extends Schema {
  @type(["string"])
  @defaultValue([])
  entityDescriptions: string[];

  @type(["number"])
  @defaultValue([])
  entityInstances: number[];
}

class EntityPlacerSystem implements System {
  schema = EntityPlacerSchema;
  type = "EntityPlacer";
  depth = DEPTHS.ITEMS;

  run(entity: number, gameModel: GameModel) {
    const entityPlacerData = gameModel.getTypedUnsafe(entity, EntityPlacerSchema);

    if (entityPlacerData.entityDescriptions.length !== entityPlacerData.entityInstances.length) {
      const entityInstances = cloneDeep(entityPlacerData.entityInstances);
      const missingEntities = entityPlacerData.entityDescriptions.filter((turret) => {
        for (let i = 0; i < entityInstances.length; i++) {
          if (gameModel.getTypedUnsafe(entityInstances[i], DescriptionSchema).description === turret) {
            entityInstances.splice(i, 1);
            return false;
          }
        }
        return true;
      });
      for (let i = 0; i < missingEntities.length; i++) {
        this.spawnEntity(missingEntities[i], entity, entityPlacerData, entity, gameModel);
      }
    }
  }

  spawnEntity(
    entityDescription: string,
    entity: number,
    data: EntityPlacerSchema,
    owner: number,
    gameModel: GameModel
  ) {
    const [augmentComponents, auraComponents] = getWeaponAugmentComponents(entity, owner, gameModel);
    const position = findSpawnNearEntity(gameModel, owner, 200, 500);
    const projectile = EntityFactory.getInstance().generateEntity(gameModel, entityDescription, {
      Transform: { ...position },
      colliders: EntityType.ENEMY,
      Owner: { owner },
      Child: {
        parent: owner,
      },
    });

    data.entityInstances.push(projectile);

    applyComponentsToProjectile(projectile, DamageDirectionEnum.OWNER, augmentComponents, auraComponents, gameModel);
  }

  cleanup(entity: number, gameModel: GameModel, ejecting: boolean) {
    const entityPlacerData = gameModel.getTypedUnsafe(entity, EntityPlacerSchema);
    for (const mine of entityPlacerData.entityInstances) {
      gameModel.removeEntity(mine);
    }
    entityPlacerData.entityInstances = [];
  }
}

registerSystem(EntityPlacerSystem);

import { HtmlSchema } from "../components/core/Shop";

const styleElement = document.createElement("span");

export const HtmlSchemaToHtml = (schema: HtmlSchema): string => {
  const children = schema.children?.map((child) => HtmlSchemaToHtml(child)).join("") ?? "";
  let style = "";
  if (typeof schema.style === "object") {
    styleElement.style.cssText = "";
    Object.assign(styleElement.style, schema.style);

    style = styleElement.style.cssText;
  } else {
    style = schema.style ?? "";
  }
  const res = `<${schema.tag} style="${style}">${schema.innerText ?? ""}${children}</${schema.tag}>`;
  return res;
};
